import { put, takeLatest } from "redux-saga/effects"
import APIPromises from "../../../util/APIPromises"
import {
  retrieveCalibrationMonthEquipmentDetailsFailure,
  retrieveCalibrationMonthEquipmentDetailsSuccess,
  retrieveCalibrationMonthSummaryFailure,
  retrieveCalibrationMonthSummarySuccess,
} from "../action"

function* retrieveCalibrationMonthSummary(req) {
  try {
    const res = yield APIPromises.retrieveCalibrationMonthSummary(req.payload)
    yield put(retrieveCalibrationMonthSummarySuccess(res.data.summary))
  } catch (err) {
    yield put(retrieveCalibrationMonthSummaryFailure(err))
  }
}

export function* retrieveCalibrationMonthSummaryWatcher() {
  yield takeLatest(
    "RETRIEVE_CALIBRATION_MONTH_SUMMARY",
    retrieveCalibrationMonthSummary
  )
}

function* retrieveCalibrationMonthEquipmentDetails(req) {
  try {
    const res = yield APIPromises.retrieveCalibrationMonthEquipmentDetails(
      req.payload
    )
    yield put(
      retrieveCalibrationMonthEquipmentDetailsSuccess(res.data.equipmentDetails)
    )
  } catch (err) {
    yield put(retrieveCalibrationMonthEquipmentDetailsFailure(err))
  }
}

export function* retrieveCalibrationMonthEquipmentDetailsWatcher() {
  yield takeLatest(
    "RETRIEVE_CALIBRATION_MONTH_EQUIPMENT_DETAILS",
    retrieveCalibrationMonthEquipmentDetails
  )
}
