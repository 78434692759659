import React, { useState, useEffect } from "react"
// import {useHistory} from "react-router-dom";
import Row from "@amzn/meridian/row"
// import Checkbox from "@amzn/meridian/checkbox";
// import Column from "@amzn/meridian/column"
import Button from "@amzn/meridian/button"
// import Column from "@amzn/meridian/column"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import Column from "@amzn/meridian/column"
import Checkbox from "@amzn/meridian/checkbox"
import { useDispatch, useSelector } from "react-redux"

import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import {
  retrieveCalibratedEquips,
  selectEquipIds,
} from "../../../redux/project/action"

const SelectEquipmentModal = props => {
  const [checkedIds, setCheckedIds] = useState([])
  const calibrated = useSelector(state => state.projectReducer.calibrated)
  const reduxDispatch = useDispatch()
  const {
    selectEquipmentModalOpen,
    setSelectEquipmentModalOpen,
    setUploadSelfModalOpen,
    currentData,
  } = props
  useEffect(() => {
    if (selectEquipmentModalOpen) {
      reduxDispatch(
        retrieveCalibratedEquips(currentData.cm, currentData.cmLocation)
      )
    }
  }, [selectEquipmentModalOpen, reduxDispatch, currentData])

  const resetState = () => {
    setCheckedIds([])
    setSelectEquipmentModalOpen(false)
  }

  return (
    <Modal
      title="Select Equipment"
      open={selectEquipmentModalOpen}
      onClose={() => resetState()}
      // scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
      width="100%"
      minHeight="120px"
      scrollContainer="modal"
    >
      <form>
        <Row spacingInset="small none">
          <Column>
            <Table minWidth={1740} headerRows={1} showDividers={true}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Equipment Name</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
              {calibrated &&
                calibrated.map((row, index) => (
                  <TableRow key={row.equipName} highlightOnHover={true}>
                    <TableCell>
                      <Checkbox
                        checked={checkedIds.find(i => i === row.equipId) > 0}
                        onChange={checked => {
                          let newCheckedIds = [...checkedIds]
                          if (checked) {
                            newCheckedIds.push(row.equipId)
                          } else {
                            newCheckedIds = checkedIds.filter(
                              id => id !== row.equipId
                            )
                          }
                          setCheckedIds(newCheckedIds)
                          reduxDispatch(selectEquipIds(newCheckedIds))
                        }}
                      ></Checkbox>
                    </TableCell>
                    <TableCell>{row.equipName}</TableCell>
                    <TableCell>{row.calibratedStatus}</TableCell>
                  </TableRow>
                ))}
            </Table>
          </Column>
        </Row>
        <ModalFooter>
          <Row alignmentHorizontal="right" widths="fit">
            <Button type="secondary" size="small" onClick={() => resetState()}>
              Cancel
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                resetState()
                setUploadSelfModalOpen(true)
              }}
            >
              Next &#62;
            </Button>
          </Row>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default SelectEquipmentModal
