/**
 * A component that uses react-router to render the current application screen
 * based on the URL.
 */

import React from "react"
import { Switch, Route } from "react-router-dom"
// Screens
// import Landing from "../screens/landing"
// Context
// Metrics
// import {publishCounter} from "../metrics"
import Dashboard from "../screens/dashboard/dashboard"
import DashboardDetails from "../screens/dashboard/dashboard-details"
import FileLibrary from "../screens/fileLibrary/file-library"
import EquipmentList from "../screens/equipment/equipment-list"
import EquipmentDetails from "../screens/equipment/equipment-details"
import ProjectStatus from "../screens/project/project-status"
import AuditReport from "../screens/audit/audit-report"
import AuditReport2 from "../screens/audit/audit-report-2"
import Settings from "../screens/settings/settings"
import Users from "../screens/labManagement/subPages/users"
import TestLocation from "../screens/labManagement/subPages/test-location"
import CapabilityTemplate from "../screens/labManagement/subPages/capability-template"
import Requirements from "../screens/labManagement/subPages/requirements"

import { FileViewPreferencesProvider } from "../hooks/use-file-preference"
import { EquipmentViewPreferencesProvider } from "../hooks/use-equipment-prefernece"
import { FiltersContextProvider } from "../hooks/use-filters"
import PerformanceSummary from "../screens/performance/performance-summary"
import CalibrationTool from "../screens/labManagement/subPages/calibration-tool"
import LabPersonnel from "../screens/labManagement/subPages/lab-personnel"
import AuditReportDetails from "../screens/audit/audit-report-details"
import ConsumableList from "../screens/labManagement/subPages/consumable-list"
import ConsumableDetails from "../screens/labManagement/subPages/consumable-details"
import UserActivity from "../screens/labManagement/subPages/user-activity"
import StatusChecklist from "../screens/labManagement/subPages/status-checklist"
// Every time a user loads our page and
// or changes route we publish a counter
// with the pathname so we can track it with Katal.
// const usePageViews = () => {
//     let location = useLocation()
//     useEffect(() => {
//         publishCounter(location.pathname, "hits")
//         publishCounter("All", "hits")
//     }, [location])
// }

const CurrentScreen = () => {
  // Publish counter for the new session
  // and hit on initial page load. We only
  // want this to happen once, which is
  // why we pass an empty array
  // as the second argument.
  // useEffect(() => {
  //     publishCounter("All", "sessions")
  // }, [])

  // usePageViews()
  return (
    <Switch>
      <Route path="/dashboard">
        <Dashboard />
      </Route>
      <Route path="/dashboard-details">
        <DashboardDetails />
      </Route>
      <Route path="/file-library">
        <FileViewPreferencesProvider>
          <FiltersContextProvider>
            <FileLibrary />
          </FiltersContextProvider>
        </FileViewPreferencesProvider>
      </Route>
      <Route path="/equipment-list">
        <EquipmentViewPreferencesProvider>
          <FiltersContextProvider>
            <EquipmentList />
          </FiltersContextProvider>
        </EquipmentViewPreferencesProvider>
      </Route>
      <Route path="/equipment-details">
        <EquipmentDetails />
      </Route>
      <Route path="/project-status">
        <FiltersContextProvider>
          <ProjectStatus />
        </FiltersContextProvider>
      </Route>
      {/*<Route path="/report/audit-report">*/}
      {/*  <AuditReport />*/}
      {/*</Route>*/}
      <Route path="/report/audit-report">
        <FiltersContextProvider>
          <AuditReport2 />
        </FiltersContextProvider>
      </Route>
      <Route path="/report/audit-report-details">
        <FiltersContextProvider>
          <AuditReportDetails />
        </FiltersContextProvider>
      </Route>
      <Route path="/report/performance">
        <FiltersContextProvider>
          <PerformanceSummary />
        </FiltersContextProvider>
      </Route>
      <Route path="/settings">
        <Settings />
      </Route>
      <Route exact path="/lab-management/test-location">
        <TestLocation />
      </Route>
      <Route exact path="/lab-management/users">
        <Users />
      </Route>
      <Route exact path="/lab-management/user-activity">
        <UserActivity />
      </Route>
      <Route exact path="/lab-management/capability-template">
        <CapabilityTemplate />
      </Route>
      <Route exact path="/lab-management/calibration-tool">
        <CalibrationTool />
      </Route>
      <Route exact path="/lab-management/lab-personnel">
        <LabPersonnel />
      </Route>
      <Route exact path="/lab-management/consumable-list">
        <ConsumableList />
      </Route>
      <Route exact path="/lab-management/consumable-details">
        <ConsumableDetails />
      </Route>
      <Route exact path="/lab-management/requirements">
        <Requirements />
      </Route>
      <Route exact path="/lab-management/status-checklist">
        <StatusChecklist />
      </Route>
      <Route path="/">
        <Dashboard />
      </Route>
    </Switch>
  )
}

export default CurrentScreen
