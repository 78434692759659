import React, { useState, useEffect } from "react"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import { useDispatch } from "react-redux"
import { FormSelect, FormInput } from "../../../components/app"
import { addUser, updateUser } from "../../../redux/users/action"

const userTypeOptions = [
  {
    value: "Internal",
    label: "Internal",
  },
  {
    value: "External",
    label: "External",
  },
]

const internalUserGroupOptions = [
  {
    value: "CPI",
    label: "CPI",
  },
  {
    value: "ORT",
    label: "ORT",
  },
  {
    value: "CMM",
    label: "CMM",
  },
  {
    value: "Others",
    label: "Others",
  },
]

const externalUserGroupOptions = [
  {
    value: "CM",
    label: "CM",
  },
]

const AddUserModal = props => {
  const { modalOpen, setModalOpen, mode, currentData } = props
  const [user, setUser] = useState({})

  const getUserType = isCmUser => {
    if (isCmUser) {
      return "External"
    } else if (isCmUser === false) {
      return "Internal"
    } else {
      return null
    }
  }

  useEffect(() => {
    setUser(currentData)
  }, [currentData, setUser])

  const reduxDispatch = useDispatch()
  return (
    <Modal
      title={mode === "edit" ? "Edit User" : "Add User"}
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
    >
      <form>
        <FormInput
          value={user.name}
          setValue={value => setUser({ ...user, name: value })}
          label={"Name"}
        />
        <FormInput
          value={user.userName}
          setValue={value => setUser({ ...user, userName: value })}
          label={"Login"}
        />
        <FormInput
          value={user.email}
          setValue={value => setUser({ ...user, email: value })}
          label={"Email"}
        />
        <FormSelect
          value={getUserType(user.isCmUser)}
          setValue={value =>
            setUser({ ...user, isCmUser: value === "External" })
          }
          label={"Type"}
          options={userTypeOptions}
        />
        <FormSelect
          value={user.userGroup}
          disabled={user.isCmUser === undefined}
          setValue={value => setUser({ ...user, userGroup: value })}
          label={"Group"}
          options={
            user.isCmUser ? externalUserGroupOptions : internalUserGroupOptions
          }
        />
      </form>
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            disabled={!user.name || !user.email || !user.userGroup}
            type="primary"
            size="small"
            onClick={() => {
              if (mode === "add") {
                reduxDispatch(
                  addUser({
                    addUsersList: [user],
                  })
                )
              } else {
                reduxDispatch(
                  updateUser({
                    userId: user.id,
                    userManagedInfo: user,
                  })
                )
              }
              setModalOpen(false)
            }}
          >
            {mode === "edit" ? "Update" : "Add"}
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default AddUserModal
