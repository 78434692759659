/**
 * The application landing page/home page.
 */
import React, { useState, useEffect } from "react"
import * as xlsx from "xlsx"
// import Loader from "@amzn/meridian/loader"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import { useSelector, useDispatch } from "react-redux"
import Table, {
  TableRow,
  TableCell,
  TableActionBar,
} from "@amzn/meridian/table"
import Icon from "@amzn/meridian/icon"
import Link from "@amzn/meridian/link"
import Text from "@amzn/meridian/text"
import SearchField from "@amzn/meridian/search-field"
import { useHistory } from "react-router-dom"
import pencilTokens from "@amzn/meridian-tokens/base/icon/pencil"
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large"
import plusCircleTokens from "@amzn/meridian-tokens/base/icon/plus-circle"
import Tooltip from "@amzn/meridian/tooltip"
import deleteTokens from "@amzn/meridian-tokens/base/icon/delete"
import axios from "axios"
import qs from "qs"

import { usePagination, useSort } from "../../../hooks"

import PageLayout from "../../../components/app/page-layout"
import EmptyState from "../../../components/app/empty-state"
import { AddConsumableModal } from "../modals"

import {
  deleteConsumable,
  retrieveConsumable,
} from "../../../redux/equipmentDetail/action"
import { getRegex } from "../../../util/Util"

import * as auth from "../../../services/Auth/auth"
import env from "../../../services/Auth/env"
import { openToast } from "../../../api/toasts"

const EquipmentConsumableTab = props => {
  // const [checked, setChecked] = useState(false);
  const { equipmentBasicInfo, cm, testLocation } = props
  const { consumableList } = useSelector(state => state.equipmentDetailReducer)

  const [currentData, setCurrentData] = useState()
  // const [projectParam, setProjectParam] = useState()
  const reduxDispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  // for searching and filtering
  const [keyword, setKeyword] = useState("")
  const [addConsumableModalOpen, setAddConsumableModalOpen] = useState(false)
  const { isCMUser, isAdmin } = useSelector(state => state.userReducer)

  const data =
    consumableList?.filter(f => {
      let regex = getRegex(keyword)
      return regex.test(JSON.stringify(f))
    }) || []

  console.warn("data", data)

  const handleAddUser = () => {}

  useEffect(() => {
    reduxDispatch(
      retrieveConsumable({
        equipment_id: equipmentBasicInfo.equipmentId,
      })
    )
  }, [equipmentBasicInfo, reduxDispatch])

  const { sortedData, onSort, sortColumn, sortDirection } = useSort(data)

  const history = useHistory()
  const { TableFooter, visibleIndices } = usePagination(data.length)

  return (
    <div role="tabpanel" id="tabpanel-planets" aria-labelledby="tab-planets">
      <Row spacingInset="small none">
        <Column spacing="large">
          <Table
            headerRows={1}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={onSort}
            showDividers={true}
            spacing="small"
            // stickyHeaderRow={true}
            fixHeaderRows={true}
            // showStripes={true}
          >
            <TableActionBar widths={[300]}>
              <SearchField
                value={keyword}
                onChange={setKeyword}
                placeholder="Search for..."
                // size="medium"
                type="text"
                onSubmit={() => {}}
              />
              <Tooltip position="top" title="Add new user" id="myTooltip">
                <Button
                  type="icon"
                  disabled={!isAdmin && !isCMUser}
                  onClick={() => {
                    setAddConsumableModalOpen(true)
                  }}
                >
                  <Icon tokens={plusCircleTokens}>Save</Icon>
                </Button>
              </Tooltip>
            </TableActionBar>
            <TableRow>
              <TableCell sortColumn="name">Item Name</TableCell>
              <TableCell sortColumn="useNum">Usage Number</TableCell>
              <TableCell sortColumn="date">Date</TableCell>
              <TableCell sortColumn="comment">Comment</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
            {sortedData &&
              sortedData
                .slice(visibleIndices[0], visibleIndices[1])
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.useNum}</TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>{row.comment}</TableCell>
                    <TableCell>
                      <Text>
                        <Link
                          disabled={!isAdmin}
                          onClick={() => {
                            console.warn("delete")
                            setCurrentData(row)
                            reduxDispatch(
                              deleteConsumable({
                                consumableId: row.id,
                                equipmentId: equipmentBasicInfo.equipmentId,
                              })
                            )
                          }}
                        >
                          <Icon tokens={deleteTokens} />
                        </Link>
                      </Text>
                    </TableCell>
                  </TableRow>
                ))}
          </Table>
          {sortedData && sortedData.length === 0 ? (
            <EmptyState target={"consumable"} />
          ) : null}
          <TableFooter />
        </Column>
      </Row>

      <AddConsumableModal
        addConsumableModalOpen={addConsumableModalOpen}
        setAddConsumableModalOpen={setAddConsumableModalOpen}
        onAddUser={handleAddUser}
        cm={cm}
        testLocation={testLocation}
        equipmentId={equipmentBasicInfo.equipmentId}
      />
    </div>
  )
}

export default EquipmentConsumableTab
