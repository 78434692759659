/**
 * The side navigation for the application.
 */

import React, { useEffect, useCallback, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import SideMenu, { SideMenuLink } from "@amzn/meridian/side-menu"
import Icon from "@amzn/meridian/icon"
import Text from "@amzn/meridian/text"
import Row from "@amzn/meridian/row"
import dashboardTokens from "@amzn/meridian-tokens/base/icon/dashboard"
import folderTokens from "@amzn/meridian-tokens/base/icon/folder"
import bulbTokens from "@amzn/meridian-tokens/base/icon/bulb"
import gridTokens from "@amzn/meridian-tokens/base/icon/grid"
import viewTokens from "@amzn/meridian-tokens/base/icon/view"
import homeTokens from "@amzn/meridian-tokens/base/icon/home"

import { css } from "emotion"

import useBreakpoint from "@amzn/meridian/_use-breakpoint"
import useSideMenuOpen from "../../hooks/use-side-menu-open"

const paths = [
  { title: "Dashboard", href: "/dashboard", iconTokens: dashboardTokens },
  { title: "Project Status", href: "/project-status", iconTokens: gridTokens },
  { title: "Audit Report", href: "/audit-report", iconTokens: viewTokens },
  { title: "Equipment List", href: "/equipment-list", iconTokens: bulbTokens },
  { title: "File Library", href: "/file-library", iconTokens: folderTokens },
  { title: "Lab Management", href: "/lab-management", iconTokens: homeTokens },
]

const textStyles = css({
  textWrap: "nowrap",
})

const AppSideMenu = () => {
  const history = useHistory()
  const location = useLocation()
  const [open, setOpen] = useSideMenuOpen()
  const [, rootPath] = location.pathname.split("/")
  const breakpointState = useBreakpoint("600px")
  const mobile = breakpointState === "smaller"

  const [labManagmentGroupOpen, setLabManagmentGroupOpen] = useState(
    location.pathname.includes("/lab-management")
  )

  const [reportGroupOpen, setReportGroupOpen] = useState(
    location.pathname.includes("/report")
  )

  const { isCMUser } = useSelector(state => state.userReducer)

  const onMobileClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  // close all of side menus when hide the menu
  useEffect(() => {
    if (!open) {
      setLabManagmentGroupOpen(false)
      setReportGroupOpen(false)
    }
  }, [open, setLabManagmentGroupOpen, setReportGroupOpen])

  // When the browser resizes from desktop -> mobile, close the side menu
  // and vicaversa
  useEffect(() => {
    setOpen(!mobile)
  }, [mobile, setOpen])

  // On mobile, clicking on a link will close the menu
  const onClickLink = useCallback(
    href => {
      history.push(href)
      if (mobile) setOpen(false)
    },
    [mobile, history, setOpen]
  )

  return (
    <SideMenu
      open={open}
      type={mobile ? "overlay" : "skinny"}
      width="240px"
      onClose={mobile ? onMobileClose : undefined}
    >
      <SideMenuLink
        href="/dashboard"
        icon={dashboardTokens}
        onClick={onClickLink}
        selected={location.pathname === "/dashboard"}
      >
        Dashboard
      </SideMenuLink>
      <SideMenuLink
        icon={bulbTokens}
        href="/equipment-list"
        onClick={onClickLink}
        selected={location.pathname === "/equipment-list"}
      >
        Equipment List
      </SideMenuLink>
      <SideMenuLink
        icon={homeTokens}
        onClick={() => {
          setLabManagmentGroupOpen(!labManagmentGroupOpen)
          setOpen(true)
        }}
        open={labManagmentGroupOpen}
      >
        Lab Management
        <SideMenuLink
          href="/lab-management/calibration-tool"
          onClick={onClickLink}
          selected={location.pathname === "/lab-management/calibration-tool"}
          onEscape={() => setLabManagmentGroupOpen(false)}
        >
          <Text type="b200">● Calibration Tool</Text>
        </SideMenuLink>
        <SideMenuLink
          href="/lab-management/lab-personnel"
          onClick={onClickLink}
          selected={location.pathname === "/lab-management/lab-personnel"}
          onEscape={() => setLabManagmentGroupOpen(false)}
        >
          <Text type="b200">● Lab Personnel</Text>
        </SideMenuLink>
        <SideMenuLink
          href="/lab-management/consumable-list"
          onClick={onClickLink}
          selected={location.pathname === "/lab-management/consumable-list"}
          onEscape={() => setLabManagmentGroupOpen(false)}
        >
          <Text type="b200">● Consumable</Text>
        </SideMenuLink>
        <SideMenuLink
          href="/lab-management/requirements"
          onClick={onClickLink}
          selected={location.pathname === "/lab-management/requirements"}
          onEscape={() => setLabManagmentGroupOpen(false)}
        >
          <Text type="b200">● Requirements</Text>
        </SideMenuLink>
        {!isCMUser && (
          <SideMenuLink
            href="/lab-management/capability-template"
            onClick={onClickLink}
            selected={
              location.pathname === "/lab-management/capability-template"
            }
            onEscape={() => setLabManagmentGroupOpen(false)}
          >
            <Text className={textStyles} type="b200">
              ● Capability Template
            </Text>
          </SideMenuLink>
        )}
        {!isCMUser && (
          <SideMenuLink
            href="/lab-management/test-location"
            onClick={onClickLink}
            selected={location.pathname === "/lab-management/test-location"}
            onEscape={() => setLabManagmentGroupOpen(false)}
          >
            <Text type="b200">● Test Location</Text>
          </SideMenuLink>
        )}
        {!isCMUser && (
          <SideMenuLink
            href="/lab-management/users"
            onClick={onClickLink}
            selected={location.pathname === "/lab-management/users"}
            onEscape={() => setLabManagmentGroupOpen(false)}
          >
            <Text type="b200">● Users</Text>
          </SideMenuLink>
        )}
        <SideMenuLink
          href="/lab-management/status-checklist"
          onClick={onClickLink}
          selected={location.pathname === "/lab-management/status-checklist"}
          onEscape={() => setLabManagmentGroupOpen(false)}
        >
          <Text type="b200">● Status Checklist</Text>
        </SideMenuLink>
      </SideMenuLink>
      <SideMenuLink
        icon={viewTokens}
        onClick={() => {
          setOpen(true)
          setReportGroupOpen(!reportGroupOpen)
        }}
        open={reportGroupOpen}
      >
        Report
        <SideMenuLink
          href="/report/audit-report"
          onClick={onClickLink}
          selected={location.pathname === "/report/audit-report"}
        >
          <Text type="b200">● Audit Report</Text>
        </SideMenuLink>
        {!isCMUser && (
          <SideMenuLink
            href="/report/performance"
            onClick={onClickLink}
            selected={location.pathname === "/report/performance"}
          >
            <Text className={textStyles} type="b200">
              ● Performance Summary
            </Text>
          </SideMenuLink>
        )}
        {!isCMUser && (
          <SideMenuLink
            href="https://wiki.labcollab.net/confluence/display/RELIABILITY/CM+SBR+Score+Summary"
            target="_blank"
          >
            <Text className={textStyles} type="b200">
              ● CM SBR Score Summary
            </Text>
          </SideMenuLink>
        )}
      </SideMenuLink>

      <SideMenuLink
        icon={folderTokens}
        href="/file-library"
        onClick={onClickLink}
        selected={location.pathname === "/file-library"}
      >
        File Library
      </SideMenuLink>
    </SideMenu>
  )
}

export default AppSideMenu
