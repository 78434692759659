/**
 * The application landing page/home page.
 */
import React, { useState, useCallback, useEffect, useRef } from "react"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import Popover, { PopoverHeader } from "@amzn/meridian/popover"
import Link from "@amzn/meridian/link"
import Text from "@amzn/meridian/text"
import { useLocation } from "react-router-dom"
import CalendarMonthView from "@amzn/meridian/calendar-month-view"
import CalendarTimeframeSelect from "@amzn/meridian/calendar-timeframe-select"
import Tile, { TileActionBar } from "@amzn/meridian/tile"
import axios from "axios"
import qs from "qs"
import Thumbnail from "@amzn/meridian/thumbnail"
import format from "date-fns/format"

import ProgressTracker, {
  ProgressTrackerStep,
} from "@amzn/meridian/progress-tracker"

import PageLayout from "../../../components/app/page-layout"
import * as auth from "../../../services/Auth/auth"
import env from "../../../services/Auth/env"

const now = format(new Date(), "yyyy-MM-dd")

const CompactTile = ({ action, disabled, activities }) => {
  const buttonRef = useRef()
  const [open, setOpen] = useState(false)
  const onClickButton = () => setOpen(true)
  const onClose = () => setOpen(false)
  return (
    <Tile accent={"success"} disabled={disabled}>
      <Row alignmentHorizontal="justify">
        <Link
          onClick={onClickButton}
          ref={buttonRef}
          aria-haspopup="dialog"
          aria-expanded="false"
          type="text"
        >
          <Text>{action}</Text>
        </Link>
        <Popover
          anchorNode={buttonRef.current}
          open={open}
          onClose={onClose}
          position="bottom"
        >
          <PopoverHeader closeLabel="Close">Activities</PopoverHeader>
          <Column>
            {activities?.map((activity, i) => {
              return (
                // eslint-disable-next-line prettier/prettier
                <li key={i}>{activity.behavior}</li>
              )
            })}
          </Column>
        </Popover>
      </Row>
    </Tile>
  )
}

const UserActivity = () => {
  const [events, setEvents] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [date, setDate] = useState(now)

  const [timeline, setTimeline] = useState([])
  const [lastId, setlastId] = useState(0)
  const [isEnd, setIsEnd] = useState(false)

  const onClickNow = useCallback(() => setDate(now), [])

  const location = useLocation()

  const MonthDay = React.memo(
    ({ date }) => {
      const dayEvents = events[date]
      return dayEvents ? (
        <Column>
          {dayEvents.map((event, i) => {
            return <CompactTile key={i} {...event} />
          })}
        </Column>
      ) : null
    },
    (prevProps, nextProps) => prevProps.date === nextProps.date
  )

  useEffect(() => {
    const calendarQuery = {
      user: location.state.userId,
      year: new Date(date).getFullYear(),
      month: new Date(date).getMonth() + 1,
    }
    auth.getToken().then(cookie => {
      setIsLoading(true)
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrieveCalendarByUser +
          "?" +
          qs.stringify(calendarQuery),
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(response => {
          // const data = response.data.complianceStatusList
          setEvents(response?.data?.retrieveTimelineByUserResponse)
          setIsLoading(false)
        })
        .catch(error => {
          setIsLoading(false)
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  useEffect(() => {
    const timelineQuery = {
      user: location.state.userId,
      last_id: lastId,
    }
    auth.getToken().then(cookie => {
      setIsLoading(true)
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrieveTimelineByUser +
          "?" +
          qs.stringify(timelineQuery),
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(response => {
          const data = response.data.retrieveTimelineDetailsByUserResponse
          if (data?.length === 0) {
            setIsEnd(true)
          } else {
            setTimeline(timeline.concat(data))
          }
          setIsLoading(false)
        })
        .catch(error => {
          setIsLoading(false)
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastId])

  return (
    <React.Fragment>
      <PageLayout
        spacing="large"
        alignmentHorizontal={"start"}
        title={`User Activity - ${location.state.name}`}
        loading={isLoading}
        width={1600}
        breadcrumbs={[{ title: "Users", path: "/lab-management/users" }]}
      >
        <Row
          alignmentVertical="top"
          spacingInset="large none"
          widths={["65%", "35%"]}
        >
          <Column
            type="outline"
            heights={["fit", "fill"]}
            spacing="none"
            width="100%"
          >
            <Row
              spacingInset="400"
              alignmentHorizontal="justify"
              widths={[100, "fit", 100]}
            >
              <Button type="secondary" onClick={onClickNow}>
                This month
              </Button>
              <CalendarTimeframeSelect
                type="month"
                value={date}
                onChange={setDate}
              />
              <span />
            </Row>
            <CalendarMonthView viewDate={date} now={now} renderDay={MonthDay} />
          </Column>
          <Column>
            <ProgressTracker type="theme" direction="column">
              {timeline.map((t, index) => {
                return (
                  <ProgressTrackerStep
                    key={index}
                    type="future"
                    label={
                      <strong>
                        {format(new Date(t.timestamp), "yyyy-MM-dd hh:mm:ss")}
                      </strong>
                    }
                    trackLength={3}
                  >
                    <Row>
                      {!t.isCm ? (
                        <Thumbnail
                          source={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${t.user}`}
                          size="small"
                        />
                      ) : (
                        ""
                      )}
                      <Text type={"h50"}>
                        <Link>{t.name ? t.name : t.user}</Link>
                      </Text>
                      <Text type="b200">{t.behavior}</Text>
                    </Row>
                  </ProgressTrackerStep>
                )
              })}
            </ProgressTracker>
            {!isEnd && (
              <Button
                type="link"
                size="small"
                onClick={() => setlastId(timeline[0].id)}
              >
                Load more ...
              </Button>
            )}
          </Column>
        </Row>
      </PageLayout>
    </React.Fragment>
  )
}

export default UserActivity
