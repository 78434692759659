import { put, takeLatest } from "redux-saga/effects"
import APIPromises from "../../../util/APIPromises"
import {
  uploadFileSuccess,
  uploadFileError,
  deleteFileSuccess,
  deleteFileError,
  downloadFileSuccess,
  downloadFileError,
  listFilesSuccess,
  listFilesError,
  approveFileError,
  approveFileSuccess,
  updateEffectiveDateSuccess,
  updateEffectiveDateError,
  retrieveProjectListSuccess,
  retrieveProjectListError,
} from "../action"

function* uploadFile(req) {
  try {
    yield APIPromises.uploadFilePromise(req.payload)
    yield put(uploadFileSuccess(req.payload))
  } catch (error) {
    yield put(uploadFileError(error))
  }
}

export function* uploadFileWatcher() {
  yield takeLatest("UPLOAD_FILE", uploadFile)
  // yield takeLatest('UPLOAD_FILE_SUCCESS', fetchMatrix)
}

function* approveFile(req) {
  try {
    let res = yield APIPromises.approveFilePromise(req.payload)
    if (res.data && res.data.isSuccess === false) {
      yield put(approveFileError())
    } else {
      yield put({
        type: "LIST_FILES",
      })
      yield put(approveFileSuccess(req.payload))
    }
  } catch (error) {
    yield put(approveFileError(error))
  }
}

export function* approveFileWatcher() {
  yield takeLatest("APPROVE_FILE", approveFile)
}

function* listFiles(req) {
  try {
    console.warn("list files req", req)
    let response = yield APIPromises.listFilesPromise(req.payload)
    yield put(listFilesSuccess(response.data))
  } catch (error) {
    yield put(listFilesError(error))
  }
}

export function* listFilesWatcher() {
  yield takeLatest("LIST_FILES", listFiles)
  // yield takeLatest('UPLOAD_FILE_SUCCESS', fetchMatrix)
}

function* deleteFile(req) {
  try {
    let res = yield APIPromises.deleteFilePromise(req.payload)

    if (res.data && res.data.isSuccess === false) {
      yield put(deleteFileError())
    } else {
      if (req.isCMUser) {
        yield put({
          type: "LIST_FILES",
          payload: {
            cm: req.cm,
          },
        })
      } else {
        yield put({
          type: "LIST_FILES",
        })
      }
      yield put(deleteFileSuccess(req.payload))
    }
  } catch (error) {
    yield put(deleteFileError(error))
  }
}

export function* deleteFileWatcher() {
  yield takeLatest("DELETE_FILE", deleteFile)
  // yield takeLatest('UPLOAD_FILE_SUCCESS', fetchMatrix)
}

function* downloadFile(req) {
  try {
    let res = yield APIPromises.downloadFilePromise(req.payload)

    if (res.data && res.data.isSuccess === false) {
      yield put(downloadFileError())
    } else {
      yield put(downloadFileSuccess(res.data))
    }
  } catch (error) {
    yield put(downloadFileError(error))
  }
}

export function* downloadFileWatcher() {
  yield takeLatest("DOWNLOAD_FILE", downloadFile)
}

function* updateEffectiveDate(req) {
  try {
    console.warn("list files req", req)
    let response = yield APIPromises.updateEffectiveDatePromise(req.payload)
    yield put(updateEffectiveDateSuccess(response.data))
  } catch (error) {
    yield put(updateEffectiveDateError(error))
  }
}

export function* updateEffectiveDateWatcher() {
  yield takeLatest("UPDATE_EFFECTIVE_DATE", updateEffectiveDate)
  // yield takeLatest('UPLOAD_FILE_SUCCESS', fetchMatrix)
}

function* retrieveProjectList(req) {
  try {
    console.warn("list files req", req)
    let response = yield APIPromises.retrieveProject(req.cmName)
    const project = response.data.retrieveProjectsByCMResponse

    yield put(retrieveProjectListSuccess(project))
  } catch (error) {
    yield put(retrieveProjectListError(error))
  }
}

export function* retrieveProjectListWatcher() {
  yield takeLatest("RETRIEVE_PROJECT_LIST", retrieveProjectList)
  // yield takeLatest('UPLOAD_FILE_SUCCESS', fetchMatrix)
}
