export const retrieveCapabilityTemplateList = payload => ({
  type: "RETRIEVE_CAPABILITY_TEMPLATE_LIST",
  payload,
})

export const retrieveCapabilityTemplateListSuccess = list => ({
  type: "RETRIEVE_CAPABILITY_TEMPLATE_LIST_SUCCESS",
  payload: list,
})

export const retrieveCapabilityTemplateListFailure = res => ({
  type: "RETRIEVE_CAPABILITY_TEMPLATE_LIST_FAILURE",
  payload: res,
})

export const addCapabilityTemplate = payload => ({
  type: "ADD_CAPABILITY_TEMPLATE",
  payload: payload,
})

export const addCapabilityTemplateSuccess = list => ({
  type: "ADD_CAPABILITY_TEMPLATE_SUCCESS",
  payload: list,
})

export const addCapabilityTemplateFailure = res => ({
  type: "ADD_CAPABILITY_TEMPLATE_FAILURE",
  payload: res,
})

export const deleteCapabilityTemplate = payload => ({
  type: "DELETE_CAPABILITY_TEMPLATE",
  payload: payload,
})

export const deleteCapabilityTemplateSuccess = list => ({
  type: "DELETE_CAPABILITY_TEMPLATE_SUCCESS",
  payload: list,
})

export const deleteCapabilityTemplateFailure = res => ({
  type: "DELETE_CAPABILITY_TEMPLATE_FAILURE",
  payload: res,
})
