/**
 * The footer shown below the current page's content.
 */

import React from "react"
import Column from "@amzn/meridian/column"
import Text from "@amzn/meridian/text"
import Row from "@amzn/meridian/row"
import Textarea from "@amzn/meridian/textarea"

const FormTextArea = props => {
  const { value, setValue, label, placeholder } = props
  return (
    <Row spacingInset="small none">
      <Column minWidth="150px">
        <Text tag="label" type="b200" color="primary" htmlFor="my-input">
          {label}
        </Text>
      </Column>
      <Column width="250px">
        <Textarea
          type="text"
          size="small"
          value={value}
          onChange={setValue}
          placeholder={placeholder}
        />
      </Column>
    </Row>
  )
}

export default FormTextArea
