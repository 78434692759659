import React from "react"
import { Select, SelectOption } from "../app/form-fields"

const AuditSelect = props => {
  const { value, onChange, minWidth, options } = props
  return (
    <Select
      value={value}
      onChange={onChange}
      minWidth={minWidth}
      size={"small"}
    >
      {options.map(x => (
        <SelectOption key={x.label} value={x.value} label={x.label} />
      ))}
    </Select>
  )
}

export default AuditSelect
