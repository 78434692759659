export const fetchUserInfo = () => ({
  type: "FETCH_USERINFO",
})

export const fetchUserInfoSuccess = userInfo => ({
  type: "FETCH_USERINFO_SUCCESS",
  payload: userInfo,
})

export const fetchUserInfoError = errorMessage => ({
  type: "FETCH_USERINFO_ERROR",
  payload: errorMessage,
})

export const retrieveNotifications = username => ({
  type: "FETCH_NOTIFICATIONS",
  payload: { username: username },
})

export const retrieveNotificationsSuccess = notifications => ({
  type: "FETCH_NOTIFICATIONS_SUCCESS",
  payload: notifications,
})

export const retrieveNotificationsError = errorMessage => ({
  type: "FETCH_NOTIFICATIONS_ERROR",
  payload: errorMessage,
})
