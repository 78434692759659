import React, { useState, useEffect } from "react"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import Modal from "@amzn/meridian/modal"
import Column from "@amzn/meridian/column"
import { useSelector, useDispatch } from "react-redux"
import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import { retrieveEquipment } from "../../../redux/project/action"
import { Input } from "../../../components/app/form-fields"
import { useSort } from "../../../hooks"
import StatusTag from "../../../components/app/status-tag"
import EmptyState from "../../../components/app/empty-state"

const EquipmentListModal = props => {
  const [searchValue, setSearchValue] = useState("")

  const equipment = useSelector(state => state.projectReducer.equipment)
  const { sortedData, onSort, sortColumn, sortDirection } = useSort(equipment)

  const reduxDispatch = useDispatch()
  const {
    equipmentListModalOpen,
    setEquipmentListModalOpen,
    currentData,
  } = props

  const resetState = () => {
    setSearchValue()
    setEquipmentListModalOpen(false)
  }

  useEffect(() => {
    if (equipmentListModalOpen) {
      reduxDispatch(retrieveEquipment(currentData.projectName, currentData.cm))
    }
  }, [equipmentListModalOpen, reduxDispatch, currentData])

  return (
    <Modal
      title="Equipment List"
      open={equipmentListModalOpen}
      onClose={() => resetState()}
      // scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
      width="90%"
      minHeight="900px"
      scrollContainer="modal"
    >
      <form>
        <Row spacingInset="small none" alignmentHorizontal="right">
          <Column>
            <Input
              type="text"
              size="medium"
              value={searchValue}
              onChange={setSearchValue}
            />
          </Column>
          <Column>
            <Button
              type="primary"
              size="small"
              // onClick={() => setAddProjecteModalOpen(false)}
            >
              Copy
            </Button>
          </Column>
          <Column>
            <Button
              type="primary"
              size="small"
              // onClick={() => setAddProjecteModalOpen(false)}
            >
              Excel
            </Button>
          </Column>
          <Column>
            <Button
              type="primary"
              size="small"
              // onClick={() => setAddProjecteModalOpen(false)}
            >
              PDF
            </Button>
          </Column>
        </Row>
        <Row spacingInset="small none">
          <Column>
            <Table
              minWidth={1540}
              headerRows={1}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              onSort={onSort}
              showDividers={true}
              // stickyHeaderRow={true}
            >
              <TableRow>
                <TableCell sortColumn="no">No.</TableCell>
                <TableCell sortColumn="category">Category</TableCell>
                <TableCell sortColumn="equipment">Equipment Name</TableCell>
                <TableCell sortColumn="brand">Brand</TableCell>
                <TableCell sortColumn="model">Model</TableCell>
                <TableCell sortColumn="sn">SN</TableCell>
                <TableCell sortColumn="controlNumber">Control Number</TableCell>
                <TableCell sortColumn="location">Location</TableCell>
                <TableCell sortColumn="cert">Certificate No.</TableCell>
                <TableCell sortColumn="calibrationdate">
                  Calibration date
                </TableCell>
                <TableCell sortColumn="validdate">Valid Date</TableCell>
                <TableCell sortColumn="status">Status</TableCell>
              </TableRow>
              {sortedData &&
                sortedData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index}</TableCell>
                    <TableCell>{row.category}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.brand}</TableCell>
                    <TableCell>{row.model}</TableCell>
                    <TableCell>{row.serialNumber}</TableCell>
                    <TableCell>{row.controlNumber}</TableCell>
                    <TableCell>{row.cmLocation}</TableCell>
                    <TableCell>{row.certificationNumber}</TableCell>
                    <TableCell>
                      {new Date(row.calibrationDate).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {new Date(row.calibrationValidDate).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <StatusTag status={row.qualifiedStatus} />
                    </TableCell>
                  </TableRow>
                ))}
            </Table>
            {sortedData && sortedData.length === 0 ? (
              <EmptyState target={"equipment selected"} />
            ) : null}
          </Column>
        </Row>
      </form>
    </Modal>
  )
}

export default EquipmentListModal
