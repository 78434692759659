const INITIAL_STATE = {
  cmIndex: 0,
  year: new Date().getFullYear(),
  calibrationSummary: [],
  calibrationDetails: [],
  isLoading: false,
  locationList: [],
  yearList: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "RETRIEVE_CALIBRATION_MONTH_SUMMARY":
      return {
        ...state,
        calibrationSummary: [],
      }
    case "RETRIEVE_CALIBRATION_MONTH_SUMMARY_SUCCESS":
      return {
        ...state,
        calibrationSummary: action.payload,
      }
    case "RETRIEVE_CALIBRATION_MONTH_SUMMARY_FAILURE":
      return {
        ...state,
      }
    case "SELECT_CM":
      return {
        ...state,
        cmIndex: action.payload,
      }
    case "SELECT_YEAR":
      return {
        ...state,
        year: action.payload,
      }
    case "RETRIEVE_CALIBRATION_MONTH_EQUIPMENT_DETAILS":
      return {
        ...state,
        calibrationDetails: [],
      }
    case "RETRIEVE_CALIBRATION_MONTH_EQUIPMENT_DETAILS_SUCCESS":
      return {
        ...state,
        calibrationDetails: action.payload,
      }
    case "RETRIEVE_CALIBRATION_MONTH_EQUIPMENT_DETAILS_FAILURE":
      return {
        ...state,
      }
    default:
      return state
  }
}

export default reducer
