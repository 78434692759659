import React, { useCallback, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"

import Column from "@amzn/meridian/column"
import Icon from "@amzn/meridian/icon"
import Text from "@amzn/meridian/text"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import SearchField from "@amzn/meridian/search-field"
import Badge from "@amzn/meridian/badge"
import Tooltip from "@amzn/meridian/tooltip"
import refreshTokens from "@amzn/meridian-tokens/base/icon/refresh"

import Table, {
  TableRow,
  TableCell,
  TableActionBar,
} from "@amzn/meridian/table"
import { useDispatch, useSelector } from "react-redux"

import thumbsUpTokens from "@amzn/meridian-tokens/base/icon/thumbs-up"
import thumbsDownTokens from "@amzn/meridian-tokens/base/icon/thumbs-down"
import deleteTokens from "@amzn/meridian-tokens/base/icon/delete"
import cogIconTokens from "@amzn/meridian-tokens/base/icon/cog"
import filterTokens from "@amzn/meridian-tokens/base/icon/filter"
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large"

import EmptyState from "../../components/app/empty-state"
import PageLayout from "../../components/app/page-layout"
import FilterTags from "../../components/file-library/filter-tag"
import StatusTag from "../../components/app/status-tag"
import {
  UploadFileModal,
  FileFiltersModal,
  ApproveModal,
  EffectiveModal,
  PreferenceModal,
} from "./modals"

import { DeleteConfirmModal } from "../../components/app"
import { ApproveEquipmentFileModal } from "../equipment/modals"

import "../../App.css"
import {
  listFiles,
  approveFile,
  deleteFile,
  updateEffectiveDate,
  downloadFile,
} from "../../redux/fileLibrary/action"
import fileTableColumns from "../../data/file-table-column"
import {
  useFilters,
  usePagination,
  useSort,
  useFileViewPreferences,
} from "../../hooks"

import { getFileTypeByValue, getEffectivePeriod } from "../../data/options"
import filterFiles from "../../filter-files"
import { getRegex, getUrlParams } from "../../util/Util"

const CenterTableCell = ({ ...extraProps }) => (
  <TableCell alignmentHorizontal="center" {...extraProps} />
)

const FileLibrary = () => {
  const onClickButton = useCallback(() => setOpenFileUploadModal(true), [])
  const reduxDispatch = useDispatch()
  const { cm, isCMUser } = useSelector(state => state.userReducer)

  const onClickFooterButton = useCallback(() => {
    setOpenFileUploadModal(false)
    if (isCMUser) {
      reduxDispatch(listFiles({ cm }))
    } else {
      reduxDispatch(listFiles())
    }
  }, [reduxDispatch, cm, isCMUser])

  const location = useLocation()
  const { dispatch } = useFilters()

  useEffect(() => {
    const param = location.state
    if (param?.fileName) {
      dispatch({
        type: "UPDATE_FILTER",
        filter: "fileName",
        value: param.fileName,
        tagLabel: `File Name: ${param.fileName}`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const urlParams = getUrlParams(window.location.search)
    let fileName = urlParams["fileName"]
    if (fileName) {
      dispatch({
        type: "UPDATE_FILTER",
        filter: "fileName",
        value: fileName,
        tagLabel: `File Name: ${fileName}`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const onClose = useCallback(() => setOpenFileUploadModal(false), [])
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [effectiveModalOpen, setEffectiveModalOpen] = useState(false)
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false)
  const [approveModalOpen, setApproveModalOpen] = useState(false)
  const [preferenceModalOpen, setPreferenceModalOpen] = useState(false)
  const [deleteFileModalOpen, setDeleteFileModalOpen] = useState(false)
  const fileList = useSelector(state => state.fileLibraryReducer.fileList)
  const isLoading = useSelector(state => state.fileLibraryReducer.isLoading)

  // eslint-disable-next-line no-unused-vars
  const [percentage, setPercentage] = useState(undefined)
  const [currentFile, setCurrentFile] = useState(undefined)

  // for user preference
  const [viewPreferences] = useFileViewPreferences()
  const visibleColumns = viewPreferences.get("columns")

  // useEffect(() => {
  //   if (isCMUser) {
  //     reduxDispatch(listFiles({ cm }))
  //   } else {
  //     reduxDispatch(listFiles())
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // for searching and filtering
  const [keyword, setKeyword] = useState("")
  const { filters } = useFilters()
  const data = filterFiles(fileList, filters).filter(f => {
    let regex = getRegex(keyword)
    return regex.test(JSON.stringify(f))
  })

  // for sorting
  const { sortedData, onSort, sortColumn, sortDirection } = useSort(data)
  // for pagnation
  const { TableFooter, visibleIndices } = usePagination(data.length)

  const handleApproveFile = data => {
    console.warn("current file", currentFile, data)
    reduxDispatch(
      approveFile({
        ...currentFile,
        ...data,
        fileId: currentFile.id,
      })
    )
  }

  const handleDeleteFile = data => {
    console.warn("current file", currentFile)
    reduxDispatch(
      deleteFile(
        {
          ...currentFile,
        },
        cm,
        isCMUser
      )
    )
  }

  const handleUpdateEffectiveDate = data => {
    console.warn("current file", currentFile)
    reduxDispatch(
      updateEffectiveDate({
        ...currentFile,
        ...data,
      })
    )
  }

  return (
    <React.Fragment>
      <PageLayout
        spacing="large"
        title={"File Library"}
        alignmentHorizontal={"start"}
        // width={data ? "inherit" : undefined}
        width="100%"
        loading={isLoading}
      >
        <Row spacingInset="small none">
          <Column spacing="large" width="100%">
            <Table
              headerRows={1}
              showDividers={true}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              // stickyHeaderRow={true}
              fixHeaderRows={true}
              onSort={onSort}
              spacing="small"
              showStripes={viewPreferences.get("stripes")}
            >
              <TableActionBar widths={[300]}>
                <SearchField
                  value={keyword}
                  onChange={setKeyword}
                  placeholder="Search for..."
                  type="text"
                  onSubmit={() => {}}
                />
                <Button onClick={() => setFilterModalOpen(true)} type="icon">
                  {filters.size > 0 ? (
                    <Icon tokens={filterTokens}>
                      {`${filters.size} Filters`}
                      <Badge type="theme" value={filters.size} locale="en-US" />
                    </Icon>
                  ) : (
                    <Icon tokens={filterTokens}></Icon>
                  )}
                </Button>
                <Button
                  type="icon"
                  onClick={() => setPreferenceModalOpen(true)}
                >
                  <Icon tokens={cogIconTokens}>Settings</Icon>
                </Button>
                <Button type="primary" onClick={onClickButton}>
                  Upload More
                </Button>
                <Tooltip position="top" title="Refresh page" id="refresh">
                  <Button type="icon" onClick={onClickFooterButton}>
                    <Icon tokens={refreshTokens}>Copy</Icon>
                  </Button>
                </Tooltip>
              </TableActionBar>
              <FilterTags stick={true} />
              <TableRow highlightOnHover={true}>
                <CenterTableCell sortColumn="cm">CM</CenterTableCell>
                <CenterTableCell sortColumn="cmLocation">
                  Location
                </CenterTableCell>
                <CenterTableCell sortColumn="fileType">Type</CenterTableCell>
                <CenterTableCell width={250} sortColumn="fileName">
                  File Name
                </CenterTableCell>
                <CenterTableCell sortColumn="status">Status</CenterTableCell>
                {fileTableColumns
                  .filter(({ id }) => visibleColumns[id])
                  .map(header => (
                    <CenterTableCell
                      key={header.id}
                      sortColumn={header.id}
                      // width={header.width}
                      // alignmentHorizontal={header.alignment}
                    >
                      {header.label}
                    </CenterTableCell>
                  ))}
                <CenterTableCell sortColumn="effectiveStartDate">
                  Effective Period
                </CenterTableCell>
                <CenterTableCell>Approve/Reject</CenterTableCell>
                <CenterTableCell>Download</CenterTableCell>
                <CenterTableCell>Delete</CenterTableCell>
              </TableRow>
              {sortedData
                .slice(visibleIndices[0], visibleIndices[1])
                .map((x, index) => (
                  <TableRow key={index}>
                    <TableCell>{x.cm}</TableCell>
                    <TableCell>{x.cmLocation}</TableCell>
                    <TableCell>{getFileTypeByValue(x.fileType)}</TableCell>
                    <TableCell>{x.fileName}</TableCell>
                    <TableCell>
                      {x.status === "Expired" ? (
                        <Button
                          onClick={() => {
                            setCurrentFile(x)
                            setEffectiveModalOpen(true)
                          }}
                          type="link"
                        >
                          expired
                        </Button>
                      ) : (
                        <Text alignment="center">
                          <StatusTag status={x.status} />
                        </Text>
                      )}
                    </TableCell>
                    {visibleColumns.uploadedBy && (
                      <TableCell>{x.uploadedBy}</TableCell>
                    )}
                    {visibleColumns.uploadedDate && (
                      <TableCell>{x.uploadedDate}</TableCell>
                    )}
                    {visibleColumns.approvedBy && (
                      <TableCell>{x.approvedBy}</TableCell>
                    )}
                    {visibleColumns.approvalDate && (
                      <TableCell>{x.approvalDate}</TableCell>
                    )}
                    <TableCell>{getEffectivePeriod(x)}</TableCell>
                    <TableCell alignmentHorizontal={"center"}>
                      <Button
                        type="icon"
                        disabled={isCMUser}
                        onClick={() => {
                          setApproveModalOpen(true)
                          setCurrentFile(x)
                        }}
                      >
                        <Icon
                          tokens={
                            x.status === "UnApproved"
                              ? thumbsDownTokens
                              : thumbsUpTokens
                          }
                          className={`${x.status}-color`}
                        />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        type="icon"
                        onClick={() => {
                          reduxDispatch(downloadFile({ ...x }))
                          setCurrentFile(x)
                        }}
                      >
                        <Icon
                          className={"download-button"}
                          tokens={downloadLargeTokens}
                        />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        type="icon"
                        onClick={() => {
                          setDeleteFileModalOpen(true)
                          setCurrentFile(x)
                        }}
                      >
                        <Icon
                          className={"delete-button"}
                          tokens={deleteTokens}
                        />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </Table>
            {sortedData && sortedData.length === 0 ? (
              <EmptyState searching={Boolean(filters.size)} />
            ) : null}
            <TableFooter />
          </Column>
        </Row>
        <ApproveEquipmentFileModal
          approveEquipmentFileModalOpen={approveModalOpen}
          setApproveEquipmentFileModalOpen={setApproveModalOpen}
          onApproveFile={handleApproveFile}
          currentFile={currentFile}
        />
        <DeleteConfirmModal
          modalOpen={deleteFileModalOpen}
          setModalOpen={setDeleteFileModalOpen}
          action={handleDeleteFile}
          message={"Are you sure you want to delete this file?"}
        />
        <EffectiveModal
          effectiveModalOpen={effectiveModalOpen}
          setEffectiveModalOpen={setEffectiveModalOpen}
          onUpdateEffectiveDate={handleUpdateEffectiveDate}
        />
        <FileFiltersModal
          filterModalOpen={filterModalOpen}
          setFilterModalOpen={setFilterModalOpen}
        />
        <UploadFileModal
          uploadFileModalOpen={openFileUploadModal}
          onClickFooterButton={onClickFooterButton}
        />
        <PreferenceModal
          preferenceModalOpen={preferenceModalOpen}
          setPreferenceModalOpen={setPreferenceModalOpen}
        />
      </PageLayout>
    </React.Fragment>
  )
}

export default FileLibrary
