import { put, takeLatest } from "redux-saga/effects"
import APIPromises from "../../../util/APIPromises"
import { retrieveCmsSuccess, retrieveCmsFailure } from "../action"

function* retrieveCms(req) {
  try {
    const response = yield APIPromises.retrieveCms(
      req.userName,
      req.cm_location_id
    )
    const allCms = response.data.CMs
    yield put(retrieveCmsSuccess(allCms))
  } catch (error) {
    yield put(retrieveCmsFailure(error))
  }
}

export function* retrieveCmsWatcher() {
  yield takeLatest("RETRIEVE_CMS", retrieveCms)
}
