import React, { useState, useEffect } from "react"
// import {useHistory} from "react-router-dom";
import Row from "@amzn/meridian/row"
import Column from "@amzn/meridian/column"
import Button from "@amzn/meridian/button"
import FileInput, { FileDetails } from "@amzn/meridian/file-input"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import Text from "@amzn/meridian/text"

import axios from "axios"
import { useDispatch, useSelector } from "react-redux"

import { Select, SelectOption } from "../../../components/app/form-fields"
import { fileTypeOptions } from "../../../data/options"
import env from "../../../services/Auth/env"
import * as auth from "../../../services/Auth/auth"
import { FILE_TYPES } from "../../../data/constants"

const UploadFileModal = props => {
  const { uploadFileModalOpen, onClickFooterButton } = props
  // eslint-disable-next-line no-unused-vars
  const [files, setFiles] = useState([])
  const [level, setLevel] = useState("equipment")
  const [cmIndex, setCmIndex] = useState()
  const { username } = useSelector(state => state.userReducer)
  const { locationList } = useSelector(state => state.locationReducer)

  // const [location, setLocation] = useState()
  const [fileType, setFileType] = useState(null)
  const reduxDispatch = useDispatch()

  const isFileUploadDisabled = () => {
    return !fileType || !Number.isInteger(cmIndex)
  }

  const resetState = () => {
    setFiles([])
    setLevel("equipment")
    setFileType(null)
  }

  return (
    <Modal
      title="Upload File(s)"
      open={uploadFileModalOpen}
      // onClose={onClose}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
    >
      <form>
        <Row spacingInset="small none">
          <Column minWidth="150px">
            <Text tag="label" type="b200" color="primary" htmlFor="my-input">
              {"Location"}
            </Text>
          </Column>
          <Select
            value={cmIndex}
            onChange={setCmIndex}
            placeholder="Select location"
            minWidth={240}
            size={"small"}
          >
            {locationList.map((x, index) => (
              <SelectOption
                key={index}
                value={index}
                label={`${x.cm}-${x.cmLocation}`}
              />
            ))}
          </Select>
        </Row>
        <Row>
          <Column minWidth="150px">
            <Text tag="label" type="b200" color="primary" htmlFor="my-input">
              {"Type"}
            </Text>
          </Column>
          <Select
            placeholder="Select file type"
            value={fileType}
            onChange={setFileType}
            minWidth={240}
            size={"small"}
          >
            {FILE_TYPES.concat("Other").map(type => (
              <SelectOption key={type} value={type} label={type} />
            ))}
          </Select>
        </Row>
        <Row spacingInset="medium none">
          <FileInput
            size="small"
            // dropZoneDirection="column"
            uploadButtonDisabled={isFileUploadDisabled()}
            showDropZone={!isFileUploadDisabled()}
            onFileAttached={acceptedFiles => {
              // filter to avoid duplicate
              let filteredFiles = acceptedFiles.filter(
                f => !files.some(fl => fl.name === f.name)
              )

              filteredFiles.forEach(file => {
                // file.uploadedFileHref = "https://www.google.com"
                console.warn("herereojroej", level, fileType, file)
                let params = {
                  file_name: file.name,
                  file_level: level,
                  file_type: fileType,
                  status: "pending",
                  cm: locationList[cmIndex]?.cm,
                  cm_location: locationList[cmIndex]?.cmLocation,
                  uploaded_by: username,
                  uploaded_date: new Date().getTime(),
                }

                auth.getToken().then(cookie => {
                  axios(env.API_ENDPOINT + env.APIPath.uploadFile, {
                    method: "PUT",
                    headers: { idToken: cookie },
                    params: params,
                    // timeout: 10000,
                    data: file,
                    onUploadProgress: e => {
                      let completeProgress = ((e.loaded / e.total) * 100) | 0
                      file.uploadPercentage = completeProgress
                      setFiles([...files, ...filteredFiles])
                    },
                  })
                    .then(response => {
                      if (response.data && response.data.errorMessage) {
                        file.error = true
                        file.errorMessage = response.data.errorMessage
                        setFiles([...files, ...filteredFiles])
                        console.warn("error", file.errorMessage)
                      } else {
                        console.warn("upload succeed")
                        // file.uploadComplete = true
                        // setFiles([...files, ...filteredFiles])
                      }
                    })
                    .catch(error => {
                      console.warn("error", error)
                      file.error = true
                      setFiles([...files, ...filteredFiles])
                    })
                })

                // reduxDispatch(uploadFile(params, file))
                // file.cancel = upload(0, setPercentage)
              })
              setFiles([...files, ...filteredFiles])
            }}
            type="multiple"
          >
            {files.map(file => {
              console.warn("files", file)
              return (
                <FileDetails
                  error={file.error}
                  errorMessage={file.errorMessage}
                  file={file}
                  // connectionSpeed="5MB/s"
                  uploadPercentage={file.uploadPercentage}
                  key={file.name}
                  // onClickCancel={() => {
                  //   file.cancel()
                  //   const updatedFileArr = files.filter(
                  //     fl => fl.name !== file.name
                  //   )
                  //   setFiles(updatedFileArr)
                  // }}
                  // cancelUploadButtonLabel={`Cancel, ${file.name}`}
                  onClickRemoveFile={() => {
                    const updatedFileArr = files.filter(
                      fl => fl.name !== file.name
                    )
                    setFiles(updatedFileArr)
                  }}
                  removeFileButtonLabel={`Delete, ${file.name}`}
                  uploadComplete={file.uploadPercentage === 100}
                />
              )
            })}
          </FileInput>
        </Row>
      </form>
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            // disable if any of the files is uploading
            disabled={files && files.some(f => f.uploadPercentage !== 100)}
            type="primary"
            size="small"
            onClick={() => {
              resetState()
              onClickFooterButton()
            }}
          >
            Close
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export { UploadFileModal }
