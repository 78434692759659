import React, { useState, useEffect } from "react"
import Column from "@amzn/meridian/column"

import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import Heading from "@amzn/meridian/heading"

import { useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import PageLayout from "../../components/app/page-layout"

import {
  retrieveAuditIssues,
  retrieveAuditReport,
} from "../../redux/audit/action"
import AuditReportIssueList from "./audit-report-issue-list"
import { retrieveAllEquipment } from "../../redux/equipment/action"

const AuditReportDetails = props => {
  const [auditReport, setAuditReport] = useState({})
  const location = useLocation()

  const { cm, testLocation, auditReportId } = Object.fromEntries(
    new URLSearchParams(location.search).entries()
  )

  console.warn(cm, testLocation, auditReportId)

  const { isLoading, auditReportList } = useSelector(
    state => state.auditReducer
  )
  const reduxDispatch = useDispatch()

  useEffect(() => {
    reduxDispatch(
      retrieveAuditReport({
        cm: cm,
        test_location: testLocation,
      })
    )
    reduxDispatch(
      retrieveAllEquipment({
        cm: cm,
        test_location: testLocation,
      })
    )
    reduxDispatch(
      retrieveAuditIssues(auditReportId)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (auditReportList.length > 0) {
      auditReportList?.forEach(ar => {
        if (ar.auditReportId === parseInt(auditReportId)) {
          setAuditReport(ar)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditReportList])

  return (
    <PageLayout
      loading={isLoading}
      spacing="large"
      title={`${auditReport.reportName}`}
      breadcrumbs={[{ title: "Audit Report", path: "/report/audit-report" }]}
      width={"100%"}
      alignmentHorizontal={"start"}
    >
      <Column spacing="600">
        <Row>
          <Column spacing="300" width={"100%"}>
            <Row>
              <Heading level={4}>Basic Info</Heading>
            </Row>
            <Row>
              <Text>Report Name:{auditReport.reportName}</Text>
            </Row>
            <Row>
              <Text>
              Audit Location: {cm}-{testLocation}
              </Text>
            </Row>
            <Row>
              <Text>Request By: {auditReport.requestBy}</Text>
            </Row>
            <Row>
              <Text>Purpose: {auditReport.purpose}</Text>
            </Row>
            <Row>
              <Text>
                Auditors: {auditReport.auditorList?.reduce(
                  (acc, cur) => acc + cur.name + "; ",
                  " "
                )}
              </Text>
            </Row>
            <Row>
              <Text>Audit Date: {auditReport.startDate}</Text>
            </Row>
            <Row>
              <Text>
                Involved Equipments: {auditReport.equipmentsWithIssue != null &&
                  auditReport.equipmentsWithIssue
                  .map(eq => eq.equipmentName)
                  .join(",")}
                </Text>
            </Row>
          </Column>
        </Row>
        <Row>
          <Column width={"100%"} spacing="400">
            <Row>
              <Heading level={4}>Issue List</Heading>
            </Row>
            <Row>
              <AuditReportIssueList
                auditReport={auditReport}
                testLocation={testLocation}
                cm={cm}
              />
            </Row>
          </Column>
        </Row>
      </Column>
    </PageLayout>
  )
}

export default AuditReportDetails
