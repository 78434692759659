import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import Row from "@amzn/meridian/row"
import Column from "@amzn/meridian/column"
import Heading from "@amzn/meridian/heading"
import Text from "@amzn/meridian/text"
import plusCircleTokens from "@amzn/meridian-tokens/base/icon/plus-circle"
import deleteTokens from "@amzn/meridian-tokens/base/icon/delete"
import editTokens from "@amzn/meridian-tokens/base/icon/edit"
import Table, {
  TableActionBar,
  TableCell,
  TableRow,
} from "@amzn/meridian/table"
import SearchField from "@amzn/meridian/search-field"
import Tooltip from "@amzn/meridian/tooltip"
import Button from "@amzn/meridian/button"
import Icon from "@amzn/meridian/icon"
import { useDispatch, useSelector } from "react-redux"
import Link from "@amzn/meridian/link"
import PageLayout from "../../../components/app/page-layout"
import { usePagination, useSort } from "../../../hooks"
import { getRegex } from "../../../util/Util"
import {
  addConsumableList,
  deleteConsumableUsageRecord,
  retrieveConsumableById,
  retrieveConsumableUsageById,
} from "../../../redux/consumable/action"
import AddConsumableUsageModal from "../modals/add-consumable-usage-modal"
import AddConsumableModal from "../modals/add-consumable-modal"

const ConsumableDetails = () => {
  const [keyword, setKeyword] = useState("")
  const [addUsageRecordModalOpen, setAddUsageRecordModalOpen] = useState(false)
  const [addConsumableModalOpen, setAddConsumableModalOpen] = useState(false)
  let location = useLocation()
  let { consumableId } = location.state
  const reduxDispatch = useDispatch()
  const { isLoading, consumableUsage, consumableItem } = useSelector(
    state => state.consumableReducer
  )

  const data = consumableUsage.filter(f => {
    let regex = getRegex(keyword)
    return regex.test(JSON.stringify(f))
  })
  const { sortedData, onSort, sortColumn, sortDirection } = useSort(data)
  const { TableFooter, visibleIndices } = usePagination(data.length)

  const { locationList } = useSelector(state => state.locationReducer)
  const { cmIndex } = useSelector(state => state.consumableReducer)
  const { isCMUser, isAdmin } = useSelector(state => state.userReducer)

  useEffect(() => {
    reduxDispatch(
      retrieveConsumableUsageById({
        consumable_id: consumableId,
      })
    )
    reduxDispatch(
      retrieveConsumableById({
        consumable_id: consumableId,
      })
    )
  }, [consumableId, reduxDispatch])

  return (
    <React.Fragment>
      <PageLayout
        spacing="large"
        alignmentHorizontal={"start"}
        title={consumableItem.name}
        breadcrumbs={[
          { title: "Consumable", path: "/lab-management/consumable-list" },
        ]}
        loading={isLoading}
        width={"100%"}
      >
        <Column spacing="600">
          <Row>
            <Column spacing="200" width={"100%"}>
              <Row>
                <Heading level={4}>Basic Info</Heading>
                <Link
                  disabled={!isAdmin && !isCMUser}
                  onClick={() => setAddConsumableModalOpen(true)}
                >
                  <Icon tokens={editTokens}></Icon>
                </Link>
              </Row>
              <Row>
                <Text>Description:{consumableItem.description}</Text>
              </Row>
              <Row>
                <Text>
                  Purchase Number: {consumableItem.purchaseNum}{" "}
                  {consumableItem.unit}
                </Text>
              </Row>
              <Row>
                <Text>Purchase Date: {consumableItem.purchaseDate}</Text>
              </Row>
              <Row>
                <Text>Expire Date: {consumableItem.expireDate}</Text>
              </Row>
              <Row>
                <Text>
                  In Stock: {consumableItem.inStockNum} {consumableItem.unit}
                </Text>
              </Row>
            </Column>
          </Row>
          <Row>
            <Column width={"100%"} spacing="400">
              <Row>
                <Heading level={4}>Usage Record</Heading>
              </Row>
              <Row>
                <Table
                  headerRows={1}
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  onSort={onSort}
                  showDividers={true}
                  spacing="small"
                  // stickyHeaderRow={true}
                  fixHeaderRows={true}
                  width={"100%"}
                  // showStripes={true}
                >
                  <TableActionBar widths={[300]}>
                    <SearchField
                      value={keyword}
                      onChange={setKeyword}
                      placeholder="Search for..."
                      // size="medium"
                      type="text"
                      onSubmit={() => {}}
                    />
                    <Tooltip position="top" title="Add Item" id="myTooltip">
                      <Button
                        type="icon"
                        disabled={!isAdmin && !isCMUser}
                        onClick={() => {
                          setAddUsageRecordModalOpen(true)
                        }}
                      >
                        <Icon tokens={plusCircleTokens} />
                      </Button>
                    </Tooltip>
                  </TableActionBar>
                  <TableRow>
                    <TableCell sortColumn="date">Date</TableCell>
                    <TableCell sortColumn="action">Action</TableCell>
                    <TableCell sortColumn="number">Number</TableCell>
                    <TableCell sortColumn="comment">Comment</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                  {sortedData &&
                    sortedData
                      .slice(visibleIndices[0], visibleIndices[1])
                      .map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.date}</TableCell>
                          <TableCell>{row.action}</TableCell>
                          <TableCell>
                            {row.useNum}
                            {row.unit}
                          </TableCell>
                          <TableCell>{row.comment}</TableCell>
                          <TableCell>
                            <Text>
                              <Link
                                onClick={() => {
                                  reduxDispatch(
                                    deleteConsumableUsageRecord({
                                      consumableId: row.id,
                                      id: consumableId,
                                    })
                                  )
                                }}
                              >
                                <Icon tokens={deleteTokens} />
                              </Link>
                            </Text>
                          </TableCell>
                        </TableRow>
                      ))}
                </Table>
              </Row>
            </Column>
          </Row>
        </Column>

        <AddConsumableUsageModal
          isModalOpen={addUsageRecordModalOpen}
          setModalOpen={setAddUsageRecordModalOpen}
          consumableId={consumableId}
        />
        <AddConsumableModal
          addConsumableModalOpen={addConsumableModalOpen}
          setAddConsumableModalOpen={setAddConsumableModalOpen}
          data={consumableItem}
          consumableId={consumableId}
          callback={(
            consumableId,
            name,
            description,
            purchaseNum,
            unit,
            purchaseDate,
            expireDate
          ) => {
            reduxDispatch(
              addConsumableList({
                cm: locationList[cmIndex].cm,
                cmLocation: locationList[cmIndex].cmLocation,
                consumableId: consumableId,
                AddConsumableByLocationItem: {
                  name,
                  description,
                  purchaseNum,
                  unit,
                  purchaseDate,
                  expireDate,
                },
              })
            )
            reduxDispatch(
              retrieveConsumableById({
                consumable_id: consumableId,
              })
            )
          }}
        />
      </PageLayout>
    </React.Fragment>
  )
}

export default ConsumableDetails
