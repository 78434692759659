import { openToast } from "../../../api/toasts"
import {
  FAILURE_TIMEOUT_IN_MS,
  SUCCESS_TIMEOUT_IN_MS,
} from "../../../data/constants"

const INITIAL_STATE = {
  auditReports: {},
  auditIssues: null,
  projectList: [],
  isLoading: false,
  auditReport: null,
  isLoadingIssues: false,
  cm: "",
  cmIndex: 0,
  project: null,
  projectIndex: undefined,
  auditReportList: [],
}
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SELECT_CM_AUDIT":
      return {
        ...state,
        cmIndex: action.payload,
        project: null,
        projectIndex: undefined,
        auditReports: {},
        auditReportsList: [],
      }
    case "SELECT_PROJECT_AUDIT":
      return {
        ...state,
        project: action.payload.project,
        projectIndex: action.payload.projectIndex,
        auditReports: {},
        auditReportsList: [],
      }
    case "ADD_AUDIT_REPORT":
      return {
        ...state,
        isLoading: true,
      }
    case "ADD_AUDIT_REPORT_SUCCESS":
      openToast({
        type: "success",
        title: "Add Done",
        message: "Add Audit Report successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "ADD_AUDIT_REPORT_FAILURE":
      openToast({
        type: "error",
        title: "Add failed",
        message: "failed to add the audit report, please retry",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "EDIT_AUDIT_REPORT":
      return {
        ...state,
        isLoading: true,
      }
    case "EDIT_AUDIT_REPORT_SUCCESS":
      openToast({
        type: "success",
        title: "Edit Done",
        message: "Edited Audit Report successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "EDIT_AUDIT_REPORT_FAILURE":
      openToast({
        type: "error",
        title: "Edit failed",
        message: "Failed to edit the audit report, please retry",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }

    case "RETRIEVE_AUDIT_REPORT":
      return {
        ...state,
        isLoading: true,
      }
    case "RETRIEVE_AUDIT_REPORT_SUCCESS":
      return {
        ...state,
        auditReportList: action.payload.auditReportList,
        isLoading: false,
      }
    case "RETRIEVE_AUDIT_REPORT_FAILURE":
      return {
        ...state,
        isLoading: false,
      }

    case "DELETE_AUDIT_REPORT":
      return {
        ...state,
        isLoading: true,
      }
    case "DELETE_AUDIT_REPORT_SUCCESS":
      openToast({
        type: "success",
        title: "Delete Done",
        message: "Deleted Audit Report successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "DELETE_AUDIT_REPORT_FAILURE":
      openToast({
        type: "error",
        title: "delete failed",
        message: "Failed to delete the audit report, please retry",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }

    case "RETRIEVE_PROJECT_AUDIT":
      return {
        ...state,
        // projectList: [],
      }
    case "RETRIEVE_PROJECT_AUDIT_SUCCESS":
      return {
        ...state,
        projectList: action.payload,
      }
    case "RETRIEVE_PROJECT_AUDIT_FAILURE":
      return {
        ...state,
      }
    case "RETRIEVE_AUDIT_ISSUES":
      return {
        ...state,
        isLoadingIssues: true,
      }
    case "RETRIEVE_AUDIT_ISSUES_SUCCESS":
      return {
        ...state,
        auditIssues: action.payload,
        isLoadingIssues: false,
      }
    case "RETRIEVE_AUDIT_ISSUES_FAILURE":
      return {
        ...state,
        isLoadingIssues: false,
      }
    case "ADD_AUDIT_ISSUE":
      return {
        ...state,
        isLoading: true,
      }
    case "ADD_AUDIT_ISSUE_SUCCESS":
      return {
        ...state,
        isLoading: false,
      }
    case "ADD_AUDIT_ISSUE_FAILURE":
      return {
        ...state,
        isLoading: false,
      }
    case "EDIT_AUDIT_ISSUES":
      return {
        ...state,
        isLoading: true,
      }
    case "EDIT_AUDIT_ISSUES_SUCCESS":
      return {
        ...state,
        isLoading: false,
      }
    case "EDIT_AUDIT_ISSUES_FAILURE":
      return {
        ...state,
        isLoading: false,
      }
    case "DELETE_AUDIT_ISSUES":
      return {
        ...state,
        isLoading: true,
      }
    case "DELETE_AUDIT_ISSUES_SUCCESS":
      return {
        ...state,
        isLoading: false,
      }
    case "DELETE_AUDIT_ISSUES_FAILURE":
      return {
        ...state,
        isLoading: false,
      }
    case "ADD_NEXT_AUDIT_DATE":
      return {
        ...state,
        isLoading: true,
      }
    case "ADD_NEXT_AUDIT_DATE_SUCCESS":
      openToast({
        type: "success",
        title: "Update Done",
        message: "Updated Audit Date Successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "ADD_NEXT_AUDIT_DATE_FAILURE":
      openToast({
        type: "error",
        title: "Update failed",
        message: "Failed to update the audit date, please retry",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default reducer
