/**
 * A hook that simplifies state management for the Pagination component.
 */

import React, { useState, useMemo, useCallback } from "react"
import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import Pagination from "@amzn/meridian/pagination"

import { resultsPerPageOptions } from "../data/options"
import { Select, SelectOption } from "../components/app/form-fields"

const usePagination = numberOfResults => {
  // Get the current query parameters in the case that our
  // page should be informed by the url

  // If we are tracking a certain query parameter for our page,
  // try and grab that value and default to 1
  const [currentPage, setCurrentPage] = useState(1)
  const [resultsPerPage, setResultsPerPage] = useState(100)

  const numberOfPages = useMemo(
    () => Math.ceil(numberOfResults / resultsPerPage),
    [numberOfResults, resultsPerPage]
  )

  const onChangeResultsPerPage = resultsPerPage => {
    // Update local state variable
    setResultsPerPage(resultsPerPage)
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    setCurrentPage(1)
  }

  const visibleIndices = useMemo(() => {
    const firstIndex = (currentPage - 1) * resultsPerPage
    return [firstIndex, firstIndex + resultsPerPage]
  }, [currentPage, resultsPerPage])

  const onChange = useCallback(newValue => {
    setCurrentPage(newValue)
  }, [])

  const TableFooter = () => {
    return (
      <Row alignmentHorizontal="justify" wrap="down">
        <Row spacing="200">
          <Text id="number-of-pages-label" tag="label">
            Results per page
          </Text>
          <Select
            value={resultsPerPage}
            onChange={onChangeResultsPerPage}
            aria-labelledby="number-of-pages-label"
            width={150}
          >
            {resultsPerPageOptions.map(option => (
              <SelectOption key={option} label={option} value={option} />
            ))}
          </Select>
        </Row>
        <span>
          <Pagination
            showSkipArrows={true}
            numberOfPages={numberOfPages}
            onChange={setCurrentPage}
            currentPage={currentPage}
          />
        </span>
      </Row>
    )
  }

  return {
    numberOfPages,
    currentPage,
    onChange,
    visibleIndices,
    TableFooter,
  }
}

export default usePagination
