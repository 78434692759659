export const retrieveDashboard = data => ({
  type: "RETRIEVE_DASHBOARD",
  payload: data,
})

export const retrieveDashboardSuccess = dashboard => ({
  type: "RETRIEVE_DASHBOARD_SUCCESS",
  payload: dashboard,
})

export const retrieveDashboardFailure = res => ({
  type: "RETRIEVE_DASHBOARD_FAILURE",
  payload: res,
})
