import React from "react"
import Column from "@amzn/meridian/column"
import Box from "@amzn/meridian/box"
import Text from "@amzn/meridian/text"
import Divider from "@amzn/meridian/divider"
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryVoronoiContainer,
} from "victory"
import Legend, { LegendProvider } from "@amzn/meridian/legend"
// import Heading from "@amzn/meridian/heading"
import victoryLinePlugin from "@amzn/meridian/legend/plugin/victory-line"
import victoryTooltipPlugin from "@amzn/meridian/legend/plugin/victory-tooltip"
import useVictoryTheme from "@amzn/meridian/use-victory-theme"

const PerformanceLineChart = props => {
  const {
    data,
    title,
    xValue,
    yValue,
    tickFormat,
    xTickFormat,
    xLabel,
    yLabel,
  } = props

  const formatLabel = ({ datum }) => datum.value
  const theme = useVictoryTheme({ showIndependentGrid: false })
  const formatTick = (value, index) => (index > 0 ? value : "")

  return (
    <Box spacingInset="300" type="outline" width={500} height={350}>
      <Text type="h200">{title}</Text>
      <Divider />
      <LegendProvider
        data={data}
        plugins={[
          victoryLinePlugin({ theme }),
          victoryTooltipPlugin({ showCrosshair: true }),
        ]}
        aria-label={title}
      >
        {({ getLineProps, voronoiContainerProps, Tooltip }) => (
          <Column spacing="none">
            <VictoryChart
              theme={theme}
              width={500}
              height={260}
              containerComponent={
                <VictoryVoronoiContainer
                  labels={formatLabel}
                  {...voronoiContainerProps}
                />
              }
              // padding={{ left: 85, top: 20, bottom: 20, right: 20 }}
            >
              <VictoryAxis
                tickFormat={xTickFormat || (t => t)}
                label={xLabel}
              />
              <VictoryAxis
                dependentAxis
                tickFormat={tickFormat || formatTick}
                label={yLabel}
              />
              {data.map(({ data, key }) => (
                <VictoryLine
                  key={key}
                  data={data}
                  x={xValue}
                  y={yValue}
                  labels={formatLabel}
                  labelComponent={<Tooltip legendKey={key} />}
                  {...getLineProps(key)}
                />
              ))}
            </VictoryChart>
            <Legend direction="horizontal" />
          </Column>
        )}
      </LegendProvider>
    </Box>
  )
}

export default PerformanceLineChart
