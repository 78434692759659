import React from "react"
import {
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryAxis,
  VictoryBar,
} from "victory"
import Box from "@amzn/meridian/box"
import Legend, { LegendProvider } from "@amzn/meridian/legend"
import Heading from "@amzn/meridian/heading"
import Column from "@amzn/meridian/column"
import victoryBarPlugin from "@amzn/meridian/legend/plugin/victory-bar"
import victoryTooltipPlugin from "@amzn/meridian/legend/plugin/victory-tooltip"
import useVictoryTheme from "@amzn/meridian/use-victory-theme"
import Divider from "@amzn/meridian/divider"
import Text from "@amzn/meridian/text"

const PerformanceBarChart = props => {
  const { data, title, xValue, yValue } = props

  const formatLabel = ({ datum }) => datum.value
  const theme = useVictoryTheme({ showIndependentGrid: false })

  return (
    <Box spacingInset="300" type="outline" height={350} width={500}>
      <Text type="h200">{title}</Text>
      <Divider />
      <LegendProvider
        data={[data]}
        plugins={[victoryBarPlugin({ theme }), victoryTooltipPlugin()]}
        aria-label={title}
      >
        {({ getBarProps, voronoiContainerProps, Tooltip }) => (
          <Column spacing="none" maxWidth={600}>
            <VictoryChart
              theme={theme}
              width={600}
              height={260}
              domainPadding={{ x: 50, y: 0 }}
              containerComponent={
                <VictoryVoronoiContainer
                  labels={formatLabel}
                  {...voronoiContainerProps}
                />
              }
            >
              <VictoryAxis />
              <VictoryAxis dependentAxis />

              <VictoryBar
                data={data.data}
                x={xValue}
                y={yValue}
                barWidth={40}
                labels={formatLabel}
                labelComponent={
                  <Tooltip
                    legendKey={data.key}
                    ariaLabels={props =>
                      `${formatLabel(props)}, ${props.datum.cm}`
                    }
                  />
                }
                {...getBarProps(data.key)}
              />
            </VictoryChart>
            <Legend direction="horizontal" />
          </Column>
        )}
      </LegendProvider>
    </Box>
  )
}

export default PerformanceBarChart
