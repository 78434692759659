import { put, takeLatest } from "redux-saga/effects"
import APIPromises from "../../../util/APIPromises"
import {
  retrieveNotificationSuccess,
  retrieveNotificationFailure,
  updateNotificationSuccess,
  updateNotificationFailure,
} from "../action"

function* retrieveNotification(req) {
  try {
    const response = yield APIPromises.retrieveNotification(req.payload)
    const notification = response.data.listNotificationResponse
    yield put(retrieveNotificationSuccess(notification))
  } catch (error) {
    yield put(retrieveNotificationFailure(error))
  }
}

export function* retrieveNotificationWatcher() {
  yield takeLatest("RETRIEVE_NOTIFICATION", retrieveNotification)
}

function* updateNotification(req) {
  try {
    const response = yield APIPromises.updateNotification(req.payload)
    const notification = response.data
    yield put(updateNotificationSuccess(notification))
  } catch (error) {
    yield put(updateNotificationFailure(error))
  }
}

export function* updateNotificationWatcher() {
  yield takeLatest("UPDATE_NOTIFICATION", updateNotification)
}
