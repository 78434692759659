export const addAuditReport = payload => ({
  type: "ADD_AUDIT_REPORT",
  payload: payload,
})

export const addAuditReportSuccess = () => ({
  type: "ADD_AUDIT_REPORT_SUCCESS",
  success: true,
})

export const addAuditReportFailure = () => ({
  type: "ADD_AUDIT_REPORT_FAILURE",
  success: false,
})

export const updateAuditReport = payload => ({
  type: "EDIT_AUDIT_REPORT",
  payload: payload,
})

export const updateAuditReportSuccess = () => ({
  type: "EDIT_AUDIT_REPORT_SUCCESS",
  success: true,
})

export const updateAuditReportFailure = () => ({
  type: "EDIT_AUDIT_REPORT_FAILURE",
  success: false,
})

export const deleteAuditReport = (auditReportId, cmName, testLocation) => ({
  type: "DELETE_AUDIT_REPORT",
  payload: {
    auditReportId: auditReportId,
    cmName: cmName,
    testLocation: testLocation,
  },
})

export const deleteAuditReportSuccess = () => ({
  type: "DELETE_AUDIT_REPORT_SUCCESS",
  success: true,
})

export const deleteAuditReportFailure = () => ({
  type: "DELETE_AUDIT_REPORT_FAILURE",
  success: false,
})

export const retrieveAuditReport = payload => ({
  type: "RETRIEVE_AUDIT_REPORT",
  payload,
})

export const retrieveAuditReportSuccess = allIssues => ({
  type: "RETRIEVE_AUDIT_REPORT_SUCCESS",
  payload: allIssues,
})
export const retrieveAuditReportFailure = res => ({
  type: "RETRIEVE_AUDIT_REPORT_FAILURE",
  payload: res,
})
export const retrieveAuditIssues = auditReportId => ({
  type: "RETRIEVE_AUDIT_ISSUES",
  auditReportId,
})

export const addAuditIssues = allIssues => ({
  type: "ADD_AUDIT_ISSUES",
  allIssues,
})

export const addAuditIssuesSuccess = res => ({
  type: "ADD_AUDIT_ISSUES_SUCCESS",
  success: true,
})

export const addAuditIssuesFailure = res => ({
  type: "ADD_AUDIT_ISSUES_FAILURE",
  success: true,
})

export const editAuditIssues = (allIssues, auditReportId) => ({
  type: "EDIT_AUDIT_ISSUES",
  allIssues,
  auditReportId,
})

export const editAuditIssuesSuccess = res => ({
  type: "EDIT_AUDIT_ISSUES_SUCCESS",
  success: true,
})
export const editAuditIssuesFailure = res => ({
  type: "EDIT_AUDIT_ISSUES_FAILURE",
  success: true,
})

export const addNextAuditDate = req => ({
  type: "ADD_NEXT_AUDIT_DATE",
  req,
})

export const addNextAuditDateSuccess = res => ({
  type: "ADD_NEXT_AUDIT_DATE_SUCCESS",
  success: true,
})

export const addNextAuditDateFailure = res => ({
  type: "ADD_NEXT_AUDIT_DATE_FAILURE",
  success: true,
})

export const selectCM = cm => ({
  type: "SELECT_CM_AUDIT",
  payload: cm,
})

export const selectProject = (project, projectIndex) => ({
  type: "SELECT_PROJECT_AUDIT",
  payload: {
    project,
    projectIndex,
  },
})

export const retrieveProject = cmName => ({
  type: "RETRIEVE_PROJECT_AUDIT",
  cmName,
})

export const retrieveProjectSuccess = project => ({
  type: "RETRIEVE_PROJECT_AUDIT_SUCCESS",
  payload: project,
})
export const retrieveProjectFailure = res => ({
  type: "RETRIEVE_PROJECT_AUDIT_FAILURE",
  payload: res,
})

export const retrieveAuditIssuesSuccess = allIssues => ({
  type: "RETRIEVE_AUDIT_ISSUES_SUCCESS",
  payload: allIssues,
})
export const retrieveAuditIssuesFailure = res => ({
  type: "RETRIEVE_AUDIT_ISSUES_FAILURE",
  payload: res,
})

export const deleteAuditIssues = allIssues => ({
  type: "DELETE_AUDIT_ISSUES",
  payload: allIssues,
})

export const deleteAuditIssuesSuccess = res => ({
  type: "DELETE_AUDIT_ISSUES_SUCCESS",
  success: true,
})
export const deleteAuditIssuesFailure = res => ({
  type: "DELETE_AUDIT_ISSUES_FAILURE",
  success: true,
})
