import React, { useState, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import Select, { SelectOption } from "@amzn/meridian/select"
import Loader from "@amzn/meridian/loader"
import Column from "@amzn/meridian/column"
import Button from "@amzn/meridian/button"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import Icon from "@amzn/meridian/icon"
import deleteTokens from "@amzn/meridian-tokens/base/icon/delete"
import axios from "axios"
import qs from "qs"

import { openToast } from "../../../api/toasts"
import {
  SUCCESS_TIMEOUT_IN_MS,
  FAILURE_TIMEOUT_IN_MS,
} from "../../../data/constants"
import { retrieveEquipmentFiles } from "../../../redux/equipmentDetail/action"
import FileInputWrapper from "../../../components/file-library/fileInputWrapper"
import env from "../../../services/Auth/env"
import "../../../App.css"
import * as auth from "../../../services/Auth/auth"

const FILE_TYPES = [
  "Calibration Report",
  "Checklist",
  "SOP",
  "Maintenance Record",
  "Training Record",
  "Trial Run Data",
  "Daily Check",
]

const EquipRow = props => {
  const {
    handleDeleteRow,
    handleSelectFiles,
    showDeleteButton,
    fileIds,
    cm,
    testLocation,
    equipmentId,
    username,
  } = props
  const [type, setType] = useState()
  const [options, setOptions] = useState([])
  const [loadingFiles, setLoadingFiles] = useState(false)

  const loadFiles = callback => {
    let query = qs.stringify({
      fileType: type,
      cm: cm,
      testLocation: testLocation,
      fileLevel: "equipment",
      // TODO: fix the tricky by removing the equipmentId in the API
      equipmentId: 0,
    })
    setLoadingFiles(true)
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT + env.APIPath.retrieveFilesByFileType + "?" + query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(response => {
          setLoadingFiles(false)
          let fileList = response.data.RetrieveFilesByFileTypeResponse
          setOptions(
            fileList
              .filter(f => f.equipmentId !== equipmentId)
              .map(file => ({
                value: JSON.stringify({
                  fileId: file.fileId,
                  fileType: type,
                  fileName: file.fileName,
                }),
                label: file.fileName,
              }))
          )
          callback && callback()
        })
        .catch(error => {
          setLoadingFiles(false)
          console.warn("error", error)
        })
    })
  }

  return (
    <Row spacingInset="small none">
      <Select
        value={type}
        onChange={value => {
          if (value !== type) {
            // reset when type changed
            handleSelectFiles([])
          }
          setType(value)
        }}
        minWidth={240}
        size={"small"}
        placeholder={"Choose File Type"}
      >
        {FILE_TYPES.map((x, index) => (
          <SelectOption key={index} value={x} label={x} />
        ))}
      </Select>
      <Select
        value={fileIds}
        onChange={handleSelectFiles}
        minWidth={240}
        disabled={!type}
        size={"small"}
        placeholder={"Choose File(s)"}
        onFocus={() => {
          loadFiles()
        }}
      >
        {loadingFiles && <Loader size="medium" />}
        {options.map(type => (
          <SelectOption
            key={type.value}
            value={type.value}
            label={type.label}
          />
        ))}
        {!options.length && !loadingFiles ? (
          // If there are no matched options, we display a message to our
          // users saying their query returned no results
          <Column alignmentVertical="center" spacing="300" spacingInset="300">
            <Text alignment="center">No results</Text>
          </Column>
        ) : null}
      </Select>
      <FileInputWrapper
        uploadButtonDisabled={!type}
        uploadButtonLabel={""}
        showDropZone={false}
        accept=".pdf"
        fileParam={{
          file_level: "equipment",
          file_type: type,
          cm: cm,
          cm_location: testLocation,
          uploaded_by: username,
        }}
        simpleDetail={true}
        callback={(fileName, fileId) => {
          // setValue(fileId)
          loadFiles(() =>
            handleSelectFiles([
              {
                fileId: fileId,
                fileType: type,
                fileName: fileName,
              },
            ])
          )
        }}
      />
      {showDeleteButton && (
        <Button type="link" onClick={handleDeleteRow}>
          <Icon tokens={deleteTokens}>Close</Icon>
        </Button>
      )}
    </Row>
  )
}

const AddEquipmentFileModal = props => {
  const {
    addEquipmentFileModalOpen,
    setAddEquipmentFileModalOpen,
    currentData,
    callback,
  } = props

  const reduxDispatch = useDispatch()

  const [rowList, setRowList] = useState([[]])

  // for sorting

  const { cmIndex } = useSelector(state => state.equipmentReducer)
  const { locationList } = useSelector(state => state.locationReducer)
  const { username } = useSelector(state => state.userReducer)

  const handleSelectFiles = (files, rowIndex) => {
    let temp = JSON.parse(JSON.stringify(rowList))
    temp[rowIndex] = files.map(x => {
      if (typeof x === "string") {
        return JSON.parse(x)
      } else {
        return x
      }
    })
    setRowList(temp)
  }

  const handleAddNewRow = () => {
    let temp = JSON.parse(JSON.stringify(rowList))
    temp.push([])
    setRowList(temp)
  }

  const handleDeleteRow = index => {
    setRowList(rowList.filter((_, i) => i !== index))
  }

  const resetState = () => {
    setAddEquipmentFileModalOpen(false)
    setRowList([[]])
  }

  return (
    <Modal
      title={"Attach File(s)"}
      open={addEquipmentFileModalOpen}
      // onClose={() => resetState()}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
      width={700}
    >
      <Button type="tertiary" size="small" onClick={handleAddNewRow}>
        Add More
      </Button>
      {rowList.map((row, index) => (
        <EquipRow
          key={index}
          handleDeleteRow={() => handleDeleteRow(index)}
          handleSelectFiles={value => handleSelectFiles(value, index)}
          fileIds={row.map(x => JSON.stringify(x))}
          showDeleteButton={index !== 0}
          cm={locationList[cmIndex]?.cm}
          testLocation={locationList[cmIndex]?.cmLocation}
          equipmentId={currentData.equipmentId}
          username={username}
        />
      ))}

      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            minWidth={90}
            // disabled={fileIds.length < 1}
            disabled={rowList.some(row => row.length === 0)}
            size="small"
            type="primary"
            onClick={() => {
              // let selectedFileIds = Array.from(new Set(rowList.flat())
              callback(rowList)
              resetState()
            }}
          >
            Attach
          </Button>
          <Button
            type="secondary"
            size="small"
            onClick={() => {
              resetState()
            }}
          >
            Cancel
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default AddEquipmentFileModal
