import { openToast } from "../../../api/toasts"
import {
  SUCCESS_TIMEOUT_IN_MS,
  FAILURE_TIMEOUT_IN_MS,
} from "../../../data/constants"

const INITIAL_STATE = {
  userList: [],
  isLoading: false,
}
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "RETRIEVE_USER_LIST":
      console.warn("RETRIEVE_USER_LIST")
      return {
        ...state,
        userList: [],
      }
    case "RETRIEVE_USER_LIST_SUCCESS":
      console.warn("RETRIEVE_USER_LIST_SUCCESS", action.payload)
      return {
        ...state,
        userList: action.payload,
      }
    case "RETRIEVE_USER_LIST_FAILURE":
      console.warn("RETRIEVE_USER_LIST_FAILURE")
      return {
        ...state,
      }
    case "ADD_USER":
      return {
        ...state,
        isLoading: true,
      }
    case "ADD_USER_SUCCESS":
      openToast({
        type: "success",
        title: "Add Done",
        message: "Add new user successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "ADD_USER_FAILURE":
      openToast({
        type: "error",
        title: "Add failed",
        message: "Failed to add new user, please try again",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "UPDATE_USER":
      return {
        ...state,
        isLoading: true,
      }
    case "UPDATE_USER_SUCCESS":
      openToast({
        type: "success",
        title: "update Done",
        message: "update user successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "UPDATE_USER_FAILURE":
      openToast({
        type: "error",
        title: "update failed",
        message: "Failed to update user, please try again",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "DELETE_USER":
      console.warn("DELETE_USER")
      return {
        ...state,
        isLoading: true,
      }
    case "DELETE_USER_SUCCESS":
      console.warn("DELETE_USER_SUCCESS", action.payload)
      openToast({
        type: "success",
        title: "Delete Done",
        message: "Delete user successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "DELETE_USER_FAILURE":
      console.warn("DELETE_USER_FAILURE", action.payload)
      openToast({
        type: "error",
        title: "Delete failed",
        message: "Failed to delete user, please try again",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default reducer
