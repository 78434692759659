import React, { useState, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import Row from "@amzn/meridian/row"
import Link from "@amzn/meridian/link"
import Text from "@amzn/meridian/text"
import Loader from "@amzn/meridian/loader"
import Button from "@amzn/meridian/button"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import SearchField from "@amzn/meridian/search-field"
import Table, {
  TableRow,
  TableCell,
  TableActionBar,
} from "@amzn/meridian/table"
import Icon from "@amzn/meridian/icon"
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large"
import thumbsUpTokens from "@amzn/meridian-tokens/base/icon/thumbs-up"
import thumbsDownTokens from "@amzn/meridian-tokens/base/icon/thumbs-down"
import { useHistory } from "react-router-dom"

import { getRegex } from "../../../util/Util"
import { retrieveProjectFilesByType } from "../../../redux/project/action"
import FileInputWrapper from "../../../components/file-library/fileInputWrapper"
// import env from "../../../services/Auth/env"
import { approveFile } from "../../../redux/fileLibrary/action"
import { ApproveModal } from "../../fileLibrary/modals"

import EmptyState from "../../../components/app/empty-state"
import "../../../App.css"

const ProjectFileModal = props => {
  const {
    projectFileModalOpen,
    setProjectFileModalOpen,
    fileType,
    modalTitle,
    cm,
    currentData,
  } = props

  const { projectFiles, isLoadingFiles } = useSelector(
    state => state.projectReducer
  )
  const history = useHistory()

  const { username, isCMUser } = useSelector(state => state.userReducer)
  const isApprovingFiles = useSelector(
    state => state.fileLibraryReducer.isLoading
  )
  const [approveModalOpen, setApproveModalOpen] = useState(false)
  const [currentFile, setCurrentFile] = useState(undefined)

  const [keyword, setKeyword] = useState("")
  // for sorting
  const [sortColumn, setSortColumn] = useState("fileName")
  const [sortDirection, setSortDirection] = useState("ascending")
  const onSort = useCallback(({ sortColumn, sortDirection }) => {
    setSortDirection(sortDirection)
    setSortColumn(sortColumn)
  }, [])

  const resetState = () => {
    setKeyword("")
    setProjectFileModalOpen(false)
  }

  projectFiles.sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) {
      return sortDirection === "ascending" ? -1 : 1
    }
    if (a[sortColumn] > b[sortColumn]) {
      return sortDirection === "ascending" ? 1 : -1
    }
    return 0
  })
  const reduxDispatch = useDispatch()

  const handleApproveFile = data => {
    console.warn("current file", currentFile, data)
    reduxDispatch(
      approveFile({
        ...currentFile,
        ...data,
        cm: cm,
        cmLocation: currentData?.cmLocation,
        fileType: fileType,
      })
    )
  }

  useEffect(() => {
    if (projectFileModalOpen && isApprovingFiles === false) {
      reduxDispatch(
        retrieveProjectFilesByType({
          fileType,
          cm: cm,
          projectName: `${currentData.projectFamilyName} - ${currentData.projectName}`,
        })
      )
    }
  }, [
    reduxDispatch,
    projectFileModalOpen,
    isApprovingFiles,
    cm,
    fileType,
    currentData,
  ])

  return (
    <Modal
      title={modalTitle}
      open={projectFileModalOpen}
      onClose={() => resetState()}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
      width={700}
    >
      <Table
        headerRows={1}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={onSort}
        showDividers={true}
        spacing="small"
        stickyHeaderRow={true}
      >
        <TableActionBar widths={[300, "fill"]}>
          <SearchField
            value={keyword}
            onChange={setKeyword}
            placeholder="Search for..."
            type="text"
            onSubmit={() => {}}
          />
          <div />
          <FileInputWrapper
            // simpleDetail={false}
            uploadButtonLabel={"Upload New Files"}
            uploadButtonSize={"medium"}
            fileParam={{
              file_level: "project",
              file_type: fileType,
              cm: cm,
              cm_location: currentData?.cmLocation,
              uploaded_by: username,
              projectName: `${currentData?.projectFamilyName} - ${currentData?.projectName}`,
            }}
            callback={() => {
              reduxDispatch(
                retrieveProjectFilesByType({
                  fileType,
                  cm: cm,
                  projectName: `${currentData.projectFamilyName} - ${currentData.projectName}`,
                })
              )
            }}
          />
        </TableActionBar>
        <TableRow>
          <TableCell sortColumn="fileName">File Name</TableCell>
          <TableCell sortColumn="status">Status</TableCell>
          <TableCell sortColumn="uploadedDate">Upload Date</TableCell>
          <TableCell>Approve/Reject</TableCell>
          <TableCell>Download</TableCell>
        </TableRow>
        {projectFiles &&
          projectFiles
            .filter(f => {
              let regex = getRegex(keyword)
              return regex.test(JSON.stringify(f))
            })
            .map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Link
                    type="secondary"
                    onClick={() => {
                      history.push({
                        pathname: "file-library",
                        state: {
                          fileName: row.fileName,
                        },
                      })
                    }}
                  >
                    {row.fileName}
                  </Link>
                </TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.uploadedDate}</TableCell>
                <TableCell alignmentHorizontal={"center"}>
                  <Button
                    type="icon"
                    disabled={isCMUser}
                    onClick={() => {
                      setApproveModalOpen(true)
                      setCurrentFile(row)
                    }}
                  >
                    <Icon
                      tokens={
                        row.status === "UnApproved"
                          ? thumbsDownTokens
                          : thumbsUpTokens
                      }
                      className={`${row.status}-color`}
                    />
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    type="icon"
                    onClick={() => {
                      window.open(row.presignedURL, "_blank")
                    }}
                  >
                    <Icon
                      className={"download-button"}
                      tokens={downloadLargeTokens}
                    />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
      </Table>
      {isLoadingFiles ? (
        <Row alignmentHorizontal="center" height={40}>
          <Text>Loading...</Text>
          <Loader type="linear" size="small" />
        </Row>
      ) : (
        projectFiles?.length === 0 && <EmptyState />
      )}

      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            type="secondary"
            size="small"
            onClick={() => {
              resetState()
            }}
          >
            Cancel
          </Button>
        </Row>
      </ModalFooter>
      <ApproveModal
        approveModalOpen={approveModalOpen}
        setApproveModalOpen={setApproveModalOpen}
        onApproveFile={handleApproveFile}
      />
    </Modal>
  )
}

export default ProjectFileModal
