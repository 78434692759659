import React, { useState, useEffect } from "react"
// import {useHistory} from "react-router-dom";
import Row from "@amzn/meridian/row"
// import Checkbox from "@amzn/meridian/checkbox";
import Button from "@amzn/meridian/button"
import Column from "@amzn/meridian/column"
import Text from "@amzn/meridian/text"
import Icon from "@amzn/meridian/icon"

import Modal, { ModalFooter } from "@amzn/meridian/modal"
import Select, { SelectOptionGroup, SelectOption } from "@amzn/meridian/select"
import Input from "@amzn/meridian/input"

import deleteTokens from "@amzn/meridian-tokens/base/icon/delete"

import { useDispatch, useSelector } from "react-redux"
import { FormInput, FormSelect } from "../../../components/app"

import { copyEquipment } from "../../../redux/equipment/action"

import { retrieveCapabilityTemplateList } from "../../../redux/capabilityTemplate/action"

const EquipRow = props => {
  const {
    equipmentName,
    handleChangeName,
    handleDeleteEquipment,
    showDeleteButton,
  } = props
  return (
    <Row spacingInset="small none">
      <Column minWidth="150px">
        <Text tag="label" type="b200" color="primary" htmlFor="my-input">
          {"To"}
        </Text>
      </Column>
      <Input
        value={equipmentName}
        onChange={handleChangeName}
        size={"small"}
        width={250}
      />
      {showDeleteButton && (
        <Button type="link" onClick={handleDeleteEquipment}>
          <Icon tokens={deleteTokens}>Close</Icon>
        </Button>
      )}
    </Row>
  )
}

const CopyEquipmentModal = props => {
  // eslint-disable-next-line no-unused-vars
  const {
    copyEquipmentModalOpen,
    setCopyEquipmentModalOpen,
    cm,
    cmLocation,
  } = props
  const [equipmentList, setEquipmentList] = useState([""])
  const [sourceId, setSourceId] = useState()
  const { username } = useSelector(state => state.userReducer)

  const { cmIndex, equipment } = useSelector(state => state.equipmentReducer)
  const { locationList } = useSelector(state => state.locationReducer)
  const reduxDispatch = useDispatch()

  const handleChangeName = (name, rowIndex) => {
    let temp = JSON.parse(JSON.stringify(equipmentList))
    console.warn("handlechange", name)
    temp[rowIndex] = name
    setEquipmentList(temp)
  }

  const handleAddNewEquipment = () => {
    let temp = JSON.parse(JSON.stringify(equipmentList))
    temp.push("")
    setEquipmentList(temp)
  }

  const handleDeleteEquipment = index => {
    setEquipmentList(equipmentList.filter((_, i) => i !== index))
  }

  const resetState = () => {
    setCopyEquipmentModalOpen(false)
    setEquipmentList([""])
    setSourceId()
  }

  return (
    <Modal
      title="Copy Equipment"
      open={copyEquipmentModalOpen}
      onClose={resetState}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
      // width="500px"
    >
      <FormSelect
        value={sourceId}
        setValue={setSourceId}
        label={"Copy From"}
        options={equipment.map(x => ({
          value: x.equipmentId,
          label: x.equipmentName,
        }))}
      />
      <Button type="tertiary" size="small" onClick={handleAddNewEquipment}>
        Add More
      </Button>
      {equipmentList.map((equip, index) => (
        <EquipRow
          key={index}
          handleChangeName={value => handleChangeName(value, index)}
          handleDeleteEquipment={() => handleDeleteEquipment(index)}
          equipmentName={equip}
          showDeleteButton={index !== 0}
        />
      ))}
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            type="primary"
            size="small"
            disabled={equipmentList.some(equip =>
              Object.values(equip).some(x => x.length === 0)
            )}
            onClick={() => {
              console.warn("equipment list", equipmentList)
              reduxDispatch(
                copyEquipment({
                  sourceId,
                  cm: locationList[cmIndex]?.cm,
                  cmLocation: locationList[cmIndex]?.cmLocation,
                  targetItems: equipmentList,
                })
              )
              resetState()
            }}
          >
            Add
          </Button>
          <Button
            type="secondary"
            size="small"
            onClick={() => resetState()}
          >
            Cancel
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default CopyEquipmentModal
