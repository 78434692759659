import { useCallback, useState } from "react"

const useSort = data => {
  const [sortColumn, setSortColumn] = useState("")
  const [sortDirection, setSortDirection] = useState("ascending")
  const onSort = useCallback(({ sortColumn, sortDirection }) => {
    setSortDirection(sortDirection)
    setSortColumn(sortColumn)
  }, [])

  let sortedData = [...data]

  sortedData.sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) {
      return sortDirection === "ascending" ? -1 : 1
    }
    if (a[sortColumn] > b[sortColumn]) {
      return sortDirection === "ascending" ? 1 : -1
    }
    return 0
  })

  return {
    sortedData,
    onSort,
    sortColumn,
    sortDirection,
  }
}

export default useSort
