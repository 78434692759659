/**
 * The application landing page/home page.
 */
import React, { useState, useEffect } from "react"
import * as xlsx from "xlsx"
// import Loader from "@amzn/meridian/loader"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import ButtonGroup, { ButtonOption } from "@amzn/meridian/button-group"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"

import Table, {
  TableRow,
  TableCell,
  TableActionBar,
} from "@amzn/meridian/table"
import Icon from "@amzn/meridian/icon"
import Link from "@amzn/meridian/link"
import Text from "@amzn/meridian/text"
import SearchField from "@amzn/meridian/search-field"
import { useHistory } from "react-router-dom"
import { css } from "emotion"
import { usePagination, useSort } from "../../../hooks"

import PageLayout from "../../../components/app/page-layout"
import EmptyState from "../../../components/app/empty-state"
import { fileTypeOptions } from "../../../data/options"
import { Select, SelectOption } from "../../../components/app/form-fields"

import { getRegex, retrieveData } from "../../../util/Util"
import env from "../../../services/Auth/env"

const getActualStatus = num => {
  switch (num) {
    case "0":
      return "Pending for review"
    case "1":
      return "Approved"
    case "-1":
      return "Expired"
    case "-2":
      return "Unapproved"
    default:
      return "No File"
  }
}

const StatusChecklist = () => {
  const [statusList, setStatusList] = useState([])
  const [mode, setMode] = useState("Approval Status")
  const [quickFilter, setQuickFilter] = useState("all")

  const { locationList } = useSelector(state => state.locationReducer)
  const { isCMUser, isAdmin } = useSelector(state => state.userReducer)
  const [isLoading, setIsLoading] = useState(false)
  const [cmIndex, setCMIndex] = useState()

  const location = useLocation()

  // for searching and filtering
  const [keyword, setKeyword] = useState("")

  const data = statusList.filter(f => {
    let regex = getRegex(keyword)
    return regex.test(JSON.stringify(f))
  })

  useEffect(() => {
    const { cm, testLocation, filter } = Object.fromEntries(
      new URLSearchParams(location.search).entries()
    )

    // find cm index
    if (cm && testLocation) {
      const index = locationList?.findIndex(
        x => x.cm === cm && x.cmLocation === testLocation
      )
      if (index > -1 && cmIndex !== index) {
        setCMIndex(index)
      }
    } else {
      setCMIndex(isCMUser ? 0 : -1)
    }

    if (filter && quickFilter !== filter) {
      setQuickFilter(filter)
    }
    
  }, [location, locationList, isCMUser, quickFilter, cmIndex])

  useEffect(() => {
    if (Number.isInteger(cmIndex)) {
      let query = {}
      if (cmIndex > -1) {
        query = {
          cm: locationList[cmIndex]?.cm,
          cmLocation: locationList[cmIndex]?.cmLocation,
        }
      }
      setIsLoading(true)
      retrieveData(query, env.APIPath.retrieveStatusChecklist, data => {
        setIsLoading(false)
        if (data.EquipmentStatusChecklistDataList) {
          setStatusList(data.EquipmentStatusChecklistDataList)
        } else {
          setStatusList([])
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmIndex])


  const quickfilteredEquipment = () => {
    if (quickFilter === "all") {
      return data
    } else if (quickFilter === "approved") {
      return data?.filter(equipment => {
        return fileTypeOptions.some(({value, label}) => {
          return equipment?.[value]?.fileStatus === "1"
        })
      })
    } else if (quickFilter === "unapproved") {
      return data?.filter(equipment => {
        return fileTypeOptions.some(({value, label}) => {
          return equipment?.[value]?.fileStatus === "-2"
        })
      })
    } else if (quickFilter === "expired") {
      return data?.filter(equipment => {
        return fileTypeOptions.some(({value, label}) => {
          return equipment?.[value]?.fileStatus === "-1"
        })
      })
    } else if (quickFilter === "pending") {
      return data?.filter(equipment => {
        return fileTypeOptions.some(({value, label}) => {
          return equipment?.[value]?.fileStatus === "0"
        })
      })
    } else if (quickFilter === "noFile") {
      return data?.filter(equipment => {
        return fileTypeOptions.some(({value, label}) => {
          return !equipment?.[value]?.fileStatus
        })
      })
    }
  }

  const quickfilteredData = quickfilteredEquipment()

  const { sortedData, onSort, sortColumn, sortDirection } = useSort(quickfilteredData)

  const history = useHistory()
  const { TableFooter, visibleIndices } = usePagination(quickfilteredData.length)

  const getFileStatus = (mode, row, option) => {
    return mode === "Approval Status"
      ? getActualStatus(row[option.value]?.fileStatus) || "No File"
      : row[option.value]?.lastUpdateDate || "No File"
  }

  const getFileStatusColor = (mode, row, option) => {
    const status = getFileStatus(mode, row, option)
    switch (status) {
      case "No File":
        return "gray"
      case "Expired":
      case "Unapproved":
        return "red"
      case "Pending for review":
        return "orange"
      case "Approved":
        return "green"
      default:
        return "black"
    }
  }

  return (
    <React.Fragment>
      <PageLayout
        spacing="large"
        alignmentHorizontal={"start"}
        title="Status Checklist"
        width={"100%"}
        loading={isLoading}
      >
        <Row spacingInset="small none">
          <Column spacing="large">
            <Select
              label="CM Location"
              value={cmIndex}
              onChange={value => {
                setCMIndex(value)
                if (value !== -1) {
                  history.push(
                    `/lab-management/status-checklist?cm=${locationList[value]?.cm}&testLocation=${locationList[value]?.cmLocation}&filter=${quickFilter}`,
                    { shallow: true },
                  )
                } else {
                  history.push(
                    `/lab-management/status-checklist?filter=${quickFilter}`,
                    { shallow: true }
                  )
                }
              }}
              minWidth={240}
              size={"small"}
            >
              {isCMUser
                ? locationList.map((x, index) => (
                    <SelectOption
                      key={index}
                      value={index}
                      label={`${x.cm}-${x.cmLocation}`}
                    />
                  ))
                : [<SelectOption key={-1} value={-1} label={"All"} />].concat(
                    locationList.map((x, index) => (
                      <SelectOption
                        key={index}
                        value={index}
                        label={`${x.cm}-${x.cmLocation}`}
                      />
                    ))
                  )}
            </Select>
          </Column>
        </Row>
        <Row spacingInset="small none">
          <Column spacing="large" width="100%">
            <ButtonGroup size="small" type="outline" value={mode} onChange={setMode}>
              <ButtonOption value="Approval Status">
                {"Approval Status"}
              </ButtonOption>
              <ButtonOption value="Last Update Date">
                {"Last Update Date"}
              </ButtonOption>
            </ButtonGroup>
            {setStatusList?.length > 0 && (
              <Row>
                <Text>Quick Filters: </Text>
                <ButtonGroup
                  value={quickFilter}
                  onChange={value => {
                    setQuickFilter(value)
                    if (Number.isInteger(cmIndex) && cmIndex !== -1) {
                      history.push(
                        `/lab-management/status-checklist?cm=${locationList[cmIndex]?.cm}&testLocation=${locationList[cmIndex]?.cmLocation}&filter=${value}`,
                        { shallow: true },
                      )
                    } else {
                      history.push(
                        `/lab-management/status-checklist?filter=${value}`,
                        { shallow: true },
                      )
                    }
                  }}
                  size="small"
                >
                  <ButtonOption value="all">{`All`}</ButtonOption>
                  <ButtonOption value="approved">{`Approved`}</ButtonOption>
                  <ButtonOption value="unapproved">{`Unapproved`}</ButtonOption>
                  <ButtonOption value="expired">{`Expired`}</ButtonOption>
                  <ButtonOption value="pending">{`Pending`}</ButtonOption>
                  <ButtonOption value="noFile">{`No File`}</ButtonOption>
                </ButtonGroup>
              </Row>
            )}
            <Table
              headerRows={1}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              onSort={onSort}
              showDividers={true}
              spacing="small"
              // stickyHeaderRow={true}
              fixHeaderRows={true}
              // showStripes={true}
            >
              <TableActionBar widths={[300]}>
                <SearchField
                  value={keyword}
                  onChange={setKeyword}
                  placeholder="Search for..."
                  // size="medium"
                  type="text"
                  onSubmit={() => {}}
                />
              </TableActionBar>
              <TableRow>
                <TableCell sortColumn="equipmentName">Equipment</TableCell>
                <TableCell sortColumn="cmName">CM</TableCell>
                <TableCell sortColumn="cmLocation">Test Location</TableCell>
                {fileTypeOptions.map((option, index) => (
                  <TableCell width={"9%"} key={index}>{option.label}</TableCell>
                ))}
              </TableRow>
              {sortedData &&
                sortedData
                  .slice(visibleIndices[0], visibleIndices[1])
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Link
                          onClick={() => {
                            history.push(
                              `/equipment-details?cm=${row.cmName}&testLocation=${row.cmLocation}&equipmentId=${row.equipmentId}`
                            )
                          }}
                        >
                          {row.equipmentName}
                        </Link>
                        <p>
                          {row.equipmentLabel && (
                            <Text className="inline-text">{`Label: ${row.equipmentLabel}; `}</Text>
                          )}
                          {row.equipmentSerialNumber && (
                            <Text className="inline-text">{`SN: ${row.equipmentSerialNumber}; `}</Text>
                          )}
                          {row.equipmentBrand && (
                            <Text className="inline-text">{`Brand: ${row.equipmentBrand}; `}</Text>
                          )}
                        </p>
                      </TableCell>
                      <TableCell>{row.cmName}</TableCell>
                      <TableCell>{row.cmLocation}</TableCell>

                      {fileTypeOptions.map((option, index) => (
                        <TableCell key={index}>
                          <Text
                            className={css({
                              color: getFileStatusColor(mode, row, option),
                            })}
                          >
                            {getFileStatus(mode, row, option)}
                          </Text>
                        </TableCell>
                      ))}
                      <TableCell></TableCell>
                      <TableCell>{row.userGroup}</TableCell>
                    </TableRow>
                  ))}
            </Table>
            {sortedData && sortedData.length === 0 ? (
              <EmptyState target={"equipment"} />
            ) : null}
            <TableFooter />
          </Column>
        </Row>
      </PageLayout>
    </React.Fragment>
  )
}

export default StatusChecklist
