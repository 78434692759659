import { put, takeLatest } from "redux-saga/effects"
import APIPromises from "../../../util/APIPromises"
import {
  retrieveAllEquipmentSuccess,
  retrieveAllEquipmentFailure,
  retrieveCalibratedEquipsSuccess,
  retrieveCalibratedEquipsFailure,
  addEquipmentSuccess,
  addEquipmentFailure,
  copyEquipmentSuccess,
  copyEquipmentFailure,
  updateEquipmentSuccess,
  updateEquipmentFailure,
  deleteEquipmentSuccess,
  deleteEquipmentFailure,
  retrieveFilesBySOPSuccess,
  retrieveFilesBySOPFailure,
  retrieveFilesByChecklistSuccess,
  retrieveFilesByChecklistFailure,
  retrieveFilesByCalibrationReportSuccess,
  retrieveFilesByCalibrationReportFailure,
  retrieveFilesByTypeSuccess,
  retrieveFilesByTypeFailure,
} from "../action"

function* retrieveAllEquipment(req) {
  try {
    const response = yield APIPromises.retrieveAllEquipment(req.payload)
    const equipment = response.data.retrieveEquipsByLocationResponse
    yield put(retrieveAllEquipmentSuccess(equipment))
  } catch (error) {
    yield put(retrieveAllEquipmentFailure(error))
  }
}

export function* retrieveAllEquipmentWatcher() {
  yield takeLatest("RETRIEVE_ALL_EQUIPMENT", retrieveAllEquipment)
}
function* retrieveCalibratedEquips(req) {
  try {
    const response = yield APIPromises.retrieveCalibratedEquips(
      req.cmLocation,
      req.cmName
    )
    const equipment = response.data.RetrieveCalibratedEquipResponse
    yield put(retrieveCalibratedEquipsSuccess(equipment))
  } catch (error) {
    yield put(retrieveCalibratedEquipsFailure(error))
  }
}

export function* retrieveCalibratedEquipsWatcher() {
  yield takeLatest("RETRIEVE_CALIBRATED_EQUIPS", retrieveCalibratedEquips)
}

function* addEquipment(req) {
  try {
    const res = yield APIPromises.addEquipmentPromise(req.payload)
    if (res.data && res.data.isSuccess === false) {
      yield put(addEquipmentFailure())
    } else {
      yield put({
        type: "RETRIEVE_ALL_EQUIPMENT",
        payload: {
          cm: req.payload.cm,
          test_location: req.payload.testLocation,
        },
      })
      const addProjectStatus = res.data
      yield put(addEquipmentSuccess(addProjectStatus))
    }
  } catch (error) {
    yield put(addEquipmentFailure(error))
  }
}
export function* addEquipmentWatcher() {
  yield takeLatest("ADD_EQUIPMENT", addEquipment)
}

function* copyEquipment(req) {
  try {
    const res = yield APIPromises.copyEquipmentPromise(req.payload)
    if (res.data && res.data.isSuccess === false) {
      yield put(copyEquipmentFailure())
    } else {
      yield put({
        type: "RETRIEVE_ALL_EQUIPMENT",
        payload: {
          cm: req.payload.cm,
          test_location: req.payload.cmLocation,
        },
      })
      const addProjectStatus = res.data
      yield put(copyEquipmentSuccess(addProjectStatus))
    }
  } catch (error) {
    yield put(copyEquipmentFailure(error))
  }
}
export function* copyEquipmentWatcher() {
  yield takeLatest("COPY_EQUIPMENT", copyEquipment)
}

function* updateEquipment(req) {
  try {
    const res = yield APIPromises.updateEquipment(req.payload)
    if (res.data && res.data.isSuccess === false) {
      yield put(updateEquipmentFailure())
    } else {
      yield put({
        type: "RETRIEVE_ALL_EQUIPMENT",
        payload: {
          cm: req.payload.cm,
        },
      })
      const addProjectStatus = res.data
      yield put(updateEquipmentSuccess(addProjectStatus))
    }
  } catch (error) {
    yield put(updateEquipmentFailure(error))
  }
}
export function* updateEquipmentWatcher() {
  yield takeLatest("UPDATE_EQUIPMENT", updateEquipment)
}

function* deleteEquipment(req) {
  try {
    const res = yield APIPromises.deleteEquipment(req.payload)
    if (res.data && res.data.isSuccess === false) {
      yield put(deleteEquipmentFailure())
    } else {
      yield put({
        type: "RETRIEVE_ALL_EQUIPMENT",
        payload: {
          cm: req.payload.cm,
          test_location: req.payload.testLocation,
        },
      })
      const addProjectStatus = res.data
      yield put(deleteEquipmentSuccess(addProjectStatus))
    }
  } catch (error) {
    yield put(deleteEquipmentFailure(error))
  }
}
export function* deleteEquipmentWatcher() {
  yield takeLatest("DELETE_EQUIPMENT", deleteEquipment)
}

function* retrieveFilesBySOP(req) {
  try {
    const response = yield APIPromises.retrieveFilesByFileType({
      ...req.payload,
      fileType: "sop",
      fileLevel: "equipment",
    })
    if (response.data?.isSuccess === false || response.data?.errorMessage) {
      yield put(retrieveFilesBySOPFailure())
    } else {
      const allFiles = response.data.RetrieveFilesByFileTypeResponse
      yield put(retrieveFilesBySOPSuccess(allFiles))
    }
  } catch (error) {
    yield put(retrieveFilesBySOPFailure(error))
  }
}

export function* retrieveEquipSopFileWatcher() {
  yield takeLatest("RETRIEVE_EQUIPMENT_FILE_BY_SOP_TYPE", retrieveFilesBySOP)
}

function* retrieveFilesByChecklist(req) {
  try {
    const response = yield APIPromises.retrieveFilesByFileType({
      ...req.payload,
      fileType: "checklist",
      fileLevel: "equipment",
    })
    if (response.data?.isSuccess === false || response.data?.errorMessage) {
      yield put(retrieveFilesByChecklistFailure())
    } else {
      const allFiles = response.data.RetrieveFilesByFileTypeResponse
      yield put(retrieveFilesByChecklistSuccess(allFiles))
    }
  } catch (error) {
    yield put(retrieveFilesByChecklistFailure(error))
  }
}

export function* retrieveEquipFilesByChecklistWatcher() {
  yield takeLatest("RETRIEVE_FILE_BY_CHECKLIST_TYPE", retrieveFilesByChecklist)
}

function* retrieveFilesByCalibrationReport(req) {
  try {
    const response = yield APIPromises.retrieveFilesByFileType({
      ...req.payload,
      fileType: "calibrationReport",
      fileLevel: "equipment",
    })
    if (response.data?.isSuccess === false || response.data?.errorMessage) {
      yield put(retrieveFilesByCalibrationReportFailure())
    } else {
      const allFiles = response.data.RetrieveFilesByFileTypeResponse
      yield put(retrieveFilesByCalibrationReportSuccess(allFiles))
    }
  } catch (error) {
    yield put(retrieveFilesByCalibrationReportFailure(error))
  }
}

export function* retrieveEquipFilesByCalibrationReportWatcher() {
  yield takeLatest(
    "RETRIEVE_FILE_BY_CALIBRATION_REPORT_TYPE",
    retrieveFilesByCalibrationReport
  )
}

function* retrieveEquipmentFilesByType(req) {
  try {
    const response = yield APIPromises.retrieveFilesByFileType({
      ...req.payload,
      fileLevel: "equipment",
    })
    if (response.data?.isSuccess === false || response.data?.errorMessage) {
      yield put(retrieveFilesByTypeFailure())
    } else {
      const allFiles = response.data.RetrieveFilesByFileTypeResponse
      yield put(retrieveFilesByTypeSuccess(allFiles))
    }
  } catch (error) {
    yield put(retrieveFilesByTypeFailure(error))
  }
}

export function* retrieveEquipmentFilesByTypeWatcher() {
  yield takeLatest("RETRIEVE_FILES_BY_TYPE", retrieveEquipmentFilesByType)
}
