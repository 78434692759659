/**
 * This component renders an "empty" table illustration and a call to action
 * to create a new shipment. The messaging is different between a) no shipments
 * exists and b) no shipments were found with a particular query + filter combination.
 */

import React from "react"
import PropTypes from "prop-types"
import Column from "@amzn/meridian/column"
import Text from "@amzn/meridian/text"

const EmptyState = props => {
  return (
    <Column alignmentHorizontal="center" spacingInset="400 none none none">
      {props.searching ? (
        <Column alignmentHorizontal="center">
          <Text tag="p" alignment="center">
            No {props.target} found matching the given criteria.
          </Text>
        </Column>
      ) : (
        <Column alignmentHorizontal="center">
          <Text>You have no {props.target}.</Text>
        </Column>
      )}
    </Column>
  )
}

EmptyState.propTypes = {
  searching: PropTypes.bool,
  target: PropTypes.string,
}

EmptyState.defaultProps = {
  searching: false,
  target: "files",
}

export default EmptyState
