const fileTableColumns = [
  {
    id: "uploadedBy",
    label: "Uploaded By",
  },
  {
    id: "uploadedDate",
    label: "Uploaded Date",
  },
  {
    id: "approvedBy",
    label: "Approved By",
  },
  {
    id: "approvalDate",
    label: "Approval Date",
  },
]

export const defaultFileTableColumns = fileTableColumns.reduce(
  (columns, column) => ({
    ...columns,
    [column.id]: true,
  }),
  {}
)

export default fileTableColumns
