export const retrieveAllEquipment = data => ({
  type: "RETRIEVE_ALL_EQUIPMENT",
  payload: data,
})

export const retrieveAllEquipmentSuccess = equipment => ({
  type: "RETRIEVE_ALL_EQUIPMENT_SUCCESS",
  payload: equipment,
})
export const retrieveAllEquipmentFailure = res => ({
  type: "RETRIEVE_ALL_EQUIPMENT_FAILURE",
  payload: res,
})
export const retrieveCalibratedEquips = (cmLocation, cmName) => ({
  type: "RETRIEVE_CALIBRATED_EQUIPS",
  cmLocation,
  cmName,
})

export const retrieveCalibratedEquipsSuccess = equipment => ({
  type: "RETRIEVE_CALIBRATED_EQUIPS_SUCCESS",
  payload: equipment,
})
export const retrieveCalibratedEquipsFailure = res => ({
  type: "RETRIEVE_CALIBRATED_EQUIPS_FAILURE",
  payload: res,
})

export const addEquipment = equipment => ({
  type: "ADD_EQUIPMENT",
  payload: equipment,
})
export const addEquipmentSuccess = added => ({
  type: "ADD_EQUIPMENT_SUCCESS",
  payload: added,
})
export const addEquipmentFailure = res => ({
  type: "ADD_EQUIPMENT_FAILURE",
  payload: res,
})

export const copyEquipment = equipment => ({
  type: "COPY_EQUIPMENT",
  payload: equipment,
})
export const copyEquipmentSuccess = added => ({
  type: "COPY_EQUIPMENT_SUCCESS",
  payload: added,
})
export const copyEquipmentFailure = res => ({
  type: "COPY_EQUIPMENT_FAILURE",
  payload: res,
})

export const updateEquipment = equipment => ({
  type: "UPDATE_EQUIPMENT",
  payload: equipment,
})
export const updateEquipmentSuccess = added => ({
  type: "UPDATE_EQUIPMENT_SUCCESS",
  payload: added,
})
export const updateEquipmentFailure = res => ({
  type: "UPDATE_EQUIPMENT_FAILURE",
  payload: res,
})

export const deleteEquipment = equipmentId => ({
  type: "DELETE_EQUIPMENT",
  payload: equipmentId,
})
export const deleteEquipmentSuccess = added => ({
  type: "DELETE_EQUIPMENT_SUCCESS",
  payload: added,
})
export const deleteEquipmentFailure = res => ({
  type: "DELETE_EQUIPMENT_FAILURE",
  payload: res,
})

export const selectCM = cm => ({
  type: "SELECT_CM",
  payload: cm,
})

export const retrieveFilesBySOP = payload => ({
  type: "RETRIEVE_EQUIPMENT_FILE_BY_SOP_TYPE",
  payload,
})
export const retrieveFilesBySOPSuccess = files => ({
  type: "RETRIEVE_EQUIPMENT_FILE_BY_SOP_TYPE_SUCCESS",
  payload: files,
})
export const retrieveFilesBySOPFailure = res => ({
  type: "RETRIEVE_EQUIPMENT_FILE_BY_SOP_TYPE_FAILURE",
  payload: res,
})
export const retrieveFilesByChecklist = payload => ({
  type: "RETRIEVE_FILE_BY_CHECKLIST_TYPE",
  payload,
})
export const retrieveFilesByChecklistSuccess = files => ({
  type: "RETRIEVE_FILE_BY_CHECKLIST_TYPE_SUCCESS",
  payload: files,
})
export const retrieveFilesByChecklistFailure = res => ({
  type: "RETRIEVE_FILE_BY_CHECKLIST_TYPE_FAILURE",
  payload: res,
})

export const retrieveFilesByCalibrationReport = payload => ({
  type: "RETRIEVE_FILE_BY_CALIBRATION_REPORT_TYPE",
  payload,
})
export const retrieveFilesByCalibrationReportSuccess = files => ({
  type: "RETRIEVE_FILE_BY_CALIBRATION_REPORT_TYPE_SUCCESS",
  payload: files,
})
export const retrieveFilesByCalibrationReportFailure = res => ({
  type: "RETRIEVE_FILE_BY_CALIBRATION_REPORT_TYPE_FAILURE",
  payload: res,
})

export const retrieveFilesByType = payload => ({
  type: "RETRIEVE_FILES_BY_TYPE",
  payload,
})
export const retrieveFilesByTypeSuccess = files => ({
  type: "RETRIEVE_FILES_BY_TYPE_SUCCESS",
  payload: files,
})
export const retrieveFilesByTypeFailure = res => ({
  type: "RETRIEVE_FILES_BY_TYPE_FAILURE",
  payload: res,
})
