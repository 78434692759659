/* eslint-disable prettier/prettier */

import APIPath from "./api"

// get hostname
let hostname = window && window.location && window.location.hostname

// let devAuthSessionEndpoint = "https://adfs.lassostg.labcollab.net/adfs/"
// let prodAuthSessionEndpoint = "https://icecube.pdi.lab126.a2z.com/session/refresh"
// let LocalDevelopmentWebHost = "https://alpha-us-west-2.testfidelity.pdi.lab126.a2z.com"
let LocalDevelopmentWebHost =
  "dev-dsk-sijixiao-2a-f7b43439.us-west-2.amazon.com"
let alphaDevelopmentWebHost = "alpha-us-west-2.testfidelity.pdi.lab126.a2z.com"
let betaDevelopmentWebHost = "beta-us-west-2.testfidelity.pdi.lab126.a2z.com"
let gammaDevelopmentWebHost = "gamma-ap-east-1.testfidelity.pdi.lab126.a2z.com"
let prodDevelopmentWebHost = "testfidelity.pdi.lab126.a2z.com"

// let APIDevEndpoint = "http://localhost:8080"
let APIDevEndpoint =
  "https://alpha-us-west-2-bk.testfidelity.pdi.lab126.a2z.com"
// let APIDevEndpoint = "http://dualstack.ecsse-extfa-1vsstl58w218g-973788464.us-west-2.elb.amazonaws.com"
let APIAlphaEndpoint =
  "https://alpha-us-west-2-bk.testfidelity.pdi.lab126.a2z.com"
let APIBetaEndpoint =
  "https://beta-us-west-2-bk.testfidelity.pdi.lab126.a2z.com"
let APIGammaEndpoint =
  "https://gamma-ap-east-1-bk.testfidelity.pdi.lab126.a2z.com"
let APIProdEndpoint =
  "https://prod-ap-east-1-bk.testfidelity.pdi.lab126.a2z.com"
let lassoLoginUrlStage =
  "https://adfs.lassostg.labcollab.net/adfs/.well-known/openid-configuration"
let lassoLoginUrlProd =
  "https://adfs.lasso.labcollab.net/adfs/.well-known/openid-configuration"
let stage_client_id = "7775215e-84db-4b94-9443-c99754358bef" // test fidelity client id
let prod_client_id = "e9613356-e673-4b2d-b0a0-6b9e1e11f98b"
let stage_secret = "ZROyH78f631eUb85Ds_8J7UWUfNKWf3YjFPjh9o0"
let prod_secret = "edtwxVh0BNf5Hmp-H-hlfl3AT55BX-EWm1ko-f8b"
let token_endpoint_stag =
  "https://adfs.lassostg.labcollab.net/adfs/oauth2/token/"
let token_endpoint_prod = "https://adfs.lasso.labcollab.net/adfs/oauth2/token/"
let logout_stag = "https://adfs.lasso.labcollab.net/adfs/oauth2/logout"
let logout_prod = "https://adfs.lassostg.labcollab.net/adfs/oauth2/logout"

let betaTokenEndPoint =
  "https://vcy7o50u25.execute-api.us-west-2.amazonaws.com/prod/authn"
let client_id = ""
let client_secret = ""
let Endpoint = ""
let webHostName = ""
let lassoLoginUrl = ""
let token_endpoint = ""
let logout_endpoint = ""
// let lassoSessionUrl = ""
let tokenEndPoint = ""
// let authKey
// let accessKey
let bareEndpoint
switch (hostname) {
  case LocalDevelopmentWebHost:
    console.log()
    Endpoint = APIDevEndpoint
    webHostName = "https://" + LocalDevelopmentWebHost + ":1180/"
    lassoLoginUrl = lassoLoginUrlStage
    client_id = stage_client_id
    client_secret = stage_secret
    tokenEndPoint = betaTokenEndPoint
    bareEndpoint = LocalDevelopmentWebHost
    token_endpoint = token_endpoint_stag
    logout_endpoint = logout_stag
    break
  case alphaDevelopmentWebHost:
    Endpoint = APIAlphaEndpoint
    webHostName = "https://" + alphaDevelopmentWebHost + "/"
    lassoLoginUrl = lassoLoginUrlStage
    client_id = stage_client_id
    client_secret = stage_secret
    bareEndpoint = "n1eod6awh3.execute-api.us-west-2.amazonaws.com"
    token_endpoint = token_endpoint_stag
    logout_endpoint = logout_stag
    break
  case betaDevelopmentWebHost:
    Endpoint = APIBetaEndpoint
    webHostName = "https://" + betaDevelopmentWebHost + "/"
    lassoLoginUrl = lassoLoginUrlStage
    client_id = stage_client_id
    client_secret = stage_secret
    bareEndpoint = "n1eod6awh3.execute-api.us-west-2.amazonaws.com"
    token_endpoint = token_endpoint_stag
    logout_endpoint = logout_stag
    break
  case gammaDevelopmentWebHost:
    Endpoint = APIGammaEndpoint
    webHostName = "https://" + gammaDevelopmentWebHost + "/"
    lassoLoginUrl = lassoLoginUrlProd
    client_id = prod_client_id
    client_secret = prod_secret
    bareEndpoint = gammaDevelopmentWebHost
    token_endpoint = token_endpoint_prod
    logout_endpoint = logout_prod
    break
  case prodDevelopmentWebHost:
    Endpoint = APIProdEndpoint
    webHostName = "https://" + prodDevelopmentWebHost + "/"
    lassoLoginUrl = lassoLoginUrlProd
    client_id = prod_client_id
    client_secret = prod_secret
    bareEndpoint = prodDevelopmentWebHost
    token_endpoint = token_endpoint_prod
    logout_endpoint = logout_prod
    break
  default:
    Endpoint = APIDevEndpoint
    webHostName = "https://" + LocalDevelopmentWebHost + "/"
    lassoLoginUrl = lassoLoginUrlStage
    client_id = prod_client_id
    client_secret = stage_secret
    tokenEndPoint = stage_client_id
    bareEndpoint = LocalDevelopmentWebHost
    token_endpoint = token_endpoint_stag
    logout_endpoint = logout_stag
    break
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  AUTH_TOKEN_COOKIE: "icecube_prod_token",
  AUTH_SESSION_COOKIE: "session_token",
  API_ENDPOINT: Endpoint,
  WEB_HOSTNAME: webHostName,
  BARE_ENDPOINT: bareEndpoint,
  APIPath: APIPath,
  CLIENT_ID: client_id,
  SIGNIN: "signIn",
  UNAUTHENTICATED: "unauthenticated",
  AUTHENTICATED: "authenticated",
  SIGNIN_FAILURE: "signIn_failure",
  SIGNOUT: "signOut",
  OIDC_CONFIG: {
    tokenApi: tokenEndPoint,
    authority: lassoLoginUrl,
    client_id: client_id,
    client_secret: client_secret,
    token_endpoint: token_endpoint,
    logout_endpoint: logout_endpoint,
    post_logout_redirect_uri: webHostName + "redirect_uri",
    response_type: "code",
    scope: "openid",
    loadUserInfo: false,
    revokeAccessTokenOnSignout: true,
  },
}
