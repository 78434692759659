import React, { useState } from "react"
// import {useHistory} from "react-router-dom";
import Row from "@amzn/meridian/row"
import Column from "@amzn/meridian/column"
import Text from "@amzn/meridian/text"
import Checkbox from "@amzn/meridian/checkbox"
import { css } from "emotion"
import { useTheme } from "@amzn/meridian/theme"
import modalTokens from "@amzn/meridian-tokens/component/modal"
// import Checkbox from "@amzn/meridian/checkbox";
import Button from "@amzn/meridian/button"
import Modal, { ModalFooter } from "@amzn/meridian/modal"

import useFileViewPreferences from "../../../hooks/use-file-preference"
import fileTableColumns from "../../../data/file-table-column"

const fieldsetResetStyles = css({
  border: 0,
  padding: 0,
  margin: 0,
  "& legend": {
    padding: 0,
  },
})
const PreferenceModal = props => {
  // eslint-disable-next-line no-unused-vars
  const { preferenceModalOpen, setPreferenceModalOpen } = props
  // Load preferences from context
  const [savedPreferences, setSavedPreferences] = useFileViewPreferences()
  // Create a local state variable to store local changes from the saved preferences
  const [localPreferences, setLocalPreferences] = useState(savedPreferences)
  
  // Get the Meridian Modal footer border color and width. Used to create the
  // vertical rule separating the two columns in the Modal.
  const {
    tokens: { modalFooterBorderColor, modalFooterBorderWidth },
  } = useTheme(modalTokens, "modal")

  // Helper function for updating local values
  const updateLocalValue = id => value => {
    // Create a clone of the previous preferences
    const nextLocalPreferences = new Map(localPreferences)
    // Update the preference with the new value
    nextLocalPreferences.set(id, value)
    setLocalPreferences(nextLocalPreferences)
  }

  // Called when the user presses "Update preferences" button. Close modal and
  // save the local values
  const onUpdatePreferences = () => {
    setPreferenceModalOpen(false)
    setSavedPreferences(localPreferences)
  }

  // Called when the user presses "Cancel" button. Close modal and reset local
  // preferences to the saved preferences
  const onCancel = () => {
    setPreferenceModalOpen(false)
    setLocalPreferences(savedPreferences)
  }

  // Called when the user toggles a table column's visibility.
  const toggleColumn = columnId => value =>
    updateLocalValue("columns")({
      ...localPreferences.get("columns"),
      [columnId]: value,
    })

  return (
    <Modal
      open={preferenceModalOpen}
      onClose={() => setPreferenceModalOpen(false)}
      position="bottom"
      title="Viewing preferences"
      bodySpacingInset="none"
    >
      <Row
        alignmentVertical="stretch"
        widths={["fill", "fit", "fill"]}
        spacing="none"
        maxWidth={715}
      >
        <Column spacing="450" spacingInset="500">
          {/** Show stripes **/}
          <Column spacing="300">
            <Text type="h100">Show stripes</Text>
            <Text type="b200" color="secondary" id="show-stripes-description">
              Add background shading to alternating rows
            </Text>
            <Checkbox
              checked={localPreferences.get("stripes")}
              onChange={updateLocalValue("stripes")}
              aria-describedby="show-stripes-description"
            >
              Show stripes
            </Checkbox>
          </Column>
        </Column>
        <div
          style={{
            width: modalFooterBorderWidth,
            background: modalFooterBorderColor,
          }}
        />
        <Column spacingInset="500">
          <Column tag="fieldset" className={fieldsetResetStyles} spacing="300">
            <Text tag="legend" type="h100">
              Visible columns
            </Text>
            <Text type="b200" color="secondary">
              Show or hide columns by selecting or deselecting from the list
              below.
            </Text>
            {fileTableColumns.map(({ id, label }) => (
              <Checkbox
                key={id}
                value={id}
                name="visible-columns"
                checked={localPreferences.get("columns")[id]}
                onChange={toggleColumn(id)}
              >
                {label}
              </Checkbox>
            ))}
          </Column>
        </Column>
      </Row>
      <ModalFooter>
        <Row alignmentHorizontal="end" widths="fit">
          <Button
            type="secondary"
            onClick={() => {
              onCancel()
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              onUpdatePreferences()
            }}
          >
            Update preferences
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export { PreferenceModal }
