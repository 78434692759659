import { openToast } from "../../../api/toasts"
import {
  FAILURE_TIMEOUT_IN_MS,
  SUCCESS_TIMEOUT_IN_MS,
} from "../../../data/constants"

const INITIAL_STATE = {
  cmIndex: 0,
  labPersonnelList: [],
  isLoading: false,
  turnoverRate: "N/A",
}
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "RETRIEVE_LAB_PERSONNEL":
      console.warn("RETRIEVE_LAB_PERSONNEL")
      return {
        ...state,
        labPersonnelList: [],
      }
    case "RETRIEVE_LAB_PERSONNEL_SUCCESS":
      console.warn("RETRIEVE_LAB_PERSONNEL_SUCCESS", action.payload)
      return {
        ...state,
        labPersonnelList:
          action.payload.retrieveLabPersonnelByLocationResponse || [],
      }
    case "RETRIEVE_LAB_PERSONNEL_FAILURE":
      console.warn("RETRIEVE_LAB_PERSONNEL_FAILURE")
      openToast({
        type: "error",
        title: "Retrieve failed",
        message: "Retrieve lab personnel failed",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
      }
    case "SELECT_CM":
      console.warn("SELECT_CM")
      return {
        ...state,
        cmIndex: action.payload,
      }
    case "ADD_LAB_PERSONNEL":
      console.warn("ADD_LAB_PERSONNEL")
      return {
        ...state,
      }
    case "ADD_LAB_PERSONNEL_SUCCESS":
      console.warn("ADD_LAB_PERSONNEL_SUCCESS")
      openToast({
        type: "success",
        title: "Add Done",
        message: "Add lab personnel successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
      }
    case "ADD_LAB_PERSONNEL_FAILURE":
      console.warn("ADD_LAB_PERSONNEL_FAILURE")
      openToast({
        type: "error",
        title: "Add failed",
        message: "Add lab personnel failed",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
      }
    case "EDIT_LAB_PERSONNEL":
      console.warn("EDIT_LAB_PERSONNEL")
      return {
        ...state,
      }
    case "EDIT_LAB_PERSONNEL_SUCCESS":
      console.warn("EDIT_LAB_PERSONNEL_SUCCESS")
      openToast({
        type: "success",
        title: "Update Done",
        message: "Update lab personnel successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
      }
    case "EDIT_LAB_PERSONNEL_FAILURE":
      console.warn("EDIT_LAB_PERSONNEL_FAILURE")
      openToast({
        type: "error",
        title: "Update failed",
        message: "Update lab personnel failed",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
      }
    case "DELETE_LAB_PERSONNEL":
      console.warn("DELETE_LAB_PERSONNEL")
      return {
        ...state,
      }
    case "DELETE_LAB_PERSONNEL_SUCCESS":
      console.warn("DELETE_LAB_PERSONNEL_SUCCESS")
      return {
        ...state,
      }
    case "DELETE_LAB_PERSONNEL_FAILURE":
      console.warn("DELETE_LAB_PERSONNEL_FAILURE")
      return {
        ...state,
      }
    case "CALCULATE_TURNOVER_RATE":
      console.warn("CALCULATE_TURNOVER_RATE")
      return {
        ...state,
      }
    case "CALCULATE_TURNOVER_RATE_SUCCESS":
      console.warn("CALCULATE_TURNOVER_RATE_SUCCESS")
      return {
        ...state,
        turnoverRate: action.payload,
      }
    case "CALCULATE_TURNOVER_RATE_FAILURE":
      console.warn("CALCULATE_TURNOVER_RATE_FAILURE")
      return {
        ...state,
      }
    default:
      return state
  }
}

export default reducer
