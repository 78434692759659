import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import DateRangePicker from "@amzn/meridian/date-range-picker"
import Text from "@amzn/meridian/text"
import Box from "@amzn/meridian/box"
import Divider from "@amzn/meridian/divider"
import { Column as AntdColumn, Line } from "@ant-design/plots"
import { Select, SelectOption } from "../../../components/app/form-fields"
import PageLayout from "../../../components/app/page-layout"
import KPICard from "../components/KPICard"
import PerformanceStackedBarChart from "../charts/PerformanceStackedBarChart"
import PerformanceLineChart from "../charts/PerformanceLineChart"
import PerformancePieChart from "../charts/PerformancePieChart"
import PerformanceBarChart from "../charts/PerformanceBarChart"
import {
  retrievePerformanceSummaryByCPIData,
  retrievePerformanceSummaryByCPIDataSuccess,
  retrievePerformanceSummaryByCPIHeader,
  setCmCmIndex,
  setCMStartDate,
  setCPIDateRange,
} from "../../../redux/performanceSummary/action"
import {
  getMonthName,
  getYearName,
  transformChartData,
} from "../../../util/Util"
import PerformanceGroupedBarChart from "../charts/PerformanceGroupedBarChart"

const auditIssueByPriorityConfig = {
  isGroup: true,
  xAxis: {
    label: {
      formatter: v => {
        return "P" + v
      },
    },
  },
  // yAxis: {
  //   tickInterval: 1,
  // },
  tooltip: {
    title: d => "P" + d,
  },
}

const PerformanceCpi = props => {
  const {
    isLoading,
    cpiDateRange,
    summary_by_cpi_header,
    summary_by_cpi_data,
  } = useSelector(state => state.performanceReducer)

  const [summary_by_cpi_data_chart, setSummary_by_cpi_data_chart] = useState({
    auditFindingsByCM: {
      key: "CM Location",
      label: "Audit Issues Qty",
      data: [],
    },
    auditFindingsByCategory: {
      key: "CM Location",
      label: "Audit Issues by Category",
      data: [],
    },
    auditFindingsByPriority: [],
    avgReviewDayByMonth: {
      key: "Date",
      label: "Average Review by Month",
      data: [],
    },
  })

  const reduxDispatch = useDispatch()

  useEffect(() => {
    let startDate = cpiDateRange[0]
    let endDate = cpiDateRange[1]

    reduxDispatch(
      retrievePerformanceSummaryByCPIHeader({
        start_date: startDate,
        end_date: endDate,
      })
    )

    reduxDispatch(
      retrievePerformanceSummaryByCPIData({
        start_date: startDate,
        end_date: endDate,
      })
    )
  }, [cpiDateRange, reduxDispatch])

  useEffect(() => {
    let initialCpiDataState = {
      auditFindingsByCM: {
        key: "CM Location",
        label: "Audit Issues Qty",
        data: [],
      },
      auditFindingsByCategory: {
        key: "CM Location",
        label: "Audit Issues by Category",
        data: [],
      },
      auditFindingsByPriority: [],
      avgReviewDayByMonth: {
        key: "Date",
        label: "Average Review by Month",
        data: [],
      },
    }

    if (summary_by_cpi_data.AuditFindingsByCM != null) {
      initialCpiDataState.auditFindingsByCM.data = summary_by_cpi_data.AuditFindingsByCM.map(
        value => {
          return {
            cm: value.cmName,
            value: value.auditFindingsQty,
          }
        }
      )
    }
    if (summary_by_cpi_data.AuditFindingsByCategory != null) {
      initialCpiDataState.auditFindingsByCategory.data = summary_by_cpi_data.AuditFindingsByCategory.map(
        value => {
          return {
            key: value.categoryName,
            value: value.auditFindingsQty,
            label: value.categoryName,
          }
        }
      )
    }

    if (summary_by_cpi_data.AuditFindingsByPriority != null) {
      initialCpiDataState.auditFindingsByPriority =
        summary_by_cpi_data.AuditFindingsByPriority
    }

    if (summary_by_cpi_data.AvgReviewDayByMonth != null) {
      summary_by_cpi_data.AvgReviewDayByMonth.sort((x, y) => {
        if (x.year < y.year) {
          return -1
        } else if (x.year > y.year) {
          return 1
        } else if (x.month > y.month) {
          return 1
        } else if (x.month < y.month) {
          return -1
        }
        return 0
      })
      initialCpiDataState.avgReviewDayByMonth.data = summary_by_cpi_data.AvgReviewDayByMonth.map(
        value => ({
          avgReviewDay: value.avgReviewDay,
          date: `${getMonthName(value.month)}-${getYearName(value.year)}`,
        })
      )

      // initialCpiDataState.avgReviewDayByMonth[0].data = summary_by_cpi_data.AvgReviewDayByMonth.reverse().map(
      //   value => {
      //     return {
      //       cm: `${getMonthName(value.month)}-${getYearName(value.year)}`,
      //       value: value.avgReviewDay,
      //     }
      //   }
      // )
    }

    setSummary_by_cpi_data_chart(initialCpiDataState)
  }, [summary_by_cpi_data])

  return (
    <PageLayout
      spacing="large"
      alignmentHorizontal={"start"}
      loading={isLoading}
      width="100%"
    >
      <div role="tabpanel" id="tabpanel-planets" aria-labelledby="tab-planets">
        <Row spacingInset="small none">
          <Column>
            <DateRangePicker
              value={cpiDateRange}
              onChange={dateRange => reduxDispatch(setCPIDateRange(dateRange))}
              startLabel="Start date"
              endLabel="End date"
              monthsInView={2}
            />
          </Column>
          <Column>
            <Button minWidth={100} type="primary" size="small">
              Reset
            </Button>
          </Column>
        </Row>
        {Object.keys(summary_by_cpi_header).length > 0 && (
          <div style={{ padding: "20px 0px 20px 0" }}>
            <Row>
              <Column>
                <KPICard
                  kpi={summary_by_cpi_header.totalAuditQty}
                  title={"Total Audits"}
                />
              </Column>
              <Column>
                <KPICard
                  kpi={summary_by_cpi_header.totalFindingsQty}
                  title={"Total Issues"}
                />
              </Column>
              <Column>
                <KPICard
                  kpi={summary_by_cpi_header.auditLabsQty}
                  title={"Audited Labs"}
                />
              </Column>
            </Row>
          </div>
        )}
        <Row wrap="down" spacing="500" alignmentVertical="top">
          {summary_by_cpi_data_chart.auditFindingsByCM.data.length > 0 && (
            <Box spacingInset="300" type="outline" width={500} height={350}>
              <Text type="h200">Audit Issues by CMs</Text>
              <Divider />
              <AntdColumn
                maxColumnWidth={40}
                padding={[40, 60, 70, 40]}
                data={summary_by_cpi_data_chart.auditFindingsByCM.data}
                xField={"cm"}
                yField={"value"}
                colorField={"cm"}
                seriesField={"cm"}
              />
            </Box>
          )}
          {summary_by_cpi_data_chart.auditFindingsByCategory.data.length >
            0 && (
            <PerformancePieChart
              title={"Audit Issues by Category"}
              data={summary_by_cpi_data_chart.auditFindingsByCategory.data}
            />
          )}
          {summary_by_cpi_data_chart.auditFindingsByPriority.length > 0 && (
            <Box spacingInset="300" type="outline" width={500} height={350}>
              <Text type="h200">Audit Issues by Priority</Text>
              <Divider />
              <AntdColumn
                maxColumnWidth={40}
                padding={[40, 60, 70, 40]}
                data={summary_by_cpi_data_chart.auditFindingsByPriority}
                xField={"priority"}
                yField={"auditFindingsQty"}
                seriesField={"cmName"}
                {...auditIssueByPriorityConfig}
              />
            </Box>
          )}
          {summary_by_cpi_data_chart.avgReviewDayByMonth.data.length > 0 && (
            <Box spacingInset="300" type="outline" width={500} height={350}>
              <Text type="h200">Average Review by Month</Text>
              <Divider />
              <Line
                padding={[40, 60, 70, 40]}
                data={summary_by_cpi_data_chart.avgReviewDayByMonth.data}
                xField={"date"}
                yField={"avgReviewDay"}
              />
            </Box>
            // <PerformanceLineChart
            //   title={"Average Review by Month"}
            //   data={summary_by_cpi_data_chart.avgReviewDayByMonth}
            //   xValue={"cm"}
            //   yValue={"value"}
            // />
          )}
        </Row>
      </div>
    </PageLayout>
  )
}

export default PerformanceCpi
