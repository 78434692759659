export const retrievePerformanceSummaryByCMHeader = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_HEADER",
  payload: payload,
})

export const retrievePerformanceSummaryByCMHeaderSuccess = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_HEADER_SUCCESS",
  payload: payload,
})

export const retrievePerformanceSummaryByCMHeaderFailure = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_HEADER_FAILURE",
  payload: payload,
})

export const retrievePerformanceSummaryByCMEquipmentCategory = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_EQUIPMENT_CATEGORY",
  payload: payload,
})

export const retrievePerformanceSummaryByCMEquipmentCategorySuccess = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_EQUIPMENT_CATEGORY_SUCCESS",
  payload: payload,
})

export const retrievePerformanceSummaryByCMEquipmentCategoryFailure = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_EQUIPMENT_CATEGORY_FAILURE",
  payload: payload,
})

export const retrievePerformanceSummaryByCMEquipmentCM = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_EQUIPMENT_CM",
  payload: payload,
})

export const retrievePerformanceSummaryByCMEquipmentCMSuccess = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_EQUIPMENT_CM_SUCCESS",
  payload: payload,
})

export const retrievePerformanceSummaryByCMEquipmentCMFailure = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_EQUIPMENT_CM_FAILURE",
  payload: payload,
})

export const retrievePerformanceSummaryByCMMonth = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_MONTH",
  payload: payload,
})

export const retrievePerformanceSummaryByCMMonthSuccess = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_MONTH_SUCCESS",
  payload: payload,
})

export const retrievePerformanceSummaryByCMMonthFailure = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_MONTH_FAILURE",
  payload: payload,
})

export const retrievePerformanceSummaryByCMQuarter = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_QUARTER",
  payload: payload,
})

export const retrievePerformanceSummaryByCMQuarterSuccess = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_QUARTER_SUCCESS",
  payload: payload,
})

export const retrievePerformanceSummaryByCMQuarterFailure = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_QUARTER_FAILURE",
  payload: payload,
})

export const retrievePerformanceSummaryByCMAvgDays = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_AVG_DAYS",
  payload: payload,
})

export const retrievePerformanceSummaryByCMAvgDaysSuccess = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_AVG_DAYS_SUCCESS",
  payload: payload,
})

export const retrievePerformanceSummaryByCMAvgDaysFailure = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CM_AVG_DAYS_FAILURE",
  payload: payload,
})

export const setCMStartDate = cmDateRange => ({
  type: "SET_CM_DATE_RANGE",
  cmDateRange: cmDateRange,
})

export const setCmCmIndex = cmCmIndex => ({
  type: "SET_CM_CM_INDEX",
  cmCmIndex: cmCmIndex,
})

export const setCmReset = () => ({
  type: "SET_CM_CM_INDEX",
})

export const setCPIDateRange = cpiDateRange => ({
  type: "SET_CPI_DATE_RANGE",
  cmDateRange: cpiDateRange,
})

export const retrievePerformanceSummaryByCPIHeader = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CPI_HEADER",
  payload: payload,
})

export const retrievePerformanceSummaryByCPIHeaderSuccess = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CPI_HEADER_SUCCESS",
  payload: payload,
})

export const retrievePerformanceSummaryByCPIHeaderFailure = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CPI_HEADER_FAILURE",
  payload: payload,
})

export const retrievePerformanceSummaryByCPIData = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CPI_DATA",
  payload: payload,
})

export const retrievePerformanceSummaryByCPIDataSuccess = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CPI_DATA_SUCCESS",
  payload: payload,
})

export const retrievePerformanceSummaryByCPIDataFailure = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CPI_DATA_FAILURE",
  payload: payload,
})

export const retrievePerformanceSummaryBySystemHeader = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_SYSTEM_HEADER",
  payload: payload,
})

export const retrievePerformanceSummaryBySystemHeaderSuccess = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_SYSTEM_HEADER_SUCCESS",
  payload: payload,
})

export const retrievePerformanceSummaryBySystemHeaderFailure = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_SYSTEM_HEADER_FAILURE",
  payload: payload,
})

export const retrievePerformanceSummaryBySystemData = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_SYSTEM_DATA",
  payload: payload,
})

export const retrievePerformanceSummaryBySystemDataSuccess = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_SYSTEM_DATA_SUCCESS",
  payload: payload,
})

export const retrievePerformanceSummaryBySystemDataFailure = payload => ({
  type: "RETRIEVE_PERFORMANCE_BY_CPI_SYSTEM_DATA_FAILURE",
  payload: payload,
})