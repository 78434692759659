/**
 * This creates a fake local-storage based API. The API consists of four
 * functions:
 * - get() to fetch data
 * - set() to set data using new data
 * - update() to set data using a function that receives the current data and
 *     returns new data
 * - listen() to fetch the latest data anytime there are changes
 */

import getStorage from "./storage"

const simulateNetworkDelay = ms =>
  new Promise(resolve => setTimeout(resolve, ms))

const createApi = (
  key,
  { defaultData, networkDelay = 0, persistInLocalStorage = false } = {}
) => {
  const storage = getStorage(key)
  let dataFetched = false
  let data = persistInLocalStorage ? storage.get() : undefined
  const listeners = new Map()

  // Default the data to the `defaultData` key (and save the new default in
  // local storage if requested)
  if (data === undefined && persistInLocalStorage) {
    data = defaultData
    storage.set(data)
  } else if (data === undefined) {
    data = defaultData
  }

  const get = async () => {
    // Simulate a network delay. Only do this the first time the data is fetched
    // though... our fake API pretends that we are using client-side caching
    // best-practices :)
    if (!dataFetched) {
      await simulateNetworkDelay(networkDelay)
    }
    dataFetched = true
    return data
  }

  const listen = listener => {
    // Save the listener so we can send it future updates
    listeners.set(listener, listener)
    // Send the listener the current data now
    get().then(listener)
    // Return a cleanup function that will remove the listener
    return () => listeners.delete(listener)
  }

  const set = async nextData => {
    data = nextData
    listeners.forEach(listener => listener(data))
    if (persistInLocalStorage) {
      storage.set(data)
    }
    return data
  }

  const update = updater => set(updater(data))

  return { get, set, update, listen }
}

export default createApi
