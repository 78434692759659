import { put, takeLatest } from "redux-saga/effects"
import APIPromises from "../../../util/APIPromises"
import {
  retrieveLocationListSuccess,
  retrieveLocationListFailure,
  addLocationSuccess,
  addLocationFailure,
  updateLocationSuccess,
  updateLocationFailure,
  deleteLocationSuccess,
  deleteLocationFailure,
} from "../action"

function* retrieveLocationList(req) {
  try {
    // eslint-disable-next-line no-unused-vars
    const res = yield APIPromises.retrieveLocationList(req.payload)
    const list = res.data.Locations || []
    yield put(
      retrieveLocationListSuccess(res.data.retrieveUsersWithTestLocationList)
    )

    // if (res.data && res.data.isSuccess === false) {
    //   yield put(retrieveLocationListFailure())
    // } else {
    //   yield put(retrieveLocationListSuccess(list))
    // }
  } catch (error) {
    yield put(retrieveLocationListFailure(error))
  }
}

export function* retrieveLocationListWatcher() {
  yield takeLatest("RETRIEVE_LOCATION_LIST", retrieveLocationList)
}

function* addLocation(req) {
  try {
    let res = yield APIPromises.addLocation(req.payload)

    if (res.data && res.data.isSuccess === false) {
      yield put(addLocationFailure())
    } else {
      yield put({
        type: "RETRIEVE_LOCATION_LIST",
        payload: {
          equipmentId: req.payload.equipmentId,
        },
      })
      yield put(addLocationSuccess(req.payload))
    }
  } catch (error) {
    yield put(addLocationFailure(error))
  }
}

export function* addLocationWatcher() {
  yield takeLatest("ADD_LOCATION", addLocation)
}

function* updateLocation(req) {
  try {
    let res = yield APIPromises.updateLocation(req.payload)

    if (res.data && res.data.isSuccess === false) {
      yield put(updateLocationFailure())
    } else {
      yield put({
        type: "RETRIEVE_LOCATION_LIST",
        payload: {
          equipmentId: req.payload.equipmentId,
        },
      })
      yield put(updateLocationSuccess(req.payload))
    }
  } catch (error) {
    yield put(updateLocationFailure(error))
  }
}

export function* updateLocationWatcher() {
  yield takeLatest("UPDATE_LOCATION", updateLocation)
}


function* deleteLocation(req) {
  try {
    let res = yield APIPromises.deleteLocation(req.payload)

    if (res.data && res.data.isSuccess === false) {
      yield put(deleteLocationFailure())
    } else {
      yield put({
        type: "RETRIEVE_LOCATION_LIST",
        payload: {
          equipmentId: req.payload.equipmentId,
        },
      })
      yield put(deleteLocationSuccess(req.payload))
    }
  } catch (error) {
    yield put(deleteLocationFailure(error))
  }
}

export function* deleteLocationWatcher() {
  yield takeLatest("DELETE_LOCATION", deleteLocation)
}
