/**
 * The application landing page/Audit page.
 */
import React, { useState, useCallback, useEffect } from "react"
import { useLocation } from "react-router-dom"

import Checkbox from "@amzn/meridian/checkbox"
import Text from "@amzn/meridian/text"
import Textarea from "@amzn/meridian/textarea"
import DatePicker from "@amzn/meridian/date-picker"
import Link from "@amzn/meridian/link"
import Button from "@amzn/meridian/button"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { AddEquipmentAuditIssuesModal, AddEquipmentFileModal } from "../modals"
import AuditSelect from "../../../components/audit/audit-select"
// actions
import {
  retrieveEquipment,
  retrieveAuditIssues,
} from "../../../redux/equipmentDetail/action"
import { downloadFile } from "../../../redux/fileLibrary/action"

import {
  auditStatusOptions,
  auditIssueTypeOptions,
  auditPriorityOptions,
} from "../../../data/options"

import {
  SUCCESS_TIMEOUT_IN_MS,
  FAILURE_TIMEOUT_IN_MS,
} from "../../../data/constants"
import { openToast } from "../../../api/toasts"
import env from "../../../services/Auth/env"
import FileInputWrapper from "../../../components/file-library/fileInputWrapper"
import AuditTable from "../audit-table"
import * as auth from "../../../services/Auth/auth"
import StatusTag from "../../../components/app/status-tag"

const EquipmentIssueListTab = props => {
  const { equipmentBasicInfo, cm, testLocation } = props
  const { auditIssues } = useSelector(state => state.equipmentDetailReducer)

  const { username, isCMUser } = useSelector(state => state.userReducer)

  const [status, setStatus] = useState([])
  const [description, setDescription] = useState([])
  const [issueType, setIssueType] = useState([])
  const [priority, setPriority] = useState([])
  const [createdDate, setCreatedDate] = useState([])
  const [createdBy, setCreatedBy] = useState([])
  const [resolvedDate, setResolvedDate] = useState([])
  const [resolvedBy, setResolvedBy] = useState([])
  const [ecd, setEcd] = useState([])
  const [action, setAction] = useState([])
  const [remarks, setRemarks] = useState([])
  const [deleteIssues, setDeleteIssues] = useState([])
  const [files, setFiles] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [cmMode, setCmMode] = useState(false)
  const [addAuditModalOpen, setAddAuditModalOpen] = useState(false)
  const [actionUpdateDate, setActionUpdateDate] = useState([])
  const [addEquipmentFileModalOpen, setAddEquipmentFileModalOpen] = useState(
    false
  )
  const [currentIndex, setCurrentIndex] = useState()

  const reduxDispatch = useDispatch()

  const fetchAuditIssues = useCallback(() => {
    reduxDispatch(
      retrieveAuditIssues({
        equipment_id: equipmentBasicInfo.equipmentId,
      })
    )
  }, [reduxDispatch, equipmentBasicInfo])

  // useEffect(() => {
  //   const param = location.state
  //   if (param && param.cm && param.equipment) {
  //     reduxDispatch(selectCM(param.cm))
  //     reduxDispatch(selectEquipment(param.equipment, param.equipmentIndex))
  //     reduxDispatch(
  //       retrieveAuditReport({
  //         cm: param.cm,
  //         equipmentId: param.equipment?.equipmentId,
  //       })
  //     )
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const handleAddNewAttachment = fileList => {
    console.warn("handleAddNewAttachment -----", fileList?.flat())
    if (!files || !files[currentIndex]) {
      files[currentIndex] = []
    }
    files[currentIndex] = files[currentIndex].concat(fileList?.flat())
    setFiles([...files])
  }

  useEffect(() => {
    if (auditIssues) {
      setDescription(auditIssues.map(i => i.description))
      setPriority(auditIssues.map(i => i.priority))
      setIssueType(auditIssues.map(i => i.category))
      setEcd(auditIssues.map(i => i.ecd))
      setStatus(auditIssues.map(i => (i.isClosed ? "Closed" : "Open")))
      setCreatedDate(auditIssues.map(i => i.createdDate))
      setCreatedBy(auditIssues.map(i => i.createdBy))
      setResolvedDate(auditIssues.map(i => i.resolvedDate))
      setResolvedBy(auditIssues.map(i => i.resolvedBy))
      setAction(auditIssues.map(i => i.action))
      setActionUpdateDate(auditIssues.map(i => i.actionUpdateDate))
      setRemarks(auditIssues.map(i => i.remarks))
      setFiles(auditIssues.map(i => i.fileList))
      setCmMode(isCMUser) // Later set the CM mode
    }
  }, [reduxDispatch, auditIssues, isCMUser])

  const handleDelete = useCallback(() => {
    let deleteData = {
      auditIssueIds: deleteIssues,
      equipmentId: equipmentBasicInfo?.equipmentId,
      cm: cm,
      testLocation: testLocation,
    }
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.deleteEquipmentIssues, {
        method: "POST",
        data: deleteData,
        headers: { idToken: cookie },
      })
        .then(response => {
          console.warn("Deleted", response)
          if (response?.data?.isSuccess === false) {
            openToast({
              type: "error",
              title: "Delete failed",
              message: "Failed to delete the issue(s), please retry",
              timeout: FAILURE_TIMEOUT_IN_MS,
            })
          } else {
            openToast({
              type: "success",
              title: "Deleted!",
              message: "Deleted an issue successfully",
              timeout: SUCCESS_TIMEOUT_IN_MS,
            })
            fetchAuditIssues()
          }
        })
        .catch(error => {
          console.warn("error", error)
          openToast({
            type: "error",
            title: "Delete failed",
            message: "Failed to delete the issue(s), please retry",
            timeout: FAILURE_TIMEOUT_IN_MS,
          })
        })
    })

    setEditMode(false)
  }, [
    deleteIssues,
    equipmentBasicInfo?.equipmentId,
    cm,
    testLocation,
    fetchAuditIssues,
  ])

  const handleSave = useCallback(() => {
    let editData = {
      // equipmentId: equipmentBasicInfo.equipmentId,
      cm: cm,
      testLocation: testLocation,
      auditIssues: status.map((item, index) => {
        return {
          createdBy: createdBy[index],
          createdDate: createdDate[index],
          category: issueType[index],
          priority: priority[index],
          description: description[index],
          issueId: auditIssues[index].issueId,
          // equipmentIssueId: auditIssues[index].equipmentIssueId,
          resolvedBy: resolvedBy[index],
          resolvedDate: resolvedDate[index],
          ecd: ecd[index],
          action: action[index],
          fileIds: files[index]?.map(x => x.fileId),
          isClosed: status[index] !== "Open",
          remarks: remarks[index],
          actionUpdateDate: actionUpdateDate[index],
        }
      }),
    }

    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.updateAuditIssuesList, {
        method: "POST",
        headers: { idToken: cookie },
        data: editData,
      })
        .then(response => {
          if (response?.data?.isSuccess === false) {
            openToast({
              type: "error",
              title: "Update failed",
              message: "Failed to update the issue list, please retry",
              timeout: FAILURE_TIMEOUT_IN_MS,
            })
          } else {
            fetchAuditIssues()
            openToast({
              type: "success",
              title: "Updated!",
              message: "Updated issue list successfully",
              timeout: SUCCESS_TIMEOUT_IN_MS,
            })
          }
        })
        .catch(error => {
          console.warn("error", error)
          openToast({
            type: "error",
            title: "Update failed",
            message: "Failed to update the issue list, please retry",
            timeout: FAILURE_TIMEOUT_IN_MS,
          })
        })
    })
    setEditMode(false)
  }, [
    createdDate,
    description,
    issueType,
    priority,
    resolvedDate,
    remarks,
    ecd,
    files,
    status,
    action,
    fetchAuditIssues,
    actionUpdateDate,
    createdBy,
    resolvedBy,
    auditIssues,
    cm,
    testLocation,
  ])

  let data = auditIssues?.map((item, i) => {
    return {
      delete: editMode && !cmMode && (
        <Checkbox
          checked={deleteIssues.some(element => element === item.issueId)}
          key={item.issueId + "-checkbox"}
          onChange={value => {
            let deleteArray = deleteIssues
            if (value) {
              deleteIssues.push(item.issueId)
              setDeleteIssues([...deleteIssues])
            } else {
              deleteArray = deleteArray.filter(function(value) {
                return value !== item.issueId
              })
              setDeleteIssues([...deleteArray])
            }
          }}
        ></Checkbox>
      ),
      description:
        editMode && !cmMode ? (
          <Textarea
            value={description[i]}
            onChange={value => {
              description[i] = value
              setDescription([...description])
            }}
            placeholder="Enter value..."
            width={200}
          />
        ) : (
          <Text style={{ overflowY: "auto", width: "250px" }}>
            {description[i]}
          </Text>
        ),
      issueType:
        editMode && !cmMode ? (
          <AuditSelect
            value={issueType[i]}
            onChange={value => {
              issueType[i] = value
              setIssueType([...issueType])
            }}
            minWidth={120}
            size={"small"}
            options={auditIssueTypeOptions}
          />
        ) : (
          <Text style={{ overflowY: "auto", width: "250px" }}>
            {issueType[i]}
          </Text>
        ),
      priority:
        editMode && !cmMode ? (
          <AuditSelect
            value={priority[i]}
            onChange={value => {
              priority[i] = value
              setPriority([...priority])
            }}
            minWidth={120}
            size={"small"}
            options={auditPriorityOptions}
          />
        ) : (
          <Text style={{ overflowY: "auto", width: "250px" }}>
            {`P${priority[i]}`}
          </Text>
        ),
      status:
        editMode && !cmMode ? (
          <AuditSelect
            value={status[i]}
            onChange={value => {
              if (status[i] !== value && value === "closed") {
                resolvedDate[i] = new Date().toISOString().slice(0, 10)
                const newResolvedDate = resolvedDate.map((x, index) => {
                  return index === i ? new Date().toISOString().slice(0, 10) : x
                })
                const newResolvedBy = resolvedBy.map((x, index) => {
                  return index === i ? username : x
                })
                resolvedBy[i] = username
                setResolvedDate(newResolvedDate)
                setResolvedBy(newResolvedBy)
              }
              status[i] = value
              setStatus([...status])
            }}
            minWidth={120}
            size={"small"}
            options={auditStatusOptions}
          />
        ) : (
          <StatusTag status={status[i]} />
        ),
      createdDate:
        editMode && !cmMode ? (
          <DatePicker
            value={createdDate[i]}
            onChange={value => {
              createdDate[i] = value
              setCreatedDate([...createdDate])
            }}
            width={180}
            size="small"
          />
        ) : (
          createdDate[i]
        ),
      createdBy: createdBy[i],
      resolvedDate: resolvedDate[i],
      resolvedBy: resolvedBy[i],
      ecd: editMode ? (
        <DatePicker
          value={ecd[i]}
          onChange={value => {
            ecd[i] = value
            setEcd([...ecd])
          }}
          width={180}
          size="small"
        />
      ) : (
        ecd[i]
      ),
      action: editMode ? (
        <Textarea
          value={action[i]}
          onChange={value => {
            const newActionUpdatedDate = actionUpdateDate.map((x, index) => {
              return index === i ? new Date().toISOString().slice(0, 10) : x
            })
            setActionUpdateDate(newActionUpdatedDate)
            action[i] = value
            setAction([...action])
          }}
          placeholder="Enter value..."
          width={200}
        />
      ) : (
        <Text>{action[i]}</Text>
      ),
      attachment: (
        <div>
          <div>
            <Text tag="ol">
              {files[i]?.map((file, index) => {
                return (
                  <li key={file + index}>
                    <Link
                      // type="icon"
                      onClick={() => {
                        reduxDispatch(
                          downloadFile({
                            fileLevel: "equipment",
                            fileType: "rootCauseAction",
                            // project: project.projectName,
                            cm: cm,
                            fileName: file.fileName,
                            issueId: item.issueId,
                            uploadedBy: username,
                            fileId: file.fileId,
                          })
                        )
                      }}
                    >
                      {file.fileName}
                    </Link>
                  </li>
                )
              })}
            </Text>
          </div>
          {editMode && (
            <Button
              type="link"
              onClick={() => {
                setAddEquipmentFileModalOpen(true)
                setCurrentIndex(i)
              }}
            >
              + Add Attachment
            </Button>
          )}
        </div>
      ),
      remarks: editMode ? (
        <Textarea
          value={remarks[i]}
          onChange={value => {
            const newActionUpdatedDate = actionUpdateDate.map((x, index) => {
              return index === i ? new Date().toISOString().slice(0, 10) : x
            })
            setActionUpdateDate(newActionUpdatedDate)
            remarks[i] = value
            setRemarks([...remarks])
          }}
          placeholder="Enter value..."
          width={200}
        />
      ) : (
        <Text>{remarks[i]}</Text>
      ),
      actionUpdateDate: editMode ? (
        <DatePicker
          value={actionUpdateDate[i]}
          onChange={value => {
            actionUpdateDate[i] = value
            setActionUpdateDate([...actionUpdateDate])
          }}
          width={180}
          size="small"
        />
      ) : (
        actionUpdateDate[i]
      ),
    }
  })

  return (
    <div
      role="tabpanel"
      id="tabpanel-planets"
      aria-labelledby="tab-planets"
      // style={{ overflow: "auto" }}
    >
      {
        <AuditTable
          data={data}
          addAuditModalOpen={addAuditModalOpen}
          setAddAuditModalOpen={setAddAuditModalOpen}
          isDeleteEnabled={editMode && deleteIssues.length > 0}
          onDeleteButtonClick={handleDelete}
          onSaveButtonClick={handleSave}
          onCloseButtonClick={fetchAuditIssues}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      }
      <AddEquipmentFileModal
        addEquipmentFileModalOpen={addEquipmentFileModalOpen}
        setAddEquipmentFileModalOpen={setAddEquipmentFileModalOpen}
        currentData={equipmentBasicInfo}
        callback={handleAddNewAttachment}
      />
      <AddEquipmentAuditIssuesModal
        addAuditModalOpen={addAuditModalOpen}
        setAddAuditModalOpen={setAddAuditModalOpen}
        onAddSuccess={fetchAuditIssues}
        equipment={equipmentBasicInfo}
        cm={cm}
        testLocation={testLocation}
      />
    </div>
  )
}

export default EquipmentIssueListTab
