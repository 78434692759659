import { put, takeLatest } from "redux-saga/effects"
import APIPromises from "../../../util/APIPromises"

import {
  fetchUserInfoSuccess,
  fetchUserInfoError,
  retrieveNotificationsError,
  retrieveNotificationsSuccess,
} from "../action"
import { showNotification } from "../../../util/Util"
import {openNotifications} from "../../../api/notifications";
// import * as auth from "../../../services/Auth/auth"

function* fetchUserInfo(req) {
  try {
    // let token = yield auth.getToken()
    // if (token === 403 || token === "undefined") {
    //   yield put(fetchUserInfoError(403))
    // } else {
    //   let decodedToken = JSON.parse(atob(token.split(".")[1]))
    //   if (decodedToken.email.includes("amazon")) {
    //     yield put({
    //       type: "RETRIEVE_CMS",
    //     })
    //   }

    const response = yield APIPromises.login(req.payload)
    const res = response.data
    console.warn("login response", res)
    if (res.errorMessage) {
      yield put(fetchUserInfoError(res.errorMessage))
    } else if (res?.UserInfo) {
      yield put({
        type: "RETRIEVE_LOCATION_LIST",
      })
      if (showNotification()) {
        yield put({
          type: "FETCH_NOTIFICATIONS",
          payload: {
            username: res?.UserInfo?.userName,
          },
        })
      }
      if (res?.UserInfo?.isAmazonEmployee) {
        yield put({ type: "LIST_FILES" })
        // yield put({ type: "RETRIEVE_DASHBOARD" })
      } else {
        yield put({
          type: "LIST_FILES",
          payload: {
            cm: res?.UserInfo?.cm,
          },
        })
        // yield put({
        //   type: "RETRIEVE_DASHBOARD",
        //   payload: {
        //     cm: res?.UserInfo?.cm,
        //   },
        // })
      }
      yield put(fetchUserInfoSuccess(res))
    }
  } catch (error) {
    yield put(fetchUserInfoError(error))
  }
}

export default function* actionWatcher() {
  yield takeLatest("FETCH_USERINFO", fetchUserInfo)
}

function* fetchNotification(req) {
  try {
    const response = yield APIPromises.retrieveNotificationList(req.payload)
    console.warn("fetchNotification response", response)
    if (response.errorMessage) {
      yield put(retrieveNotificationsError(response.errorMessage))
    }
    yield put(retrieveNotificationsSuccess(response))
    openNotifications(response.notificationList)
  } catch (error) {
    yield put(retrieveNotificationsError(error))
  }
}

export function* notificationActionWatcher() {
  yield takeLatest("FETCH_NOTIFICATIONS", fetchNotification)
}
