import React, { useCallback, useContext, createContext, useState } from "react"
import useLocalStorage from "./use-local-storage"
import { openToast } from "../api/toasts"

const themeContext = createContext("white")

const ThemeContextProvider = ({ children }) => {
  const [savedThemeCode, presistThemeCode] = useLocalStorage("themeCode")
  const [themeCode, setThemeCode] = useState(savedThemeCode || "white")

  const changeTheme = useCallback(
    value => {
      presistThemeCode(value)
      setThemeCode(value)

      openToast({
        type: "success",
        title: "Change Detected",
        message: `Changed theme to ${value}!`,
        timeout: 2000,
      })
    },
    [presistThemeCode]
  )

  return (
    <themeContext.Provider value={[themeCode, changeTheme]}>
      {children}
    </themeContext.Provider>
  )
}

const useTheme = () => useContext(themeContext)

export { ThemeContextProvider }
export default useTheme
