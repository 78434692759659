export const retrieveCms = (userName, cm_location_id) => ({
  type: "RETRIEVE_CMS",
  userName,
  cm_location_id,
})

export const retrieveCmsSuccess = allCms => ({
  type: "RETRIEVE_CMS_SUCCESS",
  payload: allCms,
})

export const retrieveCmsFailure = res => ({
  type: "RETRIEVE_CMS_FAILURE",
  payload: res,
})
