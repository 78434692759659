import { put, takeLatest } from "redux-saga/effects"
import APIPromises from "../../../util/APIPromises"
import {
  retrieveUserListSuccess,
  retrieveUserListFailure,
  addUserSuccess,
  addUserFailure,
  updateUserSuccess,
  updateUserFailure,
  deleteUserSuccess,
  deleteUserFailure,
} from "../action"

function* retrieveUserList(req) {
  try {
    // eslint-disable-next-line no-unused-vars
    const res = yield APIPromises.retrieveUserList(req.payload)
    const list = res.data.users || []
    yield put(retrieveUserListSuccess(res.data.userList))

    // if (res.data && res.data.isSuccess === false) {
    //   yield put(retrieveUserListFailure())
    // } else {
    //   yield put(retrieveUserListSuccess(list))
    // }
  } catch (error) {
    yield put(retrieveUserListFailure(error))
  }
}

export function* retrieveUserListWatcher() {
  yield takeLatest("RETRIEVE_USER_LIST", retrieveUserList)
}

function* addUser(req) {
  try {
    let res = yield APIPromises.addUser(req.payload)

    if (res.data && res.data.isSuccess === false) {
      yield put(addUserFailure())
    } else {
      yield put({
        type: "RETRIEVE_USER_LIST",
        payload: {
          equipmentId: req.payload.equipmentId,
        },
      })
      yield put(addUserSuccess(req.payload))
    }
  } catch (error) {
    yield put(addUserFailure(error))
  }
}

export function* addUserWatcher() {
  yield takeLatest("ADD_USER", addUser)
}

function* updateUser(req) {
  try {
    let res = yield APIPromises.updateUser(req.payload)

    if (res.data && res.data.isSuccess === false) {
      yield put(updateUserFailure())
    } else {
      yield put({
        type: "RETRIEVE_USER_LIST",
        payload: {
          equipmentId: req.payload.equipmentId,
        },
      })
      yield put(updateUserSuccess(req.payload))
    }
  } catch (error) {
    yield put(updateUserFailure(error))
  }
}

export function* updateUserWatcher() {
  yield takeLatest("UPDATE_USER", updateUser)
}

function* deleteUser(req) {
  try {
    let res = yield APIPromises.deleteUser(req.payload)

    if (res.data && res.data.isSuccess === false) {
      yield put(deleteUserFailure())
    } else {
      yield put({
        type: "RETRIEVE_USER_LIST",
        payload: {
          equipmentId: req.payload.equipmentId,
        },
      })
      yield put(deleteUserSuccess(req.payload))
    }
  } catch (error) {
    yield put(deleteUserFailure(error))
  }
}

export function* deleteUserWatcher() {
  yield takeLatest("DELETE_USER", deleteUser)
}
