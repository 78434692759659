export const uploadFile = (params, data) => ({
  type: "UPLOAD_FILE",
  payload: {
    params,
    data,
  },
})

export const uploadFileSuccess = res => ({
  type: "UPLOAD_FILE_SUCCESS",
  payload: res,
})

export const uploadFileError = errorMessage => ({
  type: "UPLOAD_FILE_ERROR",
  payload: errorMessage,
})

export const listFiles = data => ({
  type: "LIST_FILES",
  payload: data,
})

export const listFilesSuccess = res => ({
  type: "LIST_FILES_SUCCESS",
  payload: res,
})

export const listFilesError = errorMessage => ({
  type: "LIST_FILES_ERROR",
  payload: errorMessage,
})

export const approveFile = data => ({
  type: "APPROVE_FILE",
  payload: data,
})

export const approveFileSuccess = res => ({
  type: "APPROVE_FILE_SUCCESS",
  payload: res,
})

export const approveFileError = errorMessage => ({
  type: "APPROVE_FILE_ERROR",
  payload: errorMessage,
})

export const updateEffectiveDate = data => ({
  type: "UPDATE_EFFECTIVE_DATE",
  payload: data,
})

export const updateEffectiveDateSuccess = res => ({
  type: "UPDATE_EFFECTIVE_DATE_SUCCESS",
  payload: res,
})

export const updateEffectiveDateError = errorMessage => ({
  type: "UPDATE_EFFECTIVE_DATE_ERROR",
  payload: errorMessage,
})

export const deleteFile = (data, cm, isCMUser) => ({
  type: "DELETE_FILE",
  payload: data,
  cm,
  isCMUser,
})

export const deleteFileSuccess = res => ({
  type: "DELETE_FILE_SUCCESS",
  payload: res,
})

export const deleteFileError = errorMessage => ({
  type: "DELETE_FILE_ERROR",
  payload: errorMessage,
})

export const downloadFile = data => ({
  type: "DOWNLOAD_FILE",
  payload: data,
})

export const downloadFileSuccess = res => ({
  type: "DOWNLOAD_FILE_SUCCESS",
  payload: res,
})

export const downloadFileError = errorMessage => ({
  type: "DOWNLOAD_FILE_ERROR",
  payload: errorMessage,
})

export const retrieveProjectList = cmName => ({
  type: "RETRIEVE_PROJECT_LIST",
  cmName,
})

export const retrieveProjectListSuccess = res => ({
  type: "RETRIEVE_PROJECT_LIST_SUCCESS",
  payload: res,
})

export const retrieveProjectListError = errorMessage => ({
  type: "RETRIEVE_PROJECT_LIST_ERROR",
  payload: errorMessage,
})
