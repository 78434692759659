import React, { useState } from "react"
import Button from "@amzn/meridian/button"
import Column from "@amzn/meridian/column"
import SearchField from "@amzn/meridian/search-field"
import Tooltip from "@amzn/meridian/tooltip"
import Icon from "@amzn/meridian/icon"
// import copyTokens from "@amzn/meridian-tokens/base/icon/copy"
import trashTokens from "@amzn/meridian-tokens/base/icon/trash"
import editTokens from "@amzn/meridian-tokens/base/icon/edit"
import closeLargeTokens from "@amzn/meridian-tokens/base/icon/close-large"
import plusCircleTokens from "@amzn/meridian-tokens/base/icon/plus-circle"
import saveTokens from "@amzn/meridian-tokens/base/icon/save"
import { useSelector } from "react-redux"

import Table, {
  TableRow,
  TableCell,
  TableActionBar,
} from "@amzn/meridian/table"
import Row from "@amzn/meridian/row"

import EmptyState from "../../components/app/empty-state"
import { useSort } from "../../hooks"
import { getCategoryByValue } from "../../data/options"

const AuditTable = props => {
  const {
    data,
    editMode,
    setAddAuditModalOpen,
    // setCopyAuditIssuesModalOpen,
    isDeleteEnabled,
    onDeleteButtonClick,
    onCloseButtonClick,
    onSaveButtonClick,
    setEditMode,
  } = props
  const { isCMUser, isAdmin } = useSelector(state => state.userReducer)

  const [keyword, setKeyword] = useState("")
  const { sortedData, onSort, sortColumn, sortDirection } = useSort(data || [])

  return (
    <Row>
      <Column>
        <Table
          headerRows={1}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={onSort}
          showDividers={true}
          // stickyHeaderRow={true}
          // showStripes={true}
          fixHeaderRows={true}
        >
          <TableActionBar widths={[300]}>
            <SearchField
              value={keyword}
              onChange={setKeyword}
              placeholder="Search for..."
              type="text"
              onSubmit={() => {}}
            />
            {isDeleteEnabled && (
              <Tooltip position="top" title="Delete issue(s)" id="delete">
                <Button
                  disabled={!isAdmin}
                  type="icon"
                  onClick={() => onDeleteButtonClick()}
                >
                  <Icon tokens={trashTokens}>Delete</Icon>
                </Button>
              </Tooltip>
            )}
            <Tooltip position="top" title="Add new issue" id="add">
              <Button
                type="icon"
                disabled={isCMUser || editMode || !isAdmin}
                onClick={() => setAddAuditModalOpen(true)}
              >
                <Icon tokens={plusCircleTokens}>Save</Icon>
              </Button>
            </Tooltip>
            {editMode ? (
              <Tooltip position="top" title="Save changes" id="save">
                <Button type="icon" onClick={() => onSaveButtonClick()}>
                  <Icon tokens={saveTokens}>Save</Icon>
                </Button>
              </Tooltip>
            ) : (
              <Tooltip position="top" title="Edit issue(s)" id="edit">
                <Button
                  disabled={!data || data.length < 1 || (!isAdmin && !isCMUser)}
                  type="icon"
                  onClick={() => setEditMode(true)}
                >
                  <Icon tokens={editTokens}>Edit</Icon>
                </Button>
              </Tooltip>
            )}
            {editMode && (
              <Tooltip position="top" title="Cancel changes" id="cancel">
                <Button
                  type="icon"
                  onClick={() => {
                    setEditMode(false)
                    onCloseButtonClick()
                  }}
                >
                  <Icon tokens={closeLargeTokens}>Close</Icon>
                </Button>
              </Tooltip>
            )}
            <div />
          </TableActionBar>
          <TableRow>
            <TableCell></TableCell>
            <TableCell sortColumn="description" width={200}>
              Issue Description
            </TableCell>
            <TableCell sortColumn="issueType">Issue Type</TableCell>
            <TableCell sortColumn="priority">Priority</TableCell>
            <TableCell sortColumn="status">Status</TableCell>
            <TableCell sortColumn="createdDate" width={120}>
              Created Date
            </TableCell>
            <TableCell sortColumn="createdBy">Created By</TableCell>
            <TableCell sortColumn="resolvedDate" width={120}>
              Closed Date
            </TableCell>
            <TableCell sortColumn="resolvedBy">Resolved By</TableCell>
            <TableCell sortColumn="ecd" width={120}>
              ECD(Estimated complete date)
            </TableCell>
            <TableCell width={200}>Action</TableCell>
            <TableCell width={200}>Attachment</TableCell>
            <TableCell width={200}>Remarks</TableCell>
            <TableCell sortColumn="actionUpdateDate" width={120}>
              Action Update Date
            </TableCell>
          </TableRow>
          {sortedData &&
            sortedData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.delete}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.issueType}</TableCell>
                <TableCell>{row.priority}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.createdDate}</TableCell>
                <TableCell>{row.createdBy}</TableCell>
                <TableCell>{row.resolvedDate}</TableCell>
                <TableCell>{row.resolvedBy}</TableCell>
                <TableCell>{row.ecd}</TableCell>
                <TableCell>{row.action}</TableCell>
                <TableCell>{row.attachment}</TableCell>
                <TableCell>{row.remarks}</TableCell>
                <TableCell>{row.actionUpdateDate}</TableCell>
              </TableRow>
            ))}
        </Table>
        {sortedData && sortedData.length === 0 ? (
          <EmptyState target={"audit issues"} />
        ) : null}
      </Column>
    </Row>
  )
}

export default AuditTable
