/**
 * A context-based hook that allows components to determine the view of the
 * "File Library" page. The API is the same as useContext.
 */

import React, { useState, useCallback, useContext, createContext } from "react"
import PropTypes from "prop-types"
import useLocalStorage from "./use-local-storage"
import { defaultFileTableColumns } from "../data/file-table-column"

const FileViewPreferencesContext = createContext()

export const FileViewPreferencesProvider = props => {
  // Get view preferences out of local storage
  const [savedPreferences, persistPreferences] = useLocalStorage(
    "file-view-preferences"
  )

  // Initialize state value falling back to the default values defined above
  const [preferences, setPreferences] = useState(
    // Revive preference map from local storage if it exists
    savedPreferences
      ? new Map(savedPreferences)
      : new Map([
          ["stripes", false],
          ["columns", defaultFileTableColumns],
        ])
  )

  // When the user changes the preferences, persist the value to local storage
  // and update the state value
  const changePreferences = useCallback(
    values => {
      setPreferences(values)
      // Convert the values Map to an array when putting into local storage
      // so it can be serialized with JSON.stringify
      persistPreferences(Array.from(values.entries()))
    },
    [persistPreferences]
  )

  return (
    <FileViewPreferencesContext.Provider
      value={[preferences, changePreferences]}
    >
      {props.children}
    </FileViewPreferencesContext.Provider>
  )
}

FileViewPreferencesProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useFileViewPreferences = () => useContext(FileViewPreferencesContext)

export default useFileViewPreferences
