/**
 * This module exports a function that filters an array of files according
 * to a Map of filters.
 */

/**
 * Returns a function that checks if an object's value is in an array of options.
 * @example
 * const checkFoo = isInOptions("foo")
 * const object = { foo: "bar" }
 * checkFoo(object, ["bar", "baz"])  // returns true
 * checkFoo(object, ["baz"]) // returns false
 */
const isInOptions = key => (obj, options) => {
  return options.includes(obj[key])
}

// An object of filter applications where each key points to a function
// that expects `files` and `value` to consider
const applyFilter = {
  cmLocation: isInOptions("cmLocation"),
  types: isInOptions("fileType"),
  projects: isInOptions("project"),
  statuses: isInOptions("auditResult"),
}

/**
 * This function filters an array of files according to a Map of filters.
 *
 * @param  files          Array of files objects
 * @param  filters            Map of filter objects (see `src/hooks/use-shipments-filters`)
 * @return filteredFiles  Array of filtered files
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default (project, filters) => {
  if (filters.size === 0) return project
  let filteredProject = [...project]
  // Loop through and apply filter in the Map to the shipments array
  // console.warn("filteredProject prev", filteredProject)

  for (const filter of filters) {
    const [key, { value }] = filter

    filteredProject = filteredProject.filter(file => {
      return applyFilter[key](file, value)
    })
  }
  // console.warn("filteredProject", filteredProject)
  return filteredProject
}
