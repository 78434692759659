import { openToast } from "../../../api/toasts"
import {
  SUCCESS_TIMEOUT_IN_MS,
  FAILURE_TIMEOUT_IN_MS,
} from "../../../data/constants"

const INITIAL_STATE = {
  cm: "",
  cmIndex: 0,
  equipment: [],
  operatorList: [],
  calibrated: [],
  projects: [],
  isLoading: false,
  isLoadingFiles: false,
  status: false,
  sopFiles: [],
  checklistFiles: [],
  calibrationReportFiles: [],
  equipmentFiles: [],
}
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SELECT_CM":
      return {
        ...state,
        cmIndex: action.payload,
      }
    case "RETRIEVE_ALL_EQUIPMENT":
      return {
        ...state,
        isLoading: true,
      }
    case "RETRIEVE_ALL_EQUIPMENT_SUCCESS":
      // openToast({
      //   type: "success",
      //   title: "Retrieve Done",
      //   message: "Retrieve equipment successfully",
      //   timeout: SUCCESS_TIMEOUT_IN_MS,
      // })
      return {
        ...state,
        equipment: action.payload,
        isLoading: false,
      }
    case "RETRIEVE_ALL_EQUIPMENT_ERROR":
      openToast({
        type: "error",
        title: "Retrieve failed",
        message: "Retrieve equipment failed",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        equipment: action.payload,
        isLoading: false,
      }
    case "ADD_EQUIPMENT":
      return {
        ...state,
        isLoading: true,
      }
    case "ADD_EQUIPMENT_SUCCESS":
      openToast({
        type: "success",
        title: "Add Done",
        message: "Add equipment successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        status: action.payload,
        isLoading: false,
      }
    case "ADD_EQUIPMENT_FAILURE":
      openToast({
        type: "error",
        title: "Add failed",
        message: "failed to add the equipment, please retry",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "COPY_EQUIPMENT":
      return {
        ...state,
        isLoading: true,
      }
    case "COPY_EQUIPMENT_SUCCESS":
      openToast({
        type: "success",
        title: "Add Done",
        message: "Copy equipment successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        status: action.payload,
        isLoading: false,
      }
    case "COPY_EQUIPMENT_FAILURE":
      openToast({
        type: "error",
        title: "Add failed",
        message: "failed to copy the equipment, please retry",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "UPDATE_EQUIPMENT":
      return {
        ...state,
        isLoading: true,
      }
    case "UPDATE_EQUIPMENT_SUCCESS":
      openToast({
        type: "success",
        title: "Update Done",
        message: "Update equipment successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        status: action.payload,
        isLoading: false,
      }
    case "UPDATE_EQUIPMENT_FAILURE":
      openToast({
        type: "error",
        title: "Update failed",
        message: "failed to Update the equipment, please retry",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "DELETE_EQUIPMENT":
      return {
        ...state,
        isLoading: true,
      }
    case "DELETE_EQUIPMENT_SUCCESS":
      openToast({
        type: "success",
        title: "Add Done",
        message: "Delete equipment successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        status: action.payload,
        isLoading: false,
      }
    case "DELETE_EQUIPMENT_FAILURE":
      openToast({
        type: "error",
        title: "Add failed",
        message: "failed to delete the equipment, please retry",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "RETRIEVE_CALIBRATED_EQUIPS":
      return {
        ...state,
        isLoading: true,
      }
    case "RETRIEVE_CALIBRATED_EQUIPS_SUCCESS":
      return {
        ...state,
        calibrated: action.payload,
        isLoading: false,
      }

    case "RETRIEVE_EQUIPMENT_FILE_BY_SOP_TYPE":
      return {
        ...state,
      }
    case "RETRIEVE_EQUIPMENT_FILE_BY_SOP_TYPE_SUCCESS":
      return {
        ...state,
        sopFiles: action.payload,
      }
    case "RETRIEVE_FILE_BY_CHECKLIST_TYPE":
      return {
        ...state,
      }
    case "RETRIEVE_FILE_BY_CHECKLIST_TYPE_SUCCESS":
      return {
        ...state,
        checklistFiles: action.payload,
      }
    case "RETRIEVE_FILE_BY_CALIBRATION_REPORT_TYPE":
      return {
        ...state,
      }
    case "RETRIEVE_FILE_BY_CALIBRATION_REPORT_TYPE_SUCCESS":
      return {
        ...state,
        calibrationReportFiles: action.payload,
      }
    case "RETRIEVE_FILES_BY_TYPE":
      return {
        ...state,
        equipmentFiles: [],
        isLoadingFiles: true,
      }
    case "RETRIEVE_FILES_BY_TYPE_SUCCESS":
      return {
        ...state,
        equipmentFiles: action.payload,
        isLoadingFiles: false,
      }
    case "RETRIEVE_FILES_BY_TYPE_FAILURE":
      return {
        ...state,
        isLoadingFiles: false,
      }
    default:
      return state
  }
}

export default reducer
