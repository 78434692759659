import { openToast } from "../../../api/toasts"
import { FAILURE_TIMEOUT_IN_MS } from "../../../data/constants"
import { openNotifications } from "../../../api/notifications"

const INITIAL_STATE = {
  userInfo: undefined,
  errorMessage: "",
  isCMUser: true,
  isAdmin: false,
  cm: "",
  username: "",
  notifications: [],
  loading: false,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_USERINFO":
      return state
    case "FETCH_USERINFO_SUCCESS":
      // let user = {
      //   email: action.payload.email,
      //   unique_name: action.payload.unique_name,
      //   name: action.payload.first_name + " " + action.payload.last_name,
      //   userId: action.payload.unique_name,
      //   exp: action.payload.exp,
      // }
      return {
        ...state,
        isAdmin: action.payload?.UserInfo?.isAdminRole,
        userInfo: action.payload.UserInfo,
        isCMUser: !action.payload?.UserInfo?.isAmazonEmployee,
        username: action.payload?.UserInfo?.userName,
        nickname: action.payload?.UserInfo?.nickName,
        cm: action.payload?.UserInfo?.cm,
      }
    case "FETCH_USERINFO_ERROR":
      openToast({
        type: "error",
        title: "Login failed",
        message: "failed to login, please try again",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        userInfo: {},
        errorMessage: action.payload,
      }
    case "FETCH_NOTIFICATIONS":
      return {
        ...state,
        loading: true,
      }

    case "FETCH_NOTIFICATIONS_SUCCESS":
      return {
        ...state,
        notifications: action.payload,
        loading: false,
      }

    case "FETCH_NOTIFICATIONS_ERROR":
      openToast({
        type: "error",
        title: "Notifications failed",
        message: "failed to fetch notification, please try again",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        notifications: [],
        errorMessage: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default reducer
