import React, { useCallback, useEffect } from "react"
import Row from "@amzn/meridian/row"
import { useDispatch, useSelector } from "react-redux"
import Select, { SelectOption } from "@amzn/meridian/select"
import Input from "@amzn/meridian/input"
import Column from "@amzn/meridian/column"
import Button from "@amzn/meridian/button"
import CalendarCard from "../../../components/app/calendar-card"
import {
  retrieveCalibrationMonthSummary,
  selectCM,
  selectYear,
} from "../../../redux/calibrationTool/action"
import PageLayout from "../../../components/app/page-layout"

const CalibrationTool = () => {
  const reduxDispatch = useDispatch()
  const { calibrationSummary, cmIndex, year, isLoading } = useSelector(
    state => state.calibrationToolReducer
  )
  const { locationList } = useSelector(state => state.locationReducer)

  return (
    <React.Fragment>
      <PageLayout
        spacing="large"
        alignmentHorizontal={"start"}
        title="Calibration Tool"
        loading={isLoading}
        width="100%"
      >
        <Row spacing="small" width="100%">
          <Column>
            <Select
              label="CM Location"
              value={cmIndex}
              onChange={c => reduxDispatch(selectCM(c))}
              minWidth={240}
              size={"small"}
            >
              {locationList.map((x, index) => (
                <SelectOption
                  key={index}
                  value={index}
                  label={`${x.cm}-${x.cmLocation}`}
                />
              ))}
            </Select>
          </Column>
          <Column>
            <Select
              label="Year"
              value={year}
              onChange={c => reduxDispatch(selectYear(c))}
              minWidth={240}
              size={"small"}
            >
              {Array.from({ length: 20 }, (_, k) => k + 2022).map(
                (x, index) => (
                  <SelectOption key={index} value={x} label={x} />
                )
              )}
            </Select>
          </Column>
          <Button
            onClick={() =>
              reduxDispatch(
                retrieveCalibrationMonthSummary({
                  cm: locationList[cmIndex].cm,
                  cmLocation: locationList[cmIndex].cmLocation,
                  year: year,
                })
              )
            }
          >
            View
          </Button>
        </Row>
        <Row width="100%" wrap="down" alignmentVertical="top">
          {calibrationSummary.map(el => (
            <CalendarCard
              monthCode={el.monthName - 1}
              data={el}
              key={el.monthName}
            />
          ))}
        </Row>
      </PageLayout>
    </React.Fragment>
  )
}

export default CalibrationTool
