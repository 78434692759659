import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Row from "@amzn/meridian/row"
import Column from "@amzn/meridian/column"
import Select, { SelectOption } from "@amzn/meridian/select"
import Button from "@amzn/meridian/button"
import Table, {
  TableActionBar,
  TableCell,
  TableRow,
} from "@amzn/meridian/table"
import Divider from "@amzn/meridian/divider"
import Text from "@amzn/meridian/text"
import Tooltip from "@amzn/meridian/tooltip"
import Icon from "@amzn/meridian/icon"
import SearchField from "@amzn/meridian/search-field"
import Link from "@amzn/meridian/link"

import deleteTokens from "@amzn/meridian-tokens/base/icon/delete"
import editTokens from "@amzn/meridian-tokens/base/icon/edit"
import plusCircleTokens from "@amzn/meridian-tokens/base/icon/plus-circle"
import DatePicker from "@amzn/meridian/date-picker"
import Input from "@amzn/meridian/input"
import { format } from "date-fns"
import uploadLargeTokens from "@amzn/meridian-tokens/base/icon/upload-large"
import PageLayout from "../../../components/app/page-layout"
import { usePagination, useSort } from "../../../hooks"
import {
  calculateTurnoverRate,
  deleteLabPersonnel,
  retrieveLabPersonnel,
  selectCM,
} from "../../../redux/labPersonnel/action"
import { getRegex } from "../../../util/Util"
import AddLabPersonnelModal from "../modals/add-lab-personnel-modal"
import { DeleteConfirmModal } from "../../../components/app"

const isActive = (onboardDate, endDate) => {
  if (!onboardDate) {
    return false
  }
  let nowDatetime = new Date().getTime()
  let onboardDatetime = new Date(onboardDate).getTime()
  if (nowDatetime < onboardDatetime) {
    return false
  }
  if (!endDate) {
    return true
  }
  let endDatetime = new Date(endDate).getTime()
  if (nowDatetime > endDatetime) {
    return false
  }
  return true
}

const LabPersonnel = () => {
  const [keyword, setKeyword] = useState("")
  const [mode, setMode] = useState("add")
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [quickDate, setQuickDate] = useState("none")
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState("")
  const [currentData, setCurrentData] = useState({})
  const [addLabPersonnelModalOpen, setAddLabPersonnelModalOpen] = useState(
    false
  )
  const reduxDispatch = useDispatch()
  const { isLoading, labPersonnelList, cmIndex, turnoverRate } = useSelector(
    state => state.labPersonnelReducer
  )
  const { locationList } = useSelector(state => state.locationReducer)
  const { isCMUser, isAdmin } = useSelector(state => state.userReducer)

  const data = labPersonnelList.filter(f => {
    let regex = getRegex(keyword)
    return regex.test(JSON.stringify(f))
  })
  const { sortedData, onSort, sortColumn, sortDirection } = useSort(data)
  const { TableFooter, visibleIndices } = usePagination(data.length)

  useEffect(() => {
    if (locationList[cmIndex]) {
      reduxDispatch(
        retrieveLabPersonnel({
          cm: locationList[cmIndex].cm,
          test_location: locationList[cmIndex].cmLocation,
        })
      )
    }
  }, [reduxDispatch, cmIndex, locationList])

  useEffect(() => {
    if (fromDate && toDate) {
      reduxDispatch(
        calculateTurnoverRate({
          cm: locationList[cmIndex].cm,
          testLocation: locationList[cmIndex].cmLocation,
          startDate: fromDate,
          endDate: toDate,
        })
      )
    }
  }, [reduxDispatch, fromDate, toDate, locationList, cmIndex])

  return (
    <React.Fragment>
      <PageLayout
        spacing="large"
        alignmentHorizontal={"start"}
        title="Lab Personnel"
        loading={isLoading}
        width={"100%"}
      >
        <Row spacingInset="small none">
          <Column spacing="large">
            <Select
              label="CM Location"
              value={cmIndex}
              onChange={c => reduxDispatch(selectCM(c))}
              minWidth={240}
              size={"small"}
            >
              {locationList.map((x, index) => (
                <SelectOption
                  key={index}
                  value={index}
                  label={`${x.cm}-${x.cmLocation}`}
                />
              ))}
            </Select>
          </Column>
        </Row>
        <Row>
          <Table
            headerRows={1}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={onSort}
            showDividers={true}
            spacing="small"
            // stickyHeaderRow={true}
            fixHeaderRows={true}
            // showStripes={true}
          >
            <TableActionBar widths={[300]}>
              <SearchField
                value={keyword}
                onChange={setKeyword}
                placeholder="Search for..."
                // size="medium"
                type="text"
                onSubmit={() => {}}
              />
              <Tooltip position="top" title="Batch Import" id="myTooltip">
                <Button
                  type="icon"
                  disabled={!isCMUser && !isAdmin}
                  // onClick={() => setBatchImportEquipmentModalOpen(true)}
                >
                  <Icon tokens={uploadLargeTokens} />
                </Button>
              </Tooltip>
              <Tooltip
                position="top"
                title="Add new lab personnel"
                id="myTooltip"
              >
                <Button
                  type="icon"
                  disabled={!isCMUser && !isAdmin}
                  onClick={() => {
                    setMode("add")
                    setCurrentData({})
                    setAddLabPersonnelModalOpen(true)
                  }}
                >
                  <Icon tokens={plusCircleTokens}>Save</Icon>
                </Button>
              </Tooltip>
            </TableActionBar>
            <TableRow>
              <TableCell sortColumn="name">Name</TableCell>
              <TableCell sortColumn="email">Email</TableCell>
              <TableCell sortColumn="type">Type</TableCell>
              <TableCell sortColumn="onboardDate">
                Onboard Amazon Project Date
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell sortColumn="leaveDate">Leave Date</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
            {sortedData &&
              sortedData
                .slice(visibleIndices[0], visibleIndices[1])
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.onboardDate}</TableCell>
                    <TableCell>
                      {isActive(row.onboardDate, row.leaveDate)
                        ? "Active"
                        : "Expired"}
                    </TableCell>
                    <TableCell>{row.leaveDate}</TableCell>
                    <TableCell>
                        <Text>
                          <Link
                            disabled={!isAdmin}
                            onClick={() => {
                              setMode("edit")
                              setCurrentData(row)
                              setAddLabPersonnelModalOpen(true)
                            }}
                          >
                            <Icon tokens={editTokens}>Edit</Icon>
                          </Link>
                        </Text>
                      </TableCell>
                    <TableCell>
                      <Text>
                        <Link
                          disabled={!isCMUser && !isAdmin}
                          onClick={() => {
                            setCurrentData(row)
                            setDeleteConfirmModalOpen(true)
                          }}
                        >
                          <Icon tokens={deleteTokens} />
                        </Link>
                      </Text>
                    </TableCell>
                  </TableRow>
                ))}
          </Table>
        </Row>
        <Row>
          <Column>
            <Select
              label="Quick Select"
              value={quickDate}
              onChange={v => {
                if (v === "monthly") {
                  let date = new Date()
                  setToDate(format(new Date(), "yyyy-MM-dd"))
                  let date2 = new Date(date.setMonth(date.getMonth() - 1))
                  setFromDate(format(date2, "yyyy-MM-dd"))
                } else if (v === "quarterly") {
                  let date = new Date()
                  setToDate(format(new Date(), "yyyy-MM-dd"))
                  let date2 = new Date(date.setMonth(date.getMonth() - 3))
                  setFromDate(format(date2, "yyyy-MM-dd"))
                } else if (v === "yearly") {
                  let date = new Date()
                  setToDate(format(new Date(), "yyyy-MM-dd"))
                  let date2 = new Date(date.setFullYear(date.getFullYear() - 1))
                  setFromDate(format(date2, "yyyy-MM-dd"))
                }
                setQuickDate(v)
              }}
              width={180}
            >
              <SelectOption value="none" label="None" />
              <SelectOption value="monthly" label="Monthly" />
              <SelectOption value="quarterly" label="Quarterly" />
              <SelectOption value="yearly" label="Yearly" />
            </Select>
          </Column>
          <Column>
            <Text>Turnover Rate = </Text>
          </Column>
          <Column>
            <Row style={{ border: "1px solid black" }}>
              <Text>Number of leave from</Text>
              <DatePicker
                value={fromDate}
                onChange={setFromDate}
                size={"small"}
                width={180}
              />
              <Text>to </Text>
              <DatePicker
                value={toDate}
                onChange={setToDate}
                size={"small"}
                width={180}
              />
            </Row>
            <Divider />
            <Row alignmentHorizontal="center">
              <Text>Average Number on Job from </Text>
              <Input
                value={fromDate}
                type="text"
                disabled={true}
                size="small"
                width={180}
              />
              <Text>to</Text>
              <Input
                value={toDate}
                type="text"
                disabled={true}
                size="small"
                width={180}
              />
            </Row>
          </Column>
          <Column>
            <Text>= {turnoverRate}%</Text>
          </Column>
        </Row>
      </PageLayout>

      <AddLabPersonnelModal
        modalOpen={addLabPersonnelModalOpen}
        setModalOpen={setAddLabPersonnelModalOpen}
        currentData={currentData}
        mode={mode}
      />
      <DeleteConfirmModal
        modalOpen={deleteConfirmModalOpen}
        setModalOpen={setDeleteConfirmModalOpen}
        action={() => {
          reduxDispatch(
            deleteLabPersonnel({
              labPersonnelId: currentData?.id,
              cm: locationList[cmIndex]?.cm,
              cmLocation: locationList[cmIndex]?.cmLocation,
            })
          )
        }}
        message={"Are you sure you want to delete this person?"}
      />
    </React.Fragment>
  )
}

export default LabPersonnel
