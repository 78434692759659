/**
 * The footer shown below the current page's content.
 */

import React from "react"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import { Select, SelectOption } from "./form-fields"

const FormSelect = props => {
  const { value, setValue, label, options, disabled } = props
  return (
    <Row spacingInset="small none">
      <Column minWidth="150px">
        <Text tag="label" type="b200" color="primary" htmlFor="my-input">
          {label}
        </Text>
      </Column>
      <Column minWidth="250px">
        <Select
          value={value}
          onChange={setValue}
          minWidth={240}
          size={"small"}
          disabled={disabled}
        >
          {options.map(type => (
            <SelectOption
              key={type.value}
              value={type.value}
              label={type.label}
              disabled={disabled}
            />
          ))}
        </Select>
      </Column>
    </Row>
  )
}

export default FormSelect
