/**
 * This is a client for a fake toasts service. This persists toasts in memory
 * using the `createApi` helper. In a real application you would probably store
 * toasts in something like a redux store or in React context.
 */

import createApi from "./create-api"

const api = createApi("toasts", {
  defaultData: [],
  persistInLocalStorage: false,
})

const listenToToasts = api.listen

const openToast = ({ type, title, message, action, actionLabel, timeout }) => {
  const id = Math.random().toString()
  return api.update(toasts =>
    toasts.concat({ type, title, message, action, actionLabel, timeout, id })
  )
}

const closeToast = id =>
  api.update(toasts => toasts.filter(toast => toast.id !== id))

export { listenToToasts, openToast, closeToast }
