import React from "react"
// import {useHistory} from "react-router-dom";
import Row from "@amzn/meridian/row"
import Column from "@amzn/meridian/column"
// import Checkbox from "@amzn/meridian/checkbox";
import Button from "@amzn/meridian/button"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import { useSelector } from "react-redux"

import { Select, SelectOption } from "../../../components/app/form-fields"
import { categoryOptions, getStatusByValue } from "../../../data/options"
import { EQUIPMENT_STATUS } from "../../../data/constants"
import useFilters from "../../../hooks/use-filters"
import { getLocationsByCmName } from "../../../util/Util"

const EquipmentFiltersModal = props => {
  // eslint-disable-next-line no-unused-vars
  const { filterModalOpen, setFilterModalOpen } = props
  const { cm } = useSelector(state => state.equipmentReducer)
  const { dispatch, filters } = useFilters()
  const { cms } = useSelector(state => state.commonReducer)

  const onChangeFilter = (filter, makeLabel) => value => {
    if (!value || value.length === 0) {
      dispatch({ type: "REMOVE_FILTER", filter })
    } else {
      dispatch({
        type: "UPDATE_FILTER",
        filter,
        value,
        tagLabel: makeLabel(value),
      })
    }
  }

  return (
    <Modal
      title="Filters"
      open={filterModalOpen}
      onClose={() => setFilterModalOpen(false)}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
    >
      <form>
        <Column width={320} spacingInset="200">
          <text>Category</text>
          <Select
            value={filters.get("category")?.value || []}
            onChange={onChangeFilter("category", value =>
              value.map(category => `Category: ${getStatusByValue(category)}`)
            )}
            id="category-select"
            placeholder="Select a category"
          >
            {categoryOptions.map(x => (
              <SelectOption key={x.value} value={x.value} label={x.label} />
            ))}
          </Select>
          <text>Flag</text>
          <Select
            value={filters.get("flag")?.value || []}
            onChange={onChangeFilter("flag", value =>
              value.map(status => `Flag: ${getStatusByValue(status)}`)
            )}
            id="status-select"
            placeholder="Select a status"
          >
            {EQUIPMENT_STATUS.map(type => (
              <SelectOption key={type} value={type} label={type} />
            ))}
          </Select>
        </Column>
      </form>
      <ModalFooter>
        <Row
          alignmentHorizontal="center"
          spacingInset="200 none 400 none"
          widths="fill"
        >
          <Button type="link" onClick={() => dispatch({ type: "RESET" })}>
            Reset filters
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default EquipmentFiltersModal
