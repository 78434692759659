import React from "react"
// import {useHistory} from "react-router-dom";
import Row from "@amzn/meridian/row"
// import Checkbox from "@amzn/meridian/checkbox";
import Button from "@amzn/meridian/button"
import Text from "@amzn/meridian/text"

import Modal, { ModalFooter } from "@amzn/meridian/modal"

const DeleteConfirmModal = props => {
  // eslint-disable-next-line no-unused-vars
  const { modalOpen, setModalOpen, action, message } = props

  return (
    <Modal
      title="Warning!"
      open={modalOpen}
      // onClose={onClose}
      width={600}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
    >
      <form>
        <Row spacingInset="small none">
          <Text>{message}</Text>
        </Row>
      </form>
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              action()
              setModalOpen(false)
            }}
          >
            Yes
          </Button>
          <Button
            type="secondary"
            size="small"
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export { DeleteConfirmModal }
