export const SUFIX = "_EQUIPMENT"

export const retrieveAuditReport = payload => ({
  type: "RETRIEVE_AUDIT_REPORT" + SUFIX,
  payload,
})

export const retrieveAuditReportSuccess = allIssues => ({
  type: "RETRIEVE_AUDIT_REPORT_SUCCESS" + SUFIX,
  payload: allIssues,
})

export const retrieveAuditReportFailure = res => ({
  type: "RETRIEVE_AUDIT_REPORT_FAILURE" + SUFIX,
  payload: res,
})

export const selectCM = cm => ({
  type: "SELECT_CM_AUDIT" + SUFIX,
  payload: cm,
})

export const selectEquipment = (equipment, equipmentIndex) => ({
  type: "SELECT_EQUIPMENT_AUDIT" + SUFIX,
  payload: {
    equipment,
    equipmentIndex,
  },
})

export const retrieveEquipment = payload => ({
  type: "RETRIEVE_EQUIPMENT_AUDIT" + SUFIX,
  payload,
})

export const retrieveEquipmentSuccess = equipment => ({
  type: "RETRIEVE_EQUIPMENT_AUDIT_SUCCESS" + SUFIX,
  payload: equipment,
})
export const retrieveEquipmentFailure = res => ({
  type: "RETRIEVE_EQUIPMENT_AUDIT_FAILURE" + SUFIX,
  payload: res,
})

export const retrieveEquipmentFiles = payload => ({
  type: "RETRIEVE_EQUIPMENT_FILES" + SUFIX,
  payload,
})

export const retrieveEquipmentFilesSuccess = equipment => ({
  type: "RETRIEVE_EQUIPMENT_FILES_SUCCESS" + SUFIX,
  payload: equipment,
})

export const retrieveEquipmentFilesFailure = res => ({
  type: "RETRIEVE_EQUIPMENT_FILES_FAILURE" + SUFIX,
  payload: res,
})

export const retrieveAuditIssues = payload => ({
  type: "RETRIEVE_AUDIT_ISSUES" + SUFIX,
  payload,
})

export const retrieveAuditIssuesSuccess = allIssues => ({
  type: "RETRIEVE_AUDIT_ISSUES_SUCCESS" + SUFIX,
  payload: allIssues,
})

export const retrieveAuditIssuesFailure = res => ({
  type: "RETRIEVE_AUDIT_ISSUES_FAILURE" + SUFIX,
  payload: res,
})

export const retrieveEquipmentStatusHistory = payload => ({
  type: "RETRIEVE_STATUS_HISTORY" + SUFIX,
  payload,
})

export const retrieveEquipmentStatusHistorySuccess = history => ({
  type: "RETRIEVE_STATUS_HISTORY_SUCCESS" + SUFIX,
  payload: history,
})

export const retrieveEquipmentStatusHistoryFailure = res => ({
  type: "RETRIEVE_STATUS_HISTORY_FAILURE" + SUFIX,
  payload: res,
})

export const updateBasicInfo = payload => ({
  type: "UPDATE_BASIC_INFO" + SUFIX,
  payload,
})

export const updateBasicInfoSuccess = history => ({
  type: "UPDATE_BASIC_INFO_SUCCESS" + SUFIX,
  payload: history,
})

export const updateBasicInfoFailure = res => ({
  type: "UPDATE_BASIC_INFO_FAILURE" + SUFIX,
  payload: res,
})

// consumable
export const retrieveConsumable = payload => ({
  type: "RETRIEVE_CONSUMABLE" + SUFIX,
  payload,
})

export const retrieveConsumableSuccess = allIssues => ({
  type: "RETRIEVE_CONSUMABLE_SUCCESS" + SUFIX,
  payload: allIssues,
})

export const retrieveConsumableFailure = res => ({
  type: "RETRIEVE_CONSUMABLE_FAILURE" + SUFIX,
  payload: res,
})

export const addConsumable = payload => ({
  type: "ADD_CONSUMABLE" + SUFIX,
  payload,
})

export const addConsumableSuccess = allIssues => ({
  type: "ADD_CONSUMABLE_SUCCESS" + SUFIX,
  payload: allIssues,
})

export const addConsumableFailure = res => ({
  type: "ADD_CONSUMABLE_FAILURE" + SUFIX,
  payload: res,
})

export const deleteConsumable = payload => ({
  type: "DELETE_CONSUMABLE" + SUFIX,
  payload,
})

export const deleteConsumableSuccess = allIssues => ({
  type: "DELETE_CONSUMABLE_SUCCESS" + SUFIX,
  payload: allIssues,
})

export const deleteConsumableFailure = res => ({
  type: "DELETE_CONSUMABLE_FAILURE" + SUFIX,
  payload: res,
})

export const retrieveOperatorList = payload => ({
  type: "RETRIEVE_OPERATOR_LIST",
  payload,
})

export const retrieveOperatorListSuccess = list => ({
  type: "RETRIEVE_OPERATOR_LIST_SUCCESS",
  payload: list,
})

export const retrieveOperatorListFailure = res => ({
  type: "RETRIEVE_OPERATOR_LIST_FAILURE",
  payload: res,
})

export const addOperator = payload => ({
  type: "ADD_OPERATOR",
  payload: payload,
})

export const addOperatorSuccess = list => ({
  type: "ADD_OPERATOR_SUCCESS",
  payload: list,
})

export const addOperatorFailure = res => ({
  type: "ADD_OPERATOR_FAILURE",
  payload: res,
})

export const deleteOperator = payload => ({
  type: "DELETE_OPERATOR",
  payload: payload,
})

export const deleteOperatorSuccess = list => ({
  type: "DELETE_OPERATOR_SUCCESS",
  payload: list,
})

export const deleteOperatorFailure = res => ({
  type: "DELETE_OPERATOR_FAILURE",
  payload: res,
})
