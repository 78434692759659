import React, { useState, useEffect } from "react"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import { useDispatch } from "react-redux"
import { FormSelect, FormInput, FormTextArea } from "../../../components/app"
import { categoryOptions } from "../../../data/options"
import { addCapabilityTemplate } from "../../../redux/capabilityTemplate/action"

const AddCapabilityTemplateModal = props => {
  const { modalOpen, setModalOpen, mode, currentData } = props

  const [capability, setCapablitiy] = useState({})

  useEffect(() => {
    setCapablitiy(currentData)
  }, [currentData, setCapablitiy])

  const reduxDispatch = useDispatch()
  return (
    <Modal
      title={mode === "edit" ? "Edit Template" : "Add Template"}
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
    >
      <form>
        <FormSelect
          value={capability.category}
          setValue={value => setCapablitiy({ ...capability, category: value })}
          label={"Category"}
          options={categoryOptions}
        />
        <FormInput
          value={capability.subcategory}
          setValue={value =>
            setCapablitiy({ ...capability, subcategory: value })
          }
          label={"Subcategory"}
        />
        <FormInput
          value={capability.coverageTemplate}
          setValue={value =>
            setCapablitiy({ ...capability, coverageTemplate: value })
          }
          label={"Coverage"}
        />
        <FormTextArea
          value={capability.capacityTemplate}
          setValue={value =>
            setCapablitiy({ ...capability, capacityTemplate: value })
          }
          placeholder="Enter value..."
          label="Capability Template"
        />
      </form>
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            disabled={!capability.category || !capability.subcategory}
            type="primary"
            size="small"
            onClick={() => {
              setModalOpen(false)
              reduxDispatch(addCapabilityTemplate(capability))
            }}
          >
            {mode === "edit" ? "Update" : "Add"}
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default AddCapabilityTemplateModal
