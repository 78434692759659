import { openToast } from "../../../api/toasts"
import {
  SUCCESS_TIMEOUT_IN_MS,
  FAILURE_TIMEOUT_IN_MS,
} from "../../../data/constants"

const INITIAL_STATE = {
  locationList: [],
  isLoading: false,
}
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "RETRIEVE_LOCATION_LIST":
      console.warn("RETRIEVE_LOCATION_LIST")
      return {
        ...state,
        locationList: [],
      }
    case "RETRIEVE_LOCATION_LIST_SUCCESS":
      console.warn("RETRIEVE_LOCATION_LIST_SUCCESS", action.payload)
      return {
        ...state,
        locationList: action.payload,
      }
    case "RETRIEVE_LOCATION_LIST_FAILURE":
      console.warn("RETRIEVE_LOCATION_LIST_FAILURE")
      return {
        ...state,
      }
    case "ADD_LOCATION":
      return {
        ...state,
        isLoading: true,
      }
    case "ADD_LOCATION_SUCCESS":
      openToast({
        type: "success",
        title: "Add Done",
        message: "Add new location successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "ADD_LOCATION_FAILURE":
      openToast({
        type: "error",
        title: "Add failed",
        message: "Failed to add new location, please try again",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "UPDATE_LOCATION":
      return {
        ...state,
        isLoading: true,
      }
    case "UPDATE_LOCATION_SUCCESS":
      openToast({
        type: "success",
        title: "Update Done",
        message: "Update location successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "UPDATE_LOCATION_FAILURE":
      openToast({
        type: "error",
        title: "Update failed",
        message: "Failed to Update location, please try again",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "DELETE_LOCATION":
      console.warn("DELETE_LOCATION")
      return {
        ...state,
        isLoading: true,
      }
    case "DELETE_LOCATION_SUCCESS":
      console.warn("DELETE_LOCATION_SUCCESS", action.payload)
      openToast({
        type: "success",
        title: "Delete Done",
        message: "Delete location successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "DELETE_LOCATION_FAILURE":
      console.warn("DELETE_LOCATION_FAILURE", action.payload)
      openToast({
        type: "error",
        title: "Delete failed",
        message: "Failed to delete location, please try again",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default reducer
