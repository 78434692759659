/**
 * The footer shown below the current page's content.
 */

import React from "react"

import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import FileInputWrapper from "../file-library/fileInputWrapper"
// import { Select, SelectOption } from "./form-fields"
import SearchableSelect from "./searchable-select"

const FormFilePicker = props => {
  const { value, setValue, label, files, fileParam, callback, disabled } = props
  return (
    <Row spacingInset="small none">
      <Column minWidth="150px">
        <Text tag="label" type="b200" color="primary" htmlFor="my-input">
          {label}
        </Text>
      </Column>
      <Column minWidth="300px">
        <SearchableSelect
          value={value}
          onChange={setValue}
          minWidth={240}
          size={"small"}
          options={files.map(f => ({ value: f.fileId, label: f.fileName }))}
        />
      </Column>
      <Column>
        <FileInputWrapper
          uploadButtonDisabled={disabled}
          uploadButtonLabel={""}
          showDropZone={false}
          fileParam={fileParam}
          simpleDetail={true}
          callback={(fileName, fileId) => {
            callback()
            setValue(fileId)
          }}
        />
      </Column>
    </Row>
  )
}

export default FormFilePicker
