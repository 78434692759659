/**
 * A helper for laying out page contents including a title, breadcrumbs, content,
 * and a loader.
 */

import React from "react"
import { useHistory } from "react-router-dom"
import Column from "@amzn/meridian/column"
import Heading from "@amzn/meridian/heading"
import Breadcrumb, { BreadcrumbGroup } from "@amzn/meridian/breadcrumb"
import Loader from "@amzn/meridian/loader"
import { css } from "emotion"

const PageLayout = ({
  children,
  title,
  loading,
  breadcrumbs = [],
  spacing = "large",
  width = 950,
  alignmentHorizontal = "center",
}) => {
  const history = useHistory()
  return (
    <Column
      alignmentHorizontal={alignmentHorizontal}
      spacingInset="large large xlarge"
    >
      <Column width={width} maxWidth="100%" spacing={spacing}>
        {breadcrumbs.length > 0 ? (
          <BreadcrumbGroup>
            {breadcrumbs.map(({ title, path }) => (
              <Breadcrumb key={path} href={path} onClick={history.push}>
                {title}
              </Breadcrumb>
            ))}
            <Breadcrumb>{title}</Breadcrumb>
          </BreadcrumbGroup>
        ) : null}
        {title ? (
          <Heading
            className={css({ fontWeight: "400!important" })}
            level={1}
            type="h600"
          >
            {title}
          </Heading>
        ) : null}
        {loading ? (
          <Column alignmentHorizontal="center" spacingInset="xlarge none">
            <Loader type="circular" />
          </Column>
        ) : (
          children
        )}
      </Column>
    </Column>
  )
}

export default PageLayout
