const equipmentTableColumns = [
  {
    id: "calibrationScore",
    label: "Calibration Score",
  },
  {
    id: "capabilityScore",
    label: "Capability Score",
  },
  {
    id: "checklistScore",
    label: "Checklist Score",
  },
  {
    id: "sopScore",
    label: "Sop Score",
  },
]

export const defaultEquipmentTableColumns = equipmentTableColumns.reduce(
  (columns, column) => ({
    ...columns,
    [column.id]: false,
  }),
  {}
)

export default equipmentTableColumns
