import React, { useState, useEffect } from "react"
import * as xlsx from "xlsx"
// import Loader from "@amzn/meridian/loader"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import { useSelector, useDispatch } from "react-redux"
import Table, {
  TableRow,
  TableCell,
  TableActionBar,
} from "@amzn/meridian/table"
import Icon from "@amzn/meridian/icon"
import Link from "@amzn/meridian/link"
import Text from "@amzn/meridian/text"
import SearchField from "@amzn/meridian/search-field"
import { useHistory } from "react-router-dom"
import plusCircleTokens from "@amzn/meridian-tokens/base/icon/plus-circle"
import Tooltip from "@amzn/meridian/tooltip"
import deleteTokens from "@amzn/meridian-tokens/base/icon/delete"
import editTokens from "@amzn/meridian-tokens/base/icon/edit"

import { usePagination, useSort } from "../../../hooks"

import PageLayout from "../../../components/app/page-layout"
import EmptyState from "../../../components/app/empty-state"
import AddLocationModal from "../modals/add-location-modal"
import { DeleteConfirmModal } from "../../../components/app"

import {
  retrieveLocationList,
  deleteLocation,
} from "../../../redux/testLocation/action"
import { getRegex } from "../../../util/Util"

const TestLocation = () => {
  const [currentData, setCurrentData] = useState({})
  const [mode, setMode] = useState("add")
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState("")

  const { locationList, isLoading } = useSelector(
    state => state.locationReducer
  )

  const reduxDispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  // for searching and filtering
  const [keyword, setKeyword] = useState("")
  const [addLocationModalOpen, setAddLocationModalOpen] = useState(false)
  const { isAdmin } = useSelector(state => state.userReducer)

  const data = locationList.filter(f => {
    let regex = getRegex(keyword)
    return regex.test(JSON.stringify(f))
  })

  const getCapablity = ({ canNPI, canSustaining }) => {
    if (canNPI && canSustaining) {
      return "NPI/Sustaining"
    } else if (!canNPI && canSustaining) {
      return "Sustaining"
    } else if (canNPI && !canSustaining) {
      return "NPI"
    }
    return ""
  }

  useEffect(() => {
    reduxDispatch(retrieveLocationList())
    // if (isCMLocation) {
    //   reduxDispatch(listFiles({ cm }))
    // } else {
    //   reduxDispatch(listFiles())
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { sortedData, onSort, sortColumn, sortDirection } = useSort(data)

  const history = useHistory()
  const { TableFooter, visibleIndices } = usePagination(data.length)

  return (
    <React.Fragment>
      <PageLayout
        spacing="large"
        alignmentHorizontal={"start"}
        title="Test Location"
        loading={isLoading}
        width={"100%"}
      >
        <Row spacingInset="small none">
          <Column spacing="large" width="100%">
            <Table
              headerRows={1}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              onSort={onSort}
              showDividers={true}
              spacing="small"
              // stickyHeaderRow={true}
              fixHeaderRows={true}
              // showStripes={true}
            >
              <TableActionBar widths={[300]}>
                <SearchField
                  value={keyword}
                  onChange={setKeyword}
                  placeholder="Search for..."
                  // size="medium"
                  type="text"
                  onSubmit={() => {}}
                />
                <Tooltip position="top" title="Add new location" id="myTooltip">
                  <Button
                    type="icon"
                    disabled={!isAdmin}
                    onClick={() => {
                      setMode("add")
                      setCurrentData({})
                      setAddLocationModalOpen(true)
                    }}
                  >
                    <Icon tokens={plusCircleTokens}>Save</Icon>
                  </Button>
                </Tooltip>
              </TableActionBar>
              <TableRow>
                <TableCell sortColumn="cm">CM</TableCell>
                <TableCell sortColumn="cmLocation">Test Location</TableCell>
                <TableCell>Build Capability</TableCell>
                <TableCell>DRI</TableCell>
                <TableCell>CM Users</TableCell>
                <TableCell>Edit</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
              {sortedData &&
                sortedData
                  .slice(visibleIndices[0], visibleIndices[1])
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.cm}</TableCell>
                      <TableCell>{row.cmLocation}</TableCell>
                      <TableCell>{getCapablity(row)}</TableCell>
                      <TableCell>
                        {row.driUsers.map(x => x.nickName).join(", ")}
                      </TableCell>
                      <TableCell>
                        {row.cmUsers.map(x => x.nickName).join(", ")}
                      </TableCell>
                      <TableCell>
                        <Text>
                          <Link
                            disabled={!isAdmin}
                            onClick={() => {
                              setMode("edit")
                              setCurrentData(row)
                              setAddLocationModalOpen(true)
                            }}
                          >
                            <Icon tokens={editTokens}>Edit</Icon>
                          </Link>
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text>
                          <Link
                            disabled={!isAdmin}
                            onClick={() => {
                              setCurrentData(row)
                              setDeleteConfirmModalOpen(true)
                            }}
                          >
                            <Icon tokens={deleteTokens} />
                          </Link>
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))}
            </Table>
            {sortedData && sortedData.length === 0 ? (
              <EmptyState target={"locations"} />
            ) : null}
            <TableFooter />
          </Column>
        </Row>

        <AddLocationModal
          modalOpen={addLocationModalOpen}
          setModalOpen={setAddLocationModalOpen}
          currentData={currentData}
          mode={mode}
        />
        <DeleteConfirmModal
          modalOpen={deleteConfirmModalOpen}
          setModalOpen={setDeleteConfirmModalOpen}
          action={() => {
            reduxDispatch(
              deleteLocation({
                cm: currentData.cm,
                testLocation: currentData.cmLocation,
                // locationId: currentData.id,
              })
            )
          }}
          message={"Are you sure you want to delete this location?"}
        />
      </PageLayout>
    </React.Fragment>
  )
}

export default TestLocation
