import { openToast } from "../../../api/toasts"
import {
  SUCCESS_TIMEOUT_IN_MS,
  FAILURE_TIMEOUT_IN_MS,
} from "../../../data/constants"

const INITIAL_STATE = {
  userInfo: {},
  errorMessage: "",
  isLoading: false,
  isUploading: false,
  fileList: [],
  projectList: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPLOAD_FILE":
      return {
        ...state,
        isUploading: true,
      }
    case "UPLOAD_FILE_SUCCESS":
      console.warn("UPLOAD_FILE_SUCCESS", action)
      openToast({
        type: "success",
        title: "Upload Done",
        message: "Upload file successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })

      return {
        ...state,
        isUploading: false,
      }
    case "UPLOAD_FILE_ERROR":
      console.warn("UPLOAD_FILE_ERROR", action)
      openToast({
        type: "error",
        title: "Error.",
        message: "There was a problem uploading your file, please try again.",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        errorMessage: action.payload,
        isUploading: false,
      }
    case "LIST_FILES":
      return {
        ...state,
        isLoading: true,
      }
    case "LIST_FILES_SUCCESS":
      console.warn("LIST_FILES_SUCCESS", action)
      // openToast({
      //   type: "success",
      //   title: "Retrieve Done",
      //   message: "Retrieve files successfully",
      //   timeout: SUCCESS_TIMEOUT_IN_MS,
      // })
      return {
        ...state,
        fileList: action.payload.retrieveFilesResponse || [],
        isLoading: false,
      }
    case "LIST_FILES_ERROR":
      console.warn("LIST_FILES_ERROR", action)
      openToast({
        type: "error",
        title: "Retrieve Done",
        message: "Error retrieving files",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      }
    case "APPROVE_FILE":
      return {
        ...state,
        isLoading: true,
      }
    case "APPROVE_FILE_SUCCESS":
      console.warn("APPROVE_FILE_SUCCESS", action)
      openToast({
        type: "success",
        title: "Approval Done",
        message: "Approve files successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        // fileList: action.payload,
        isLoading: false,
      }
    case "APPROVE_FILE_ERROR":
      console.warn("APPROVE_FILE_ERROR", action)
      openToast({
        type: "error",
        title: "Approval Done",
        message: "Error Approving files",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      }
    case "UPDATE_EFFECTIVE_DATE":
      return {
        ...state,
        isLoading: true,
      }
    case "UPDATE_EFFECTIVE_DATE_SUCCESS":
      console.warn("UPDATE_EFFECTIVE_DATE_SUCCESS", action)
      openToast({
        type: "success",
        title: "Update Done",
        message: "Update effective date successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        // fileList: action.payload,
        isLoading: false,
      }
    case "UPDATE_EFFECTIVE_DATE_ERROR":
      console.warn("UPDATE_EFFECTIVE_DATE_ERROR", action)
      openToast({
        type: "error",
        title: "Approval Done",
        message: "Error updating effective date",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      }
    case "DELETE_FILE":
      return {
        ...state,
        isLoading: true,
      }
    case "DELETE_FILE_SUCCESS":
      console.warn("DELETE_FILE_SUCCESS", action)

      openToast({
        type: "success",
        title: "Delete Done",
        message: "Delete file successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "DELETE_FILE_ERROR":
      openToast({
        type: "error",
        title: "Delete Done",
        message: "Error Deleting files",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      }
    case "DOWNLOAD_FILE":
      return {
        ...state,
        isLoading: true,
      }
    case "DOWNLOAD_FILE_SUCCESS":
      console.warn("DOWNLOAD_FILE_SUCCESS", action)
      window.open(action.payload.presignedURL, "_blank")
      return {
        ...state,
        isLoading: false,
      }
    case "DOWNLOAD_FILE_ERROR":
      openToast({
        type: "error",
        title: "download Done",
        message: "Error downloading files",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      }
    case "RETRIEVE_PROJECT_LIST":
      return {
        ...state,
      }
    case "RETRIEVE_PROJECT_LIST_SUCCESS":
      console.warn("RETRIEVE_PROJECT_LIST_SUCCESS", action)
      return {
        ...state,
        projectList: action.payload,
      }
    case "RETRIEVE_PROJECT_LIST_ERROR":
      console.warn("RETRIEVE_PROJECT_LIST_ERROR", action)
      openToast({
        type: "error",
        title: "Retrieve Done",
        message: "Error retrieving projects",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}

export default reducer
