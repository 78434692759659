import React, { useState } from "react"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import { useSelector } from "react-redux"
import axios from "axios"
import {
  auditBlockerOptions,
  auditCategoryOptions,
  auditStatusOptions,
  auditIssueTypeOptions,
  auditPriorityOptions,
} from "../../../data/options"
import {
  SUCCESS_TIMEOUT_IN_MS,
  FAILURE_TIMEOUT_IN_MS,
} from "../../../data/constants"
import { openToast } from "../../../api/toasts"
import env from "../../../services/Auth/env"
import {
  FormSelect,
  FormDatePicker,
  FormTextArea,
} from "../../../components/app"
import { getLocationsByCmName } from "../../../util/Util"
import * as auth from "../../../services/Auth/auth"

const AddAuditIssuesModal = props => {
  const [issueType, setIssueType] = useState()
  const [description, setDescription] = useState()
  const [priority, setPriority] = useState()
  const [status, setStatus] = useState("Open")
  const [createdDate, setCreatedDate] = useState(
    new Date().toISOString().slice(0, 10)
  )
  const { username } = useSelector(state => state.userReducer)

  const {
    addAuditModalOpen,
    setAddAuditModalOpen,
    equipment,
    onAddSuccess,
    fileId,
    cm,
    testLocation,
  } = props

  const resetState = () => {
    setDescription("")
    setIssueType()
    setCreatedDate(new Date().toISOString().slice(0, 10))
    setAddAuditModalOpen(false)
  }
  return (
    <Modal
      title="Add Issue(s)"
      open={addAuditModalOpen}
      onClose={resetState}
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
      scrollContainer="viewport"
    >
      <form>
        <FormTextArea
          value={description}
          setValue={setDescription}
          placeholder="Enter value..."
          label="Issue Description"
        />
        <FormSelect
          value={issueType}
          setValue={setIssueType}
          label={"Issue Type"}
          options={auditIssueTypeOptions}
        />
        <FormSelect
          value={priority}
          setValue={setPriority}
          label={"Priority"}
          options={auditPriorityOptions}
        />
        <FormSelect
          value={status}
          setValue={setStatus}
          label={"Status"}
          options={auditStatusOptions}
        />
        <FormDatePicker
          value={createdDate}
          setValue={setCreatedDate}
          label="Created Date"
        />
      </form>
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit" height={100}>
          <Button
            minWidth={100}
            type="primary"
            size="small"
            disabled={
              !description ||
              !status ||
              !issueType ||
              !Number.isInteger(priority)
            }
            onClick={() => {
              let addData = {
                equipmentsWithIssue: [{ equipmentId: equipment.equipmentId }],
                cm: cm,
                testLocation: testLocation,
                description: description,
                priority: priority,
                category: issueType,
                fileIds: fileId ? [fileId] : null,
                // status: status,
                isClosed: status === "Open" ? false : true,
                createdDate: createdDate,
                createdBy: username,
              }

              auth.getToken().then(cookie => {
                axios(env.API_ENDPOINT + env.APIPath.addAuditIssues, {
                  method: "POST",
                  data: addData,
                  headers: { idToken: cookie },
                })
                  .then(response => {
                    if (response?.isSuccess === false) {
                      openToast({
                        type: "error",
                        title: "Add failed",
                        message: "Failed to add the issue, please retry",
                        timeout: FAILURE_TIMEOUT_IN_MS,
                      })
                    } else {
                      openToast({
                        type: "success",
                        title: "Added!",
                        message: "Added an issue successfully",
                        timeout: SUCCESS_TIMEOUT_IN_MS,
                      })
                      onAddSuccess()
                      resetState()
                    }
                  })
                  .catch(error => {
                    console.warn("error", error)
                    openToast({
                      type: "error",
                      title: "Add failed",
                      message: "Failed to add the issue, please retry",
                      timeout: FAILURE_TIMEOUT_IN_MS,
                    })
                  })
              })
            }}
          >
            Add &#62;
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default AddAuditIssuesModal
