/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"

import chevronDoubleLeftTokens from "@amzn/meridian-tokens/base/icon/chevron-double-left"
import chevronDoubleRightTokens from "@amzn/meridian-tokens/base/icon/chevron-double-right"
import chevronLeftSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-left-small"
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small"
import minusKnockoutTokens from "@amzn/meridian-tokens/base/icon/minus-knockout"
import plusKnockoutTokens from "@amzn/meridian-tokens/base/icon/plus-knockout"
import downloadSmallTokens from "@amzn/meridian-tokens/base/icon/download-small"
import undoTokens from "@amzn/meridian-tokens/base/icon/undo"
import redoTokens from "@amzn/meridian-tokens/base/icon/redo"


import Button from "@amzn/meridian/button"
import Icon from "@amzn/meridian/icon"

const ControlPanel = props => {
  const { pageNumber, numPages, setPageNumber, scale, setScale, presignedUrl, rotation, setRotation } = props

  const isFirstPage = pageNumber === 1
  const isLastPage = pageNumber === numPages

  // const firstPageClass = isFirstPage ? "disabled" : "clickable"
  // const lastPageClass = isLastPage ? "disabled" : "clickable"

  const goToFirstPage = () => {
    if (!isFirstPage) setPageNumber(1)
  }
  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1)
  }
  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1)
  }
  const goToLastPage = () => {
    if (!isLastPage) setPageNumber(numPages)
  }

  const onPageChange = e => {
    const { value } = e.target
    setPageNumber(Number(value))
  }

  const isMinZoom = scale < 0.6
  const isMaxZoom = scale >= 2.0

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1)
  }

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1)
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div>
        <Button type="icon" disabled={isFirstPage} onClick={goToFirstPage}>
          <Icon tokens={chevronDoubleLeftTokens}>Close</Icon>
        </Button>
        <Button type="icon" disabled={isFirstPage} onClick={goToPreviousPage}>
          <Icon tokens={chevronLeftSmallTokens}>Close</Icon>
        </Button>

        <span>
          Page{" "}
          <input
            name="pageNumber"
            type="number"
            min={1}
            max={numPages || 1}
            className="p-0 pl-1 mx-2"
            value={pageNumber}
            onChange={onPageChange}
          />{" "}
          of {numPages}
        </span>
        <Button disabled={isLastPage} type="icon" onClick={goToNextPage}>
          <Icon tokens={chevronRightSmallTokens}>Close</Icon>
        </Button>
        <Button disabled={isLastPage} type="icon" onClick={goToLastPage}>
          <Icon tokens={chevronDoubleRightTokens}>Close</Icon>
        </Button>
      </div>
      <div>
        <Button type="icon" onClick={zoomOut} disabled={isMinZoom}>
          <Icon tokens={minusKnockoutTokens}>Close</Icon>
        </Button>
        <span>{(scale * 100).toFixed()}%</span>
        <Button type="icon" onClick={zoomIn} disabled={isMaxZoom}>
          <Icon tokens={plusKnockoutTokens}>Close</Icon>
        </Button>
      </div>
      <div>
        <Button type="icon" href={presignedUrl} download={true} rel="noopener noreferrer" target="_blank">
          <Icon tokens={downloadSmallTokens}>Close</Icon>
        </Button>
        <Button type="icon" onClick={() => {
          setRotation(rotation + 90)
        }}>
          <Icon tokens={redoTokens}>Rotate left</Icon>
        </Button>
        <Button type="icon" onClick={() => {
          setRotation(rotation - 90)
        }}>
          <Icon tokens={undoTokens}>Rotate right</Icon>
        </Button>
      </div>
    </div>
  )
}

export default ControlPanel
