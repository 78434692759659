import React, { useState, useEffect } from "react"
// import {useHistory} from "react-router-dom";
import Row from "@amzn/meridian/row"
// import Checkbox from "@amzn/meridian/checkbox";
import Button from "@amzn/meridian/button"
import axios from "axios"
import qs from "qs"
// import Column from "@amzn/meridian/column"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import { useDispatch, useSelector } from "react-redux"
import {
  FormSelect,
  FormInput,
  FormTextArea,
  FormDatePicker,
} from "../../../components/app"

import { addConsumable } from "../../../redux/equipmentDetail/action"

import * as auth from "../../../services/Auth/auth"
import env from "../../../services/Auth/env"
import { openToast } from "../../../api/toasts"

const AddConsumableModal = props => {
  const {
    addConsumableModalOpen,
    setAddConsumableModalOpen,
    cm,
    testLocation,
    equipmentId,
  } = props

  const [consumableId, setConsumableId] = useState()
  const [number, setNumber] = useState()
  const [date, setDate] = useState()
  const [comments, setComments] = useState()
  const [nameList, setNameList] = useState([])

  const cms = useSelector(state => state.commonReducer.cms)

  useEffect(() => {
    const query = qs.stringify({
      cm: cm,
      // TODO: change field name to test_location
      cm_location: testLocation,
    })
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrieveConsumableByLocation +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(response => {
          console.warn("response", response.data)
          const data = response.data.ConsumableByLocationList
          setNameList(data)
        })
        .catch(error => {})
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetState = () => {
    setConsumableId()
    setNumber()
    setDate()
    setComments()
    setAddConsumableModalOpen(false)
  }

  const reduxDispatch = useDispatch()
  return (
    <Modal
      title="Add Users"
      open={addConsumableModalOpen}
      onClose={() => resetState()}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
    >
      <form>
        <FormSelect
          value={consumableId}
          setValue={setConsumableId}
          label={"Item Name"}
          options={nameList.map(x => ({
            value: x.id,
            label: x.name,
          }))}
          // options={userTypeOptions}
        />
        <FormInput
          value={number}
          setValue={value => setNumber(Number(value))}
          label={"Usage Number"}
        />
        <FormDatePicker value={date} setValue={setDate} label="Date" />
        <FormTextArea
          value={comments}
          setValue={setComments}
          label={"Comments"}
        />
      </form>
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            disabled={!number || !date}
            type="primary"
            size="small"
            onClick={() => {
              reduxDispatch(
                addConsumable({
                  equipmentId: equipmentId,
                  consumableId: consumableId,
                  action: "Use in Equipment",
                  comment: comments,
                  useNum: Number(number),
                  actionDate: date,
                })
              )
              resetState()
            }}
          >
            Add
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default AddConsumableModal
