import React from "react"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Box from "@amzn/meridian/box"
import Text from "@amzn/meridian/text"
import Divider from "@amzn/meridian/divider"
import Pie from "@amzn/meridian/pie"
import Legend, { LegendProvider } from "@amzn/meridian/legend"

const PerformancePieChart = props => {
  const { data, title } = props

  return (
    <Box spacingInset="300" type="outline" height={350} width={500}>
      <Text type="h200">{title}</Text>
      <Divider />
      <LegendProvider data={data}>
        <Row
          spacing="500"
          alignmentHorizontal="center"
          alignmentVertical="center"
          height={300}
        >
          <Column>
            <Pie width={150} />
          </Column>
          <Column>
            <Legend
              direction="vertical"
              values={item => item.value.toLocaleString()}
            />
          </Column>
        </Row>
      </LegendProvider>
    </Box>
  )
}

export default PerformancePieChart
