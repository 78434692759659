import { put, takeLatest } from "redux-saga/effects"
import APIPromises from "../../../util/APIPromises"
import {
  retrieveCapabilityTemplateListSuccess,
  retrieveCapabilityTemplateListFailure,
  addCapabilityTemplateSuccess,
  addCapabilityTemplateFailure,
  deleteCapabilityTemplateSuccess,
  deleteCapabilityTemplateFailure,
} from "../action"

function* retrieveCapabilityTemplateList(req) {
  try {
    // eslint-disable-next-line no-unused-vars
    const res = yield APIPromises.retrieveCapabilityTemplateList(req.payload)
    const list = res.data.CapabilityTemplates || []
    yield put(retrieveCapabilityTemplateListSuccess(res.data.equipTemplateList))

    // if (res.data && res.data.isSuccess === false) {
    //   yield put(retrieveCapabilityTemplateListFailure())
    // } else {
    //   yield put(retrieveCapabilityTemplateListSuccess(list))
    // }
  } catch (error) {
    yield put(retrieveCapabilityTemplateListFailure(error))
  }
}

export function* retrieveCapabilityTemplateListWatcher() {
  yield takeLatest(
    "RETRIEVE_CAPABILITY_TEMPLATE_LIST",
    retrieveCapabilityTemplateList
  )
}

function* addCapabilityTemplate(req) {
  try {
    let res = yield APIPromises.addCapabilityTemplate(req.payload)

    if (res.data && res.data.isSuccess === false) {
      yield put(addCapabilityTemplateFailure(res.data.errorMessage))
    } else {
      yield put({
        type: "RETRIEVE_CAPABILITY_TEMPLATE_LIST",
        payload: {
          equipmentId: req.payload.equipmentId,
        },
      })
      yield put(addCapabilityTemplateSuccess(req.payload))
    }
  } catch (error) {
    yield put(addCapabilityTemplateFailure(error))
  }
}

export function* addCapabilityTemplateWatcher() {
  yield takeLatest("ADD_CAPABILITY_TEMPLATE", addCapabilityTemplate)
}

function* deleteCapabilityTemplate(req) {
  try {
    let res = yield APIPromises.deleteCapabilityTemplate(req.payload)

    if (res.data && res.data.isSuccess === false) {
      yield put(deleteCapabilityTemplateFailure())
    } else {
      yield put({
        type: "RETRIEVE_CAPABILITY_TEMPLATELIST",
        payload: {
          equipmentId: req.payload.equipmentId,
        },
      })
      yield put(deleteCapabilityTemplateSuccess(req.payload))
    }
  } catch (error) {
    yield put(deleteCapabilityTemplateFailure(error))
  }
}

export function* deleteCapabilityTemplateWatcher() {
  yield takeLatest("DELETE_CAPABILITY_TEMPLATE", deleteCapabilityTemplate)
}
