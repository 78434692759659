const INITIAL_STATE = {
  cms: [],
  cmList: [],
}
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "RETRIEVE_CMS":
      return {
        ...state,
        isLoading: true,
      }
    case "RETRIEVE_CMS_SUCCESS":
      let cmList = Array.isArray(action.payload)
        ? action.payload.map(c => c.cm)
        : []
      return {
        ...state,
        cms: action.payload,
        cmList: cmList,
        isLoading: false,
      }
    default:
      return state
  }
}

export default reducer
