/**
 * The top level application file. See `./index.js` for how this is actually
 * rendered to the DOM.
 */

import React, { useEffect } from "react"
import AppLayout from "@amzn/meridian/app-layout"
import Theme from "@amzn/meridian/theme"
import blueDarkTokens from "@amzn/meridian-tokens/theme/blue-dark"
import blueLightTokens from "@amzn/meridian-tokens/theme/blue-light"
import { connect } from "react-redux"

import Masthead from "./components/app/masthead"
import SideMenu from "./components/app/side-menu"
import Notifications from "./components/app/notifications";
import Footer from "./components/app/footer"
import Toasts from "./components/app/toasts"
import ResetScroll from "./components/app/reset-scroll"
import Router from "./routing/router"
import CurrentScreen from "./routing/current-screen"
import { SideMenuOpenContextProvider } from "./hooks/use-side-menu-open"
import { InspectModeContextProvider } from "./hooks/use-inspect-mode"
import useTheme from "./hooks/use-theme"
import { fetchUserInfo } from "./redux/userInfo/action"

// Metrics
// import { publishCounter } from "./metrics"
// import { camelCase } from "./utils"

// Helper function for metrics tracking.
// Lets us know if a clicked element
// is a button or a link. If so, we want
// to track it!
// const isTrackedElement = el => el.nodeName === "BUTTON" || el.nodeName === "A"

// Our current host. Used for metrics
// tracking as described below.
// const host = window.location.host

const App = props => {
  const { fetchUserInfo, userInfo } = props
  useEffect(() => {
    if (!userInfo) {
      fetchUserInfo()
    }
  }, [fetchUserInfo, userInfo])

  const [themeCode] = useTheme()
  return (
    <Theme tokens={themeCode === "white" ? blueLightTokens : blueDarkTokens}>
      <SideMenuOpenContextProvider>
        <Router>
          <ResetScroll />
          <InspectModeContextProvider>
            <Toasts />
            <Notifications />
            <AppLayout
              backgroundColor="alternatePrimary"
              headerComponent={Masthead}
              sidebarComponent={SideMenu}
              footerComponent={Footer}
              alwaysShowScrollbar={true}
              spacingInset={"small"}
            >
              <Masthead />
              <SideMenu />
              <CurrentScreen />
              <Footer />
            </AppLayout>
          </InspectModeContextProvider>
        </Router>
      </SideMenuOpenContextProvider>
    </Theme>
  )
}

const mapStateToProps = state => {
  return {
    userInfo: state.userReducer.userInfo,
  }
}
const mapDispatchToProps = {
  fetchUserInfo: fetchUserInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
