import axios from "axios"
import qs from "qs"
import * as auth from "../services/Auth/auth"
import env from "../services/Auth/env"

export default class callBackEndAPI {
  constructor() {
    this.publicPath = "/"
  }

  errorCallback = error => {
    // console.error(error.toString())
    if (
      error === "Error: Request failed with status code 401" ||
      error.toString() === "Error: Network Error"
    ) {
      auth.removeToken()
      auth.getToken().then(cookie => {
        return true
      })
    }
  }

  testApiPromise = async (resolve, reject) => {
    auth.getToken().then(cookie => {
      fetch(env.API_ENDPOINT + env.APIPath.testApi, {
        method: "POST",
        headers: {
          token: cookie,
        },
      })
        .then(function(response) {
          resolve(response.json())
        })
        .catch(function(error) {
          reject(error)
        })
    })
  }

  retrieveCmsPromise = async (resolve, reject, userName, cm_location_id) => {
    let query = qs.stringify({
      userName,
      cm_location_id,
    })
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.retrieveCms + "?" + query, {
        method: "GET",
        headers: {
          idToken: cookie,
        },
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveCms error", error)
          reject(error)
        })
    })
  }
  retrieveEquipmentPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)

    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.retrieveEquipment + "?" + query, {
        method: "GET",
        headers: { idToken: cookie },
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveCms error", error)
          reject(error)
        })
    })
  }
  retrieveAllEquipmentPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.retrieveAllEquipment + "?" + query, {
        method: "GET",
        headers: { idToken: cookie },
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveCms error", error)
          reject(error)
        })
    })
  }

  addProjectPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.addProject, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("addProjectPromise error", error)
          reject(error)
        })
    })
  }

  archiveProjectPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.archiveProject, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("archiveProjectPromise error", error)
          reject(error)
        })
    })
  }

  updateProjectPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.updateProject, {
        method: "PUT",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("updateProjectPromise error", error)
          reject(error)
        })
    })
  }

  listFilesPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.listFiles + "?" + query, {
        method: "GET",
        headers: {
          idToken: cookie,
        },
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("addProjectPromise error", error)
          reject(error)
        })
    })
  }

  uploadFilePromise = async (resolve, reject, payload) => {
    console.warn("payload", payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.uploadFile, {
        method: "PUT",
        params: payload.params,
        data: payload.data,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("uploadFilePromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("uploadFilePromise error", error)
          reject(error)
        })
    })
  }

  approveFilePromise = async (resolve, reject, payload) => {
    console.warn("approveFilePromise", payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.approveFile, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("approveFilePromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("approveFilePromise error", error)
          reject(error)
        })
    })
  }

  addEquipmentPromise = async (resolve, reject, payload) => {
    console.warn("payload", payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.addEquipment, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("addEquipmentPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("addEquipmentPromise error", error)
          reject(error)
        })
    })
  }

  copyEquipmentPromise = async (resolve, reject, payload) => {
    console.warn("payload", payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.copyEquipment, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("copyEquipmentPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("copyEquipmentPromise error", error)
          reject(error)
        })
    })
  }

  updateEquipmentPromise = async (resolve, reject, payload) => {
    console.warn("payload", payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.updateEquipment, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("updateEquipmentPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("updateEquipmentPromise error", error)
          reject(error)
        })
    })
  }

  deleteEquipmentPromise = async (resolve, reject, payload) => {
    console.warn("deleteEquipmentPromise", payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.deleteEquipment, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("deleteEquipmentPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("deleteEquipmentPromise error", error)
          reject(error)
        })
    })
  }

  retrieveCalibratedEquipsPromise = async (
    resolve,
    reject,
    cmName,
    cmLocation
  ) => {
    let query = qs.stringify({
      cm: cmName,
      cmLocation,
    })
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT + env.APIPath.retrieveCalibratedEquips + "?" + query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveCalibratedEquips error", error)
          reject(error)
        })
    })
  }
  retrieveProjectPromise = async (resolve, reject, cmName) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.retrieveProject + "?cm=" + cmName, {
        method: "GET",
        headers: { idToken: cookie },
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveCalibratedEquips error", error)
          reject(error)
        })
    })
  }

  updateEffectiveDatePromise = async (resolve, reject, payload) => {
    console.warn("updateEffectiveDatePromise", payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.updateEffectiveDate, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("updateEffectiveDatePromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("updateEffectiveDatePromise error", error)
          reject(error)
        })
    })
  }

  deleteFilePromise = async (resolve, reject, payload) => {
    console.warn("deleteFilePromise", payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.deleteFile, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveCalibratedEquips error", error)
          reject(error)
        })
    })
  }

  updateBasicInfoPromise = async (resolve, reject, payload) => {
    console.warn("updateBasicInfoPromise", payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.updateBasicInfo, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("updateBasicInfoPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("updateBasicInfoPromise error", error)
          reject(error)
        })
    })
  }

  downloadFilePromise = async (resolve, reject, payload) => {
    console.warn("downloadFilePromise", payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.downloadFile, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("downloadFilePromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("downloadFilePromise error", error)
          reject(error)
        })
    })
  }

  retrieveOperatorListPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.retrieveOperatorList + "?" + query, {
        method: "GET",
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("retrieveOperatorListPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveOperatorListPromise error", error)
          reject(error)
        })
    })
  }

  retrieveConsumablePromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrieveConsumableByEquipment +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(function(response) {
          console.warn("retrieveConsumableByEquipment response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveConsumableByEquipment error", error)
          reject(error)
        })
    })
  }

  addConsumablePromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.addConsumableUsageByEquipment, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("addConsumableByEquipment response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("addConsumableByEquipment error", error)
          reject(error)
        })
    })
  }

  deleteConsumablePromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.deleteConsumable, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("deleteConsumableUsage response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("deleteConsumableUsage error", error)
          reject(error)
        })
    })
  }

  deleteOperatorPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.deleteOperator, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("deleteOperatorPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("deleteOperatorPromise error", error)
          reject(error)
        })
    })
  }

  addOperatorPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.addOperator, {
        method: "POST",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          console.warn("addOperatorPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("addOperatorPromise error", error)
          reject(error)
        })
    })
  }

  retrieveFilesByFileTypePromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT + env.APIPath.retrieveFilesByFileType + "?" + query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(function(response) {
          console.warn("downloadFilePromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("downloadFilePromise error", error)
          reject(error)
        })
    })
  }

  retrieveEquipmentAuditReportsPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT + env.APIPath.retrieveIssuesByEquipment + "?" + query,
        {
          headers: { idToken: cookie },
          method: "GET",
        }
      )
        .then(function(response) {
          console.warn(
            "retrieveEquipmentAuditReportsPromise response",
            response
          )
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveEquipmentAuditReportsPromise error", error)
          reject(error)
        })
    })
  }

  retrieveEquipmentFilesPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT + env.APIPath.retrieveEquipmentFiles + "?" + query,
        {
          headers: { idToken: cookie },
          method: "GET",
        }
      )
        .then(function(response) {
          console.warn("retrieveEquipmentFilesPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveEquipmentFilesPromise error", error)
          reject(error)
        })
    })
  }

  retrieveEquipmentStatusHistoryPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrieveEquipmentStatusHistory +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(function(response) {
          console.warn(
            "retrieveEquipmentStatusHistoryPromise response",
            response
          )
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveEquipmentStatusHistoryPromise error", error)
          reject(error)
        })
    })
  }

  addAuditReportPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.addAuditReport, {
        method: "POST",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("addAuditReport error", error)
          reject(error)
        })
    })
  }

  updateAuditReportPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.updateAuditReport, {
        method: "POST",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("updateAuditReport error", error)
          reject(error)
        })
    })
  }

  deleteAuditReportPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.deleteAuditReport, {
        method: "POST",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("deleteAuditReport error", error)
          reject(error)
        })
    })
  }

  retrieveAuditReportsPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.retrieveAuditReport + "?" + query, {
        method: "GET",
        headers: { idToken: cookie },
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveAuditIssues error", error)
          reject(error)
        })
    })
  }
  retrieveAuditIssuesPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)

    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.retrieveAuditIssues + "?" + query, {
        method: "GET",
        headers: { idToken: cookie },
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveAuditIssues error", error)
          reject(error)
        })
    })
  }

  addAuditIssuesPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.addAuditIssues, {
        method: "POST",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("updateAuditReportIssueList error", error)
          reject(error)
        })
    })
  }

  editAuditIssuesPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.updateAuditIssuesList, {
        method: "POST",
        headers: { idToken: cookie },
        data: { auditIssues: payload },
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("updateAuditReportIssueList error", error)
          reject(error)
        })
    })
  }

  addNextAuditDatePromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.addNextAuditDate, {
        method: "POST",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("updateAuditReportIssueList error", error)
          reject(error)
        })
    })
  }

  copyAuditIssuesPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.copyAuditIssues, {
        method: "POST",
        body: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("copyAuditIssuesPromise error", error)
          reject(error)
        })
    })
  }

  deleteAuditIssuesPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.deleteEquipmentIssues, {
        method: "POST",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("deleteAuditIssuesPromise error", error)
          reject(error)
        })
    })
  }

  // Performance Summary
  retrievePerformanceSummaryByCMHeaderPromise = async (
    resolve,
    reject,
    payload
  ) => {
    let query = qs.stringify(payload, { skipNulls: true })
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrievePerformanceSummaryByCMHeader +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(function(response) {
          console.warn(
            "retrievePerformanceSummaryByCMHeader response",
            response
          )
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrievePerformanceSummaryByCMHeader error", error)
          reject(error)
        })
    })
  }

  retrievePerformanceSummaryByCMEquipmentCategoryPromise = async (
    resolve,
    reject,
    payload
  ) => {
    let query = qs.stringify(payload, { skipNulls: true })
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrievePerformanceSummaryByCMEquipmentCategory +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(function(response) {
          console.warn(
            "retrievePerformanceSummaryByCMEquipmentCategory response",
            response
          )
          resolve(response)
        })
        .catch(function(error) {
          console.warn(
            "retrievePerformanceSummaryByCMEquipmentCategory error",
            error
          )
          reject(error)
        })
    })
  }

  retrievePerformanceSummaryByCMEquipmentCMPromise = async (
    resolve,
    reject,
    payload
  ) => {
    let query = qs.stringify(payload, { skipNulls: true })
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrievePerformanceSummaryByCMEquipmentCM +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(function(response) {
          console.warn(
            "retrievePerformanceSummaryByCMEquipmentCMPromise response",
            response
          )
          resolve(response)
        })
        .catch(function(error) {
          console.warn(
            "retrievePerformanceSummaryByCMEquipmentCMPromise error",
            error
          )
          reject(error)
        })
    })
  }

  retrievePerformanceSummaryByCMMonthPromise = async (
    resolve,
    reject,
    payload
  ) => {
    let query = qs.stringify(payload, { skipNulls: true })
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrievePerformanceSummaryByCMMonth +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(function(response) {
          console.warn(
            "retrievePerformanceSummaryByCMMonthPromise response",
            response
          )
          resolve(response)
        })
        .catch(function(error) {
          console.warn(
            "retrievePerformanceSummaryByCMMonthPromise error",
            error
          )
          reject(error)
        })
    })
  }

  retrievePerformanceSummaryByCMQuarterPromise = async (
    resolve,
    reject,
    payload
  ) => {
    let query = qs.stringify(payload, { skipNulls: true })
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrievePerformanceSummaryByCMQuarter +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(function(response) {
          console.warn(
            "retrievePerformanceSummaryByCMQuarterPromise response",
            response
          )
          resolve(response)
        })
        .catch(function(error) {
          console.warn(
            "retrievePerformanceSummaryByCMQuarterPromise error",
            error
          )
          reject(error)
        })
    })
  }

  retrievePerformanceSummaryByCMAvgDaysPromise = async (
    resolve,
    reject,
    payload
  ) => {
    let query = qs.stringify(payload, { skipNulls: true })
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrievePerformanceSummaryByCMAvgDays +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(function(response) {
          console.warn(
            "retrievePerformanceSummaryByCMAvgDaysPromise response",
            response
          )
          resolve(response)
        })
        .catch(function(error) {
          console.warn(
            "retrievePerformanceSummaryByCMAvgDaysPromise error",
            error
          )
          reject(error)
        })
    })
  }
  // Performance Review CPI
  retrievePerformanceSummaryByCPIHeaderPromise = async (
    resolve,
    reject,
    payload
  ) => {
    let query = qs.stringify(payload, { skipNulls: true })
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrievePerformanceSummaryByCPIHeader +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(function(response) {
          console.warn(
            "retrievePerformanceSummaryByCPIHeaderPromise response",
            response
          )
          resolve(response)
        })
        .catch(function(error) {
          console.warn(
            "retrievePerformanceSummaryByCPIHeaderPromise error",
            error
          )
          reject(error)
        })
    })
  }

  retrievePerformanceSummaryByCPIDataPromise = async (
    resolve,
    reject,
    payload
  ) => {
    let query = qs.stringify(payload, { skipNulls: true })
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrievePerformanceSummaryByCPIData +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(function(response) {
          console.warn(
            "retrievePerformanceSummaryByCPIDataPromise response",
            response
          )
          resolve(response)
        })
        .catch(function(error) {
          console.warn(
            "retrievePerformanceSummaryByCPIDataPromise error",
            error
          )
          reject(error)
        })
    })
  }

  retrievePerformanceSummaryBySystemHeaderPromise = async (
    resolve,
    reject,
    payload
  ) => {
    let query = qs.stringify(payload, { skipNulls: true })
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrievePerformanceSummaryBySystemHeader +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(function(response) {
          console.warn(
            "retrievePerformanceSummaryByCPIHeaderPromise response",
            response
          )
          resolve(response)
        })
        .catch(function(error) {
          console.warn(
            "retrievePerformanceSummaryByCPIHeaderPromise error",
            error
          )
          reject(error)
        })
    })
  }

  retrievePerformanceSummaryBySystemDataPromise = async (
    resolve,
    reject,
    payload
  ) => {
    let query = qs.stringify(payload, { skipNulls: true })
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrievePerformanceSummaryBySystemData +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(function(response) {
          console.warn(
            "retrievePerformanceSummaryBySystemDataPromise response",
            response
          )
          resolve(response)
        })
        .catch(function(error) {
          console.warn(
            "retrievePerformanceSummaryBySystemDataPromise error",
            error
          )
          reject(error)
        })
    })
  }

  retrieveDashboardPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.retrieveDashboard + "?" + query, {
        method: "GET",
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("retrieveDashboard response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveDashboard error", error)
          reject(error)
        })
    })
  }

  retrieveNotificationPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.retrieveNotification + "?" + query, {
        method: "GET",
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("retrieveNotification response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveNotification error", error)
          reject(error)
        })
    })
  }

  updateNotificationPromise = async (resolve, reject, payload) => {
    console.warn("updateNotificationPromise", payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.updateNotification, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("updateNotificationPromise error", error)
          reject(error)
        })
    })
  }

  loginPromise = async (resolve, reject, payload) => {
    console.warn("loginPromise", payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.login, {
        method: "POST",
        // data: payload,
        data: {
          loginHeaders: {
            // environment: "DEV-LOCAL",
            idToken: cookie,
            // redirect_uri: redirect_uri,
          },
        },
        headers: {
          loginHeaders: JSON.stringify({
            // environment: "DEV-LOCAL",
            idToken: cookie,
            // redirect_uri: redirect_uri,
          }),
        },
      })
        .then(function(response) {
          resolve(response)
        })
        .catch(function(error) {
          console.warn("loginPromise error", error)
          reject(error)
        })
    })
  }

  retrieveNotificationListPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT + env.APIPath.retrieveNotificationList + "?" + query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(function(response) {
          console.warn("retrieveNotificationsPromise response", response)
          resolve(response.data)
        })
        .catch(function(error) {
          console.warn("retrieveNotificationsPromise error", error)
          reject(error)
        })
    })
  }

  retrieveUserListPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.retrieveUserList + "?" + query, {
        method: "GET",
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("retrieveUserListPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveUserListPromise error", error)
          reject(error)
        })
    })
  }

  deleteUserPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.deleteUser, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("deleteUserPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("deleteUserPromise error", error)
          reject(error)
        })
    })
  }

  addUserPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.addUser, {
        method: "POST",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          console.warn("addUserPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("addUserPromise error", error)
          reject(error)
        })
    })
  }

  updateUserPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.updateUser, {
        method: "POST",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          console.warn("updateUserPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("updateUserPromise error", error)
          reject(error)
        })
    })
  }

  retrieveLocationListPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.retrieveLocationList + "?" + query, {
        method: "GET",
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("retrieveLocationListPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveLocationListPromise error", error)
          reject(error)
        })
    })
  }

  deleteLocationPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.deleteLocation, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("deleteLocationPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("deleteLocationPromise error", error)
          reject(error)
        })
    })
  }

  addLocationPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.addLocation, {
        method: "POST",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          console.warn("addLocationPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("addLocationPromise error", error)
          reject(error)
        })
    })
  }

  updateLocationPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.updateLocation, {
        method: "POST",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          console.warn("updateLocationPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("updateLocationPromise error", error)
          reject(error)
        })
    })
  }

  retrieveCapabilityTemplateListPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrieveCapabilityTemplateList +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(function(response) {
          console.warn(
            "retrieveCapabilityTemplateListPromise response",
            response
          )
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveCapabilityTemplateListPromise error", error)
          reject(error)
        })
    })
  }

  deleteCapabilityTemplatePromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.deleteCapabilityTemplate, {
        method: "POST",
        data: payload,
        headers: { idToken: cookie },
      })
        .then(function(response) {
          console.warn("deleteCapabilityTemplatePromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("deleteCapabilityTemplatePromise error", error)
          reject(error)
        })
    })
  }

  addCapabilityTemplatePromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.addCapabilityTemplate, {
        method: "POST",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          console.warn("addCapabilityTemplatePromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("addCapabilityTemplatePromise error", error)
          reject(error)
        })
    })
  }

  retrieveCalibrationMonthSummaryPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrieveCalibrationMonthSummary +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(function(response) {
          console.warn(
            "retrieveCalibrationMonthSummaryPromise response",
            response
          )
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveCalibrationMonthSummaryPromise error", error)
          reject(error)
        })
    })
  }

  retrieveCalibrationEquipmentDetailsPromise = async (
    resolve,
    reject,
    payload
  ) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrieveCalibrationMonthEquipmentDetails +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
          data: payload,
        }
      )
        .then(function(response) {
          console.warn(
            "retrieveCalibrationEquipmentDetailsPromise response",
            response
          )
          resolve(response)
        })
        .catch(function(error) {
          console.warn(
            "retrieveCalibrationEquipmentDetailsPromise error",
            error
          )
          reject(error)
        })
    })
  }

  retrieveLabPersonnelPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.retrieveLabPersonnel + "?" + query, {
        method: "GET",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          console.warn("retrieveLabPersonnelPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveLabPersonnelPromise error", error)
          reject(error)
        })
    })
  }

  addLabPersonnelPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.addLabPersonnel, {
        method: "POST",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          console.warn("addLabPersonnelPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("addLabPersonnelPromise error", error)
          reject(error)
        })
    })
  }

  editLabPersonnelPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.editLabPersonnel, {
        method: "POST",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          console.warn("editLabPersonnelPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("editLabPersonnelPromise error", error)
          reject(error)
        })
    })
  }

  deleteLabPersonnelPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.deleteLabPersonnel, {
        method: "POST",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          console.warn("deleteLabPersonnel response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("deleteLabPersonnel error", error)
          reject(error)
        })
    })
  }

  calculateTurnoverRatePromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT + env.APIPath.calculateTurnoverRate + "?" + query,
        {
          method: "GET",
          headers: { idToken: cookie },
          data: payload,
        }
      )
        .then(function(response) {
          console.warn("calculateTurnoverRate response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("calculateTurnoverRate error", error)
          reject(error)
        })
    })
  }

  // consumable
  retrieveConsumableListByLocationPromise = async (
    resolve,
    reject,
    payload
  ) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrieveConsumableByLocation +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
          data: payload,
        }
      )
        .then(function(response) {
          console.warn(
            "retrieveConsumableListByLocationPromise response",
            response
          )
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveConsumableListByLocationPromise error", error)
          reject(error)
        })
    })
  }

  addConsumableListPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.addConsumableByLocation, {
        method: "POST",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          console.warn("addConsumableListPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("addConsumableListPromise error", error)
          reject(error)
        })
    })
  }

  deleteConsumableByIdPromise = async (resolve, reject, payload) => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.deleteConsumableById, {
        method: "POST",
        headers: { idToken: cookie },
        data: payload,
      })
        .then(function(response) {
          console.warn("deleteConsumableByIdPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("deleteConsumableByIdPromise error", error)
          reject(error)
        })
    })
  }

  retrieveConsumableUsageByIdPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrieveConsumableUsageById +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
          data: payload,
        }
      )
        .then(function(response) {
          console.warn("retrieveConsumableUsageByIdPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveConsumableUsageByIdPromise error", error)
          reject(error)
        })
    })
  }
  retrieveConsumableByIdPromise = async (resolve, reject, payload) => {
    let query = qs.stringify(payload)
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT + env.APIPath.retrieveConsumableById + "?" + query,
        {
          method: "GET",
          headers: { idToken: cookie },
          data: payload,
        }
      )
        .then(function(response) {
          console.warn("retrieveConsumableByIdPromise response", response)
          resolve(response)
        })
        .catch(function(error) {
          console.warn("retrieveConsumableByIdPromise error", error)
          reject(error)
        })
    })
  }
}
