/**
 * A context-based hook that allows components to determine whether Firefly's
 * "Inspect mode" is on or off. The API is the same as useContext.
 */

import React, { useState, useContext, useCallback, createContext } from "react"
import useLocalStorage from "./use-local-storage"
import { publishCounter } from "../metrics"

const InspectModeContext = createContext()

const InspectModeContextProvider = ({ children }) => {
  const [savedInspectMode, persistInspectMode] = useLocalStorage("inspect-mode")

  const [inspectMode, setInspectMode] = useState(savedInspectMode || false)

  const changeInspectMode = useCallback(
    value => {
      persistInspectMode(value)
      setInspectMode(value)
      // We want to track every time a user
      // inspect mode on and off.
      publishCounter(
        `${value ? "toggleOn" : "toggleOff"}Inspect`,
        "inspectMode"
      )
    },
    [persistInspectMode]
  )

  return (
    <InspectModeContext.Provider value={[inspectMode, changeInspectMode]}>
      {children}
    </InspectModeContext.Provider>
  )
}

const useInspectMode = () => useContext(InspectModeContext)

export { InspectModeContextProvider }
export default useInspectMode
