import React, { useState } from "react"
// import {useHistory} from "react-router-dom";
import Row from "@amzn/meridian/row"
// import Checkbox from "@amzn/meridian/checkbox";
import Button from "@amzn/meridian/button"
import RadioButton from "@amzn/meridian/radio-button"
import Textarea from "@amzn/meridian/textarea"
import Text from "@amzn/meridian/text"
import { useSelector } from "react-redux"
import Column from "@amzn/meridian/column"
// import { useDispatch } from "react-redux"
import addYears from "date-fns/addYears"
import format from "date-fns/format"

import Modal, { ModalFooter } from "@amzn/meridian/modal"
import DateRangePicker from "@amzn/meridian/date-range-picker"

// import { approveFile } from "../../../redux/fileLibrary/action"
import { formatDate } from "../../../util/Util"

const formatIso = date => format(date, "yyyy-MM-dd")

const ApproveModal = props => {
  // eslint-disable-next-line no-unused-vars
  const { approveModalOpen, setApproveModalOpen } = props
  const [value, setValue] = useState(["", ""])
  const [approve, setApprove] = useState(true)
  const [comments, setComments] = useState("")
  const { username } = useSelector(state => state.userReducer)

  // const reduxDispatch = useDispatch()
  const resetState = () => {
    setValue(["", ""])
    setApprove(true)
    setComments("")
    setApproveModalOpen(false)
  }

  return (
    <Modal
      title="Approve File"
      open={approveModalOpen}
      // onClose={onClose}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
    >
      <form>
        <Row spacingInset="small none">
          <RadioButton
            checked={approve}
            onChange={() => setApprove(true)}
            name="Approve"
            value={true}
          >
            Approve
          </RadioButton>
          <RadioButton
            checked={!approve}
            onChange={() => setApprove(false)}
            name="Unapprove"
            value={false}
          >
            Unapprove
          </RadioButton>
        </Row>
        <Row spacingInset="small none">
          {approve ? (
            <DateRangePicker
              value={value}
              onChange={x => {
                console.warn(x)
                if (x[0] && x[0] !== value[0]) {
                  let now = new Date(x[0])
                  setValue([x[0], formatIso(addYears(now, 1))])
                } else {
                  setValue([x[0], x[1]])
                }
              }}
              startLabel="Start date"
              endLabel="End date"
              monthsInView={2}
            />
          ) : (
            <Column spacing="200">
              <Text tag="label" type="b200" color="primary" htmlFor="my-input">
                Comments
              </Text>
              <Textarea
                width={460}
                value={comments}
                onChange={setComments}
                placeholder="Enter your comments here ..."
              />
            </Column>
          )}
        </Row>
      </form>
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            type="primary"
            size="small"
            disabled={
              approve && (value[0]?.length === 0 || value[1]?.length === 0)
            }
            onClick={() => {
              let data = {
                status: approve ? "approve" : "unapprove",
                approvedBy: username,
                approvalDate: formatDate(new Date(), "yyyy/MM/dd"),
              }
              if (approve) {
                data.effectiveStartDate = value[0]
                data.effectiveEndDate = value[1]
              } else {
                data.comments = comments
              }
              props.onApproveFile(data)
              setApproveModalOpen(false)
            }}
          >
            Confirm
          </Button>
          <Button type="secondary" size="small" onClick={() => resetState()}>
            Cancel
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export { ApproveModal }
