import React from "react"
import PropTypes from "prop-types"
import { css } from "emotion"

const styles = css({
  display: "block",
  // The diamond has to poke out of the viewbox ever so slightly to appear of
  // similar size to the other symbols. The human mind is an amazing thing.
  overflow: "visible",
})

/**
 * Renders the various symbols (e.g. circle, square, etc.) that can be used to
 * uniquely identify different items in a legend.
 */
const LegendSymbol = ({ type, color, size }) => {
  // If the "type" is a function (custom render), call the function with the color
  if (typeof type === "function") {
    return type({ color })
  }
  // Else generate a predetermined shape
  let shape
  if (type === "square") {
    shape = <path d="M -0.886 -0.886 H 0.886 V 0.886 H -0.886 Z" fill={color} />
  } else if (type === "diamond") {
    shape = (
      <path
        d="M -0.82 -0.82 H 0.82 V 0.82 H -0.82 Z"
        fill={color}
        transform="rotate(45)"
      />
    )
  } else if (type === "triangleUp" || type === "triangleDown") {
    shape = (
      <path
        d="M 1 0.9 L -1 0.9 L 0 -0.9 Z"
        fill={color}
        transform={`rotate(${type === "triangleDown" ? 180 : 0})`}
      />
    )
  }
  // Default to the circle... that's what Victory does too
  else {
    shape = <circle cx="0" cy="0" r="1" fill={color} />
  }
  return (
    <svg
      viewBox="-1 -1 2 2"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      role="presentation"
      className={styles}
    >
      {shape}
    </svg>
  )
}

LegendSymbol.displayName = "LegendSymbol"

LegendSymbol.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number,
  type: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.oneOf([
      "circle",
      "diamond",
      "square",
      "triangleDown",
      "triangleUp",
    ]),
  ]),
}

LegendSymbol.defaultProps = {
  size: 14,
  type: "circle",
}

export default LegendSymbol
