import { put, takeLatest } from "redux-saga/effects"
import APIPromises from "../../../util/APIPromises"
import {
  retrievePerformanceSummaryByCMHeaderSuccess,
  retrievePerformanceSummaryByCMHeaderFailure,
  retrievePerformanceSummaryByCMEquipmentCategorySuccess,
  retrievePerformanceSummaryByCMEquipmentCategoryFailure,
  retrievePerformanceSummaryByCMEquipmentCMSuccess,
  retrievePerformanceSummaryByCMEquipmentCMFailure,
  retrievePerformanceSummaryByCMMonthSuccess,
  retrievePerformanceSummaryByCMMonthFailure,
  retrievePerformanceSummaryByCMQuarterSuccess,
  retrievePerformanceSummaryByCMQuarterFailure,
  retrievePerformanceSummaryByCMAvgDaysSuccess,
  retrievePerformanceSummaryByCMAvgDaysFailure,
  retrievePerformanceSummaryByCPIHeaderSuccess,
  retrievePerformanceSummaryByCPIHeaderFailure,
  retrievePerformanceSummaryByCPIDataFailure,
  retrievePerformanceSummaryByCPIDataSuccess,
  retrievePerformanceSummaryBySystemHeaderSuccess,
  retrievePerformanceSummaryBySystemHeaderFailure,
  retrievePerformanceSummaryBySystemDataFailure,
  retrievePerformanceSummaryBySystemDataSuccess,
} from "../action"

function* retrievePerformanceSummaryByCMHeader(req) {
  try {
    const res = yield APIPromises.retrievePerformanceSummaryByCMHeader(
      req.payload
    )
    yield put(retrievePerformanceSummaryByCMHeaderSuccess(res.data || []))
  } catch (error) {
    yield put(retrievePerformanceSummaryByCMHeaderFailure(error))
  }
}

export function* retrievePerformanceSummaryByCMHeaderWatcher() {
  yield takeLatest(
    "RETRIEVE_PERFORMANCE_BY_CM_HEADER",
    retrievePerformanceSummaryByCMHeader
  )
}

function* retrievePerformanceSummaryByCMEquipmentCategory(req) {
  try {
    const res = yield APIPromises.retrievePerformanceSummaryByCMEquipmentCategory(
      req.payload
    )
    yield put(
      retrievePerformanceSummaryByCMEquipmentCategorySuccess(res.data || [])
    )
  } catch (error) {
    yield put(retrievePerformanceSummaryByCMEquipmentCategoryFailure(error))
  }
}

export function* retrievePerformanceSummaryByCMEquipmentCategoryWatcher() {
  yield takeLatest(
    "RETRIEVE_PERFORMANCE_BY_CM_EQUIPMENT_CATEGORY",
    retrievePerformanceSummaryByCMEquipmentCategory
  )
}

function* retrievePerformanceSummaryByCMEquipmentCM(req) {
  try {
    const res = yield APIPromises.retrievePerformanceSummaryByCMEquipmentCM(
      req.payload
    )
    yield put(retrievePerformanceSummaryByCMEquipmentCMSuccess(res.data || []))
  } catch (error) {
    yield put(retrievePerformanceSummaryByCMEquipmentCMFailure(error))
  }
}

export function* retrievePerformanceSummaryByCMEquipmentCMWatcher() {
  yield takeLatest(
    "RETRIEVE_PERFORMANCE_BY_CM_EQUIPMENT_CM",
    retrievePerformanceSummaryByCMEquipmentCM
  )
}

function* retrievePerformanceSummaryByCMMonth(req) {
  try {
    const res = yield APIPromises.retrievePerformanceSummaryByCMMonth(
      req.payload
    )
    yield put(retrievePerformanceSummaryByCMMonthSuccess(res.data || []))
  } catch (error) {
    yield put(retrievePerformanceSummaryByCMMonthFailure(error))
  }
}

export function* retrievePerformanceSummaryByCMMonthWatcher() {
  yield takeLatest(
    "RETRIEVE_PERFORMANCE_BY_CM_MONTH",
    retrievePerformanceSummaryByCMMonth
  )
}

function* retrievePerformanceSummaryByCMQuarter(req) {
  try {
    const res = yield APIPromises.retrievePerformanceSummaryByCMQuarter(
      req.payload
    )
    yield put(retrievePerformanceSummaryByCMQuarterSuccess(res.data || []))
  } catch (error) {
    yield put(retrievePerformanceSummaryByCMQuarterFailure(error))
  }
}

export function* retrievePerformanceSummaryByCMQuarterWatcher() {
  yield takeLatest(
    "RETRIEVE_PERFORMANCE_BY_CM_MONTH",
    retrievePerformanceSummaryByCMQuarter
  )
}

function* retrievePerformanceSummaryByCMAvgDays(req) {
  try {
    const res = yield APIPromises.retrievePerformanceSummaryByCMAvgDays(
      req.payload
    )
    yield put(retrievePerformanceSummaryByCMAvgDaysSuccess(res.data || []))
  } catch (error) {
    yield put(retrievePerformanceSummaryByCMAvgDaysFailure(error))
  }
}

export function* retrievePerformanceSummaryByCMAvgDaysWatcher() {
  yield takeLatest(
    "RETRIEVE_PERFORMANCE_BY_CM_AVG_DAYS",
    retrievePerformanceSummaryByCMAvgDays
  )
}

function* retrievePerformanceSummaryByCPIHeader(req) {
  try {
    const res = yield APIPromises.retrievePerformanceSummaryByCPIHeader(
      req.payload
    )
    yield put(retrievePerformanceSummaryByCPIHeaderSuccess(res.data || []))
  } catch (error) {
    yield put(retrievePerformanceSummaryByCPIHeaderFailure(error))
  }
}

export function* retrievePerformanceSummaryByCPIHeaderWatcher() {
  yield takeLatest(
    "RETRIEVE_PERFORMANCE_BY_CPI_HEADER",
    retrievePerformanceSummaryByCPIHeader
  )
}

function* retrievePerformanceSummaryByCPIData(req) {
  try {
    const res = yield APIPromises.retrievePerformanceSummaryByCPIData(
      req.payload
    )
    yield put(retrievePerformanceSummaryByCPIDataSuccess(res.data || []))
  } catch (error) {
    yield put(retrievePerformanceSummaryByCPIDataFailure(error))
  }
}

export function* retrievePerformanceSummaryByCPIDataWatcher() {
  yield takeLatest(
    "RETRIEVE_PERFORMANCE_BY_CPI_HEADER",
    retrievePerformanceSummaryByCPIData
  )
}

function* retrievePerformanceSummaryBySystemHeader(req) {
  try {
    const res = yield APIPromises.retrievePerformanceSummaryBySystemHeader(
      req.payload
    )
    yield put(retrievePerformanceSummaryBySystemHeaderSuccess(res.data || []))
  } catch (error) {
    yield put(retrievePerformanceSummaryBySystemHeaderFailure(error))
  }
}

export function* retrievePerformanceSummaryBySystemHeaderWatcher() {
  yield takeLatest(
    "RETRIEVE_PERFORMANCE_BY_SYSTEM_HEADER",
    retrievePerformanceSummaryBySystemHeader
  )
}

function* retrievePerformanceSummaryBySystemData(req) {
  try {
    const res = yield APIPromises.retrievePerformanceSummaryBySystemData(
      req.payload
    )
    yield put(retrievePerformanceSummaryBySystemDataSuccess(res.data || []))
  } catch (error) {
    yield put(retrievePerformanceSummaryBySystemDataFailure(error))
  }
}

export function* retrievePerformanceSummaryBySystemDataWatcher() {
  yield takeLatest(
    "RETRIEVE_PERFORMANCE_BY_SYSTEM_DATA",
    retrievePerformanceSummaryBySystemData
  )
}
