import React, { useDebugValue, useEffect, useState } from "react"
import Row from "@amzn/meridian/row"
import Column from "@amzn/meridian/column"
import Select, { SelectOption } from "@amzn/meridian/select"
import Table, {
  TableActionBar,
  TableCell,
  TableRow,
} from "@amzn/meridian/table"
import SearchField from "@amzn/meridian/search-field"
import Tooltip from "@amzn/meridian/tooltip"
import Button from "@amzn/meridian/button"
import Icon from "@amzn/meridian/icon"
import { useDispatch, useSelector } from "react-redux"
import uploadLargeTokens from "@amzn/meridian-tokens/base/icon/upload-large"
import deleteTokens from "@amzn/meridian-tokens/base/icon/delete"
import plusCircleTokens from "@amzn/meridian-tokens/base/icon/plus-circle"
import Text from "@amzn/meridian/text"
import Link from "@amzn/meridian/link"
import { useHistory } from "react-router-dom"
import PageLayout from "../../../components/app/page-layout"
import { getRegex } from "../../../util/Util"
import { usePagination, useSort } from "../../../hooks"
import {
  addConsumableList,
  deleteConsumableList,
  retrieveConsumableList,
  selectCM,
} from "../../../redux/consumable/action"
import AddConsumableModal from "../modals/add-consumable-modal"
import { retrieveAllEquipment } from "../../../redux/equipment/action"
import { DeleteConfirmModal } from "../../../components/app"

const ConsumableList = () => {
  const [keyword, setKeyword] = useState("")
  const [addConsumableModalOpen, setAddConsumableModalOpen] = useState(false)
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState("")
  const [currentData, setCurrentData] = useState()

  const reduxDispatch = useDispatch()
  const { isLoading, cmIndex, consumableList } = useSelector(
    state => state.consumableReducer
  )
  const history = useHistory()
  const { locationList } = useSelector(state => state.locationReducer)
  const { isCMUser, isAdmin } = useSelector(state => state.userReducer)

  const data = consumableList.filter(f => {
    let regex = getRegex(keyword)
    return regex.test(JSON.stringify(f))
  })
  const { sortedData, onSort, sortColumn, sortDirection } = useSort(data)
  const { TableFooter, visibleIndices } = usePagination(data.length)

  useEffect(() => {
    if (locationList[cmIndex]) {
      reduxDispatch(
        retrieveConsumableList({
          cm: locationList[cmIndex].cm,
          cm_location: locationList[cmIndex].cmLocation,
        })
      )
      reduxDispatch(
        retrieveAllEquipment({
          cm: locationList[cmIndex].cm,
          test_location: locationList[cmIndex].cmLocation,
        })
      )
    }
  }, [reduxDispatch, cmIndex, locationList])

  return (
    <React.Fragment>
      <PageLayout
        spacing="large"
        alignmentHorizontal={"start"}
        title="Consumable"
        loading={isLoading}
        width={"100%"}
      >
        <Row spacingInset="small none">
          <Column spacing="large">
            <Select
              label="CM Location"
              value={cmIndex}
              onChange={c => reduxDispatch(selectCM(c))}
              minWidth={240}
              size={"small"}
            >
              {locationList.map((x, index) => (
                <SelectOption
                  key={index}
                  value={index}
                  label={`${x.cm}-${x.cmLocation}`}
                />
              ))}
            </Select>
          </Column>
        </Row>
        <Row>
          <Table
            headerRows={1}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={onSort}
            showDividers={true}
            spacing="small"
            // stickyHeaderRow={true}
            fixHeaderRows={true}
            // showStripes={true}
          >
            <TableActionBar widths={[300]}>
              <SearchField
                value={keyword}
                onChange={setKeyword}
                placeholder="Search for..."
                // size="medium"
                type="text"
                onSubmit={() => {}}
              />
              <Tooltip position="top" title="Batch Import" id="myTooltip">
                <Button
                  type="icon"
                  // onClick={() => setBatchImportEquipmentModalOpen(true)}
                >
                  <Icon tokens={uploadLargeTokens} />
                </Button>
              </Tooltip>
              <Tooltip position="top" title="Add Item" id="myTooltip">
                <Button
                  type="icon"
                  disabled={!isCMUser && !isAdmin}
                  onClick={() => {
                    setAddConsumableModalOpen(true)
                  }}
                >
                  <Icon tokens={plusCircleTokens} />
                </Button>
              </Tooltip>
            </TableActionBar>
            <TableRow>
              <TableCell sortColumn="id">Item No.</TableCell>
              <TableCell sortColumn="name">Name</TableCell>
              <TableCell sortColumn="description">Description</TableCell>
              <TableCell sortColumn="purchase_num">Purchase Number</TableCell>
              <TableCell sortColumn="puchase_date">Purchase Date</TableCell>
              <TableCell sortColumn="expire_date">Expire Date</TableCell>
              <TableCell sortColumn="in_stock number">In Stock</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
            {sortedData &&
              sortedData
                .slice(visibleIndices[0], visibleIndices[1])
                .map((row, index) => (
                  <TableRow key={index + 1}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <Link
                        onClick={() => {
                          history.push(`/lab-management/consumable-details`, {
                            consumableId: row.id,
                          })
                        }}
                      >
                        {row.name}
                      </Link>
                    </TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>
                      {row.purchaseNum} {row.unit}
                    </TableCell>
                    <TableCell>{row.purchaseDate}</TableCell>
                    <TableCell>{row.expireDate}</TableCell>
                    <TableCell>
                      {row.inStockNum} {row.unit}
                    </TableCell>
                    <TableCell>
                      <Text>
                        <Link
                          disabled={!isCMUser && !isAdmin}
                          onClick={() => {
                            setCurrentData(row)
                            setDeleteConfirmModalOpen(true)
                          }}
                        >
                          <Icon tokens={deleteTokens} />
                        </Link>
                      </Text>
                    </TableCell>
                  </TableRow>
                ))}
          </Table>
        </Row>
      </PageLayout>

      <AddConsumableModal
        addConsumableModalOpen={addConsumableModalOpen}
        setAddConsumableModalOpen={setAddConsumableModalOpen}
        callback={(
          consumableId,
          name,
          description,
          purchaseNum,
          unit,
          purchaseDate,
          expireDate
        ) => {
          reduxDispatch(
            addConsumableList({
              cm: locationList[cmIndex].cm,
              cmLocation: locationList[cmIndex].cmLocation,
              consumableId: consumableId,
              AddConsumableByLocationItem: {
                name,
                description,
                purchaseNum,
                unit,
                purchaseDate,
                expireDate,
              },
            })
          )
        }}
      />
      <DeleteConfirmModal
        modalOpen={deleteConfirmModalOpen}
        setModalOpen={setDeleteConfirmModalOpen}
        action={() => {
          reduxDispatch(
            deleteConsumableList({
              consumableId: currentData.id,
              cm: locationList[cmIndex].cm,
              cmLocation: locationList[cmIndex].cmLocation,
            })
          )
        }}
        message={"Are you sure you want to delete this consumable?"}
      />
    </React.Fragment>
  )
}

export default ConsumableList
