import { put, takeLatest } from "redux-saga/effects"
import APIPromises from "../../../util/APIPromises"
import { retrieveDashboardSuccess, retrieveDashboardFailure } from "../action"

function* retrieveDashboard(req) {
  try {
    const response = yield APIPromises.retrieveDashboard(req.payload)
    const dashboard = response.data.retrieveDashboardResponse
    yield put(retrieveDashboardSuccess(dashboard))
  } catch (error) {
    yield put(retrieveDashboardFailure(error))
  }
}

export function* retrieveDashboardWatcher() {
  yield takeLatest("RETRIEVE_DASHBOARD", retrieveDashboard)
}
