/**
 * The footer shown below the current page's content.
 */

import React from "react"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import { Input } from "./form-fields"

const FormInput = props => {
  const { value, setValue, label, type = "text" } = props
  return (
    <Row spacingInset="small none">
      <Column minWidth="150px">
        <Text tag="label" type="b200" color="primary" htmlFor="my-input">
          {label}
        </Text>
      </Column>
      <Column minWidth="250px">
        <Input type={type} size="small" value={value} onChange={setValue} />
      </Column>
    </Row>
  )
}

export default FormInput
