import React from "react"
import { useSelector } from "react-redux"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import { Select, SelectOption } from "../../../components/app/form-fields"
import { equipStatusOptions, getStatusByValue } from "../../../data/options"
import useFilters from "../../../hooks/use-filters"
import { getLocationsByCmName } from "../../../util/Util"

const AuditReportFiltersModal = props => {
  const { filterModalOpen, setFilterModalOpen } = props
  const { locationList } = useSelector(state => state.locationReducer)
  const { dispatch, filters } = useFilters()

  const onChangeFilter = (filter, makeLabel) => value => {
    if (!value || value.length === 0) {
      dispatch({ type: "REMOVE_FILTER", filter })
    } else {
      dispatch({
        type: "UPDATE_FILTER",
        filter,
        value,
        tagLabel: makeLabel(value),
      })
    }
  }

  return (
    <Modal
      title="Filters"
      open={filterModalOpen}
      onClose={() => setFilterModalOpen(false)}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
    >
      <form>
        <Column width={320} spacingInset="200">
          <text>CM Location</text>
          <Select
            value={filters.get("cmLocation")?.value || []}
            onChange={onChangeFilter("cmLocation", value =>
              value.map(location => `Location: ${location}`)
            )}
            id="location-select"
            placeholder="Select a location"
          >
            {locationList.map((x, index) => (
              <SelectOption
                key={index}
                value={index}
                label={`${x.cm}-${x.cmLocation}`}
              />
            ))}
          </Select>
        </Column>
      </form>
      <ModalFooter>
        <Row
          alignmentHorizontal="center"
          spacingInset="200 none 400 none"
          widths="fill"
        >
          <Button type="link" onClick={() => dispatch({ type: "RESET" })}>
            Reset filters
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default AuditReportFiltersModal
