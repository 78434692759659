import { openToast } from "../../../api/toasts"
import {
  SUCCESS_TIMEOUT_IN_MS,
  FAILURE_TIMEOUT_IN_MS,
} from "../../../data/constants"

import { SUFIX } from "../action"

const INITIAL_STATE = {
  auditReports: {},
  equipmentBasicInfo: {},
  auditIssues: [],
  equipmentList: [],
  equipmentFiles: [],
  isLoading: false,
  isLoadingIssues: false,
  cm: "",
  equipment: null,
  equipmentIndex: undefined,
  unApprovedDates: [],
  approvedDates: [],
  consumableList: [],
  operatorList: [],
}
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SELECT_CM_AUDIT" + SUFIX:
      return {
        ...state,
        cm: action.payload,
        equipment: null,
        equipmentIndex: undefined,
        auditReports: {},
        unApprovedDates: [],
        approvedDates: [],
      }
    case "SELECT_EQUIPMENT_AUDIT" + SUFIX:
      return {
        ...state,
        equipment: action.payload.equipment,
        equipmentIndex: action.payload.equipmentIndex,
        auditReports: {},
        unApprovedDates: [],
        approvedDates: [],
      }
    case "RETRIEVE_AUDIT_REPORT" + SUFIX:
      return {
        ...state,
        isLoading: true,
      }
    case "RETRIEVE_AUDIT_REPORT_SUCCESS" + SUFIX:
      return {
        ...state,
        auditReports: action.payload,
        isLoading: false,
      }
    case "RETRIEVE_AUDIT_REPORT_FAILURE" + SUFIX:
      return {
        ...state,
        isLoading: false,
      }
    case "RETRIEVE_EQUIPMENT_AUDIT" + SUFIX:
      return {
        ...state,
      }
    case "RETRIEVE_EQUIPMENT_AUDIT_SUCCESS" + SUFIX:
      return {
        ...state,
        equipmentBasicInfo: action.payload,
      }
    case "RETRIEVE_EQUIPMENT_AUDIT_FAILURE" + SUFIX:
      return {
        ...state,
      }
    case "RETRIEVE_EQUIPMENT_FILES" + SUFIX:
      return {
        ...state,
      }
    case "RETRIEVE_EQUIPMENT_FILES_SUCCESS" + SUFIX:
      return {
        ...state,
        equipmentFiles: action.payload,
      }
    case "RETRIEVE_EQUIPMENT_FILES_FAILURE" + SUFIX:
      return {
        ...state,
      }
    case "RETRIEVE_AUDIT_ISSUES" + SUFIX:
      return {
        ...state,
        isLoadingIssues: true,
      }
    case "RETRIEVE_AUDIT_ISSUES_SUCCESS" + SUFIX:
      return {
        ...state,
        auditIssues: action.payload,
        isLoadingIssues: false,
      }
    case "RETRIEVE_AUDIT_ISSUES_FAILURE" + SUFIX:
      return {
        ...state,
        isLoadingIssues: false,
      }
    case "RETRIEVE_CONSUMABLE" + SUFIX:
      return {
        ...state,
      }
    case "RETRIEVE_CONSUMABLE_SUCCESS" + SUFIX:
      return {
        ...state,
        consumableList: action.payload,
      }
    case "RETRIEVE_CONSUMABLE_FAILURE" + SUFIX:
      return {
        ...state,
      }
    case "ADD_CONSUMABLE" + SUFIX:
      return {
        ...state,
      }
    case "ADD_CONSUMABLE_SUCCESS" + SUFIX:
      return {
        ...state,
      }
    case "ADD_CONSUMABLE_FAILURE" + SUFIX:
      return {
        ...state,
      }
    case "DELETE_CONSUMABLE" + SUFIX:
      return {
        ...state,
      }
    case "DELETE_CONSUMABLE_SUCCESS" + SUFIX:
      return {
        ...state,
      }
    case "DELETE_CONSUMABLE_FAILURE" + SUFIX:
      return {
        ...state,
      }

    case "RETRIEVE_OPERATOR_LIST":
      return {
        ...state,
        operatorList: [],
      }
    case "RETRIEVE_OPERATOR_LIST_SUCCESS":
      return {
        ...state,
        operatorList: action.payload,
      }
    case "RETRIEVE_OPERATOR_LIST_FAILURE":
      return {
        ...state,
      }
    case "ADD_OPERATOR":
      return {
        ...state,
        isLoading: true,
      }
    case "ADD_OPERATOR_SUCCESS":
      openToast({
        type: "success",
        title: "Add Done",
        message: "Add new operator successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "ADD_OPERATOR_FAILURE":
      openToast({
        type: "error",
        title: "Add failed",
        message: "Failed to add new operator, please try again",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "DELETE_OPERATOR":
      return {
        ...state,
        isLoading: true,
      }
    case "DELETE_OPERATOR_SUCCESS":
      openToast({
        type: "success",
        title: "Delete Done",
        message: "Delete operator successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "DELETE_OPERATOR_FAILURE":
      openToast({
        type: "error",
        title: "Delete failed",
        message: "Failed to delete operator, please try again",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "RETRIEVE_STATUS_HISTORY" + SUFIX:
      return {
        ...state,
      }
    case "RETRIEVE_STATUS_HISTORY_SUCCESS" + SUFIX:
      return {
        ...state,
        unApprovedDates: action.payload.unApprovedDates,
        approvedDates: action.payload.approvedDates,
      }
    case "RETRIEVE_STATUS_HISTORY_FAILURE" + SUFIX:
      return {
        ...state,
      }
    case "UPDATE_BASIC_INFO" + SUFIX:
      return {
        isLoading: true,
        ...state,
      }
    case "UPDATE_BASIC_INFO_SUCCESS" + SUFIX:
      return {
        isLoading: false,
        ...state,
      }
    case "UPDATE_BASIC_INFO_FAILURE" + SUFIX:
      return {
        isLoading: false,
        ...state,
      }
    default:
      return state
  }
}

export default reducer
