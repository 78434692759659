/**
 * The navigation bar across the top of the application.
 */

import React, { useCallback, useEffect, useState } from "react"
import { css } from "emotion"
import { useHistory } from "react-router-dom"
import Masthead, {
  MastheadTitle,
  MastheadMenuButton,
  MastheadLink,
} from "@amzn/meridian/masthead"
import Theme from "@amzn/meridian/theme"
import Button from "@amzn/meridian/button"
import Text from "@amzn/meridian/text"
import Icon from "@amzn/meridian/icon"
// import userTokens from "@amzn/meridian-tokens/base/icon/user"
import bellTokens from "@amzn/meridian-tokens/base/icon/bell"
import cogTokens from "@amzn/meridian-tokens/base/icon/cog"
import blueDarkTokens from "@amzn/meridian-tokens/theme/blue-dark"
import { useDispatch, useSelector } from "react-redux"
import Alert from "@amzn/meridian/alert"

import Toggle from "@amzn/meridian/toggle"
import Loader from "@amzn/meridian/loader"
import useSideMenuOpen from "../../hooks/use-side-menu-open"
import useTheme from "../../hooks/use-theme"

// import Responsive from "@amzn/meridian/responsive"
// import { breakpointWidth } from "../../helpers/widths/content"

import logo from "../../amazon-logo-1.png"
import { openNotifications } from "../../api/notifications"
import { retrieveNotifications } from "../../redux/userInfo/action"

const styles = css({
  fontSize: "22px!important",
  color: "#e47911",
  lineHeight: "20px!important",
})
const AppMasthead = () => {
  const history = useHistory()
  const [sideMenuOpen, setSideMenuOpen] = useSideMenuOpen()
  const [themeCode, setThemeCode] = useTheme()

  const {
    isCMUser,
    username,
    nickname,
    errorMessage,
    notifications,
    loading,
  } = useSelector(state => state.userReducer)
  const reduxDispatch = useDispatch()

  const onClickMenuButton = useCallback(() => setSideMenuOpen(!sideMenuOpen), [
    sideMenuOpen,
    setSideMenuOpen,
  ])

  // useEffect(() => {
  //   retrieveNotifications()
  // }, []);

  return (
    <Theme tokens={blueDarkTokens}>
      <Masthead backgroundColor="#222E39" size="large">
        <MastheadMenuButton onClick={onClickMenuButton} />
        <MastheadTitle href="/" onClick={history.push}>
          <span style={{ display: "flex" }}>
            <img alt="logo" width="90" src={logo} />
            <Text
              fontFamily="AmazonEmber, sans-serif"
              type="h400"
              className={styles}
              // color={"inverted"}
            >
              Test Fidelity
            </Text>
          </span>
        </MastheadTitle>
        <MastheadLink
          onClick={() =>
            window.open(
              "mailto:test-fidelity-dev@amazon.com?subject=Request for Access&body=Enter your info"
            )
          }
        >
          {errorMessage && (
            <Alert type="error" size="medium">
              {`${errorMessage}, please contact PDI for access`}
            </Alert>
          )}
        </MastheadLink>
        {username && <Text>{`Welcome, ${nickname}`}</Text>}
        <Toggle
          checked={themeCode === "white"}
          onChange={value => setThemeCode(value ? "white" : "dark")}
        />
        {loading ? (
          <Loader size={"small"} />
        ) : (
          <Button
            type="icon"
            size="small"
            onClick={() => reduxDispatch(retrieveNotifications(username))}
          >
            <Icon tokens={bellTokens} color="primary">
              Notifications
            </Icon>
          </Button>
        )}

        {!isCMUser && (
          <Button
            type="icon"
            href="/settings"
            size="small"
            onClick={history.push}
          >
            <Icon tokens={cogTokens}>Account</Icon>
          </Button>
        )}
      </Masthead>
    </Theme>
  )
}

export default AppMasthead
