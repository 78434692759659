import React, { useState } from "react"
import escapeRegExp from "lodash/escapeRegExp"
import Select, { SelectOption } from "@amzn/meridian/select"
import Text from "@amzn/meridian/text"
import Column from "@amzn/meridian/column"

const SearchableSelect = props => {
  const [searchQuery, setSearchQuery] = useState()

  const { options, value, onChange, minWidth, size } = props

  // Here we filter out options by creating an escaped regular expression
  // out of the search query and test it against each option label
  const searchRegExp = new RegExp(escapeRegExp(searchQuery), "i")
  const matchedOptions = options.filter(
    option => !searchQuery || searchRegExp.test(option.label)
  )

  return (
    <Select
      value={value}
      onChange={onChange}
      searchQuery={searchQuery}
      onSearch={setSearchQuery}
      placeholder="Enter value..."
      minWidth={minWidth}
      size={size}
    >
      {matchedOptions.map(option => (
        // We take all matched options and pass them as SelectOptions
        <SelectOption
          label={option.label}
          value={option.value}
          key={option.value}
        />
      ))}
      {!matchedOptions.length ? (
        // If there are no matched options, we display a message to our
        // users saying their query returned no results
        <Column alignmentVertical="center" spacing="300" spacingInset="600">
          <Text alignment="center">No results</Text>
        </Column>
      ) : null}
    </Select>
  )
}

export default SearchableSelect
