import { openToast } from "../../../api/toasts"

import { FAILURE_TIMEOUT_IN_MS } from "../../../data/constants"

const INITIAL_STATE = {
  dashboardList: [],
  isLoading: false,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "RETRIEVE_DASHBOARD":
      return {
        ...state,
        isLoading: true,
      }
    case "RETRIEVE_DASHBOARD_SUCCESS":
      return {
        ...state,
        dashboardList: action.payload,
        isLoading: false,
      }
    case "RETRIEVE_DASHBOARD_FAILURE":
      openToast({
        type: "error",
        title: "Error",
        message: "Failed to load the dashboard, please try again.",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default reducer
