import { put, takeLatest } from "redux-saga/effects"
import APIPromises from "../../../util/APIPromises"
import {
  retrieveAuditReportSuccess,
  retrieveAuditReportFailure,
  retrieveAuditIssuesSuccess,
  retrieveAuditIssuesFailure,
  retrieveProjectSuccess,
  retrieveProjectFailure,
  addAuditReportSuccess,
  addAuditReportFailure,
  updateAuditReportSuccess,
  updateAuditReportFailure,
  deleteAuditReportSuccess,
  deleteAuditReportFailure,
  editAuditIssuesFailure,
  editAuditIssuesSuccess,
  addAuditIssuesFailure,
  addAuditIssuesSuccess,
  addNextAuditDateSuccess,
  addNextAuditDateFailure,
  deleteAuditIssuesSuccess,
  deleteAuditIssuesFailure,
} from "../action"
import { addEquipmentSuccess } from "../../equipment/action"

function* addAuditReport(req) {
  try {
    const response = yield APIPromises.addAuditReport(req.payload)
    if (response.data && response.data.isSuccess === false) {
      yield put(addAuditReportFailure())
    } else {
      console.log(req.payload)
      yield put({
        type: "RETRIEVE_AUDIT_REPORT",
        payload: {
          cm: req.payload.cm,
          test_location: req.payload.testLocation,
        },
      })
      yield put(addAuditReportSuccess())
    }
  } catch (error) {
    yield put(addAuditReportFailure())
  }
}

function* updateAuditReport(req) {
  try {
    const response = yield APIPromises.updateAuditReport(req.payload)
    if (response.data && response.data.isSuccess === false) {
      yield put(updateAuditReportFailure())
    } else {
      yield put({
        type: "RETRIEVE_AUDIT_REPORT",
        payload: {
          cm: req.payload.cm,
          test_location: req.payload.testLocation,
        },
      })
      yield put(updateAuditReportSuccess())
    }
  } catch (error) {
    yield put(updateAuditReportFailure())
  }
}

export function* addAuditReportWatcher() {
  yield takeLatest("ADD_AUDIT_REPORT", addAuditReport)
}

export function* updateAuditReportWatcher() {
  yield takeLatest("EDIT_AUDIT_REPORT", updateAuditReport)
}

export function* deleteAuditReport(req) {
  try {
    const response = yield APIPromises.deleteAuditReport({
      auditReportId: req.payload.auditReportId,
    })
    if (response.data && response.data.isSuccess === false) {
      yield put(deleteAuditReportFailure())
    } else {
      console.log(req.payload)
      yield put(deleteAuditReportSuccess())
      yield put({
        type: "RETRIEVE_AUDIT_REPORT",
        payload: {
          cm: req.payload.cmName,
          test_location: req.payload.testLocation,
        },
      })
    }
  } catch (error) {
    yield put(deleteAuditReportFailure())
  }
}

export function* deleteAuditReportWatcher() {
  yield takeLatest("DELETE_AUDIT_REPORT", deleteAuditReport)
}

function* retrieveAuditReport(req) {
  try {
    const response = yield APIPromises.retrieveAuditReports(req.payload)
    const allAuditData = response.data
    yield put(retrieveAuditReportSuccess(allAuditData))
  } catch (error) {
    yield put(retrieveAuditReportFailure(error))
  }
}

export function* retrieveAuditReportWatcher() {
  yield takeLatest("RETRIEVE_AUDIT_REPORT", retrieveAuditReport)
}

function* retrieveAuditIssues(req) {
  try {
    const response = yield APIPromises.retrieveAuditIssues({
      auditReportId: req.auditReportId,
    })
    const allAuditData = response.data.retrieveAuditIssuesResponse
    yield put(retrieveAuditIssuesSuccess(allAuditData))
  } catch (error) {
    yield put(retrieveAuditIssuesFailure(error))
  }
}

export function* retrieveAuditIssuesWatcher() {
  yield takeLatest("RETRIEVE_AUDIT_ISSUES", retrieveAuditIssues)
}

function* retrieveProject(req) {
  try {
    const response = yield APIPromises.retrieveProject(req.cm)
    const project = response.data.retrieveProjectsByCMResponse
    yield put(retrieveProjectSuccess(project))
  } catch (error) {
    yield put(retrieveProjectFailure(error))
  }
}

export function* retrieveProjectAuditWatcher() {
  yield takeLatest("RETRIEVE_PROJECT_AUDIT", retrieveProject)
}

function* addAuditIssues(req) {
  try {
    const response = yield APIPromises.addAuditReport(req.allIssues)
    if (response.data && response.data.isSuccess === false) {
      yield put(addAuditIssuesFailure())
    } else {
      yield put(addAuditIssuesSuccess())
    }
  } catch (error) {
    yield put(addAuditIssuesFailure())
  }
}

export function* addAuditIssuesWatcher() {
  yield takeLatest("ADD_AUDIT_ISSUES", addAuditIssues)
}

function* editAuditIssues(req) {
  try {
    const response = yield APIPromises.editAuditIssues(req.allIssues)
    if (response.data && response.data.isSuccess === false) {
      yield put(editAuditIssuesFailure())
    } else {
      yield put(editAuditIssuesSuccess())
      yield put({
        type: "RETRIEVE_AUDIT_ISSUES",
        auditReportId: req.auditReportId,
      })
    }
  } catch (error) {
    yield put(editAuditIssuesFailure())
  }
}

export function* editAuditIssuesWatcher() {
  yield takeLatest("EDIT_AUDIT_ISSUES", editAuditIssues)
}

function* addNextAuditDate(req) {
  try {
    const response = yield APIPromises.addNextAuditDate(req.req)
    if (response.data && response.data.isSuccess === false) {
      yield put(addNextAuditDateFailure())
    } else {
      yield put(addNextAuditDateSuccess())
    }
  } catch (error) {
    yield put(addNextAuditDateFailure())
  }
}
export function* addNextAuditDateWatcher() {
  yield takeLatest("ADD_NEXT_AUDIT_DATE", addNextAuditDate)
}

function* deleteAuditIssues(req) {
  try {
    const response = yield APIPromises.deleteAuditIssues(req.payload.allIssues)
    if (response.data && response.data.isSuccess === false) {
      yield put(deleteAuditIssuesFailure())
    } else {
      yield put(deleteAuditIssuesSuccess())
      yield put({
        type: "RETRIEVE_AUDIT_ISSUES",
        auditReportId: req.payload.auditReportId
      })
    }
  } catch (error) {
    yield put(deleteAuditIssuesFailure())
  }
}

export function* deleteAuditIssuesWatcher() {
  yield takeLatest("DELETE_AUDIT_ISSUES", deleteAuditIssues)
}
