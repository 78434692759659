import { combineReducers } from "redux"
import userReducer from "./userInfo/reducer"
import fileLibraryReducer from "./fileLibrary/reducer"
import projectReducer from "./project/reducer"
import auditReducer from "./audit/reducer"
import equipmentReducer from "./equipment/reducer"
import commonReducer from "./common/reducer"
import equipmentDetailReducer from "./equipmentDetail/reducer"
import dashboardReducer from "./dashboard/reducer"
import settingsReducer from "./settings/reducer"
import usersReducer from "./users/reducer"
import locationReducer from "./testLocation/reducer"
import templateReducer from "./capabilityTemplate/reducer"
import calibrationToolReducer from "./calibrationTool/reducer"
import labPersonnelReducer from "./labPersonnel/reducer"
import performanceReducer from "./performanceSummary/reducer"
import consumableReducer from "./consumable/reducer"

export default combineReducers({
  userReducer,
  fileLibraryReducer,
  projectReducer,
  auditReducer,
  performanceReducer,
  equipmentReducer,
  commonReducer,
  equipmentDetailReducer,
  dashboardReducer,
  settingsReducer,
  usersReducer,
  locationReducer,
  templateReducer,
  calibrationToolReducer,
  labPersonnelReducer,
  consumableReducer,
})
