import React, { useState, useEffect } from "react"
import { css } from "emotion"
import { Document, Page, pdfjs } from "react-pdf"

import { useDispatch, useSelector } from "react-redux"

import Loader from "@amzn/meridian/loader"
import Column from "@amzn/meridian/column"
import Box from "@amzn/meridian/box"
import Divider from "@amzn/meridian/divider"
import SearchField from "@amzn/meridian/search-field"
import Tooltip from "@amzn/meridian/tooltip"
import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import Expander from "@amzn/meridian/expander"
import Button from "@amzn/meridian/button"
import Icon from "@amzn/meridian/icon"
import Link from "@amzn/meridian/link"
import plusCircleTokens from "@amzn/meridian-tokens/base/icon/plus-circle"
import axios from "axios"

import pdfFile from "../../../data/file-sample.pdf"
import ControlPanel from "../../../components/app/control-panel"
import { getRegex } from "../../../util/Util"

import * as auth from "../../../services/Auth/auth"
import env from "../../../services/Auth/env"
import { openToast } from "../../../api/toasts"

import {
  fileSvg,
  unlinkSvg,
  FILE_TYPES,
  SUCCESS_TIMEOUT_IN_MS,
  FAILURE_TIMEOUT_IN_MS,
  getTextColor,
} from "../../../data/constants"
import {
  AddEquipmentFileModal,
  ApproveEquipmentFileModal,
  AddEquipmentAuditIssuesModal,
} from "../modals"

import { approveFile } from "../../../redux/fileLibrary/action"

import {
  retrieveAuditIssues,
  retrieveEquipmentFiles,
} from "../../../redux/equipmentDetail/action"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const treeViewStyles = css({ display: "table" })
const containerStyles = css({ alignItems: "start" })
const filenameStyles = css({
  textWrap: "no-wrap",
  display: "flex",
  alignItems: "center",
})

const selectedFileStyles = css({
  fontSize: "14px",
  backgroundColor: "lightblue",
})

const normalFileStyles = css({
  fontSize: "12px",
})

const ControlledExpander = props => {
  const [open, setOpen] = useState()
  return <Expander open={open} onChange={setOpen} {...props} />
}

const EquipmentAttachmentTab = props => {
  const { equipmentBasicInfo, equipmentFiles, cm, testLocation } = props
  const [scale, setScale] = useState(1.0)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [isLoadingDoc, setIsLoadingDoc] = useState(true)
  const [currentFile, setCurrentFile] = useState()
  const [currentType, setCurrentType] = useState()
  const [keyword, setKeyword] = useState("")

  const { isCMUser, isAdmin } = useSelector(state => state.userReducer)
  const [addEquipmentFileModalOpen, setAddEquipmentFileModalOpen] = useState(
    false
  )
  const [addAuditModalOpen, setAddAuditModalOpen] = useState(false)
  const [
    approveEquipmentFileModalOpen,
    setApproveEquipmentFileModalOpen,
  ] = useState(false)
  const reduxDispatch = useDispatch()

  // useEffect(() => {
  //   if (equipmentFiles?.length) {
  //     setCurrentFile(equipmentFiles[0]?.fileListByEquipment[0])
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [equipmentFiles])

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    setIsLoadingDoc(false)
  }

  const getStatusComponent = status => {
    switch (status) {
      case "Expired":
      case "Approved":
      case "UnApproved":
      case "Unapproved":
        return (
          <Column spacingInset="400">
            <Text>{`Score: ${currentFile.score}`}</Text>
            <Text>{`Effective Period: ${currentFile.effectiveStartDate} - ${currentFile.effectiveEndDate}`}</Text>
            <Text>{`Comments: ${currentFile.comment || ""}`}</Text>
            <Button
              minWidth={90}
              size="small"
              type="primary"
              disabled={!isAdmin}
              onClick={() => {
                setApproveEquipmentFileModalOpen(true)
              }}
            >
              Change approval status
            </Button>
            <Button
              type="tertiary"
              size="small"
              disabled={!isAdmin}
              onClick={() => setAddAuditModalOpen(true)}
            >
              Add to issue list
            </Button>
          </Column>
        )
      case "Pending":
        return (
          <Column spacingInset="400">
            <Button
              minWidth={90}
              size="small"
              type="primary"
              disabled={!isAdmin}
              onClick={() => {
                setApproveEquipmentFileModalOpen(true)
              }}
            >
              Approve
            </Button>
            <Button
              type="tertiary"
              size="small"
              disabled={!isAdmin}
              onClick={() => setAddAuditModalOpen(true)}
            >
              Add to issue list
            </Button>
          </Column>
        )
      default:
        return null
    }
  }

  const handleApproveFile = data => {
    console.warn("current file", currentFile, data)
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.approveFile, {
        method: "POST",
        headers: { idToken: cookie },
        data: {
          ...currentFile,
          ...data,
          fileType: currentType,
          cm: cm,
          cmLocation: testLocation,
        },
      })
        .then(response => {
          console.warn("response", response)
          if (response.data.isSuccess === true) {
            openToast({
              type: "success",
              title: "Approve Done",
              message: "Approve file successfully",
              timeout: SUCCESS_TIMEOUT_IN_MS,
            })
            reduxDispatch(
              retrieveEquipmentFiles({
                cm: cm,
                test_location: testLocation,
                equipment_id: equipmentBasicInfo.equipmentId,
              })
            )
            setCurrentFile()
          } else {
            openToast({
              type: "error",
              title: "Approve failed",
              message: "failed to approve file, please retry",
              timeout: FAILURE_TIMEOUT_IN_MS,
            })
          }
        })
        .catch(error => {
          console.warn("error", error)
          openToast({
            type: "error",
            title: "Approve failed",
            message: "failed to approve file, please retry",
            timeout: FAILURE_TIMEOUT_IN_MS,
          })
        })
    })
  }

  const handleLinkFilesToEquipment = rowList => {
    auth.getToken().then(cookie => {
      axios(env.API_ENDPOINT + env.APIPath.linkFilesToEquipment, {
        method: "POST",
        headers: { idToken: cookie },
        data: {
          cm: cm,
          cmLocation: testLocation,
          linkFilesList: rowList.flat(),
          equipmentId: equipmentBasicInfo.equipmentId,
          equipmentName: equipmentBasicInfo.equipmentName,
        },
      })
        .then(response => {
          console.warn("response", response)
          if (response.data.isSuccess === true) {
            openToast({
              type: "success",
              title: "Link Done",
              message: "Link the file(s) to equipment successfully",
              timeout: SUCCESS_TIMEOUT_IN_MS,
            })
            reduxDispatch(
              retrieveEquipmentFiles({
                cm: cm,
                test_location: testLocation,
                equipment_id: equipmentBasicInfo.equipmentId,
              })
            )
          } else {
            openToast({
              type: "error",
              title: "Link failed",
              message: "failed to link the file(s) to equipment, please retry",
              timeout: FAILURE_TIMEOUT_IN_MS,
            })
          }
        })
        .catch(error => {
          console.warn("error", error)
          openToast({
            type: "error",
            title: "Link failed",
            message: "failed to link the file(s) to equipment, please retry",
            timeout: FAILURE_TIMEOUT_IN_MS,
          })
        })
    })
  }

  return (
    <Row
      className={containerStyles}
      width="100%"
      widths={["grid-3", "grid-6", "grid-3"]}
    >
      <Box className={treeViewStyles}>
        <Row>
          <SearchField
            value={keyword}
            onChange={setKeyword}
            placeholder="Search for..."
            // onSubmit={onSubmit}
            size="small"
          />
          <Tooltip position="top" title="Add new file" id="myTooltip">
            <Button
              type="icon"
              disabled={!isAdmin && !isCMUser}
              onClick={() => setAddEquipmentFileModalOpen(true)}
            >
              <Icon tokens={plusCircleTokens}>Save</Icon>
            </Button>
          </Tooltip>
        </Row>
        {FILE_TYPES.map((type, index) => {
          const files = equipmentFiles.find(file => file.fileType === type)
          return (
            <ControlledExpander
              open
              title={type}
              type="list"
              key={type + index}
            >
              {files?.fileListByEquipment
                ?.filter(f => {
                  let regex = getRegex(keyword)
                  return regex.test(JSON.stringify(f))
                })
                .map((file, index) => (
                  <Text
                    className={filenameStyles}
                    type="b100"
                    key={file.fileName + index}
                  >
                    <Tooltip position="top" title={file.status} id="myTooltip">
                      <Icon
                        className={css({
                          color: getTextColor(file.status),
                        })}
                        tokens={{
                          iconData: fileSvg,
                          iconWidth: 12,
                          iconHeight: 12,
                        }}
                      />
                    </Tooltip>
                    <Link
                      onClick={() => {
                        setCurrentFile(file)
                        setCurrentType(type)
                      }}
                    >
                      <Text
                        className={
                          currentFile?.fileId === file.fileId
                            ? selectedFileStyles
                            : normalFileStyles
                        }
                      >
                        {file.fileName}
                      </Text>
                    </Link>
                    {!isCMUser && (
                      <Tooltip
                        position="top"
                        title={"Unlink File"}
                        id="unlinkFile"
                      >
                        <Button
                          disabled={!isAdmin}
                          size="small"
                          type="link"
                          onClick={() => {
                            auth.getToken().then(cookie => {
                              axios(
                                env.API_ENDPOINT +
                                  env.APIPath.unlinkFileToEquipment,
                                {
                                  method: "POST",
                                  headers: { idToken: cookie },
                                  data: {
                                    // fileIds: rowList.reduce((a, b) => ),
                                    fileId: file.fileId,
                                    fileName: file.fileName,
                                    fileType: type,
                                    equipmentId: equipmentBasicInfo.equipmentId,
                                    equipmentName:
                                      equipmentBasicInfo.equipmentName,
                                  },
                                }
                              )
                                .then(response => {
                                  console.warn("response", response)
                                  if (response.data.isSuccess === true) {
                                    openToast({
                                      type: "success",
                                      title: "Unlink Done",
                                      message:
                                        "Unlink the file to equipment successfully",
                                      timeout: SUCCESS_TIMEOUT_IN_MS,
                                    })
                                    reduxDispatch(
                                      retrieveEquipmentFiles({
                                        cm: cm,
                                        test_location: testLocation,
                                        equipment_id:
                                          equipmentBasicInfo.equipmentId,
                                      })
                                    )
                                  } else {
                                    openToast({
                                      type: "error",
                                      title: "Link failed",
                                      message:
                                        "failed to unlink the file to equipment, please retry",
                                      timeout: FAILURE_TIMEOUT_IN_MS,
                                    })
                                  }
                                })
                                .catch(error => {
                                  console.warn("error", error)
                                  openToast({
                                    type: "error",
                                    title: "Link failed",
                                    message:
                                      "failed to unlink the file to equipment, please retry",
                                    timeout: FAILURE_TIMEOUT_IN_MS,
                                  })
                                })
                            })
                          }}
                        >
                          <Icon
                            tokens={{
                              iconData: unlinkSvg,
                            }}
                          >
                            Unlink
                          </Icon>
                        </Button>
                      </Tooltip>
                    )}
                  </Text>
                ))}
            </ControlledExpander>
          )
        })}
      </Box>
      <Box type="outline" spacingInset="400">
        <section id="pdf-section">
          <ControlPanel
            scale={scale}
            setScale={setScale}
            numPages={numPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            rotation={rotation}
            setRotation={setRotation}
            file={{ url: currentFile?.presignedUrl }}
            presignedUrl={currentFile?.presignedUrl}
          />
          <Divider />
          <Document
            file={{ url: currentFile?.presignedUrl }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {isLoadingDoc ? (
              <Column alignmentHorizontal="center" spacingInset="xlarge none">
                <Loader type="circular" />
              </Column>
            ) : (
              <Page pageNumber={pageNumber} scale={scale} rotate={rotation} />
            )}
          </Document>
        </section>
      </Box>
      <Box>{getStatusComponent(currentFile?.status)}</Box>
      <AddEquipmentFileModal
        addEquipmentFileModalOpen={addEquipmentFileModalOpen}
        setAddEquipmentFileModalOpen={setAddEquipmentFileModalOpen}
        currentData={equipmentBasicInfo}
        callback={handleLinkFilesToEquipment}
      />
      <ApproveEquipmentFileModal
        approveEquipmentFileModalOpen={approveEquipmentFileModalOpen}
        setApproveEquipmentFileModalOpen={setApproveEquipmentFileModalOpen}
        onApproveFile={handleApproveFile}
        currentFile={currentFile}
      />
      <AddEquipmentAuditIssuesModal
        addAuditModalOpen={addAuditModalOpen}
        setAddAuditModalOpen={setAddAuditModalOpen}
        onAddSuccess={() =>
          reduxDispatch(
            retrieveAuditIssues({
              equipment_id: equipmentBasicInfo.equipmentId,
            })
          )
        }
        equipment={equipmentBasicInfo}
        fileId={currentFile?.fileId}
        callback={handleLinkFilesToEquipment}
        cm={cm}
        testLocation={testLocation}
      />
    </Row>
  )
}

export default EquipmentAttachmentTab
