export const retrieveLocationList = payload => ({
  type: "RETRIEVE_LOCATION_LIST",
  payload,
})

export const retrieveLocationListSuccess = list => ({
  type: "RETRIEVE_LOCATION_LIST_SUCCESS",
  payload: list,
})

export const retrieveLocationListFailure = res => ({
  type: "RETRIEVE_LOCATION_LIST_FAILURE",
  payload: res,
})

export const addLocation = payload => ({
  type: "ADD_LOCATION",
  payload: payload,
})

export const addLocationSuccess = list => ({
  type: "ADD_LOCATION_SUCCESS",
  payload: list,
})

export const addLocationFailure = res => ({
  type: "ADD_LOCATION_FAILURE",
  payload: res,
})

export const updateLocation = payload => ({
  type: "UPDATE_LOCATION",
  payload: payload,
})

export const updateLocationSuccess = list => ({
  type: "UPDATE_LOCATION_SUCCESS",
  payload: list,
})

export const updateLocationFailure = res => ({
  type: "UPDATE_LOCATION_FAILURE",
  payload: res,
})

export const deleteLocation = payload => ({
  type: "DELETE_LOCATION",
  payload: payload,
})

export const deleteLocationSuccess = list => ({
  type: "DELETE_LOCATION_SUCCESS",
  payload: list,
})

export const deleteLocationFailure = res => ({
  type: "DELETE_LOCATION_FAILURE",
  payload: res,
})
