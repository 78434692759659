import React from "react"
import Tag from "@amzn/meridian/tag"

const StatusTag = props => {
  const { status } = props
  const getType = () => {
    switch (status) {
      case "pass":
      case "approved":
      case "Approved":
      case "resolved":
      case "Available":
        return "success"
      case "fail":
      case "UnApproved":
      case "Unavailable":
      case "Need repair":
      case "Need calibration":
      case "Missing":
      case "Repairing":
      case "Maintance":
      case "Open":
        return "error"
      case "pending":
      case "Pending":
      case "in progress":
        return "neutral"
      default:
        return "neutral"
    }
  }

  return (
    <Tag type={getType(status)}>
      <span style={{ textTransform: "capitalize" }}>{status}</span>
    </Tag>
  )
}

export default StatusTag
