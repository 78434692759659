import {getDefaultPerformanceSummaryDate} from "../../../util/Util";

const INITIAL_STATE = {
  isLoading: false,

  cmDateRange: getDefaultPerformanceSummaryDate(),
  cmCmIndex: null,
  summary_by_cm_header: {},
  summary_by_cm_equipment_category: {},
  summary_by_cm_equipment_cm: {},
  summary_by_cm_month: {},
  summary_by_cm_quarter: {},
  summary_by_cm_avg_days: {},

  cpiDateRange: getDefaultPerformanceSummaryDate(),
  summary_by_cpi_header: {},
  summary_by_cpi_data: {},

  summary_by_system_header: {},
  summary_by_system_data: {},
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_CM_DATE_RANGE":
      return {
        ...state,
        cmDateRange: action.cmDateRange,
      }
    case "SET_CM_CM_INDEX":
      return {
        ...state,
        cmCmIndex: action.cmCmIndex,
      }
    case "SET_CM_RESET":
      return INITIAL_STATE
    case "RETRIEVE_PERFORMANCE_BY_CM_HEADER":
      return {
        ...state,
        isLoading: true,
      }
    case "RETRIEVE_PERFORMANCE_BY_CM_HEADER_SUCCESS":
      return {
        ...state,
        summary_by_cm_header: action.payload,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_CM_HEADER_FAILURE":
      return {
        ...state,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_CM_EQUIPMENT_CATEGORY":
      return {
        ...state,
        isLoading: true,
      }
    case "RETRIEVE_PERFORMANCE_BY_CM_EQUIPMENT_CATEGORY_SUCCESS":
      return {
        ...state,
        summary_by_cm_equipment_category: action.payload,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_CM_EQUIPMENT_CATEGORY_FAILURE":
      return {
        ...state,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_CM_EQUIPMENT_CM":
      return {
        ...state,
        isLoading: true,
      }
    case "RETRIEVE_PERFORMANCE_BY_CM_EQUIPMENT_CM_SUCCESS":
      return {
        ...state,
        summary_by_cm_equipment_cm: action.payload,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_CM_EQUIPMENT_CM_FAILURE":
      return {
        ...state,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_CM_MONTH":
      return {
        ...state,
        isLoading: true,
      }
    case "RETRIEVE_PERFORMANCE_BY_CM_MONTH_SUCCESS":
      return {
        ...state,
        summary_by_cm_month: action.payload,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_CM_MONTH_FAILURE":
      return {
        ...state,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_CM_QUARTER":
      return {
        ...state,
        isLoading: true,
      }
    case "RETRIEVE_PERFORMANCE_BY_CM_QUARTER_SUCCESS":
      return {
        ...state,
        summary_by_cm_quarter: action.payload,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_CM_QUARTER_FAILURE":
      return {
        ...state,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_CM_AVG_DAYS":
      return {
        ...state,
        isLoading: true,
      }
    case "RETRIEVE_PERFORMANCE_BY_CM_AVG_DAYS_SUCCESS":
      return {
        ...state,
        summary_by_cm_avg_days: action.payload,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_CM_AVG_DAYS_FAILURE":
      return {
        ...state,
        isLoading: false,
      }
    case "SET_CPI_DATE_RANGE":
      return {
        ...state,
        cpiDateRange: action.cpiDateRange,
      }
    case "RETRIEVE_PERFORMANCE_BY_CPI_HEADER":
      return {
        ...state,
        isLoading: true,
      }
    case "RETRIEVE_PERFORMANCE_BY_CPI_HEADER_SUCCESS":
      return {
        ...state,
        summary_by_cpi_header: action.payload,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_CPI_HEADER_FAILURE":
      return {
        ...state,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_CPI_DATA":
      return {
        ...state,
        isLoading: true,
      }
    case "RETRIEVE_PERFORMANCE_BY_CPI_DATA_SUCCESS":
      return {
        ...state,
        summary_by_cpi_data: action.payload,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_CPI_DATA_FAILURE":
      return {
        ...state,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_SYSTEM_HEADER":
      return {
        ...state,
        isLoading: true,
      }
    case "RETRIEVE_PERFORMANCE_BY_SYSTEM_HEADER_SUCCESS":
      return {
        ...state,
        summary_by_system_header: action.payload,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_SYSTEM_HEADER_FAILURE":
      return {
        ...state,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_SYSTEM_DATA":
      return {
        ...state,
        isLoading: true,
      }
    case "RETRIEVE_PERFORMANCE_BY_SYSTEM_DATA_SUCCESS":
      return {
        ...state,
        summary_by_system_data: action.payload,
        isLoading: false,
      }
    case "RETRIEVE_PERFORMANCE_BY_SYSTEM_DATA_FAILURE":
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default reducer
