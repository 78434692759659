import React, { useState, useEffect } from "react"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import Checkbox from "@amzn/meridian/checkbox"
import { useDispatch, useSelector } from "react-redux"
import { FormSelect, FormInput } from "../../../components/app"
import { addLocation, updateLocation } from "../../../redux/testLocation/action"

import { retrieveUserList } from "../../../redux/users/action"

const AddLocationModal = props => {
  const { modalOpen, setModalOpen, mode, currentData } = props
  const [location, setLocation] = useState({})
  const [originalCm, setOriginalCm] = useState()
  const [originalCmLocation, setOriginalCmLocation] = useState()

  const { userList } = useSelector(state => state.usersReducer)

  useEffect(() => {
    reduxDispatch(retrieveUserList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setLocation(currentData)
    setOriginalCm(currentData.cm)
    setOriginalCmLocation(currentData.cmLocation)
  }, [currentData, setLocation])

  const { cm, cmLocation, canNPI, canSustaining, cmUsers, driUsers } = location

  const reduxDispatch = useDispatch()
  return (
    <Modal
      title="Add Location"
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
    >
      <form>
        <FormInput
          value={cm}
          setValue={value => setLocation({ ...location, cm: value })}
          label={"CM Name"}
        />
        <FormInput
          value={cmLocation}
          setValue={value => setLocation({ ...location, cmLocation: value })}
          label={"Test Location"}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <Checkbox
            checked={canNPI}
            onChange={value => setLocation({ ...location, canNPI: value })}
          >
            NPI
          </Checkbox>
          <Checkbox
            checked={canSustaining}
            onChange={value =>
              setLocation({ ...location, canSustaining: value })
            }
          >
            Sustaining
          </Checkbox>
        </div>
        <FormSelect
          value={driUsers ? driUsers.map(x => x.userId) : []}
          setValue={value => {
            setLocation({
              ...location,
              driUsers: value?.map(x => ({ userId: x })),
            })
          }}
          label={"DRI"}
          options={userList
            .filter(x => x.userGroup === "CPI")
            .map(y => ({ value: y.id, label: y.name }))}
        />
        <FormSelect
          value={cmUsers ? cmUsers.map(x => x.userId) : []}
          setValue={value =>
            setLocation({
              ...location,
              cmUsers: value?.map(x => ({ userId: x })),
            })
          }
          label={"CM Users"}
          options={userList
            .filter(x => x.isCmUser)
            .map(y => ({ value: y.id, label: y.name }))}
        />
      </form>
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            disabled={!location.cm || !location.cmLocation}
            type="primary"
            size="small"
            onClick={() => {
              if (mode === "add") {
                reduxDispatch(
                  addLocation({
                    UserWithLocationDef: location,
                  })
                )
              } else {
                reduxDispatch(
                  updateLocation({
                    cm: originalCm,
                    cmLocation: originalCmLocation,
                    editTestLocation: location,
                  })
                )
              }
              setModalOpen(false)
            }}
          >
            {mode === "edit" ? "Update" : "Add"}
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default AddLocationModal
