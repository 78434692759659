import React, { useEffect, useState } from "react"
import Box from "@amzn/meridian/box"
import Text from "@amzn/meridian/text"
import Row from "@amzn/meridian/row"
import Column from "@amzn/meridian/column"
import Expander from "@amzn/meridian/expander"
import Button from "@amzn/meridian/button"
import Table, { TableCell, TableRow } from "@amzn/meridian/table"
import { useSelector } from "react-redux"
import APIPromises from "../../util/APIPromises"

const CURRENT_MONTH = new Date().getMonth()
const CURRENT_YEAR = new Date().getFullYear()
const MONTH = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

const CalendarCard = props => {
  const { data, monthCode } = props
  const [open, setOpen] = useState(false)
  const [calibrationDetails, setCalibrationDetails] = useState([])
  const { cmIndex, year } = useSelector(state => state.calibrationToolReducer)
  const { locationList } = useSelector(state => state.locationReducer)

  const handleCalendarDetailEvent = async (e, btnType) => {
    setOpen(true)

    async function getDetailsResponse() {
      let detailsResponse = await APIPromises.retrieveCalibrationMonthEquipmentDetails(
        {
          cm: locationList[cmIndex].cm,
          cmLocation: locationList[cmIndex].cmLocation,
          year: year,
          monthName: monthCode + 1,
        }
      )
      return detailsResponse.data.equipmentDetails
    }
    let equipmentDetails = await getDetailsResponse()
    // console.log(equipmentDetails)
    let filteredResponse
    if (btnType === "due") {
      // get all due date within this month
      filteredResponse = equipmentDetails.filter(v => {
        let curMonth = new Date(v.dueDate).getMonth()
        let curYear = new Date(v.dueDate).getFullYear()
        return curMonth === parseInt(monthCode) && curYear === year
      })
    } else if (btnType === "calibrated") {
      filteredResponse = equipmentDetails.filter(v => {
        let curMonth = new Date(v.calibrationDate).getMonth()
        let curYear = new Date(v.calibrationDate).getFullYear()
        return curMonth === parseInt(monthCode) && curYear === year
      })
    } else {
      filteredResponse = equipmentDetails.filter(v => {
        let curMonth = new Date(v.dueDate).getMonth()
        let curYear = new Date(v.dueDate).getFullYear()
        return curYear < year || curMonth < monthCode
      })
    }
    setCalibrationDetails([...filteredResponse])
  }

  return (
    <Box type="outline" width="30%" minWidth="500px">
      <div
        style={{
          background:
            year === parseInt(CURRENT_YEAR) &&
            monthCode === parseInt(CURRENT_MONTH)
              ? "#ff7750"
              : "#b3d8ff",
          padding: "10px",
        }}
      >
        <Text type="h500">{MONTH[monthCode]}</Text>
      </div>
      <Row alignmentHorizontal="center" height="160px">
        {(year === parseInt(CURRENT_YEAR) &&
          CURRENT_MONTH <= parseInt(monthCode)) ||
        year > parseInt(CURRENT_YEAR) ? (
          <Column alignmentHorizontal="center">
            <Button
              type="link"
              size="xlarge"
              style={{ color: "red" }}
              onClick={e => handleCalendarDetailEvent(e, "due")}
            >
              <Text type="h500">{data.due}</Text>
            </Button>
            <Text>Due</Text>
          </Column>
        ) : (
          ""
        )}
        {(year === parseInt(CURRENT_YEAR) &&
          CURRENT_MONTH >= parseInt(monthCode)) ||
        year < parseInt(CURRENT_YEAR) ? (
          <Column alignmentHorizontal="center">
            <Button
              type="link"
              size="xlarge"
              style={{ color: "red" }}
              onClick={e => handleCalendarDetailEvent(e, "calibrated")}
            >
              <Text className="pass" type="h500">
                {data.calibrated}
              </Text>
            </Button>
            <Text>Calibrated</Text>
          </Column>
        ) : (
          ""
        )}
        {(year === parseInt(CURRENT_YEAR) &&
          CURRENT_MONTH >= parseInt(monthCode)) ||
        year < parseInt(CURRENT_YEAR) ? (
          <Column alignmentHorizontal="center">
            <Button
              type="link"
              size="xlarge"
              style={{ color: "red" }}
              onClick={e => handleCalendarDetailEvent(e, "expired")}
            >
              <Text color="error" type="h500">
                {data.expired}
              </Text>
            </Button>
            <Text>Expired</Text>
          </Column>
        ) : (
          ""
        )}
      </Row>

      <div style={{ paddingLeft: "10px", paddingRight: "30px" }}>
        <Expander
          open={open}
          onChange={setOpen}
          type="inline"
          title=""
          spacingInset="100 none none none"
        >
          {calibrationDetails && calibrationDetails.length > 0 ? (
            <Table
              headerRows={1}
              spacing="small"
              showDividers={false}
              showStripes={true}
              layout="fixed"
            >
              <TableRow>
                <TableCell>Equipment Name</TableCell>
                <TableCell>Due Date</TableCell>
                <TableCell>Calibration Date</TableCell>
              </TableRow>
              {calibrationDetails.map((v, index) => (
                <TableRow key={index}>
                  <TableCell>{v.equipmentName}</TableCell>
                  <TableCell>{v.dueDate}</TableCell>
                  <TableCell>{v.calibrationDate}</TableCell>
                </TableRow>
              ))}
            </Table>
          ) : (
            <Text>No Content</Text>
          )}
        </Expander>
      </div>
    </Box>
  )
}

export default CalendarCard
