import React, { useState, useEffect } from "react"
import Loader from "@amzn/meridian/loader"
import Column from "@amzn/meridian/column"
import Box from "@amzn/meridian/box"
import Divider from "@amzn/meridian/divider"
import Input from "@amzn/meridian/input"
import Textarea from "@amzn/meridian/textarea"
import Tab, { TabGroup } from "@amzn/meridian/tab"
import SearchField from "@amzn/meridian/search-field"
import Select, { SelectOption } from "@amzn/meridian/select"
import FileInput from "@amzn/meridian/file-input"
import Tooltip from "@amzn/meridian/tooltip"
import { css } from "emotion"

import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import Thumbnail from "@amzn/meridian/thumbnail"
import Heading from "@amzn/meridian/heading"
import Button from "@amzn/meridian/button"
import Icon from "@amzn/meridian/icon"
import Link from "@amzn/meridian/link"
import caretLeftTokens from "@amzn/meridian-tokens/base/icon/caret-left"
import caretRightTokens from "@amzn/meridian-tokens/base/icon/caret-right"

import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

import { openToast } from "../../api/toasts"
import {
  SUCCESS_TIMEOUT_IN_MS,
  FAILURE_TIMEOUT_IN_MS,
  EQUIPMENT_STATUS,
} from "../../data/constants"
import * as auth from "../../services/Auth/auth"
import env from "../../services/Auth/env"

import thumbnailImage from "./placeholder.jpeg"

import PageLayout from "../../components/app/page-layout"
import StatusTag from "../../components/app/status-tag"
import {
  EquipmentBasicInfoTab,
  EquipmentAttachmentTab,
  EquipmentHistoryTab,
  EquipmentIssueListTab,
  EquipmentConsumableTab,
  EquipmentCertifiedOperatorListTab,
} from "./tabs"

import {
  retrieveEquipment,
  updateBasicInfo,
  retrieveEquipmentFiles,
  retrieveAuditReport,
  retrieveAuditIssues,
  selectCM,
  selectEquipment,
} from "../../redux/equipmentDetail/action"

import {
  retrieveAllEquipment,
} from "../../redux/equipment/action"

const imageContainerStyles = css({
  display: "inline-block",
  position: "relative",
})

const imageStyles = css({
  pointerEvents: "none",
  opacity: 1,
  textAlign: "center",
})

const fileUploadStyles = css({
  opacity: 0,
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
})

const EquipmentDetails = () => {
  const [currentTab, setCurrentTab] = useState("basic-info")

  const [canGoPrevious, setCanGoPrevious] = useState(false)
  const [canGoNext, setCanGoNext] = useState(false)

  const location = useLocation()
  const { cm, testLocation } = Object.fromEntries(
    new URLSearchParams(location.search).entries()
  )
  const reduxDispatch = useDispatch()
  const history = useHistory()

  const { equipmentBasicInfo, equipmentFiles, isLoading } = useSelector(
    state => state.equipmentDetailReducer
  )

  const { equipment: equipmentList } = useSelector(
    state => state.equipmentReducer
  )

  const { isCMUser } = useSelector(state => state.userReducer)

  useEffect(() => {
    const { equipmentId } = Object.fromEntries(
      new URLSearchParams(location.search).entries()
    )
    if (cm && equipmentId && testLocation) {
      reduxDispatch(
        retrieveEquipment({
          cm: cm,
          test_location: testLocation,
          equipment_id: equipmentId,
        })
      )
      reduxDispatch(
        retrieveEquipmentFiles({
          cm: cm,
          test_location: testLocation,
          equipment_id: equipmentId,
        })
      )
      reduxDispatch(
        retrieveAuditIssues({
          equipment_id: equipmentId,
        })
      )
      reduxDispatch(
        retrieveAllEquipment({
          cm: cm,
          test_location: testLocation,
        })
      )
    }
  }, [location, cm, reduxDispatch, testLocation])

  useEffect(() => {
    if (equipmentList?.length) {
      const currentIndex = equipmentList.findIndex(e => e.equipmentId === equipmentBasicInfo?.equipmentId)
      if (currentIndex > 0) {
        setCanGoPrevious(true)
      } else {
        setCanGoPrevious(false)
      }
      if (currentIndex + 1 < equipmentList.length && currentIndex !== -1) {
        setCanGoNext(true)
      } else {
        setCanGoNext(false)
      }
    }
  }, [equipmentList, equipmentBasicInfo])

  const moveToNextEquipment = () => {
    const currentIndex = equipmentList?.findIndex(e => e.equipmentId == equipmentBasicInfo.equipmentId)
    history.push(
      `equipment-details?cm=${cm}&testLocation=${testLocation}&equipmentId=${equipmentList[currentIndex + 1]?.equipmentId}`
    )
  }

  const moveToPreviousEquipment = () => {
    const currentIndex = equipmentList?.findIndex(e => e.equipmentId == equipmentBasicInfo.equipmentId)
    history.push(
      `equipment-details?cm=${cm}&testLocation=${testLocation}&equipmentId=${equipmentList[currentIndex - 1]?.equipmentId}`
    )
  }

  return (
    <PageLayout
      loading={isLoading}
      spacing="small"
      title={`${cm} - ${testLocation}`}
      breadcrumbs={[{ title: "Equipment List", path: "/equipment-list" }]}
      width={1200}
      alignmentHorizontal={"start"}
    >
      <Column spacing="small">
        <Column>
          <Row width="100%" widths={["grid-1.5", "grid-10.5"]}>
            <div className={imageContainerStyles}>
              <Thumbnail
                className={imageStyles}
                source={equipmentBasicInfo?.profileLink || thumbnailImage}
                size="large"
                type="square"
              />
              <input
                className={fileUploadStyles}
                type="file"
                onChange={e => {
                  if (e.target.files) {
                    let file = e.target.files[0]
                    console.warn("file", file)
                    let params = {
                      file_name: file.name,
                      equipment_name: equipmentBasicInfo.equipmentName,
                      equipment_id: equipmentBasicInfo.equipmentId,
                      cm: cm,
                      test_location: testLocation,
                    }
                    auth.getToken().then(cookie => {
                      axios(
                        env.API_ENDPOINT + env.APIPath.updateEquipmentImage,
                        {
                          method: "PUT",
                          params: params,
                          headers: { idToken: cookie },
                          // timeout: 10000,
                          data: file,
                          onUploadProgress: e => {
                            let completeProgress =
                              ((e.loaded / e.total) * 100) | 0
                            file.uploadPercentage = completeProgress
                          },
                        }
                      )
                        .then(response => {
                          if (response.data && response.data.errorMessage) {
                            file.error = true
                            file.errorMessage = response.data.errorMessage
                            console.warn("error", file.errorMessage)
                            openToast({
                              type: "error",
                              title: "Update failed",
                              message:
                                "failed to update equipment profile, please retry",
                              timeout: FAILURE_TIMEOUT_IN_MS,
                            })
                          } else {
                            console.warn("upload succeed")
                            openToast({
                              type: "success",
                              title: "Update Done",
                              message: "Update equipment profile successfully",
                              timeout: SUCCESS_TIMEOUT_IN_MS,
                            })
                            reduxDispatch(
                              retrieveEquipment({
                                cm: cm,
                                test_location: testLocation,
                                equipment_id: equipmentBasicInfo.equipmentId,
                              })
                            )
                          }
                        })
                        .catch(error => {
                          console.warn("error", error)
                          file.error = true
                        })
                    })
                  }
                }}
              />
            </div>
            <Box>
              <Row>
                <Tooltip title="Previous equipment" position="start">
                  <Button disabled={!canGoPrevious} type="icon" onClick={() => moveToPreviousEquipment()}>
                    <Icon tokens={caretLeftTokens}>Settings</Icon>
                  </Button>
                </Tooltip>
                <Heading level={4}>{equipmentBasicInfo.equipmentName}</Heading>
                <Tooltip title="Next equipment" position="end">
                  <Button disabled={!canGoNext} type="icon" onClick={() => moveToNextEquipment()}>
                    <Icon tokens={caretRightTokens}>Settings</Icon>
                  </Button>
                </Tooltip>
              </Row>
              <>
                {equipmentBasicInfo.label && (
                  <Text className="inline-text">{`Label: ${equipmentBasicInfo.label}; `}</Text>
                )}
                {equipmentBasicInfo.serialNumber && (
                  <Text className="inline-text">{`SN: ${equipmentBasicInfo.serialNumber}; `}</Text>
                )}
                {equipmentBasicInfo.brand && (
                  <Text className="inline-text">{`Brand: ${equipmentBasicInfo.brand}; `}</Text>
                )}
              </>
              <Row>
                <StatusTag
                  status={
                    equipmentBasicInfo.isApproved ? "Approved" : "UnApproved"
                  }
                />
                <Select
                  value={equipmentBasicInfo.equipmentFlag}
                  onChange={value => {
                    auth.getToken().then(cookie => {
                      axios(
                        env.API_ENDPOINT + env.APIPath.updateEquipmentFlag,
                        {
                          method: "POST",
                          headers: { idToken: cookie },
                          data: {
                            // fileIds: rowList.reduce((a, b) => ),
                            flag: value === "No Flag" ? "" : value,
                            equipmentId: equipmentBasicInfo.equipmentId,
                          },
                        }
                      )
                        .then(response => {
                          console.warn("response", response)
                          if (response.data.isSuccess === true) {
                            openToast({
                              type: "success",
                              title: "Update Done",
                              message: "Update equipment flag successfully",
                              timeout: SUCCESS_TIMEOUT_IN_MS,
                            })
                            reduxDispatch(
                              retrieveEquipment({
                                cm: cm,
                                test_location: testLocation,
                                equipment_id: equipmentBasicInfo.equipmentId,
                              })
                            )
                          } else {
                            openToast({
                              type: "error",
                              title: "Update failed",
                              message:
                                "failed to update equipment flag, please retry",
                              timeout: FAILURE_TIMEOUT_IN_MS,
                            })
                          }
                        })
                        .catch(error => {
                          console.warn("error", error)
                          openToast({
                            type: "error",
                            title: "Link failed",
                            message:
                              "failed to update equipment flag, please retry",
                            timeout: FAILURE_TIMEOUT_IN_MS,
                          })
                        })
                    })
                  }}
                  minWidth={200}
                  size={"small"}
                  placeholder={"Unflagged"}
                >
                  {EQUIPMENT_STATUS.map((x, index) => (
                    <SelectOption key={index} value={x} label={x} />
                  ))}
                </Select>
              </Row>
            </Box>
          </Row>
          <TabGroup
            value={currentTab}
            onChange={setCurrentTab}
            aria-labelledby="tablist-heading"
          >
            <Tab value="basic-info" aria-controls="basic-info" id="basic-info">
              Basic Info
            </Tab>
            <Tab value="attachment" aria-controls="attachment" id="attachment">
              Attachment
            </Tab>
            <Tab value="issue-list" aria-controls="issue-list" id="issue-list">
              Issue List
            </Tab>
            <Tab value="history" aria-controls="history" id="history">
              History
            </Tab>
            <Tab value="consumable" aria-controls="consumable" id="consumable">
              Consumable
            </Tab>
            <Tab
              value="certifiedOP"
              aria-controls="certifiedOP"
              id="certifiedOP"
            >
              Certified OP
            </Tab>
          </TabGroup>
          {currentTab === "basic-info" && (
            <EquipmentBasicInfoTab
              equipmentBasicInfo={equipmentBasicInfo}
              cm={cm}
              testLocation={testLocation}
            />
          )}
          {currentTab === "attachment" && (
            <EquipmentAttachmentTab
              equipmentBasicInfo={equipmentBasicInfo}
              cm={cm}
              testLocation={testLocation}
              isCMUser={isCMUser}
              equipmentFiles={equipmentFiles}
            />
          )}
          {currentTab === "issue-list" && (
            <EquipmentIssueListTab
              equipmentBasicInfo={equipmentBasicInfo}
              cm={cm}
              testLocation={testLocation}
            />
          )}
          {currentTab === "history" && (
            <EquipmentHistoryTab equipmentBasicInfo={equipmentBasicInfo} />
          )}
          {currentTab === "consumable" && (
            <EquipmentConsumableTab
              cm={cm}
              testLocation={testLocation}
              equipmentBasicInfo={equipmentBasicInfo}
            />
          )}
          {currentTab === "certifiedOP" && (
            <EquipmentCertifiedOperatorListTab
              cm={cm}
              testLocation={testLocation}
              equipmentBasicInfo={equipmentBasicInfo}
            />
          )}
        </Column>
      </Column>
    </PageLayout>
  )
}

export default EquipmentDetails
