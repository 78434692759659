import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Column from "@amzn/meridian/column"
import Button from "@amzn/meridian/button"
import Row from "@amzn/meridian/row"
import Table, {
  TableActionBar,
  TableCell,
  TableRow,
} from "@amzn/meridian/table"
import deleteTokens from "@amzn/meridian-tokens/base/icon/delete"
import editTokens from "@amzn/meridian-tokens/base/icon/edit"
import SearchField from "@amzn/meridian/search-field"
import Icon from "@amzn/meridian/icon"
import filterTokens from "@amzn/meridian-tokens/base/icon/filter"
import Tooltip from "@amzn/meridian/tooltip"
import plusCircleTokens from "@amzn/meridian-tokens/base/icon/plus-circle"
import Text from "@amzn/meridian/text"
import Link from "@amzn/meridian/link"
import { useHistory } from "react-router-dom"
import {
  deleteAuditReport,
  retrieveAuditReport,
  selectCM,
} from "../../redux/audit/action"
import { Select, SelectOption } from "../../components/app/form-fields"
import PageLayout from "../../components/app/page-layout"
import FilterTags from "../../components/file-library/filter-tag"
import { useFilters, useSort, usePagination } from "../../hooks"
import { getRegex } from "../../util/Util"
import filterEquipment from "../../filter-equipment"
import AuditReportFiltersModal from "./modals/audit-report-filters-modal"
import EmptyState from "../../components/app/empty-state"

import AuditReportFormModal from "./modals/audit-report-form-modal"
import { retrieveAllEquipment } from "../../redux/equipment/action"
import { auditReportObj } from "../../data/constants"
import { retrieveUserList } from "../../redux/users/action/index"

const AuditReport = () => {
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [addAuditReportModalOpen, setAddAuditReportModalOpen] = useState(false)
  const [editAuditReportModalOpen, setEditAuditReportModalOpen] = useState(
    false
  )
  const [addAuditReportObj, setAddAuditReportObj] = useState(auditReportObj)
  const [editAuditReport, setEditAuditReport] = useState()

  const { isCMUser, isAdmin } = useSelector(state => state.userReducer)
  const { locationList } = useSelector(state => state.locationReducer)
  const { auditReportList, isLoading, cmIndex } = useSelector(
    state => state.auditReducer
  )
  const [keyword, setKeyword] = useState("")
  const { equipment } = useSelector(state => state.equipmentReducer)
  const history = useHistory()

  const reduxDispatch = useDispatch()
  // for searching and filtering
  const { filters, dispatch } = useFilters()

  const data = filterEquipment(auditReportList, filters).filter(f => {
    let regex = getRegex(keyword)
    return regex.test(JSON.stringify(f))
  })

  const { sortedData, onSort, sortColumn, sortDirection } = useSort(data)
  const { TableFooter, visibleIndices } = usePagination(sortedData.length)

  const onClickAddAuditReport = () => {
    let addAuditReportObj = auditReportObj
    addAuditReportObj.cm = locationList[cmIndex]?.cm
    setAddAuditReportObj(addAuditReportObj)
    setAddAuditReportModalOpen(true)
  }

  const onClickEditAuditReport = index => {
    let editAuditReportObj = auditReportList[index]
    setEditAuditReport(editAuditReportObj)
    setEditAuditReportModalOpen(true)
  }

  const onClickDeleteAuditReport = auditReportId => {
    reduxDispatch(
      deleteAuditReport(
        auditReportId,
        locationList[cmIndex]?.cm,
        locationList[cmIndex]?.cmLocation
      )
    )
  }

  useEffect(() => {
    reduxDispatch(
      retrieveAuditReport({
        cm: locationList[cmIndex]?.cm,
        test_location: locationList[cmIndex]?.cmLocation,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmIndex, locationList])

  useEffect(() => {
    reduxDispatch(
      retrieveAllEquipment({
        cm: locationList[cmIndex]?.cm,
        test_location: locationList[cmIndex]?.cmLocation,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmIndex])

  useEffect(() => {
    reduxDispatch(retrieveUserList())
    console.log("retrieved users")
  }, [reduxDispatch])

  return (
    <React.Fragment>
      <PageLayout
        spacing="large"
        alignmentHorizontal={"start"}
        title="Audit Report List"
        loading={isLoading}
        width={"100%"}
      >
        <Row spacingInset="small none">
          <Column spacing="large">
            <Select
              label="CM Location"
              value={cmIndex}
              onChange={x => {
                reduxDispatch(selectCM(x))
              }}
              minWidth={240}
              size={"small"}
            >
              {locationList.map((x, index) => (
                <SelectOption
                  key={index}
                  value={index}
                  label={`${x.cm}-${x.cmLocation}`}
                />
              ))}
            </Select>
          </Column>
        </Row>
        <Row spacingInset="small none">
          <Column spacing="large" width="100%">
            <Table
              headerRows={1}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              onSort={onSort}
              showDividers={true}
              spacing="small"
              stickyHeaderRow={true}
              fixHeaderRows={true}
              // showStripes={true}
            >
              <TableActionBar widths={[300]}>
                <SearchField
                  value={keyword}
                  onChange={setKeyword}
                  placeholder="Search for..."
                  // size="medium"
                  type="text"
                  onSubmit={() => {}}
                />
                <Tooltip
                  position="top"
                  title="Add new Audit Report"
                  id="myTooltip"
                >
                  <Button
                    type="icon"
                    // disabled={!isCMUser && !isAdmin}
                    onClick={onClickAddAuditReport}
                  >
                    <Icon tokens={plusCircleTokens}>Save</Icon>
                  </Button>
                </Tooltip>
              </TableActionBar>
              <FilterTags stick={true} />
              <TableRow>
                <TableCell sortColumn="reportName">Report Name</TableCell>
                <TableCell sortColumn="auditorList">Auditor</TableCell>
                <TableCell sortColumn="auditDate">Audit Date</TableCell>
                <TableCell sortColumn="lastUpdate">Last Update</TableCell>
                <TableCell sortColumn="issueQty">Issue Qty</TableCell>
                <TableCell sortColumn="resolveRate">Resolve Rate</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
              {sortedData &&
                sortedData
                  .slice(visibleIndices[0], visibleIndices[1])
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Link
                          onClick={() => {
                            history.push(
                              `audit-report-details?cm=${locationList[cmIndex]?.cm}&testLocation=${locationList[cmIndex]?.cmLocation}&auditReportId=${row.auditReportId}`
                            )
                            //   history.push("audit-report-details", {
                            //     cm: locationList[cmIndex]?.cm,
                            //     testLocation: locationList[cmIndex]?.cmLocation,
                            //     auditReport: row,
                            //   })
                          }}
                        >
                          {row.reportName}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {row.auditorList.length > 1
                          ? row.auditorList
                              .reduce((acc, cur) => acc + cur.name + "; ", "")
                              .slice(0, -2)
                          : row.auditorList.length === 1
                          ? row.auditorList[0].name
                          : ""}
                      </TableCell>
                      <TableCell>{row.startDate}</TableCell>
                      <TableCell>{row.lastUpdate}</TableCell>
                      <TableCell>{row.issueQty}</TableCell>
                      {row.resolveRate && row.issueQty && row.issueQty > 0 ? (
                        <TableCell>
                          {((row.resolveRate / row.issueQty) * 100).toFixed(2)}{" "}
                          %
                        </TableCell>
                      ) : (
                        <TableCell>0 %</TableCell>
                      )}
                      <TableCell>
                        <Text>
                          <Link
                            disabled={!isAdmin}
                            onClick={() => {
                              onClickEditAuditReport(index)
                            }}
                          >
                            <Icon tokens={editTokens} />
                          </Link>
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text>
                          <Link
                            disabled={!isAdmin}
                            onClick={() => {
                              onClickDeleteAuditReport(row.auditReportId)
                            }}
                          >
                            <Icon tokens={deleteTokens} />
                          </Link>
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))}
            </Table>
            {sortedData && sortedData.length === 0 ? (
              <EmptyState target={"audit reports"} />
            ) : null}
            <TableFooter />
          </Column>
        </Row>
        <AuditReportFormModal
          key={"add-report"}
          auditReportModalOpen={addAuditReportModalOpen}
          setAuditReportModalOpen={setAddAuditReportModalOpen}
          equipment={equipment}
          cm={cmIndex}
          // auditReportResp={addAuditReportObj}
          auditReportData={addAuditReportObj}
          setAuditReportData={setAddAuditReportObj}
          isEdit={false}
        />
        <AuditReportFormModal
          key={"edit-report"}
          auditReportModalOpen={editAuditReportModalOpen}
          setAuditReportModalOpen={setEditAuditReportModalOpen}
          equipment={equipment}
          cm={cmIndex}
          setAuditReportData={setEditAuditReport}
          auditReportData={editAuditReport}
          isEdit={true}
        />
        <AuditReportFiltersModal
          filterModalOpen={filterModalOpen}
          setFilterModalOpen={setFilterModalOpen}
        />
      </PageLayout>
    </React.Fragment>
  )
}

export default AuditReport
