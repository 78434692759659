export const retrieveEquipment = (projectName, cmName) => ({
  type: "RETRIEVE_EQUIPMENT",
  projectName,
  cmName,
})

export const retrieveEquipmentSuccess = equipment => ({
  type: "RETRIEVE_EQUIPMENT_SUCCESS",
  payload: equipment,
})

export const retrieveEquipmentFailure = res => ({
  type: "RETRIEVE_EQUIPMENT_FAILURE",
  payload: res,
})

export const retrieveCalibratedEquips = (cmName, cmLocation) => ({
  type: "RETRIEVE_CALIBRATED_EQUIPS",
  cmName,
  cmLocation,
})

export const selectCM = cm => ({
  type: "SELECT_CM_PROJECT",
  payload: cm,
})

export const retrieveCalibratedEquipsSuccess = equipment => ({
  type: "RETRIEVE_CALIBRATED_EQUIPS_SUCCESS",
  payload: equipment,
})

export const retrieveCalibratedEquipsFailure = res => ({
  type: "RETRIEVE_CALIBRATED_EQUIPS_FAILURE",
  payload: res,
})

export const retrieveProject = cmName => ({
  type: "RETRIEVE_PROJECT",
  cmName,
})

export const retrieveProjectSuccess = project => ({
  type: "RETRIEVE_PROJECT_SUCCESS",
  payload: project,
})

export const retrieveProjectFailure = res => ({
  type: "RETRIEVE_PROJECT_FAILURE",
  payload: res,
})

export const archiveProject = (payload, cm) => ({
  type: "ARCHIVE_PROJECT",
  payload,
  cm,
})

export const archiveProjectSuccess = project => ({
  type: "ARCHIVE_PROJECT_SUCCESS",
  payload: project,
})

export const archiveProjectFailure = res => ({
  type: "ARCHIVE_PROJECT_FAILURE",
  payload: res,
})

export const updateProject = payload => ({
  type: "UPDATE_PROJECT",
  payload,
})

export const updateProjectSuccess = project => ({
  type: "UPDATE_PROJECT_SUCCESS",
  payload: project,
})

export const updateProjectFailure = res => ({
  type: "UPDATE_PROJECT_FAILURE",
  payload: res,
})

export const retrieveFilesBySOP = cm => ({
  type: "RETRIEVE_FILE_BY_SOP_TYPE",
  payload: cm,
})

export const retrieveFilesBySOPSuccess = files => ({
  type: "RETRIEVE_FILE_BY_SOP_TYPE_SUCCESS",
  payload: files,
})

export const retrieveFilesBySOPFailure = res => ({
  type: "RETRIEVE_FILE_BY_SOP_TYPE_FAILURE",
  payload: res,
})

export const retrieveFilesByAssessment = cm => ({
  type: "RETRIEVE_FILE_BY_ASSESSMENT_TYPE",
  payload: cm,
})

export const retrieveFilesByAssessmentSuccess = files => ({
  type: "RETRIEVE_FILE_BY_ASSESSMENT_TYPE_SUCCESS",
  payload: files,
})

export const retrieveFilesByAssessmentFailure = res => ({
  type: "RETRIEVE_FILE_BY_ASSESSMENT_TYPE_FAILURE",
  payload: res,
})

export const retrieveFilesByTraining = cm => ({
  type: "RETRIEVE_FILE_BY_TRAINING_TYPE",
  payload: cm,
})

export const retrieveFilesByTrainingSuccess = files => ({
  type: "RETRIEVE_FILE_BY_TRAINING_TYPE_SUCCESS",
  payload: files,
})

export const retrieveFilesByTrainingFailure = res => ({
  type: "RETRIEVE_FILE_BY_TRAINING_TYPE_FAILURE",
  payload: res,
})

export const addProject = project => ({
  type: "ADD_PROJECT",
  payload: project,
})

export const addProjectSuccess = added => ({
  type: "ADD_PROJECT_SUCCESS",
  payload: added,
})

export const addProjectFailure = res => ({
  type: "ADD_PROJECT_FAILURE",
  payload: res,
})

export const selectEquipIds = equipIds => ({
  type: "SELECT_EQUIP_IDS",
  payload: equipIds,
})

export const retrieveProjectFilesByType = payload => ({
  type: "RETRIEVE_PROJECT_FILES_BY_TYPE",
  payload,
})

export const retrieveProjectFilesByTypeSuccess = files => ({
  type: "RETRIEVE_PROJECT_FILES_BY_TYPE_SUCCESS",
  payload: files,
})

export const retrieveProjectFilesByTypeFailure = res => ({
  type: "RETRIEVE_PROJECT_FILES_BY_TYPE_FAILURE",
  payload: res,
})
