import React, { useState, useEffect } from "react"
import { css } from "emotion"

import Thumbnail from "@amzn/meridian/thumbnail"
import format from "date-fns/format"
import qs from "qs"

import ProgressTracker, {
  ProgressTrackerStep,
} from "@amzn/meridian/progress-tracker"

import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import Button from "@amzn/meridian/button"
import Link from "@amzn/meridian/link"
import axios from "axios"

import * as auth from "../../../services/Auth/auth"
import env from "../../../services/Auth/env"
import { openToast } from "../../../api/toasts"

const customTheme = {
  borderDotColor: "#ffffff",
  descriptionColor: "#262626",
  dotColor: "#d0cdc4",
  eventColor: "#965500",
  lineColor: "#d0cdc4",
  subtitleColor: "#7c7c7c",
  titleColor: "#405b73",
  yearColor: "#405b73",
}

const EquipmentHistoryTab = props => {
  const { equipmentBasicInfo, } = props
  const [timeline, setTimeline] = useState([])
  const [currentPageId, setCurrentPageId] = useState(1)
  const [isEnd, setIsEnd] = useState(false)

  useEffect(() => {
    const query = qs.stringify({
      equipment_id: equipmentBasicInfo.equipmentId,
      last_id: currentPageId,
    })
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          env.APIPath.retrieveTimelineByEquipment +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(response => {
          console.warn(
            "response",
            response.data.retrieveTimelineByEquipResponse
          )
          const data = response.data.retrieveTimelineByEquipResponse
          if (data?.length === 0) {
            setIsEnd(true)
          } else {
            setTimeline(timeline.concat(data))
          }
        })
        .catch(error => {})
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageId])

  return (
    <div role="tabpanel" id="tabpanel-planets" aria-labelledby="tab-planets">
      <ProgressTracker type="theme" direction="column">
        {timeline.map((t, index) => {
          return (
            <ProgressTrackerStep
              key={index}
              type="future"
              label={
                <strong>
                  {format(new Date(t.timestamp), "yyyy-MM-dd hh:mm:ss")}
                </strong>
              }
              trackLength={4}
            >
              <Row>
                {!t.isCm ? (
                  <Thumbnail
                    source={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${t.user}`}
                    size="small"
                  />
                ) : (
                  ""
                )}
                <Text type={"h100"}>
                  <Link>{t.name ? t.name : t.user}</Link>
                </Text>
                <Text>{t.behavior}</Text>
              </Row>
            </ProgressTrackerStep>
          )
        })}
      </ProgressTracker>
      {!isEnd && (
        <Button
          type="link"
          size="small"
          onClick={() => setCurrentPageId(timeline[0].id)}
        >
          Load more ...
        </Button>
      )}
    </div>
  )
}

export default EquipmentHistoryTab
