import { put, takeLatest } from "redux-saga/effects"
import APIPromises from "../../../util/APIPromises"
import {
  addConsumableListFailure,
  addConsumableListSuccess,
  addConsumableUsageRecordFailure,
  addConsumableUsageRecordSuccess,
  deleteConsumableListFailure,
  deleteConsumableListSuccess,
  deleteConsumableUsageRecordFailure,
  deleteConsumableUsageRecordSuccess,
  retrieveConsumableByIdFailure,
  retrieveConsumableByIdSuccess,
  retrieveConsumableListFailure,
  retrieveConsumableListSuccess,
  retrieveConsumableUsageByIdFailure,
  retrieveConsumableUsageByIdSuccess,
} from "../action"

function* retrieveConsumableListByLocation(req) {
  try {
    const response = yield APIPromises.retrieveConsumableListByLocation(
      req.payload
    )
    yield put(retrieveConsumableListSuccess(response.data))
  } catch (err) {
    yield put(retrieveConsumableListFailure(err))
  }
}

export function* retrieveConsumableListByLocationWatcher() {
  yield takeLatest("RETRIEVE_CONSUMABLE_LIST", retrieveConsumableListByLocation)
}

function* addConsumableListByLocation(req) {
  try {
    const response = yield APIPromises.addConsumableListByLocation(req.payload)
    if (response.data && response.data.isSuccess) {
      yield put({
        type: "RETRIEVE_CONSUMABLE_LIST",
        payload: {
          cm: req.payload.cm,
          cm_location: req.payload.cmLocation,
        },
      })
      yield put(addConsumableListSuccess())
    } else {
      yield put(addConsumableListFailure(response.errorMessage))
    }
  } catch (err) {
    yield put(addConsumableListFailure(err))
  }
}

export function* addConsumableListByLocationWatcher() {
  yield takeLatest("ADD_CONSUMABLE_LIST", addConsumableListByLocation)
}

function* deleteConsumableById(req) {
  try {
    const response = yield APIPromises.deleteConsumableById(req.payload)
    if (response.data && response.data.isSuccess) {
      yield put({
        type: "RETRIEVE_CONSUMABLE_LIST",
        payload: {
          cm: req.payload.cm,
          cm_location: req.payload.cmLocation,
        },
      })
      yield put(deleteConsumableListSuccess())
    } else {
      yield put(deleteConsumableListFailure(response.errorMessage))
    }
  } catch (err) {
    yield put(deleteConsumableListFailure(err))
  }
}

export function* deleteConsumableByIdWatcher() {
  yield takeLatest("DELETE_CONSUMABLE_LIST", deleteConsumableById)
}

function* retrieveConsumableUsageById(req) {
  try {
    const response = yield APIPromises.retrieveConsumableUsageById(req.payload)
    yield put(retrieveConsumableUsageByIdSuccess(response.data))
  } catch (err) {
    yield put(retrieveConsumableUsageByIdFailure(err))
  }
}

export function* retrieveConsumableUsageByIdWatcher() {
  yield takeLatest(
    "RETRIEVE_CONSUMABLE_USAGE_BY_ID",
    retrieveConsumableUsageById
  )
}

function* retrieveConsumableById(req) {
  try {
    const response = yield APIPromises.retrieveConsumableById(req.payload)
    yield put(retrieveConsumableByIdSuccess(response.data))
  } catch (err) {
    yield put(retrieveConsumableByIdFailure(err))
  }
}

export function* retrieveConsumableByIdWatcher() {
  yield takeLatest("RETRIEVE_CONSUMABLE_BY_ID", retrieveConsumableById)
}

function* addConsumableUsageRecord(req) {
  try {
    const response = yield APIPromises.addConsumable(req.payload)
    if (response.data && response.data.isSuccess) {
      yield put({
        type: "RETRIEVE_CONSUMABLE_USAGE_BY_ID",
        payload: {
          consumable_id: req.payload.consumableId,
        },
      })
      yield put(addConsumableUsageRecordSuccess())
    } else {
      yield put(addConsumableUsageRecordFailure(response.errorMessage))
    }
  } catch (err) {
    yield put(addConsumableUsageRecordFailure(err))
  }
}

export function* addConsumableUsageRecordWatcher() {
  yield takeLatest("ADD_CONSUMABLE_USAGE_RECORD", addConsumableUsageRecord)
}

function* deleteConsumableUsageRecord(req) {
  try {
    const response = yield APIPromises.deleteConsumable(req.payload)
    if (response.data && response.data.isSuccess) {
      yield put({
        type: "RETRIEVE_CONSUMABLE_USAGE_BY_ID",
        payload: {
          consumable_id: req.payload.id,
        },
      })
      yield put(deleteConsumableUsageRecordSuccess())
    } else {
      yield put(deleteConsumableUsageRecordFailure(response.errorMessage))
    }
  } catch (err) {
    yield put(deleteConsumableUsageRecordFailure(err))
  }
}

export function* deleteConsumableUsageRecordWatcher() {
  yield takeLatest(
    "DELETE_CONSUMABLE_USAGE_RECORD",
    deleteConsumableUsageRecord
  )
}
