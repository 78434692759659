import React, { useState, useEffect } from "react"
// import {useHistory} from "react-router-dom";
import Row from "@amzn/meridian/row"
// import Checkbox from "@amzn/meridian/checkbox";
import Button from "@amzn/meridian/button"
import Column from "@amzn/meridian/column"
import Text from "@amzn/meridian/text"
import Icon from "@amzn/meridian/icon"

import Modal, { ModalFooter } from "@amzn/meridian/modal"
import Select, { SelectOptionGroup, SelectOption } from "@amzn/meridian/select"
import Input from "@amzn/meridian/input"

import deleteTokens from "@amzn/meridian-tokens/base/icon/delete"

import ProgressTracker, {
  ProgressTrackerStep,
} from "@amzn/meridian/progress-tracker"

import { useDispatch, useSelector } from "react-redux"
import { FormInput, FormSelect } from "../../../components/app"

import { copyEquipment } from "../../../redux/equipment/action"

const BatchImportEquipmentModal = props => {
  // eslint-disable-next-line no-unused-vars
  const {
    batchImportEquipmentModalOpen,
    setBatchImportEquipmentModalOpen,
  } = props
  const { username } = useSelector(state => state.userReducer)

  const { cmIndex, equipment } = useSelector(state => state.equipmentReducer)
  const { locationList } = useSelector(state => state.locationReducer)
  const reduxDispatch = useDispatch()

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetState = () => {
    setBatchImportEquipmentModalOpen(false)
  }

  return (
    <Modal
      title="Batch Import Equipment"
      open={batchImportEquipmentModalOpen}
      onClose={resetState}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
      // width="500px"
    >
      <ProgressTracker>
        <ProgressTrackerStep
          type="future"
          label={<strong>Step1:</strong>}
          showLabel={true}
        >
          <Button type="tertiary" size="small" onClick={() => {}}>
            Download Template
          </Button>
        </ProgressTrackerStep>
        <ProgressTrackerStep
          type="future"
          label={<strong>Step2:</strong>}
          showLabel={true}
        >
          Fill out data based on template
        </ProgressTrackerStep>
        <ProgressTrackerStep
          type="future"
          label={<strong>Step3:</strong>}
          showLabel={true}
        >
          <Button type="tertiary" size="small" onClick={() => {}}>
            Upload
          </Button>
        </ProgressTrackerStep>
      </ProgressTracker>
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              reduxDispatch(copyEquipment({}))
              resetState()
            }}
          >
            Add
          </Button>
          <Button
            type="secondary"
            size="small"
            onClick={() => setBatchImportEquipmentModalOpen(false)}
          >
            Cancel
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default BatchImportEquipmentModal
