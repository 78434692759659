export const SUCCESS_TIMEOUT_IN_MS = 3000
export const FAILURE_TIMEOUT_IN_MS = 5000

export const FILE_TYPES = [
  "Calibration Report",
  "Checklist",
  "SOP",
  "Maintenance Record",
  "Training Record",
  "Trial Run Data",
  "Daily Check",
]

export const EQUIPMENT_STATUS = [
  "Need repair",
  "Need calibration",
  "Missing",
  "Repairing",
  "Maintance",
  "No Flag",
]

export const fileSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16px" height="16px">    <path fill="currentColor" d="M13.172,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8.828c0-0.53-0.211-1.039-0.586-1.414l-4.828-4.828 C14.211,2.211,13.702,2,13.172,2z M18.5,9H13V3.5L18.5,9z"/></svg>`
export const unlinkSvg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" height="16px" width="16px" version="1.1" id="Layer_1" viewBox="0 0 326.157 326.157" xml:space="preserve">
<g id="XMLID_86_">
	<path fill="currentColor" id="XMLID_87_" d="M206.342,275.032c-8.284,0-15,6.716-15,15v21.124c0,8.284,6.716,15,15,15s15-6.716,15-15v-21.124   C221.342,281.748,214.626,275.032,206.342,275.032z"/>
	<path fill="currentColor" id="XMLID_88_" d="M311.158,191.343l-21.125-0.001c-0.001,0,0,0-0.001,0c-8.283,0-15,6.716-15,14.999   c0,8.284,6.715,15.001,14.999,15.001l21.125,0.001c0.001,0,0.001,0,0.001,0c8.283,0,15-6.716,15-14.999   C326.157,198.06,319.442,191.343,311.158,191.343z"/>
	<path fill="currentColor" id="XMLID_89_" d="M276.127,254.913c-5.858-5.858-15.355-5.857-21.214-0.001c-5.857,5.858-5.858,15.355-0.001,21.214   l14.938,14.938c2.93,2.93,6.768,4.394,10.607,4.394c3.838,0,7.678-1.465,10.607-4.393c5.857-5.858,5.858-15.355,0.001-21.214   L276.127,254.913z"/>
	<path fill="currentColor" id="XMLID_90_" d="M119.813,51.124c8.284,0,15-6.716,15-15V15c0-8.284-6.716-15-15-15c-8.284,0-15,6.716-15,15v21.124   C104.813,44.408,111.529,51.124,119.813,51.124z"/>
	<path fill="currentColor" id="XMLID_91_" d="M36.124,134.815c8.284,0,15-6.716,15-14.999c0-8.284-6.715-15.001-15-15.001L15,104.814h0   c-8.284,0-15,6.716-15,14.999C0,128.097,6.715,134.814,15,134.814L36.124,134.815C36.124,134.815,36.124,134.815,36.124,134.815z"/>
	<path fill="currentColor" id="XMLID_92_" d="M50.029,71.243c2.929,2.93,6.768,4.394,10.607,4.394c3.838,0,7.678-1.465,10.607-4.393   c5.858-5.858,5.858-15.355,0-21.214L56.305,35.092c-5.857-5.858-15.355-5.857-21.213-0.001c-5.858,5.858-5.858,15.355,0,21.214   L50.029,71.243z"/>
	<path fill="currentColor" id="XMLID_93_" d="M194.898,237.323c5.858-5.857,5.858-15.355,0-21.213c-5.857-5.857-15.355-5.857-21.213,0l-42.423,42.423   c-0.002,0.002-0.003,0.003-0.005,0.005c-17.543,17.545-46.092,17.546-63.638,0s-17.546-46.095,0-63.641l42.426-42.426   c5.858-5.857,5.858-15.355,0-21.213c-5.857-5.857-15.355-5.857-21.213,0l-42.426,42.426c-29.243,29.242-29.243,76.823,0,106.066   c14.166,14.166,33,21.967,53.032,21.967c0.001,0,0,0,0.001,0c20.032,0,38.867-7.802,53.032-21.967   c0.002-0.002,0.003-0.003,0.005-0.005L194.898,237.323z"/>
	<path fill="currentColor" id="XMLID_102_" d="M279.75,46.405c-14.164-14.165-32.998-21.967-53.031-21.967c-0.001,0,0.001,0-0.001,0   c-20.032,0-38.867,7.802-53.032,21.967l-0.001,0.001l-0.001,0.001l-42.425,42.426c-5.858,5.857-5.858,15.355,0,21.213   c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394l42.425-42.426l0.001-0.001l0.001-0.001   c8.499-8.499,19.8-13.18,31.82-13.18c12.02,0,23.319,4.681,31.818,13.18c17.546,17.546,17.546,46.095,0,63.641l-42.426,42.426   c-5.858,5.857-5.858,15.355,0,21.213c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394l42.426-42.426   C308.993,123.23,308.993,75.648,279.75,46.405z"/>
</g>
</svg>`

export const getTextColor = status => {
  if (status === "Approved") {
    return "#009321!important"
  } else if (status === "UnApproved") {
    return "#CC0C39!important"
  } else if (status === "Pending") {
    return "orange!important"
  }
  return "gray!important"
}

export const auditReportObj = {
  reportName: "",
  cm: "",
  auditorList: [],
  equipmentsWithIssue: [],
  startDate: "",
  endDate: "",
  requestBy: "",
  purpose: "",
}
