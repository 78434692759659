/**
 * A context-based hook that allows components to determine the view of the
 * "File Library" page. The API is the same as useContext.
 */

import React, { useState, useCallback, useContext, createContext } from "react"
import PropTypes from "prop-types"
import useLocalStorage from "./use-local-storage"
import { defaultEquipmentTableColumns } from "../data/equipment-table-column"

const EquipmentViewPreferencesContext = createContext()

export const EquipmentViewPreferencesProvider = props => {
  // Get view preferences out of local storage
  const [savedPreferences, persistPreferences] = useLocalStorage(
    "equipment-view-preferences"
  )

  // Initialize state value falling back to the default values defined above
  const [preferences, setPreferences] = useState(
    // Revive preference map from local storage if it exists
    savedPreferences
      ? new Map(savedPreferences)
      : new Map([
          ["stripes", false],
          ["columns", defaultEquipmentTableColumns],
        ])
  )

  // When the user changes the preferences, persist the value to local storage
  // and update the state value
  const changePreferences = useCallback(
    values => {
      setPreferences(values)
      // Convert the values Map to an array when putting into local storage
      // so it can be serialized with JSON.stringify
      persistPreferences(Array.from(values.entries()))
    },
    [persistPreferences]
  )

  return (
    <EquipmentViewPreferencesContext.Provider
      value={[preferences, changePreferences]}
    >
      {props.children}
    </EquipmentViewPreferencesContext.Provider>
  )
}

EquipmentViewPreferencesProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useEquipmentViewPreferences = () => useContext(EquipmentViewPreferencesContext)

export default useEquipmentViewPreferences
