/**
 * The application landing page/home page.
 */
import React, { useEffect, useState } from "react"
import * as xlsx from "xlsx"
// import Loader from "@amzn/meridian/loader"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import Tooltip from "@amzn/meridian/tooltip"
import { useSelector, useDispatch } from "react-redux"
import ButtonGroup, { ButtonOption } from "@amzn/meridian/button-group"
import { isAfter, addDays } from "date-fns"
import Tag from "@amzn/meridian/tag"

import Table, {
  TableRow,
  TableCell,
  TableActionBar,
} from "@amzn/meridian/table"
import Icon from "@amzn/meridian/icon"
import Link from "@amzn/meridian/link"
import Text from "@amzn/meridian/text"
import Badge from "@amzn/meridian/badge"
import SearchField from "@amzn/meridian/search-field"
import { useHistory } from "react-router-dom"

import filterTokens from "@amzn/meridian-tokens/base/icon/filter"
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large"
import plusCircleTokens from "@amzn/meridian-tokens/base/icon/plus-circle"
import copyTokens from "@amzn/meridian-tokens/base/icon/copy"
import cogIconTokens from "@amzn/meridian-tokens/base/icon/cog"
import deleteTokens from "@amzn/meridian-tokens/base/icon/delete"
import uploadLargeTokens from "@amzn/meridian-tokens/base/icon/upload-large"

import FilterTags from "../../components/file-library/filter-tag"
import { useFilters, usePagination, useSort, useEquipmentViewPreferences } from "../../hooks"
import equipmentTableColumns from "../../data/equipment-table-column"
import { Select, SelectOption } from "../../components/app/form-fields"
import PageLayout from "../../components/app/page-layout"
import EmptyState from "../../components/app/empty-state"
import StatusTag from "../../components/app/status-tag"
import { DeleteConfirmModal } from "../../components/app"

import {
  AddEquipmentModal,
  EditEquipmentModal,
  EquipmentFiltersModal,
  CertifiedOperatorListModal,
  EquipmentFileModal,
  CopyEquipmentModal,
  PreferenceModal,
  BatchImportEquipmentModal,
} from "./modals"
import {
  retrieveAllEquipment,
  deleteEquipment,
  selectCM,
} from "../../redux/equipment/action"
import { getRegex } from "../../util/Util"
import filterEquipment from "../../filter-equipment"

const getEquipCalibStatus = calibrationDate => {
  // if current date is after the calibration end date, it means it expired
  if (!calibrationDate) {
    return "N/A"
  }
  if (isAfter(new Date(), new Date(calibrationDate))) {
    return "expired"
  } else if (isAfter(addDays(new Date(), 30), new Date(calibrationDate))) {
    return "dueIn30Days"
  } else {
    return "normal"
  }
}

const getTagType = status => {
  switch (status) {
    case "normal":
      return "success"
    case "expired":
      return "error"
    case "dueIn30Days":
      return "warning"
    default:
      return "neutral"
  }
}

const EquipmentList = () => {
  const [addEquipmentModalOpen, setAddEquipmentModalOpen] = useState(false)
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false)
  const [preferenceModalOpen, setPreferenceModalOpen] = useState(false)

  const [currentEquipment, setCurrentEquipment] = useState()
  const [
    batchImportEquipmentModalOpen,
    setBatchImportEquipmentModalOpen,
  ] = useState(false)

  const [copyEquipmentModalOpen, setCopyEquipmentModalOpen] = useState(false)
  const [quickFilter, setQuickFilter] = useState("all")
  const { isCMUser, isAdmin } = useSelector(state => state.userReducer)
  // const [projectParam, setProjectParam] = useState()
  const { equipment, isLoading, cm, cmIndex } = useSelector(
    state => state.equipmentReducer
  )
  const [viewPreferences] = useEquipmentViewPreferences()
  const visibleColumns = viewPreferences.get("columns")

  const { locationList } = useSelector(state => state.locationReducer)

  const reduxDispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  // for searching and filtering
  const { filters, dispatch } = useFilters()
  const [keyword, setKeyword] = useState("")

  const data = filterEquipment(equipment, filters)
    .map(x => {
      x.calibrationStatus = getEquipCalibStatus(x.calibrationDate)
      return x
    })
    .filter(f => {
      let regex = getRegex(keyword)
      return regex.test(JSON.stringify(f))
    })

  const flaggedEquipment = data.filter(e => e.flag)
  const calibratedEquipment = data.filter(
    e =>
      e.calibrationStatus === "normal" || e.calibrationStatus === "dueIn30Days"
  )
  const dueIn30Equipment = data.filter(
    e => e.calibrationStatus === "dueIn30Days"
  )
  const unavailableEquipment = data.filter(
   e => !e.isApproved && !e.flag
  )

  const quickfilteredEquipment = () => {
    if (quickFilter === "flag") {
      return data.filter(e => e.flag)
    } else if (quickFilter === "calibrated") {
      return data.filter(
        e =>
          e.calibrationStatus === "normal" ||
          e.calibrationStatus === "dueIn30Days"
      )
    } else if (quickFilter === "dueIn30") {
      return data.filter(e => e.calibrationStatus === "dueIn30Days")
    } else if (quickFilter === "unavailable") {
      return data.filter(e => !e.isApproved && !e.flag)
    } else {
      return data
    }
  }

  const quickfilteredData = quickfilteredEquipment()

  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const { sortedData, onSort, sortColumn, sortDirection } = useSort(
    quickfilteredData
  )

  const history = useHistory()
  const { TableFooter, visibleIndices } = usePagination(
    quickfilteredData.length
  )

  const handleDeleteEquipment = data => {
    reduxDispatch(
      deleteEquipment({
        equipmentId: currentEquipment.equipmentId,
        cm: locationList[cmIndex]?.cm,
        testLocation: locationList[cmIndex]?.cmLocation,
      })
    )
  }

  useEffect(() => {
    reduxDispatch(
      retrieveAllEquipment({
        cm: locationList[cmIndex]?.cm,
        test_location: locationList[cmIndex]?.cmLocation,
      })
    )
  }, [cmIndex, locationList, reduxDispatch])

  return (
    <React.Fragment>
      <PageLayout
        spacing="large"
        alignmentHorizontal={"start"}
        title="Equipment List"
        loading={isLoading}
        width="100%"
      >
        <Row spacingInset="small none">
          <Column spacing="large">
            <Select
              label="CM Location"
              value={cmIndex}
              onChange={c => reduxDispatch(selectCM(c))}
              minWidth={240}
              size={"small"}
            >
              {locationList.map((x, index) => (
                <SelectOption
                  key={index}
                  value={index}
                  label={`${x.cm}-${x.cmLocation}`}
                />
              ))}
            </Select>
          </Column>
        </Row>
        {equipment?.length > 0 && (
          <Row>
            <Text>Quick Filters: </Text>
            <ButtonGroup
              value={quickFilter}
              onChange={setQuickFilter}
              size="small"
            >
              <ButtonOption value="all">{`All: ${equipment?.length}`}</ButtonOption>
              <ButtonOption value="flag">{`Flagged: ${flaggedEquipment?.length}`}</ButtonOption>
              <ButtonOption value="calibrated">{`Calibrated: ${calibratedEquipment?.length}`}</ButtonOption>
              <ButtonOption value="dueIn30">
                {`Calibration < 30: ${dueIn30Equipment?.length}`}
              </ButtonOption>
              <ButtonOption value="unavailable">{`Unavailable: ${unavailableEquipment?.length}`}</ButtonOption>
            </ButtonGroup>
          </Row>
        )}
        <Row spacingInset="small none">
          <Column spacing="large" width="100%">
            <Table
              headerRows={1}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              onSort={onSort}
              showDividers={true}
              spacing="small"
              // stickyHeaderRow={true}
              fixHeaderRows={true}
              // showStripes={true}
            >
              <TableActionBar widths={[300]}>
                <SearchField
                  value={keyword}
                  onChange={setKeyword}
                  placeholder="Search for..."
                  // size="medium"
                  type="text"
                  onSubmit={() => {}}
                />
                <Tooltip
                  position="top"
                  title="Filter equipment list"
                  id="filter"
                >
                  <Button
                    disabled={equipment?.length === 0}
                    onClick={() => setFilterModalOpen(true)}
                    type="icon"
                  >
                    {filters.size > 0 ? (
                      <Icon tokens={filterTokens}>
                        {`${filters.size} Filters`}
                        <Badge
                          type="theme"
                          value={filters.size}
                          locale="en-US"
                        />
                      </Icon>
                    ) : (
                      <Icon tokens={filterTokens}></Icon>
                    )}
                  </Button>
                </Tooltip>
                <Tooltip position="top" title="Copy equipment" id="copy">
                  <Button
                    disabled={
                      equipment?.length === 0 || (!isAdmin && !isCMUser)
                    }
                    type="icon"
                    onClick={() => setCopyEquipmentModalOpen(true)}
                  >
                    <Icon tokens={copyTokens}>Copy</Icon>
                  </Button>
                </Tooltip>
                <Tooltip
                  position="top"
                  title="Download table data"
                  id="download"
                >
                  <Button
                    disabled={equipment?.length === 0}
                    onClick={() => {
                      const wb = xlsx.utils.book_new()
                      const data = sortedData.map(s => {
                        // delete ids from data set
                        let {
                          calibrationReportId,
                          checklistId,
                          equipmentId,
                          intermediateCheckId,
                          trainingRecordId,
                          sopId,
                          maintenanceRecordId,
                          ...rest
                        } = s
                        return rest
                      })
                      const ws = xlsx.utils.json_to_sheet(data)
                      xlsx.utils.book_append_sheet(wb, ws, "sheet1")
                      xlsx.writeFile(wb, `${cm} Equipment List.xlsx`)
                    }}
                    type="icon"
                  >
                    <Icon tokens={downloadLargeTokens}></Icon>
                  </Button>
                </Tooltip>
                <Tooltip
                  position="top"
                  title="Add new equipment"
                  id="myTooltip"
                >
                  <Button
                    type="icon"
                    disabled={!isAdmin && !isCMUser}
                    onClick={() => setAddEquipmentModalOpen(true)}
                  >
                    <Icon tokens={plusCircleTokens}>Save</Icon>
                  </Button>
                </Tooltip>
                <Tooltip
                  position="top"
                  title="Update table preference"
                  id="myTooltip"
                >
                  <Button
                  type="icon"
                  onClick={() => setPreferenceModalOpen(true)}
                >
                  <Icon tokens={cogIconTokens}>Settings</Icon>
                </Button>
                </Tooltip>
              </TableActionBar>
              <FilterTags stick={true} />
              <TableRow>
                <TableCell sortColumn="equipmentName">Equipment</TableCell>
                <TableCell sortColumn="category">Category</TableCell>
                {equipmentTableColumns
                  .filter(({ id }) => visibleColumns[id])
                  .map(header => (
                    <TableCell
                      key={header.id}
                      sortColumn={header.id}
                      // width={header.width}
                      // alignmentHorizontal={header.alignment}
                    >
                      {header.label}
                    </TableCell>
                  ))}
                <TableCell>Status</TableCell>
                <TableCell>Cal Date</TableCell>
                <TableCell>{"<30 days"}</TableCell>
                <TableCell>Capability</TableCell>
                <TableCell>Coverage</TableCell>
                {isAdmin ? <TableCell>Delete</TableCell> : ""}
              </TableRow>
              {sortedData &&
                sortedData
                  .slice(visibleIndices[0], visibleIndices[1])
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Link
                          onClick={() => {
                            history.push(
                              `equipment-details?cm=${locationList[cmIndex]?.cm}&testLocation=${locationList[cmIndex]?.cmLocation}&equipmentId=${row.equipmentId}`
                            )
                          }}
                        >
                          {row.equipmentName}
                        </Link>
                        {row.label || row.serialNumber || row.brand ? (
                          <p>
                            {row.label && (
                              <Text className="inline-text">{`Label: ${row.label}; `}</Text>
                            )}
                            {row.serialNumber && (
                              <Text className="inline-text">{`SN: ${row.serialNumber}; `}</Text>
                            )}
                            {row.brand && (
                              <Text className="inline-text">{`Brand: ${row.brand}; `}</Text>
                            )}
                          </p>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell>{row.category}</TableCell>
                      {visibleColumns.calibrationScore && (
                        <TableCell>{row?.latestScore?.calibrationScore}</TableCell>
                      )}
                      {visibleColumns.capabilityScore && (
                      <TableCell>{row?.latestScore?.capabilityScore}</TableCell>
                      )}
                      {visibleColumns.checklistScore && (
                      <TableCell>{row?.latestScore?.checklistScore}</TableCell>
                      )}
                      {visibleColumns.sopScore && (
                      <TableCell>{row?.latestScore?.sopScore}</TableCell>
                      )}
                      <TableCell>
                        <StatusTag
                          status={
                            row.isApproved
                              ? "Available"
                              : row.flag
                              ? row.flag
                              : "Unavailable"
                          }
                        />
                      </TableCell>
                      <TableCell>{row.calibrationDate}</TableCell>
                      <TableCell>
                        <Tag type={getTagType(row.calibrationStatus)} />
                      </TableCell>
                      <TableCell>
                        <span style={{ whiteSpace: "pre-line" }}>
                          {row.equipmentCapacity}
                        </span>
                      </TableCell>
                      <TableCell>{row.equipmentCoverage}</TableCell>
                      {isAdmin ? (
                        <TableCell>
                          <Text>
                            <Link
                              disabled={!isAdmin}
                              onClick={() => {
                                setCurrentEquipment(row)
                                setDeleteConfirmModalOpen(true)
                              }}
                            >
                              <Icon tokens={deleteTokens} />
                            </Link>
                          </Text>
                        </TableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  ))}
            </Table>
            {sortedData && sortedData.length === 0 ? (
              <EmptyState target={"equipment"} />
            ) : null}
            <TableFooter />
          </Column>
        </Row>

        <AddEquipmentModal
          addEquipmentModalOpen={addEquipmentModalOpen}
          setAddEquipmentModalOpen={setAddEquipmentModalOpen}
          cm={locationList[cmIndex]?.cm}
          cmLocation={locationList[cmIndex]?.cmLocation}
        />
        <BatchImportEquipmentModal
          batchImportEquipmentModalOpen={batchImportEquipmentModalOpen}
          setBatchImportEquipmentModalOpen={setBatchImportEquipmentModalOpen}
        />
        <CopyEquipmentModal
          copyEquipmentModalOpen={copyEquipmentModalOpen}
          setCopyEquipmentModalOpen={setCopyEquipmentModalOpen}
          cm={locationList[cmIndex]?.cm}
          cmLocation={locationList[cmIndex]?.cmLocation}
        />
        <EquipmentFiltersModal
          filterModalOpen={filterModalOpen}
          setFilterModalOpen={setFilterModalOpen}
        />
        <DeleteConfirmModal
          modalOpen={deleteConfirmModalOpen}
          setModalOpen={setDeleteConfirmModalOpen}
          action={handleDeleteEquipment}
          message={"Are you sure you want to delete this equipment?"}
        />
        <PreferenceModal
          preferenceModalOpen={preferenceModalOpen}
          setPreferenceModalOpen={setPreferenceModalOpen}
        />
      </PageLayout>
    </React.Fragment>
  )
}

export default EquipmentList
