import { createStore, applyMiddleware } from "redux"
import createSagaMiddleware from "redux-saga"
import logger from "redux-logger"
import rootReducer from "./reducer"
import rootSaga from "./saga"

const sagaMiddleware = createSagaMiddleware()

export default function configureStore() {
  // let middlewareToUse = null
  let middlewares = []
  middlewares.push(sagaMiddleware)

  if (process.env.NODE_ENV === "production") {
    // middlewareToUse = compose(applyMiddleware(...middlewares))
  } else {
    middlewares.push(logger)
    // middlewareToUse = compose(applyMiddleware(...middlewares))
  }

  const store = createStore(
    rootReducer,
    applyMiddleware(sagaMiddleware, logger)
  )

  sagaMiddleware.run(rootSaga)

  return store
}
