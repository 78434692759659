import { put, takeLatest } from "redux-saga/effects"
import APIPromises from "../../../util/APIPromises"
import {
  retrieveFilesBySOPSuccess,
  retrieveFilesBySOPFailure,
  retrieveFilesByTrainingSuccess,
  retrieveFilesByTrainingFailure,
  retrieveEquipmentSuccess,
  retrieveEquipmentFailure,
  retrieveCalibratedEquipsSuccess,
  retrieveCalibratedEquipsFailure,
  retrieveProjectSuccess,
  retrieveProjectFailure,
  archiveProjectSuccess,
  archiveProjectFailure,
  updateProjectSuccess,
  updateProjectFailure,
  addProjectSuccess,
  addProjectFailure,
  retrieveProjectFilesByTypeSuccess,
  retrieveProjectFilesByTypeFailure,
  retrieveFilesByAssessmentFailure,
  retrieveFilesByAssessmentSuccess,
} from "../action"

function* retrieveFilesBySOP(req) {
  try {
    const response = yield APIPromises.retrieveFilesByFileType({
      ...req.payload,
      fileType: "sop",
      fileLevel: "project",
    })
    if (response.data?.isSuccess === false || response.data?.errorMessage) {
      yield put(retrieveFilesBySOPFailure())
    } else {
      const allFiles = response.data.RetrieveFilesByFileTypeResponse
      yield put(retrieveFilesBySOPSuccess(allFiles))
    }
  } catch (error) {
    yield put(retrieveFilesBySOPFailure(error))
  }
}

export function* retrieveSopFileWatcher() {
  yield takeLatest("RETRIEVE_FILE_BY_SOP_TYPE", retrieveFilesBySOP)
}

function* retrieveFilesByAssessment(req) {
  try {
    const response = yield APIPromises.retrieveFilesByFileType({
      ...req.payload,
      fileType: "selfAssessment",
      fileLevel: "project",
    })
    if (response.data?.isSuccess === false || response.data?.errorMessage) {
      yield put(retrieveFilesByAssessmentFailure())
    } else {
      const allFiles = response.data.RetrieveFilesByFileTypeResponse
      yield put(retrieveFilesByAssessmentSuccess(allFiles))
    }
  } catch (error) {
    yield put(retrieveFilesByAssessmentFailure(error))
  }
}

export function* retrieveAssessmentFileWatcher() {
  yield takeLatest(
    "RETRIEVE_FILE_BY_ASSESSMENT_TYPE",
    retrieveFilesByAssessment
  )
}

function* retrieveFilesByTraining(req) {
  try {
    const response = yield APIPromises.retrieveFilesByFileType({
      ...req.payload,
      fileType: "trainingRecord",
      fileLevel: "project",
    })
    if (response.data?.isSuccess === false || response.data?.errorMessage) {
      yield put(retrieveFilesByTrainingFailure())
    } else {
      const allFiles = response.data.RetrieveFilesByFileTypeResponse
      yield put(retrieveFilesByTrainingSuccess(allFiles))
    }
  } catch (error) {
    yield put(retrieveFilesByTrainingFailure(error))
  }
}

export function* retrieveFilesByTrainingWatcher() {
  yield takeLatest("RETRIEVE_FILE_BY_TRAINING_TYPE", retrieveFilesByTraining)
}
function* retrieveEquipment(req) {
  try {
    const response = yield APIPromises.retrieveEquipment(
      req.projectName,
      req.cmName
    )
    const equipment = response.data.retrieveEquipsByProjectResponse
    yield put(retrieveEquipmentSuccess(equipment))
  } catch (error) {
    yield put(retrieveEquipmentFailure(error))
  }
}

export function* retrieveEquipmentWatcher() {
  yield takeLatest("RETRIEVE_EQUIPMENT", retrieveEquipment)
}
function* retrieveCalibratedEquips(req) {
  try {
    const response = yield APIPromises.retrieveCalibratedEquips(
      req.cmName,
      req.cmLocation
    )
    const equipment = response.data.RetrieveCalibratedEquipResponse
    yield put(retrieveCalibratedEquipsSuccess(equipment))
  } catch (error) {
    yield put(retrieveCalibratedEquipsFailure(error))
  }
}

export function* retrieveCalibratedEquipsWatcher() {
  yield takeLatest("RETRIEVE_CALIBRATED_EQUIPS", retrieveCalibratedEquips)
}

function* retrieveProject(req) {
  try {
    const response = yield APIPromises.retrieveProject(req.cmName)
    const project = response.data.retrieveProjectsByCMResponse
    yield put(retrieveProjectSuccess(project))
  } catch (error) {
    yield put(retrieveProjectFailure(error))
  }
}

export function* retrieveProjectWatcher() {
  yield takeLatest("RETRIEVE_PROJECT", retrieveProject)
}

function* archiveProject(req) {
  try {
    const res = yield APIPromises.archiveProject(req.payload)
    if (res.data && res.data.isSuccess === false) {
      yield put(archiveProjectFailure())
    } else {
      yield put({
        type: "RETRIEVE_PROJECT",
        cmName: req.cm,
      })
      yield put(archiveProjectSuccess())
    }
  } catch (error) {
    yield put(archiveProjectFailure(error))
  }
}

export function* archiveProjectWatcher() {
  yield takeLatest("ARCHIVE_PROJECT", archiveProject)
}

function* updateProject(req) {
  try {
    const res = yield APIPromises.updateProject(req.payload)
    if (res.data && res.data.isSuccess === false) {
      yield put(updateProjectFailure())
    } else {
      yield put({
        type: "RETRIEVE_PROJECT",
        cmName: req.payload?.cm,
      })
      yield put(updateProjectSuccess())
    }
  } catch (error) {
    yield put(updateProjectFailure(error))
  }
}

export function* updateProjectWatcher() {
  yield takeLatest("UPDATE_PROJECT", updateProject)
}

function* addProject(req) {
  try {
    const res = yield APIPromises.addProject(req.payload)
    if (res.data && res.data.isSuccess === false) {
      yield put(addProjectFailure())
    } else {
      yield put({
        type: "RETRIEVE_PROJECT",
        cmName: req.payload.cm,
      })
      const addProjectStatus = res.data
      yield put(addProjectSuccess(addProjectStatus))
    }
  } catch (error) {
    yield put(addProjectFailure(error))
  }
}
export function* addProjectWatcher() {
  yield takeLatest("ADD_PROJECT", addProject)
}

function* retrieveProjectFilesByType(req) {
  try {
    const response = yield APIPromises.retrieveFilesByFileType({
      ...req.payload,
      fileLevel: "project",
    })
    const allFiles = response.data.RetrieveFilesByFileTypeResponse
    yield put(retrieveProjectFilesByTypeSuccess(allFiles))
  } catch (error) {
    yield put(retrieveProjectFilesByTypeFailure(error))
  }
}

export function* retrieveProjectFilesByTypeWatcher() {
  yield takeLatest("RETRIEVE_PROJECT_FILES_BY_TYPE", retrieveProjectFilesByType)
}
