import React, { useState, useEffect } from "react"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import { useDispatch, useSelector } from "react-redux"
import Checkbox from "@amzn/meridian/checkbox"
import Column from "@amzn/meridian/column"
import Text from "@amzn/meridian/text"
import {
  retrieveFilesBySOP,
  retrieveFilesByAssessment,
  retrieveFilesByTraining,
  retrieveCalibratedEquips,
  updateProject,
} from "../../../redux/project/action"
import { FormFilePicker, FormInput } from "../../../components/app"
import { Select } from "../../../components/app/form-fields"

const EditProjectModal = props => {
  const {
    editProjectModalOpen,
    setEditProjectModalOpen,
    currentData,
    cm,
  } = props
  const equipmentList = useSelector(state => state.projectReducer.calibrated)
  const projectName = currentData?.projectName
  const cmLocation = currentData?.cmLocation

  const { sopFiles, trainingFiles, assessmentFiles } = useSelector(
    state => state.projectReducer
  )

  const { username, isCMUser } = useSelector(state => state.userReducer)

  const [sop, setSop] = useState([])
  const [training, setTraining] = useState([])
  const [assessment, setAssessment] = useState()

  const [amazonScore, setAmazonScore] = useState()

  const [equipmentIds, setEquipmentIds] = useState([])
  const [equipmentNames, setEquipmentNames] = useState([])
  const reduxDispatch = useDispatch()

  const resetState = () => {
    setSop([])
    setTraining([])
    setAssessment()
    setAmazonScore()
    setEquipmentIds([])
    setEquipmentNames([])
    setEditProjectModalOpen(false)
  }

  useEffect(() => {
    if (editProjectModalOpen) {
      reduxDispatch(retrieveFilesBySOP({ cm }))
      reduxDispatch(retrieveFilesByTraining({ cm }))
      reduxDispatch(retrieveFilesByAssessment({ cm }))
      reduxDispatch(retrieveCalibratedEquips(cm, cmLocation))
    }
  }, [reduxDispatch, editProjectModalOpen, cm, cmLocation])
  return (
    <Modal
      title="Edit Project"
      open={editProjectModalOpen}
      onClose={() => {
        resetState()
      }}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
      width="600px"
    >
      <form>
        <Row spacingInset="small none">
          <Column minWidth="150px">
            <Text tag="label" type="b200" color="primary" htmlFor="my-input">
              Select Equipment
            </Text>
          </Column>
          <Column minWidth="250px">
            <Select size="small" valueLabel={_ => equipmentNames.join(", ")}>
              {equipmentList &&
                equipmentList.map((item, i) => (
                  <Checkbox
                    checked={equipmentIds.some(i => i === item.equipId)}
                    key={item.equipId}
                    onChange={checked => {
                      let equipArray = equipmentIds
                      let newEquipNames = equipmentNames
                      if (checked) {
                        if (!equipmentIds.some(i => i === item.equipId)) {
                          equipArray.push(item.equipId)
                          newEquipNames.push(item.equipName)
                        }
                      } else {
                        equipArray = equipArray.filter(x => x !== item.equipId)
                        newEquipNames = newEquipNames.filter(
                          x => x !== item.equipName
                        )
                      }
                      setEquipmentIds([...equipArray])
                      setEquipmentNames([...newEquipNames])
                    }}
                  >
                    {item.equipName}
                  </Checkbox>
                ))}
            </Select>
          </Column>
        </Row>
        {!isCMUser && (
          <FormInput
            value={amazonScore}
            setValue={setAmazonScore}
            label={"Amazon Audit Score"}
          />
        )}
        <FormFilePicker
          value={assessment}
          setValue={setAssessment}
          files={assessmentFiles}
          disabled={!cmLocation}
          label={"Self-assessment"}
          callback={() => reduxDispatch(retrieveFilesByAssessment({ cm }))}
          fileParam={{
            file_level: "project",
            file_type: "selfAssessment",
            cm: cm,
            cm_location: cmLocation,
            uploaded_by: username,
            project_name: projectName,
          }}
        />
        <FormFilePicker
          value={sop}
          setValue={setSop}
          files={sopFiles}
          disabled={!cmLocation}
          label={"Project SOP"}
          callback={() => reduxDispatch(retrieveFilesBySOP({ cm }))}
          fileParam={{
            file_level: "project",
            file_type: "sop",
            cm: cm,
            cm_location: cmLocation,
            uploaded_by: username,
            project_name: projectName,
          }}
        />
        <FormFilePicker
          value={training}
          disabled={!cmLocation}
          setValue={setTraining}
          files={trainingFiles}
          label={"Training Record"}
          callback={() => reduxDispatch(retrieveFilesByTraining({ cm }))}
          fileParam={{
            file_level: "project",
            file_type: "trainingRecord",
            cm: cm,
            cm_location: cmLocation,
            uploaded_by: username,
            project_name: projectName,
          }}
        />
      </form>
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            type="secondary"
            size="small"
            onClick={() => {
              setEditProjectModalOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              reduxDispatch(
                updateProject({
                  cm: cm,
                  projectName: projectName,
                  equipmentIds: equipmentIds,
                  totalScore: parseFloat(amazonScore),
                  selfAssessmentFileId: assessment,
                  sopFileIds: sop,
                  trainingRecordFileIds: training,
                })
              )
              resetState()
            }}
          >
            Save
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default EditProjectModal
