import React, { useEffect, useState } from "react"
import Row from "@amzn/meridian/row"
import Column from "@amzn/meridian/column"
import Button from "@amzn/meridian/button"
import Text from "@amzn/meridian/text"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import Checkbox from "@amzn/meridian/checkbox"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import {
  auditBlockerOptions,
  auditCategoryOptions, auditIssueStatusOptions,
  auditIssueTypeOptions,
  auditPriorityOptions,
  auditStatusOptions,
} from "../../../data/options"
import {
  SUCCESS_TIMEOUT_IN_MS,
  FAILURE_TIMEOUT_IN_MS,
} from "../../../data/constants"
import { openToast } from "../../../api/toasts"
import env from "../../../services/Auth/env"
import {
  FormSelect,
  FormDatePicker,
  FormTextArea,
} from "../../../components/app"
import {
  Input,
  Select,
  SelectOption,
} from "../../../components/app/form-fields"
import {formatDate, getLocationsByCmName} from "../../../util/Util"
import * as auth from "../../../services/Auth/auth"
import { retrieveAllEquipment } from "../../../redux/equipment/action"
import { retrieveAuditIssues } from "../../../redux/audit/action"
import AddAuditFileModal from "./add-audit-file-modal"

const AddAuditIssuesModal = props => {
  const [location, setLocation] = useState()
  const [isClosed, setIsClosed] = useState(false)
  const [blocker, setBlocker] = useState()
  const [createdDate, setCreatedDate] = useState(
    new Date().toISOString().slice(0, 10)
  )
  const [ecd, setEcd] = useState("")
  const [remarks, setRemarks] = useState("")
  const [issueType, setIssueType] = useState("")
  const [addAuditFileModalOpen, setAddAuditFileModalOpen] = useState(false)
  const [action, setAction] = useState(null)
  // const [equipmentIds, setEquipmentIds] = useState([])
  // const [equipmentNames, setEquipmentNames] = useState([])
  const [selectedEquipments, setSelectedEquipments] = useState([])
  const [priority, setPriority] = useState()
  const [description, setDescription] = useState()
  const [fileList, setFileList] = useState([])
  const { username } = useSelector(state => state.userReducer)
  const reduxDispatch = useDispatch()

  // const cms = useSelector(state => state.commonReducer.cms)
  const {
    addAuditModalOpen,
    setAddAuditModalOpen,
    project,
    cm,
    onAddSuccess,
    auditReportId,
    equipment,
  } = props

  // useEffect(() => {
  //   reduxDispatch(retrieveAllEquipment())
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const resetState = () => {
    setDescription("")
    // setEquipmentIds([])
    setSelectedEquipments([])
    setCreatedDate(new Date().toISOString().slice(0, 10))
    setAddAuditModalOpen(false)
    setFileList([])
    setAction(null)
    setEcd("")
    setIssueType(null)
    setIsClosed(false)
    setPriority(null)
    setRemarks("")
  }

  const parseEquipmentList = () => {
    let equipObj = []
    for (const selectedEquipment of selectedEquipments) {
      equipObj.push({ equipmentId: selectedEquipment })
    }
    return equipObj
  }

  const addFiles = selectedFileList => {
    let newFileList = []
    selectedFileList.map(catFile =>
      catFile.map(file => newFileList.push(file.fileId))
    )
    setFileList(newFileList)
  }

  return (
    <Modal
      title="Add Audit Issue"
      open={addAuditModalOpen}
      onClose={resetState}
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
      scrollContainer="viewport"
    >
      <form>
        {/*<FormSelect*/}
        {/*  value={location}*/}
        {/*  setValue={setLocation}*/}
        {/*  label={"Location"}*/}
        {/*  options={getLocationsByCmName(cms, cm).map(x => ({*/}
        {/*    value: x,*/}
        {/*    label: x,*/}
        {/*  }))}*/}
        {/*/>*/}
        <Row spacingInset="small none">
          <Column minWidth="150px">
            <Text tag="label" type="b200" color="primary" htmlFor="my-input">
              Select Equipment
            </Text>
          </Column>
          <Column minWidth="250px">
            {/*<Select size="small" valueLabel={_ => equipmentNames.join(",")}>*/}
            {/*  {equipment &&*/}
            {/*    equipment.map((item, i) => {*/}
            {/*      console.log(item)*/}
            {/*      return (*/}
            {/*        <Checkbox*/}
            {/*          checked={equipmentIds.some(i => i === item.equipmentId)}*/}
            {/*          key={item.equipmentId + i}*/}
            {/*          onChange={checked => {*/}
            {/*            let equipArray = equipmentIds*/}
            {/*            let newEquipNames = equipmentNames*/}
            {/*            if (checked) {*/}
            {/*              if (!equipmentIds.some(i => i === item.equipmentId)) {*/}
            {/*                equipArray.push(item.equipmentId)*/}
            {/*                newEquipNames.push(item.equipmentName)*/}
            {/*              }*/}
            {/*            } else {*/}
            {/*              equipArray = equipArray.filter(*/}
            {/*                x => x !== item.equipmentId*/}
            {/*              )*/}
            {/*              newEquipNames = newEquipNames.filter(*/}
            {/*                x => x !== item.equipmentName*/}
            {/*              )*/}
            {/*            }*/}
            {/*            setEquipmentIds([...equipArray])*/}
            {/*            setEquipmentNames([...newEquipNames])*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          {item.name}*/}
            {/*        </Checkbox>*/}
            {/*      )*/}
            {/*    })}*/}
            <Select
              value={selectedEquipments}
              onChange={val => setSelectedEquipments(val)}
              // placeholder="Select Audit Test location"
              minWidth={240}
              size="small"
            >
              {equipment.map((x, index) => (
                <SelectOption
                  key={index}
                  value={x.equipmentId}
                  label={`${x.equipmentName}`}
                />
              ))}
            </Select>
          </Column>
        </Row>
        <FormTextArea
          value={description}
          setValue={setDescription}
          placeholder="Enter value..."
          label="Description"
        />
        <FormSelect
          value={issueType}
          setValue={setIssueType}
          label={"Issue Type"}
          options={auditIssueTypeOptions}
        />
        <FormSelect
          value={priority}
          setValue={setPriority}
          label={"Priority"}
          options={auditPriorityOptions}
        />
        <FormSelect
          value={isClosed}
          setValue={setIsClosed}
          label={"Status"}
          options={auditIssueStatusOptions}
        />
        {/*<FormDatePicker*/}
        {/*    value={createdDate}*/}
        {/*    setValue={setCreatedDate}*/}
        {/*    label="Created Date"*/}
        {/*/>*/}
        <FormDatePicker value={ecd} setValue={setEcd} label={"ECD"} />
        <FormTextArea value={action} setValue={setAction} label={"Actions"} />
        <Button
          type="link"
          onClick={() => {
            setAddAuditFileModalOpen(true)
          }}
        >
          + Add Attachment
        </Button>
        <FormTextArea
          value={remarks}
          setValue={setRemarks}
          placeholder="Add Remarks..."
          label="Remarks"
        />
      </form>
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit" height={100}>
          <Button
            minWidth={100}
            type="primary"
            size="small"
            disabled={!description || !issueType || !Number.isInteger(priority)}
            onClick={() => {
              let addData = {
                description: description,
                cm: cm,
                testLocation: location,
                equipmentsWithIssue: parseEquipmentList(),
                priority: priority,
                isClosed: isClosed,
                // ticketLink: ticketText,
                createdDate: createdDate,
                createdBy: username,
                auditReportId: auditReportId,
                resolvedDate: new Date().toISOString().slice(0, 10),
                resolvedBy: username,
                action: action,
                category: issueType,
                ecd: ecd,
                remarks: remarks,
                fileIds: fileList,
                // actionUpdateDate: new Date().toISOString().slice(0, 10),
              }
              auth.getToken().then(cookie => {
                axios(env.API_ENDPOINT + env.APIPath.addAuditIssues, {
                  method: "POST",
                  headers: { idToken: cookie },
                  data: addData,
                })
                  .then(response => {
                    if (response?.isSuccess === false) {
                      openToast({
                        type: "error",
                        title: "Add failed",
                        message: "Failed to add the issue, please retry",
                        timeout: FAILURE_TIMEOUT_IN_MS,
                      })
                    } else {
                      openToast({
                        type: "success",
                        title: "Added!",
                        message: "Added an issue successfully",
                        timeout: SUCCESS_TIMEOUT_IN_MS,
                      })
                      onAddSuccess()
                      resetState()
                    }
                  })
                  .catch(error => {
                    console.warn("error", error)
                    openToast({
                      type: "error",
                      title: "Add failed",
                      message: "Failed to add the issue, please retry",
                      timeout: FAILURE_TIMEOUT_IN_MS,
                    })
                  })
              })
            }}
          >
            Add &#62;
          </Button>
        </Row>
      </ModalFooter>
      <AddAuditFileModal
        addAuditFileModalOpen={addAuditFileModalOpen}
        setAddAuditFileModalOpen={setAddAuditFileModalOpen}
        callback={addFiles}
      />
    </Modal>
  )
}

export { AddAuditIssuesModal }
