import React, { useState, useEffect } from "react"

import Modal, { ModalFooter } from "@amzn/meridian/modal"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import { useDispatch, useSelector } from "react-redux"
import { FormDatePicker, FormInput, FormSelect } from "../../../components/app"
import { addLabPersonnel, editLabPersonnel } from "../../../redux/labPersonnel/action"

const userTypeOptions = [
  {
    value: "Manager",
    label: "Manager",
  },
  {
    value: "Lead",
    label: "Lead",
  },
  {
    value: "Engineer",
    label: "Engineer",
  },
  {
    value: "Operator",
    label: "Operator",
  },
]

const AddLabPersonnelModal = ({
  modalOpen, setModalOpen, mode, currentData
}) => {
  const [personnel, setPersonnel] = useState({})

  const reduxDispatch = useDispatch()
  const { cmIndex } = useSelector(state => state.labPersonnelReducer)
  const { locationList } = useSelector(state => state.locationReducer)


  useEffect(() => {
    setPersonnel(currentData)
  }, [currentData, setPersonnel])

  return (
    <form>
      <Modal
        title="Add/Edit"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        scrollContainer="viewport"
        closeLabel="Close"
        describedById="modal-description"
        bodySpacingInset="medium"
      >
        <form>
          <FormInput value={personnel.name} setValue={value => setPersonnel({ ...personnel, name: value})} label={"Name"} />
          <FormInput
            value={personnel.email}
            setValue={value => setPersonnel({...personnel, email: value})}
            label={"Email(Optional)"}
          />
          <FormSelect
            value={personnel.type}
            setValue={value => setPersonnel({...personnel, type: value})}
            label={"Type"}
            options={userTypeOptions}
          />
          <FormDatePicker
            value={personnel.onboardDate}
            setValue={value => setPersonnel({...personnel, onboardDate: value})}
            label="OnBoard Date"
          />
          <FormDatePicker
            value={personnel.leaveDate}
            setValue={value => setPersonnel({...personnel, leaveDate: value})}
            label="Leave"
          />
        </form>
        <ModalFooter>
          <Row alignmentHorizontal="right" widths="fit">
            <Button
              disabled={!personnel.name || !personnel.type || !personnel.onboardDate}
              type="primary"
              size="small"
              onClick={() => {
                if (mode === "add") {
                  reduxDispatch(
                    addLabPersonnel({
                      cm: locationList[cmIndex].cm,
                      cmLocation: locationList[cmIndex].cmLocation,
                      labPersonnel: personnel,
                    })
                  )
                } else {
                  reduxDispatch(
                    editLabPersonnel({
                      cm: locationList[cmIndex].cm,
                      cmLocation: locationList[cmIndex].cmLocation,
                      labPersonnelItem: personnel,
                    })
                  )
                }
                setModalOpen(false)
              }}
            >
              {mode === "edit" ? "Update" : "Add"}
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </form>
  )
}

export default AddLabPersonnelModal
