import React, { useState } from "react"
// import {useHistory} from "react-router-dom";
import Row from "@amzn/meridian/row"
// import Checkbox from "@amzn/meridian/checkbox";
import Button from "@amzn/meridian/button"
// import Column from "@amzn/meridian/column"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import { useDispatch, useSelector } from "react-redux"
import { addProject } from "../../../redux/project/action"
import { FormSelect, FormInput } from "../../../components/app"
import { getLocationsByCmName } from "../../../util/Util"
import { projectFamilyOptions } from "../../../data/options"

const AddProjectModal = props => {
  const { addProjectModalOpen, setAddProjecteModalOpen, cm } = props
  const [projectFamily, setProjectFamily] = useState()
  const [location, setLocation] = useState()
  const [projectName, setProjectName] = useState("")
  const cms = useSelector(state => state.commonReducer.cms)

  const resetState = () => {
    setProjectFamily()
    setLocation()
    setProjectName()
    setAddProjecteModalOpen(false)
  }

  const reduxDispatch = useDispatch()
  return (
    <Modal
      title="Add Project"
      open={addProjectModalOpen}
      onClose={() => resetState()}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
    >
      <form>
        <FormSelect
          value={location}
          setValue={setLocation}
          label={"Location"}
          options={getLocationsByCmName(cms, cm).map(x => ({
            value: x,
            label: x,
          }))}
        />
        <FormSelect
          value={projectFamily}
          setValue={setProjectFamily}
          label={"Project Family"}
          options={projectFamilyOptions}
        />
        <FormInput
          value={projectName}
          setValue={setProjectName}
          label={"Project Name"}
        />
      </form>
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            disabled={!location || !projectFamily || !projectName}
            type="primary"
            size="small"
            onClick={() => {
              reduxDispatch(
                addProject({
                  cm,
                  cmLocation: location,
                  projectFamily,
                  projectName,
                })
              )
              resetState()
            }}
          >
            Add
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default AddProjectModal
