import { put, takeLatest } from "redux-saga/effects"
import APIPromises from "../../../util/APIPromises"
import {
  addLabPersonnelFailure,
  addLabPersonnelSuccess,
  editLabPersonnelFailure,
  editLabPersonnelSuccess,
  calculateTurnoverRateFailure,
  calculateTurnoverRateSuccess,
  deleteLabPersonnelFailure,
  retrieveLabPersonnelFailure,
  retrieveLabPersonnelSuccess,
} from "../action"

function* retrieveLabPersonnel(req) {
  try {
    const res = yield APIPromises.retrieveLabPersonnel(req.payload)
    yield put(retrieveLabPersonnelSuccess(res.data || []))
  } catch (error) {
    yield put(retrieveLabPersonnelFailure(error))
  }
}

export function* retrieveLabPersonnelWatcher() {
  yield takeLatest("RETRIEVE_LAB_PERSONNEL", retrieveLabPersonnel)
}

function* addLabPersonnel(req) {
  try {
    const res = yield APIPromises.addLabPersonnel(req.payload)
    if (res.data && res.data.isSuccess) {
      yield put({
        type: "RETRIEVE_LAB_PERSONNEL",
        payload: {
          cm: req.payload.cm,
          test_location: req.payload.cmLocation,
        },
      })
      yield put(addLabPersonnelSuccess(res.data))
    } else {
      yield put(retrieveLabPersonnelFailure())
    }
  } catch (error) {
    yield put(addLabPersonnelFailure(error))
  }
}

export function* addLabPersonnelWatcher() {
  yield takeLatest("ADD_LAB_PERSONNEL", addLabPersonnel)
}

function* editLabPersonnel(req) {
  try {
    const res = yield APIPromises.editLabPersonnel(req.payload)
    if (res.data && res.data.isSuccess) {
      yield put({
        type: "RETRIEVE_LAB_PERSONNEL",
        payload: {
          cm: req.payload.cm,
          test_location: req.payload.cmLocation,
        },
      })
      yield put(editLabPersonnelSuccess(res.data))
    } else {
      yield put(retrieveLabPersonnelFailure())
    }
  } catch (error) {
    yield put(editLabPersonnelFailure(error))
  }
}

export function* editLabPersonnelWatcher() {
  yield takeLatest("EDIT_LAB_PERSONNEL", editLabPersonnel)
}

function* deleteLabPersonnel(req) {
  try {
    const res = yield APIPromises.deleteLabPersonnel(req.payload)
    if (res.data && res.data.isSuccess) {
      yield put({
        type: "RETRIEVE_LAB_PERSONNEL",
        payload: {
          cm: req.payload.cm,
          test_location: req.payload.cmLocation,
        },
      })
      yield put(retrieveLabPersonnelSuccess(res.data))
    } else {
      yield put(retrieveLabPersonnelFailure())
    }
  } catch (error) {
    yield put(deleteLabPersonnelFailure(error))
  }
}

export function* deleteLabPersonnelWatcher() {
  yield takeLatest("DELETE_LAB_PERSONNEL", deleteLabPersonnel)
}

export function* calculateTurnoverRate(req) {
  try {
    const res = yield APIPromises.calculateTurnoverRate(req.payload)
    if (res.data.turnoverRate || res.data.turnoverRate === 0) {
      yield put(
        calculateTurnoverRateSuccess(
          Math.round(10 * res.data.turnoverRate) / 10
        )
      )
    } else {
      yield put(calculateTurnoverRateSuccess("N/A"))
    }
  } catch (error) {
    yield put(calculateTurnoverRateFailure(error))
  }
}

export function* calculateTurnoverRateWatcher() {
  yield takeLatest("CALCULATE_TURNOVER_RATE", calculateTurnoverRate)
}
