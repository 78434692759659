import React, { Fragment, useState, useEffect } from "react"
import Card from "@amzn/meridian/card"
import Text from "@amzn/meridian/text"
import Link from "@amzn/meridian/link"
import Icon from "@amzn/meridian/icon"
import Button from "@amzn/meridian/button"
import CircularGauge from "@amzn/meridian/circular-gauge"
import Tag from "@amzn/meridian/tag"
import chevronDownLargeTokens from "@amzn/meridian-tokens/base/icon/chevron-down-large"
import Tooltip from "@amzn/meridian/tooltip"
import { css } from "emotion"

import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

// import Divider from "@amzn/meridian/divider"
import Row from "@amzn/meridian/row"
// import Loader from "@amzn/meridian/loader"
import Column from "@amzn/meridian/column"
import PageLayout from "../../components/app/page-layout"
import useTheme from "../../hooks/use-theme"
// import { dashboard } from "../../data/dashboard"
import "../../App.css"
import { retrieveDashboard } from "../../redux/dashboard/action/index"

const COLOR_PALETTE = [
  "#F98771",
  "#FFAE5D",
  "#7EC55E",
  "#36C2B4",
  "#A588FF",
  "#EC88DC",
  "#FFDB86",
  "#FF8CAF",
]

const noIssuesTextStyles = css({
  color: "green",
})

const driTextStyles = css({
  position: "absolute",
  right: "8px",
  top: "21px",
})

const pendingApprovalTextStyles = css({
  color: "orange",
  textDecoration: "underline",
})

const pendingUploadTextStyles = css({
  color: "red",
  textDecoration: "underline",
})

/* Data Structure
  {
    "cm": "CNT",
    "cmStatsSummaryList": [
        {
            "canNPI": true,
            "canSustaining": false,
            "cmLocation": "Shenzhen_NPI",
            "compliancePercent": 0,
            "dri": "sijixiao",
            "issueStatusList": [
                {
                    "equipmentId": 12,
                    "equipmentName": "Drop test chamber 1",
                    "status": "SOP is not valid"
                }
            ],
            "pendingApproval": 0,
            "pendingUpload": 59
        }
    ]
  }
*/

const DashboardCard = ({ dashboard, location, backgroundColor }) => {
  const issueList = location.issueStatusList
  const history = useHistory()
  const [showMore, setShowMore] = useState(false)

  return (
    <Card
      width={450}
      spacingInset="none"
      onClick={() => {
        // history.push({
        //   pathname: "dashboard-details",
        //   state: {
        //     cm: dashboard.cm,
        //     testLocation: location.cmLocation,
        //   },
        // })
        history.push(
          `dashboard-details?cm=${dashboard.cm}&testLocation=${location.cmLocation}`
        )
      }}
    >
      <Fragment>
        <Row spacingInset="300" backgroundColor={backgroundColor}>
          <Text type="b500">{`${dashboard.cm} - ${location.cmLocation}`}</Text>
          <Text className={driTextStyles}>{`DRI: ${location.dri || ""}`}</Text>
        </Row>
        <Row alignmentVertical="center">
          <Column alignmentVertical="center">
            <Row
              // alignmentVertical="top"
              spacing="400"
              spacingInset="400 400 none 400"
              width="100%"
              widths={["40%", "60%"]}
            >
              <Column heights="fill" alignmentVertical="center">
                <CircularGauge
                  aria-label={"precentage"}
                  value={location.compliancePercent}
                  minValue={0}
                  maxValue={100}
                  width={150}
                >
                  <Text>
                    <Text type="h300">{`${location.compliancePercent}%`}</Text>
                    <Text type="b300">{"Compliant"}</Text>
                  </Text>
                </CircularGauge>
              </Column>
              <Column minWidth={250}>
                <Row>
                  {location.canNPI && <Tag>NPI</Tag>}
                  {location.canSustaining && <Tag>ORT</Tag>}
                </Row>
                <Text type="h100">{"Highlight Issue(s):"}</Text>
                <div style={{ height: "120px", overflow: "auto" }}>
                  {issueList ? (
                    <Text type="b200" tag="ul">
                      {issueList?.map((issue, i) => (
                        <li key={i}>
                          <Link
                            onClick={() => {
                              history.push(
                                `equipment-details?cm=${dashboard.cm}&testLocation=${location.cmLocation}&equipmentId=${issue.equipmentId}`
                              )
                            }}
                          >
                            {issue.equipmentName}
                          </Link>
                          {`- ${issue.status}`}
                        </li>
                      ))}
                      {/* {issueList
                        ?.filter((x, index) => (showMore ? true : index < 2))
                        .map((issue, i) => (
                          <li key={i}>
                            <Link
                              onClick={() => {
                                history.push(
                                  `equipment-details?cm=${dashboard.cm}&testLocation=${location.cmLocation}&equipmentId=${issue.equipmentId}`
                                )
                              }}
                            >
                              {issue.equipmentName}
                            </Link>
                            {`- ${issue.status}`}
                          </li>
                        ))}
                      {issueList?.length > 2 && !showMore && (
                        <Tooltip
                          position="right"
                          title="Show more ..."
                          id="filter"
                        >
                          <Button type="icon" onClick={() => setShowMore(true)}>
                            <Icon tokens={chevronDownLargeTokens}>
                              Settings
                            </Icon>
                          </Button>
                        </Tooltip>
                      )} */}
                    </Text>
                  ) : (
                    <Text className={noIssuesTextStyles} type="b200">
                      Everything went fine
                    </Text>
                  )}
                </div>
                {/* {!issueList && (
                  <Text className={noIssuesTextStyles} type="b200">
                    <div style={{ height: "150px" }}>Everything went fine</div>
                  </Text>
                )} */}
              </Column>
            </Row>
            <Row spacingInset="200 none 300 400">
              <div>
                <Link
                  onClick={() => {
                    history.push(
                      `lab-management/status-checklist?cm=${dashboard.cm}&testLocation=${location.cmLocation}&filter=pending`
                    )
                  }}
                >
                  <Text className={pendingApprovalTextStyles} type="h300">
                    {location.pendingApproval}
                  </Text>
                </Link>
                <Text>Pending File(s) for Review</Text>
              </div>
              <div>
                <Link
                  onClick={() => {
                    history.push(
                      `lab-management/status-checklist?cm=${dashboard.cm}&testLocation=${location.cmLocation}&filter=noFile`
                    )
                  }}
                >
                  <Text className={pendingUploadTextStyles} type="h300">
                    {location.pendingUpload}
                  </Text>
                </Link>
                <Text>Pending File(s) for Upload</Text>
              </div>
            </Row>
          </Column>
        </Row>
      </Fragment>
    </Card>
  )
}

const DashboardRow = ({ dashboard, backgroundColor }) => {
  const [open, setOpen] = useState(true)
  return dashboard.cmStatsSummaryList.map((location, index) => (
    <DashboardCard
      key={index}
      dashboard={dashboard}
      location={location}
      backgroundColor={backgroundColor}
    />
  ))
}

const Dashboard = () => {
  const { dashboardList, isLoading } = useSelector(
    state => state.dashboardReducer
  )
  const reduxDispatch = useDispatch()
  const { cm, isCMUser, userInfo } = useSelector(state => state.userReducer)

  useEffect(() => {
    if (userInfo) {
      if (isCMUser) {
        reduxDispatch(retrieveDashboard({ cm }))
      } else {
        reduxDispatch(retrieveDashboard())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cm, isCMUser])

  return (
    <React.Fragment>
      <PageLayout
        spacing="large"
        title="Test Fidelity Dashboard"
        loading={isLoading}
        width={"100%"}
      >
        <Column>
          <Row wrap="down" spacing="large" alignmentVertical="top">
            {dashboardList.map((dashboard, index) => (
              <DashboardRow
                key={index}
                dashboard={dashboard}
                backgroundColor={COLOR_PALETTE[index]}
              />
            ))}
          </Row>
        </Column>
        {dashboardList?.some(dashboard =>
          dashboard?.cmStatsSummaryList.some(cm => {
            return cm?.cmLocation?.includes("*")
          })
        ) && (
          <Column>
            <Text type="b300">* This lab supports both NPI and ORT test.</Text>
          </Column>
        )}
      </PageLayout>
    </React.Fragment>
  )
}

export default Dashboard
