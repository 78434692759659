import React, { useState, useEffect } from "react"
// import {useHistory} from "react-router-dom";
import Row from "@amzn/meridian/row"
// import Checkbox from "@amzn/meridian/checkbox";
import Button from "@amzn/meridian/button"
import Column from "@amzn/meridian/column"
import Text from "@amzn/meridian/text"
import Icon from "@amzn/meridian/icon"

import Modal, { ModalFooter } from "@amzn/meridian/modal"
import Select, { SelectOptionGroup, SelectOption } from "@amzn/meridian/select"
import Input from "@amzn/meridian/input"

import addYears from "date-fns/addYears"
import format from "date-fns/format"
import deleteTokens from "@amzn/meridian-tokens/base/icon/delete"

import { useDispatch, useSelector } from "react-redux"
import {
  FormInput,
  FormDatePicker,
  FormSelect,
  FormFilePicker,
} from "../../../components/app"
import { categoryOptions } from "../../../data/options"

import { addEquipment } from "../../../redux/equipment/action"

import { retrieveCapabilityTemplateList } from "../../../redux/capabilityTemplate/action"
import { getLocationsByCmName } from "../../../util/Util"

const formatIso = date => format(date, "yyyy-MM-dd")

// get a tree object for grouped dropdown items
/*
  let tree = [
    {
      key: 'EE',
      value: [
        'ee1',
        'ee2',
      ]
    },
    {
      key: 'Enroinmental',
      value: [
        'ee1',
        'ee2',
      ]
    }
  ]
*/
const getCategroyTree = (templateList, categoryOptions) => {
  return categoryOptions
    .map(o => o.value)
    .map(key => ({
      key: key,
      value: templateList
        ?.filter(x => x.category === key)
        ?.map(y => y.subcategory),
    }))
}

const EquipRow = props => {
  const {
    category,
    subcategory,
    equipmentName,
    categoryTree,
    handleSelectCategory,
    handleChangeName,
    handleDeleteEquipment,
    showDeleteButton,
  } = props
  return (
    <Row spacingInset="small none">
      <Column minWidth="150px">
        <Text tag="label" type="b200" color="primary" htmlFor="my-input">
          {"Category"}
        </Text>
      </Column>
      <Column minWidth="250px">
        <Select
          value={`${category}-${subcategory}`}
          onChange={value => handleSelectCategory(value)}
          placeholder="Select category"
          minWidth={240}
          size="small"
        >
          {categoryTree.map((cat, index) => (
            <SelectOptionGroup key={index} label={cat.key}>
              {cat.value.map((x, i) => (
                <SelectOption key={i} value={`${cat.key}-${x}`} label={x} />
              ))}
            </SelectOptionGroup>
          ))}
        </Select>
      </Column>
      <FormInput
        value={equipmentName}
        setValue={handleChangeName}
        label={"Equipment Name"}
      />
      {showDeleteButton && (
        <Button type="link" onClick={handleDeleteEquipment}>
          <Icon tokens={deleteTokens}>Close</Icon>
        </Button>
      )}
    </Row>
  )
}

const AddEquipmentModal = props => {
  // eslint-disable-next-line no-unused-vars
  const { addEquipmentModalOpen, setAddEquipmentModalOpen } = props
  const { cmIndex } = useSelector(state => state.equipmentReducer)

  const { locationList } = useSelector(state => state.locationReducer)

  const [equipmentList, setEquipmentList] = useState([
    {
      category: "",
      subcategory: "",
      equipmentName: "",
    },
  ])

  const { username } = useSelector(state => state.userReducer)

  const reduxDispatch = useDispatch()

  const { capabilityTemplateList, isLoading } = useSelector(
    state => state.templateReducer
  )

  const handleSelectCategory = (categoryString, rowIndex) => {
    const arr = categoryString.split("-")
    let temp = JSON.parse(JSON.stringify(equipmentList))
    temp[rowIndex].category = arr[0]
    temp[rowIndex].subcategory = arr[1]
    // auto set equipment name as sub category
    temp[rowIndex].equipmentName = arr[1]
    setEquipmentList(temp)
  }

  const handleChangeName = (name, rowIndex) => {
    let temp = JSON.parse(JSON.stringify(equipmentList))
    temp[rowIndex].equipmentName = name
    setEquipmentList(temp)
  }

  const handleAddNewEquipment = () => {
    let temp = JSON.parse(JSON.stringify(equipmentList))
    temp.push({
      category: "",
      subcategory: "",
      equipmentName: "",
    })
    setEquipmentList(temp)
  }

  const handleDeleteEquipment = index => {
    setEquipmentList(equipmentList.filter((_, i) => i !== index))
  }

  const categoryTree = getCategroyTree(capabilityTemplateList, categoryOptions)

  useEffect(() => {
    reduxDispatch(retrieveCapabilityTemplateList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetState = () => {
    setAddEquipmentModalOpen(false)
    setEquipmentList([
      {
        category: "",
        subcategory: "",
        equipmentName: "",
        cm: locationList[cmIndex]?.cm,
        testLocation: locationList[cmIndex]?.cmLocation,
      },
    ])
  }

  return (
    <Modal
      title="Add Equipment"
      open={addEquipmentModalOpen}
      onClose={resetState}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
      // width="500px"
    >
      <Button size="small" onClick={handleAddNewEquipment}>
        Add More
      </Button>
      {equipmentList.map((equip, index) => (
        <EquipRow
          key={index}
          handleSelectCategory={value => handleSelectCategory(value, index)}
          handleChangeName={value => handleChangeName(value, index)}
          handleDeleteEquipment={() => handleDeleteEquipment(index)}
          category={equip.category}
          subcategory={equip.subcategory}
          equipmentName={equip.equipmentName}
          categoryTree={categoryTree}
          showDeleteButton={index !== 0}
        />
      ))}

      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            type="primary"
            size="small"
            disabled={equipmentList.some(equip =>
              Object.values(equip).some(x => x.length === 0)
            )}
            onClick={() => {
              console.warn("equipment list", equipmentList)
              reduxDispatch(
                addEquipment({
                  cm: locationList[cmIndex]?.cm,
                  testLocation: locationList[cmIndex]?.cmLocation,
                  addEquipmentsList: equipmentList,
                })
              )
              resetState()
            }}
          >
            Add
          </Button>
          <Button
            type="secondary"
            size="small"
            onClick={() => setAddEquipmentModalOpen(false)}
          >
            Cancel
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default AddEquipmentModal
