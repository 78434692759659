import React, { useState, useCallback, useEffect } from "react"
// import {useHistory} from "react-router-dom";
import Row from "@amzn/meridian/row"
// import Checkbox from "@amzn/meridian/checkbox";
import Button from "@amzn/meridian/button"
import RadioButton from "@amzn/meridian/radio-button"
import Textarea from "@amzn/meridian/textarea"
import Text from "@amzn/meridian/text"
import { useSelector } from "react-redux"
import Column from "@amzn/meridian/column"
// import { useDispatch } from "react-redux"
import addYears from "date-fns/addYears"
import format from "date-fns/format"
import Select, { SelectOption } from "@amzn/meridian/select"

import Modal, { ModalFooter } from "@amzn/meridian/modal"
import DateRangePicker from "@amzn/meridian/date-range-picker"

// import { approveFile } from "../../../redux/fileLibrary/action"
import { formatDate } from "../../../util/Util"

const formatIso = date => format(date, "yyyy-MM-dd")

const getApprovalStatus = status => {
  switch (status) {
    case "Expired":
      return null
    case "Approved":
      return true
    case "UnApproved":
    case "Unapproved":
      return false
    case "Pending":
      return null
    default:
      return null
  }
}

const ApproveEquipmentFileModal = props => {
  // eslint-disable-next-line no-unused-vars
  const {
    approveEquipmentFileModalOpen,
    setApproveEquipmentFileModalOpen,
    onApproveFile,
    currentFile,
  } = props
  const [value, setValue] = useState(["", ""])
  const [approve, setApprove] = useState()
  const [comment, setComment] = useState("")
  const [score, setScore] = useState()

  const { username } = useSelector(state => state.userReducer)

  const setInitialState = useCallback(
    data => {
      if (data) {
        setValue([data?.effectiveStartDate || "", data?.effectiveEndDate || ""])
        setApprove(getApprovalStatus(data.status))
        setScore(data.score || 0)
        setComment(data.comment || "")
        setApproveEquipmentFileModalOpen(false)
      }
    },
    [setApproveEquipmentFileModalOpen]
  )

  useEffect(() => {
    // reduxDispatch(retrieveAllEquipment(props.projectSelect));
    setInitialState(currentFile)
  }, [currentFile, setInitialState])

  return (
    <Modal
      title="Approve File"
      open={approveEquipmentFileModalOpen}
      // onClose={onClose}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
    >
      <form>
        <Row spacingInset="small none">
          <RadioButton
            checked={approve}
            onChange={() => setApprove(true)}
            name="Approve"
            value={true}
          >
            Approve
          </RadioButton>
          <RadioButton
            checked={!approve}
            onChange={() => {
              setApprove(false)
              setScore()
              setValue(["", ""])
            }}
            name="Unapprove"
            value={false}
          >
            Unapprove
          </RadioButton>
        </Row>
        {approve && (
          <Row spacingInset="small none">
            <Column spacing="400">
              <Text tag="label" type="b200" color="primary" htmlFor="my-input">
                Effective Period
              </Text>
              <DateRangePicker
                value={value}
                onChange={x => {
                  console.warn(x)
                  if (x[0] && x[0] !== value[0]) {
                    let now = new Date(x[0])
                    setValue([x[0], formatIso(addYears(now, 1))])
                  } else {
                    setValue([x[0], x[1]])
                  }
                }}
                startLabel="Start date"
                endLabel="End date"
                monthsInView={2}
              />
            </Column>
          </Row>
        )}
        {approve && (
          <Row spacingInset="small none">
            <Text tag="label" type="b200" color="primary" htmlFor="my-input">
              Score
            </Text>
            <Select
              value={score}
              onChange={setScore}
              minWidth={50}
              size={"small"}
            >
              {[0, 1, 2, 3, 4, 5].map((x, index) => (
                <SelectOption key={index} value={x} label={x} />
              ))}
            </Select>
          </Row>
        )}
        <Row spacingInset="small none">
          <Column spacing="400">
            <Text tag="label" type="b200" color="primary" htmlFor="my-input">
              Comments
            </Text>
            <Textarea
              width={460}
              value={comment}
              onChange={setComment}
              placeholder="Enter your comments here ..."
            />
          </Column>
        </Row>
      </form>
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            type="primary"
            size="small"
            disabled={
              approve &&
              (value[0]?.length === 0 ||
                value[1]?.length === 0 ||
                !Number.isInteger(score))
            }
            onClick={() => {
              let data = {
                status: approve ? "approve" : "unapprove",
                approvedBy: username,
                approvalDate: formatDate(new Date(), "yyyy/MM/dd"),
              }
              if (approve) {
                data.effectiveStartDate = value[0]
                data.effectiveEndDate = value[1]
                data.score = score
              }
              data.comment = comment
              onApproveFile(data)
              setApproveEquipmentFileModalOpen(false)
            }}
          >
            Confirm
          </Button>
          <Button
            type="secondary"
            size="small"
            onClick={() => setApproveEquipmentFileModalOpen(false)}
          >
            Cancel
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default ApproveEquipmentFileModal
