import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import Column from "@amzn/meridian/column"

import Box from "@amzn/meridian/box"
import Tab, { TabGroup } from "@amzn/meridian/tab"
import Heading from "@amzn/meridian/heading"
import { Link } from "react-router-dom"
import PageLayout from "../../components/app/page-layout"
import PerformanceCm from "./tabs/performance-cm"
import PerformanceCpi from "./tabs/performance-cpi"
import PerformanceSystem from "./tabs/performance-system"

const PerformanceSummary = props => {
  const [currentTab, setCurrentTab] = useState("performance-cm")
  const { isLoading } = useSelector(state => state.auditReducer)

  return (
    <PageLayout
      loading={isLoading}
      spacing="small"
      title={`Performance Summary`}
      width={"100%"}
      alignmentHorizontal={"start"}
    >
      <Column spacing="small">
        <TabGroup
          value={currentTab}
          onChange={setCurrentTab}
          aria-labelledby="tablist-heading"
        >
          <Tab
            value="performance-cm"
            aria-controls="performance-cm"
            id="performance-cm"
          >
            CM
          </Tab>
          <Tab
            value="performance-cpi"
            aria-controls="performance-cpi"
            id="performance-cpi"
          >
            CPI
          </Tab>
          <Tab
            value="performance-system"
            aria-controls="performance-system"
            id="performance-system"
          >
            System Utilization
          </Tab>
        </TabGroup>
        {currentTab === "performance-cm" && <PerformanceCm />}
        {currentTab === "performance-cpi" && <PerformanceCpi />}
        {currentTab === "performance-system" && <PerformanceSystem />}
      </Column>
    </PageLayout>
  )
}

export default PerformanceSummary
