/**
 * The footer shown below the current page's content.
 */

import React from "react"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import DatePicker from "@amzn/meridian/date-picker"
import addYears from "date-fns/addYears"
import format from "date-fns/format"

const formatIso = date => format(date, "yyyy-MM-dd")
const lastYear = formatIso(addYears(new Date(), -1))
const today = formatIso(new Date())
const nextYear = formatIso(addYears(new Date(), 1))

const FormDatePicker = props => {
  const { value, setValue, label, callback } = props
  return (
    <Row spacingInset="small none">
      <Column minWidth="150px">
        <Text tag="label" type="b200" color="primary" htmlFor="my-input">
          {label}
        </Text>
      </Column>
      <Column width="250px">
        <DatePicker
          value={value}
          onChange={date => {
            setValue(date)
            callback && callback(date)
          }}
          size={"small"}
          presets={[
            { label: "Last Year", value: lastYear },
            { label: "Today", value: today },
            { label: "Next Year", value: nextYear },
          ]}
        />
      </Column>
    </Row>
  )
}

export default FormDatePicker
