import React, { useState, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

// import {useHistory} from "react-router-dom";
import Row from "@amzn/meridian/row"
import Column from "@amzn/meridian/column"
// import Checkbox from "@amzn/meridian/checkbox";
import Button from "@amzn/meridian/button"
import Tooltip from "@amzn/meridian/tooltip"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import Table, {
  TableRow,
  TableCell,
  TableActionBar,
} from "@amzn/meridian/table"
import minusTokens from "@amzn/meridian-tokens/base/icon/minus"
import Checkbox from "@amzn/meridian/checkbox"
import Icon from "@amzn/meridian/icon"
import Text from "@amzn/meridian/text"
import trashTokens from "@amzn/meridian-tokens/base/icon/trash"
import plusCircleTokens from "@amzn/meridian-tokens/base/icon/plus-circle"
import axios from "axios"
import qs from "qs"

import { FormSelect } from "../../../components/app"

import {
  retrieveOperatorList,
  addOperator,
  deleteOperator,
} from "../../../redux/equipmentDetail/action"
import { Input } from "../../../components/app/form-fields"
import "../../../App.css"
import { getRegex } from "../../../util/Util"
import EmptyState from "../../../components/app/empty-state"

import * as auth from "../../../services/Auth/auth"
import env from "../../../services/Auth/env"
import { openToast } from "../../../api/toasts"

const EquipmentCertifiedOperatorListTab = props => {
  const { equipmentBasicInfo, cm, testLocation } = props

  const [name, setName] = useState()
  const [addOperatorModalOpen, setAddOperatorModalOpen] = useState(false)

  const { operatorList } = useSelector(state => state.equipmentDetailReducer)
  let initialSelected = operatorList.map(_ => false)

  const [selected, setSelected] = useState(initialSelected)
  const [personList, setPersonList] = useState([])
  const [opIds, setOpIds] = useState([])
  const { isCMUser, isAdmin } = useSelector(state => state.userReducer)

  const [keyword, setKeyword] = useState("")
  const count = selected.length
  const checkedCount = selected.filter(Boolean).length
  const allSelected = count === checkedCount

  const headerCheckboxIcon = !allSelected ? minusTokens : undefined

  const onChangeRow = useCallback(
    (value, index) =>
      setSelected(selected.map((x, i) => (i === index ? value : x))),
    [selected]
  )

  const onChangeHeader = useCallback(() => {
    const allValues = allSelected ? false : true
    const nextSelected = selected.map(s => allValues)
    setSelected(nextSelected)
  }, [allSelected, selected])

  const reduxDispatch = useDispatch()

  useEffect(() => {
    reduxDispatch(
      retrieveOperatorList({ equipmentId: equipmentBasicInfo.equipmentId })
    )
  }, [reduxDispatch, equipmentBasicInfo.equipmentId])

  useEffect(() => {
    const query = qs.stringify({
      equipment_id: equipmentBasicInfo.equipmentId,
      cm: cm,
      test_location: testLocation,
    })
    auth.getToken().then(cookie => {
      axios(
        env.API_ENDPOINT +
          "/test-fidelity/retrieve_lab_personnel_by_location" +
          "?" +
          query,
        {
          method: "GET",
          headers: { idToken: cookie },
        }
      )
        .then(response => {
          console.warn(
            "response",
            response.data.retrieveLabPersonnelByLocationResponse
          )
          const data = response.data.retrieveLabPersonnelByLocationResponse
          setPersonList(data)
        })
        .catch(error => {})
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let initialSelected = operatorList.map(_ => false)
    setSelected(initialSelected)
  }, [operatorList])

  return (
    <div role="tabpanel" id="tabpanel-planets" aria-labelledby="tab-planets">
      <Table
        headerRows={1}
        showDividers={true}
        // stickyHeaderRow={true}
        // fixHeaderRowsOffset={-16}
        fixHeaderRowsFullWidth={true}
        fixHeaderRows={true}
      >
        <TableActionBar widths={[300]}>
          <Input
            aria-label="Label"
            type="text"
            size="medium"
            placeholder="Search by keyword ..."
            value={keyword}
            onChange={setKeyword}
          />
          <Tooltip position="top" title="Add new user" id="myTooltip">
            <Button
              type="icon"
              disabled={!isAdmin && !isCMUser}
              onClick={() => {
                setAddOperatorModalOpen(true)
              }}
            >
              <Icon tokens={plusCircleTokens}>Save</Icon>
            </Button>
          </Tooltip>
          {checkedCount > 0 && (
            <Button
              type="icon"
              disabled={!isAdmin && !isCMUser}
              onClick={() => {
                let selectedOperators = operatorList
                  .filter((op, index) => selected[index])
                  .map(o => o.operatorId)
                reduxDispatch(
                  deleteOperator({
                    operatorIds: selectedOperators,
                    equipmentId: equipmentBasicInfo.equipmentId,
                  })
                )
              }}
            >
              <Icon tokens={trashTokens} className=".UnApproved-color" />
            </Button>
          )}
        </TableActionBar>
        <TableRow>
          <TableCell>
            <Checkbox
              checked={checkedCount > 0}
              onChange={onChangeHeader}
              iconTokens={headerCheckboxIcon}
            />
          </TableCell>
          <TableCell>Operator Name</TableCell>
        </TableRow>
        {operatorList
          .filter(f => {
            let regex = getRegex(keyword)
            return regex.test(JSON.stringify(f))
          })
          .map((operator, index) => (
            <TableRow key={index}>
              <TableCell>
                <Checkbox
                  checked={selected[index]}
                  onChange={value => onChangeRow(value, index)}
                />
              </TableCell>
              <TableCell>{operator.name}</TableCell>
            </TableRow>
          ))}
        {operatorList?.length === 0 ? (
          <EmptyState target={"operators"} />
        ) : null}
      </Table>
      <Modal
        title="Add Operator(s)"
        open={addOperatorModalOpen}
        onClose={() => {
          setName()
          setAddOperatorModalOpen(false)
        }}
        scrollContainer="viewport"
        closeLabel="Close"
        aria-describedby="modal-description"
      >
        <Row
          minWidth={400}
          alignmentVertical="center"
          spacingInset="400"
          minHeight={50}
        >
          <Column spacing="large">
            <FormSelect
              value={opIds}
              setValue={setOpIds}
              label={"Select Operator(s)"}
              options={personList.map(x => ({
                value: x.id,
                label: x.name,
              }))}
            />
          </Column>
        </Row>
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button
              disabled={opIds.length === 0}
              type="primary"
              size="small"
              onClick={() => {
                let { equipmentId, cm, cmLocation } = equipmentBasicInfo
                reduxDispatch(
                  addOperator({
                    equipmentId,
                    opList: opIds,
                  })
                )
                setAddOperatorModalOpen(false)
              }}
            >
              Add
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default EquipmentCertifiedOperatorListTab
