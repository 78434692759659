/**
 * The footer shown below the current page's content.
 */

import React from "react"
import { colorGray200 } from "@amzn/meridian-tokens/base/color"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import { css } from "emotion"
import safariImage from "../../img/safari.png"
import chromeImage from "../../img/chrome.png"
import firefoxImage from "../../img/firefox.png"

const footerStyle = {
  borderTop: `1px solid ${colorGray200}`,
}

const titleStyle = css({
  fontSize: "14px",
  fontWeight: 600,
})

const Footer = () => (
  <div style={footerStyle}>
    <Column alignmentHorizontal="start" backgroundColor="alternateSecondary">
      <Row alignmentVertical="center" spacingInset="200 none 200 500">
        <img src={safariImage} alt="safari" width="40px" height="40px" />
        <img src={chromeImage} alt="chrome" width="40px" height="40px" />
        <img src={firefoxImage} alt="firefox" width="40px" height="40px" />
        <Column spacing="100" alignmentVertical="center" hights="fit">
          <Text className={titleStyle}>
            Test Fidelity 2.0. Amazon Confidential
          </Text>
          <Text type="b200">© 2024 Lab126 Product Data Intelligence</Text>
        </Column>
      </Row>
    </Column>
  </div>
)

export default Footer
