import React, { Fragment, useState, useEffect } from "react"
import Heading from "@amzn/meridian/heading"
import DatePicker from "@amzn/meridian/date-picker"
import Text from "@amzn/meridian/text"
import Textarea from "@amzn/meridian/textarea"
import Button from "@amzn/meridian/button"
import Icon from "@amzn/meridian/icon"
import Link from "@amzn/meridian/link"
import Divider from "@amzn/meridian/divider"
import CircularGauge from "@amzn/meridian/circular-gauge"
import Box from "@amzn/meridian/box"
import Pie from "@amzn/meridian/pie"
import Legend, { LegendProvider } from "@amzn/meridian/legend"
import Row from "@amzn/meridian/row"
import Column from "@amzn/meridian/column"
import { Line } from "@ant-design/plots"

import {
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryAxis,
  VictoryLine,
} from "victory"

import victoryLinePlugin from "@amzn/meridian/legend/plugin/victory-line"
import victoryTooltipPlugin from "@amzn/meridian/legend/plugin/victory-tooltip"
import useVictoryTheme from "@amzn/meridian/use-victory-theme"

import ProgressTracker, {
  ProgressTrackerStep,
} from "@amzn/meridian/progress-tracker"
import Thumbnail from "@amzn/meridian/thumbnail"

import { css } from "emotion"
import { useLocation, useHistory } from "react-router-dom"
import closeLargeTokens from "@amzn/meridian-tokens/base/icon/close-large"
import editTokens from "@amzn/meridian-tokens/base/icon/edit"
import checkLargeTokens from "@amzn/meridian-tokens/base/icon/check-large"

import format from "date-fns/format"
import { Radar } from "@ant-design/plots"

import { useDispatch, useSelector } from "react-redux"

import axios from "axios"
import qs from "qs"

import PageLayout from "../../components/app/page-layout"
import "../../App.css"
import { categoryOptions } from "../../data/options"

import { FormInput, FormSelect } from "../../components/app"

import * as auth from "../../services/Auth/auth"
import env from "../../services/Auth/env"
import { addNextAuditDate } from "../../redux/audit/action"

const noIssuesTextStyles = css({
  color: "green",
})
const placeholderStyles = css({
  width: "70px",
})

const listStyles = css({
  paddingTop: "10px",
  paddingBottom: "10px",
})

const complianceTypes = {
  calibration: "Calibration",
  capability: "Capability",
  consumableManagement: "Consumable",
  cooperation: "Cooperation",
  maintenance: "Maintenance",
  sop: "E-SOP",
  training: "Training",
}

const config = {
  meta: {
    score: {
      alias: "score",
      min: 0,
      // max: 5,
      nice: true,
      formatter: v => Number(v).toFixed(2),
    },
  },
  xAxis: {
    label: {
      style: {
        fontSize: 14,
        fontWeight: 500,
      },
    },
  },
  yAxis: {
    label: {
      formatter: v => Number(v).toFixed(0),
    },
  },
  area: {},
  point: {
    size: 2,
  },
}

const ComplianceCircularGauge = ({
  type,
  value,
  width = 70,
  trackWidth = 5,
  textType = "b100",
}) => (
  <CircularGauge
    aria-label={"precentage"}
    value={value}
    minValue={0}
    maxValue={100}
    width={width}
    trackWidth={trackWidth}
  >
    <Text>
      <Text type={textType}>{`${value}%`}</Text>
      <Text type={textType}>{type}</Text>
    </Text>
  </CircularGauge>
)

const retrieveData = (query, apiPath, callback) => {
  auth.getToken().then(cookie => {
    axios(env.API_ENDPOINT + apiPath + "?" + query, {
      method: "GET",
      headers: { idToken: cookie },
    })
      .then(response => {
        // const data = response.data.complianceStatusList
        callback && callback(response?.data)
      })
      .catch(error => {})
  })
}

const updateData = (payload, apiPath, callback) => {
  auth.getToken().then(cookie => {
    axios(env.API_ENDPOINT + apiPath, {
      method: "POST",
      data: payload,
      headers: { idToken: cookie },
    })
      .then(response => {
        // const data = response.data.complianceStatusList
        callback && callback(response?.data)
      })
      .catch(error => {})
  })
}

const DashboardDetails = () => {
  const [isEditing, setIsEditing] = useState(false)
  const [isEditingAuditDate, setIsEditingAuditDate] = useState(false)
  const [auditDateValue, setAuditDateValue] = useState("")
  const [issueList, setIssueList] = useState()
  const [timeline, setTimeLine] = useState([])
  const [complianceStatusList, setComplianceStatusList] = useState([])
  const [complianceScore, setComplianceScore] = useState({})
  const [cooperationScore, setCooperationScore] = useState()
  const [consumableScore, setConsumableScore] = useState()
  const [fileStatus, setFileStatus] = useState([])
  const [description, setDescription] = useState("")
  const [isEditingScore, setIsEditingScore] = useState(false)
  const [statusSummary, setStatusSummary] = useState()
  const { dashboardList, isLoading } = useSelector(
    state => state.dashboardReducer
  )
  const { isAdmin } = useSelector(state => state.userReducer)

  const location = useLocation()
  const { cm, testLocation } = Object.fromEntries(
    new URLSearchParams(location.search).entries()
  )

  const history = useHistory()
  const lineTheme = useVictoryTheme({ showIndependentGrid: false })
  const radarTheme = useVictoryTheme({
    showDependentGrid: false,
    showIndependentGrid: false,
    tickLabelSize: "v100",
    tickLabelWeight: "regular",
    axisLabelSize: "v100",
    axisLabelWeight: "regular",
  })

  const reduxDispatch = useDispatch()

  const totalCompliance = complianceStatusList?.find(
    x => x.category === "TOTAL"
  )
  const equipmentPieData = categoryOptions
    ?.map(x => x.label)
    ?.concat("Other")
    ?.map(category => {
      return {
        value: complianceStatusList?.find(y => y.category === category)
          ?.equipmentNum,
        label: category,
      }
    })
    ?.filter(z => z.value)

  const fileStatusPieData = ["Approved", "Pending", "Unapproved"]?.map(
    status => {
      return {
        value: fileStatus?.[fileStatus.length - 1]?.[status.toLowerCase()],
        label: status,
      }
    }
  )

  const scoreTrend = {
    key: "complianceScore",
    label: "Compliance Score",
    data: complianceScore?.scoreTrend || [],
  }
  scoreTrend.data.sort((a, b) => {
    return new Date(a.date) - new Date(b.date)
  })

  const fileStatusTrend = []
  fileStatus.forEach(file => {
    fileStatusTrend.push({
      date: file.date,
      value: file.approved,
      category: "Approved",
    })
    fileStatusTrend.push({
      date: file.date,
      value: file.pending,
      category: "Pending",
    })
    fileStatusTrend.push({
      date: file.date,
      value: file.unapproved,
      category: "Unapproved",
    })
  })
  fileStatusTrend.sort((a, b) => {
    return new Date(a.date) - new Date(b.date)
  })

  useEffect(() => {
    const query = qs.stringify({
      cm: cm,
      test_location: testLocation,
    })
    retrieveData(query, env.APIPath.retrieveComplianceStatus, data => {
      setComplianceStatusList(data.complianceStatusList)
      setDescription(data.description)
    })
    retrieveData(query, env.APIPath.retrieveHighlightIssues, data =>
      setIssueList(data.issueList)
    )
    retrieveData(query, env.APIPath.retrieveComplianceScore, data =>
      setComplianceScore(data.complianceScoreResponse)
    )
    retrieveData(query, env.APIPath.retrieveTimelineByCm, data =>
      setTimeLine(data.retrieveTimelineByEquipResponse)
    )
    retrieveData(query, env.APIPath.retrieveFileStatusByCm, data =>
      setFileStatus(data.retrieveFilesStatusByCmResponse)
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // find current status
    if (dashboardList.length > 0) {
      dashboardList.forEach(dashboardDetail => {
        if (dashboardDetail.cm === cm) {
          dashboardDetail.cmStatsSummaryList?.forEach(summary => {
            if (summary.cmLocation === testLocation) {
              const nextAuditDate =
                summary.nextAuditDate === "null"
                  ? "To be determined"
                  : summary.nextAuditDate
              setAuditDateValue(nextAuditDate)
              setStatusSummary(summary)
            }
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardList])

  useEffect(() => {
    console.warn("complianceScore", complianceScore)
    if (complianceScore?.complianceDetail) {
      setCooperationScore(complianceScore.complianceDetail?.cooperation)
      setConsumableScore(complianceScore.complianceDetail?.consumableManagement)
    }
  }, [complianceScore])

  const nextAuditDateClick = () => {
    reduxDispatch(
      addNextAuditDate({
        testLocation: testLocation,
        cm: cm,
        auditDate: auditDateValue,
      })
    )
    setIsEditingAuditDate(false)
    setAuditDateValue(auditDateValue)
  }

  const nextAuditDateCancelClick = () => {
    setIsEditingAuditDate(false)
  }

  return (
    <React.Fragment>
      <PageLayout
        spacing="large"
        title={`${cm} - ${testLocation} Dashboard`}
        loading={isLoading}
        breadcrumbs={[{ title: "Dashboard", path: "/dashboard" }]}
      >
        <Column spacing="400">
          <Row>
            <Text type="h200">Brief Introduction</Text>
            <Button
              type="icon"
              onClick={() => {
                const query = qs.stringify({
                  cm: cm,
                  test_location: testLocation,
                })

                if (isEditing) {
                  updateData(
                    {
                      cm: cm,
                      testLocation: testLocation,
                      description: description,
                    },
                    env.APIPath.editCmDescription,
                    () =>
                      retrieveData(
                        query,
                        env.APIPath.retrieveComplianceStatus,
                        data => {
                          setDescription(data.description)
                        }
                      )
                  )
                }
                setIsEditing(!isEditing)
              }}
            >
              {isEditing ? (
                <Icon tokens={checkLargeTokens}>Check</Icon>
              ) : (
                <Icon tokens={editTokens}>Settings</Icon>
              )}
            </Button>
          </Row>
          {isEditing ? (
            <Textarea
              value={description}
              onChange={setDescription}
              placeholder="Enter Description ..."
            />
          ) : (
            <Text>{description}</Text>
          )}
          <Box spacingInset="300" type="outline">
            <Text type="h200">Compliance Status</Text>
            <Divider />
            <Row spacingInset="300" widths={["20%", "20%", "60%"]}>
              <Column alignmentHorizontal="end">
                <Row>
                  <div className={placeholderStyles}></div>
                  <ComplianceCircularGauge
                    type={"SOP"}
                    value={totalCompliance?.sopPercent || 0}
                  />
                </Row>
                <Row>
                  <div>
                    <ComplianceCircularGauge
                      type={"Checklist"}
                      value={totalCompliance?.checklistPercent || 0}
                    />
                    <div className={placeholderStyles}></div>
                  </div>
                  <div>
                    <ComplianceCircularGauge
                      type={"Broken"}
                      value={totalCompliance?.breakdownPercent || 0}
                    />
                    <div className={placeholderStyles}></div>
                  </div>
                </Row>
                <Row>
                  <div className={placeholderStyles}></div>
                  <ComplianceCircularGauge
                    type={"Calibration"}
                    value={totalCompliance?.calibrationPercent || 0}
                  />
                </Row>
              </Column>
              <Column alignmentHorizontal="start">
                <ComplianceCircularGauge
                  type={"Compliant"}
                  value={totalCompliance?.compliancePercent || 0}
                  width={200}
                  trackWidth={20}
                  textType={"b400"}
                />
              </Column>
              <LegendProvider
                data={equipmentPieData}
                aria-labelledby="animals-one"
              >
                <Row
                  spacing="500"
                  alignmentHorizontal="center"
                  alignmentVertical="top"
                >
                  <Pie width={200} data={equipmentPieData} donutRatio={0.7}>
                    <Text type="d50" tag="div">
                      {equipmentPieData.reduce(
                        (total, slice) => total + slice.value,
                        0
                      )}
                    </Text>
                    <Text type="b400" tag="div">
                      Equipment
                    </Text>
                  </Pie>
                  <Column>
                    <Heading id="animals-one" level={4}>
                      Equipment
                    </Heading>
                    <Legend
                      direction="vertical"
                      values={item => item.value.toLocaleString()}
                    />
                  </Column>
                </Row>
              </LegendProvider>
            </Row>
          </Box>

          <Row widths={["50%", "50%"]} alignmentVertical="top">
            <Column>
              <Box spacingInset="300" type="outline">
                <Text type="h200">Highlight Issue(s)</Text>
                <Divider />
                <Column spacingInset="400">
                  <div style={{ maxHeight: "550px", overflow: "auto" }}>
                    <Text type="b200" tag="ul">
                      {issueList?.map((issue, i) => (
                        <li className={listStyles} key={i}>
                          <Link
                            onClick={() => {
                              history.push(
                                `equipment-details?cm=${cm}&testLocation=${testLocation}&equipmentId=${issue.equipmentId}`
                              )
                            }}
                          >
                            {issue.equipmentName}
                          </Link>
                          {`- ${issue.status}`}
                        </li>
                      ))}
                    </Text>
                    {!issueList && (
                      <Text className={noIssuesTextStyles} type="b200">
                        Everything went fine
                      </Text>
                    )}
                  </div>
                </Column>
              </Box>

              <Box spacingInset="300" type="outline">
                <Row>
                  <Text type="h200">Audit Status</Text>
                  <Text>
                    {!isEditingAuditDate && isAdmin && (
                      <Button
                        type="icon"
                        onClick={() => {
                          setIsEditingAuditDate(true)
                        }}
                      >
                        <Icon tokens={editTokens}>Edit</Icon>
                      </Button>
                    )}
                    {isEditingAuditDate && (
                      <>
                        <Button type="icon" onClick={nextAuditDateClick}>
                          <Icon tokens={checkLargeTokens}>Save</Icon>
                        </Button>
                        <Button
                          type="icon"
                          onClick={() => nextAuditDateCancelClick()}
                        >
                          <Icon tokens={closeLargeTokens}>Cancel</Icon>
                        </Button>
                      </>
                    )}
                  </Text>
                </Row>
                <Divider />
                <Column spacingInset="400">
                  <ProgressTracker>
                    {statusSummary?.previousAuditReportStatusList.map(
                      (audit, index) => {
                        return (
                          <ProgressTrackerStep
                            key={index}
                            type="past"
                            label={
                              <Heading level={6}>{audit.auditDate}</Heading>
                            }
                            showLabel={true}
                            trackLength={6}
                          >
                            {audit.issueFound} Issues issue
                            <br />
                            {audit.issueResolved} Issues Resolved
                            <br />
                            Target {audit.ecd} to close all
                          </ProgressTrackerStep>
                        )
                      }
                    )}
                    <ProgressTrackerStep
                      type="future"
                      label={<strong>Next Audit Date</strong>}
                      showLabel={true}
                      trackLength={6}
                    >
                      {isEditingAuditDate ? (
                        <DatePicker
                          value={auditDateValue ? auditDateValue : ""}
                          onChange={setAuditDateValue}
                          label="Date"
                          monthsInView={1}
                        />
                      ) : (
                        auditDateValue || ""
                      )}
                    </ProgressTrackerStep>
                  </ProgressTracker>
                </Column>
              </Box>

              <Box spacingInset="300" type="outline">
                <Text type="h200">File Status</Text>
                <Divider />
                <LegendProvider
                  data={fileStatusPieData}
                  aria-labelledby="animals-one"
                >
                  <Row
                    spacing="500"
                    alignmentHorizontal="center"
                    alignmentVertical="top"
                    spacingInset="400 none none none"
                  >
                    <Pie width={200} data={fileStatusPieData}></Pie>
                    <Column>
                      <Heading id="animals-one" level={4}>
                        {`Overall ${fileStatus?.[fileStatus?.length - 1]
                          ?.overall || ""}`}
                      </Heading>
                      <Legend
                        direction="vertical"
                        values={item => item?.value?.toLocaleString()}
                      />
                    </Column>
                  </Row>
                </LegendProvider>

                <div style={{ width: "100%", height: "300px" }}>
                  <Line
                    padding={[40, 20, 20, 30]}
                    data={fileStatusTrend}
                    xField={"date"}
                    yField={"value"}
                    seriesField={"category"}
                    color={({ category }) => {
                      if (category === "Approved") {
                        return "#36c2b4"
                      } else if (category === "Pending") {
                        return "#eb64d7"
                      }
                      return "#ff8f00"
                    }}
                  />
                </div>
              </Box>
            </Column>

            <Column>
              <Box spacingInset="300" type="outline">
                <Row>
                  <Text type="h200">Compliance Score</Text>
                  <Text>
                    {!isEditingScore && isAdmin && (
                      <Button
                        type="icon"
                        onClick={() => {
                          setIsEditingScore(true)
                        }}
                      >
                        <Icon tokens={editTokens}>Edit</Icon>
                      </Button>
                    )}
                    {isEditingScore && (
                      <>
                        <Button
                          type="icon"
                          onClick={() => {
                            setIsEditingScore(false)

                            const query = qs.stringify({
                              cm: cm,
                              test_location: testLocation,
                            })
                            updateData(
                              {
                                cm: cm,
                                testLocation: testLocation,
                                type: "Cooperation",
                                score: cooperationScore,
                              },
                              env.APIPath.addComplianceScore,
                              () => {
                                updateData(
                                  {
                                    cm: cm,
                                    testLocation: testLocation,
                                    type: "Consumable Management",
                                    score: consumableScore,
                                  },
                                  env.APIPath.addComplianceScore,
                                  () => {
                                    retrieveData(
                                      query,
                                      env.APIPath.retrieveComplianceScore,
                                      data => {
                                        setComplianceScore(
                                          data.complianceScoreResponse
                                        )
                                      }
                                    )
                                  }
                                )
                              }
                            )
                          }}
                        >
                          <Icon tokens={checkLargeTokens}>Save</Icon>
                        </Button>
                        <Button
                          type="icon"
                          onClick={() => setIsEditingScore(false)}
                        >
                          <Icon tokens={closeLargeTokens}>Cancel</Icon>
                        </Button>
                      </>
                    )}
                  </Text>
                </Row>
                <Divider />
                <Column spacingInset="400">
                  {isEditingScore && (
                    <>
                      <FormSelect
                        value={cooperationScore}
                        setValue={value => setCooperationScore(Number(value))}
                        label={"Cooperation Score"}
                        options={[0, 1, 2, 3, 4, 5].map(x => ({
                          label: x,
                          value: x,
                        }))}
                      />
                      <FormSelect
                        value={consumableScore}
                        setValue={value => setConsumableScore(Number(value))}
                        label={"Consumable Score"}
                        options={[0, 1, 2, 3, 4, 5].map(x => ({
                          label: x,
                          value: x,
                        }))}
                      />
                    </>
                  )}
                  <Text>{`Overall Score: ${complianceScore?.complianceDetail
                    ?.weightedScore || ""}`}</Text>
                </Column>
                <Radar
                  padding={[40, 100, 40, 80]}
                  data={Object.keys(complianceTypes).map(key => {
                    return {
                      item: complianceTypes[key],
                      score: complianceScore?.complianceDetail?.[key] || 0,
                    }
                  })}
                  xField="item"
                  yField="score"
                  {...config}
                />
              </Box>

              <Box spacingInset="300" type="outline">
                <Text type="h200">Scores Over the Past 12 Months</Text>
                <Divider />
                <div style={{ width: "100%", height: "300px" }}>
                  <Line
                    padding={[40, 20, 20, 30]}
                    data={scoreTrend.data}
                    xField={"date"}
                    yField={"complianceScore"}
                    yAxis={{
                      minLimit: 0,
                      maxLimit: 5,
                    }}
                  />
                </div>
              </Box>

              <Box spacingInset="300" type="outline">
                <Text type="h200">Recent Activities</Text>
                <Divider />
                <Column
                  spacingInset="400"
                  overflowY="scroll"
                  maxHeight={600}
                  height={600}
                >
                  <ProgressTracker type="theme" direction="column">
                    {timeline.map((t, index) => {
                      return (
                        <ProgressTrackerStep
                          key={index}
                          type="future"
                          label={
                            <Text type="h50">
                              {format(
                                new Date(t.timestamp),
                                "yyyy-MM-dd hh:mm:ss"
                              )}
                            </Text>
                          }
                          trackLength={3}
                        >
                          <Row>
                            {!t.isCm ? (
                              <Thumbnail
                                source={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${t.user}`}
                                size="small"
                              />
                            ) : (
                              ""
                            )}
                            <Text type="b200">
                              {t.name} {t.behavior}
                            </Text>
                          </Row>
                        </ProgressTrackerStep>
                      )
                    })}
                  </ProgressTracker>
                </Column>
              </Box>
            </Column>
          </Row>
        </Column>
      </PageLayout>
    </React.Fragment>
  )
}

export default DashboardDetails
