import { openToast } from "../../../api/toasts"
import {
  SUCCESS_TIMEOUT_IN_MS,
  FAILURE_TIMEOUT_IN_MS,
} from "../../../data/constants"

const INITIAL_STATE = {
  cm: "",
  equipment: [],
  calibrated: [],
  projectList: [],
  sopFiles: [],
  trainingFiles: [],
  isLoading: false,
  status: false,
  selectedEquipIds: [],
  projectFiles: [],
  assessmentFiles: [],
}
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SELECT_CM_PROJECT":
      return {
        ...state,
        cm: action.payload,
        projectList: [],
      }
    case "RETRIEVE_EQUIPMENT":
      return {
        ...state,
      }
    case "RETRIEVE_EQUIPMENT_SUCCESS":
      return {
        ...state,
        equipment: action.payload,
      }
    case "ADD_PROJECT":
      return {
        ...state,
        isLoading: true,
      }
    case "ADD_PROJECT_SUCCESS":
      openToast({
        type: "success",
        title: "Add Done",
        message: "Add project successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        status: action.payload,
        isLoading: false,
      }
    case "ADD_PROJECT_FAILURE":
      openToast({
        type: "error",
        title: "Add failed",
        message: "failed to add project, please retry",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "RETRIEVE_CALIBRATED_EQUIPS":
      return {
        ...state,
      }
    case "RETRIEVE_CALIBRATED_EQUIPS_SUCCESS":
      return {
        ...state,
        calibrated: action.payload,
      }
    case "RETRIEVE_PROJECT":
      return {
        ...state,
        isLoading: true,
      }
    case "RETRIEVE_PROJECT_SUCCESS":
      return {
        ...state,
        projectList: action.payload,
        isLoading: false,
      }
    case "RETRIEVE_PROJECT_FAILURE":
      return {
        ...state,
        isLoading: false,
      }
    case "UPDATE_PROJECT":
      return {
        ...state,
        isLoading: true,
      }
    case "UPDATE_PROJECT_SUCCESS":
      openToast({
        type: "success",
        title: "Update complete!",
        message: "update project successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        // projectList: action.payload,
        isLoading: false,
      }
    case "UPDATE_PROJECT_FAILURE":
      openToast({
        type: "error",
        title: "Update failed",
        message: "Failed to update the project, please retry",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "ARCHIVE_PROJECT":
      return {
        ...state,
        isLoading: true,
      }
    case "ARCHIVE_PROJECT_SUCCESS":
      openToast({
        type: "success",
        title: "Archive Done",
        message: "Archived project successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "ARCHIVE_PROJECT_FAILURE":
      openToast({
        type: "error",
        title: "Archive failed",
        message: "failed to archive project, please retry",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "RETRIEVE_FILE_BY_SOP_TYPE":
      return {
        ...state,
      }
    case "RETRIEVE_FILE_BY_SOP_TYPE_SUCCESS":
      return {
        ...state,
        sopFiles: action.payload,
      }
    case "RETRIEVE_FILE_BY_ASSESSMENT_TYPE":
      return {
        ...state,
      }
    case "RETRIEVE_FILE_BY_ASSESSMENT_TYPE_SUCCESS":
      return {
        ...state,
        assessmentFiles: action.payload,
      }
    case "RETRIEVE_FILE_BY_TRAINING_TYPE":
      return {
        ...state,
      }
    case "RETRIEVE_FILE_BY_TRAINING_TYPE_SUCCESS":
      return {
        ...state,
        trainingFiles: action.payload,
      }
    case "SELECT_EQUIP_IDS":
      return {
        ...state,
        selectedEquipIds: action.payload,
      }
    case "RETRIEVE_PROJECT_FILES_BY_TYPE":
      return {
        ...state,
        projectFiles: [],
        isLoadingFiles: true,
      }
    case "RETRIEVE_PROJECT_FILES_BY_TYPE_SUCCESS":
      return {
        ...state,
        isLoadingFiles: false,
        projectFiles: action.payload,
      }
    case "RETRIEVE_PROJECT_FILES_BY_TYPE_FAILURE":
      return {
        ...state,
        isLoadingFiles: false,
      }
    default:
      return state
  }
}

export default reducer
