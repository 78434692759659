import { openToast } from "../../../api/toasts"
import {
  FAILURE_TIMEOUT_IN_MS,
  SUCCESS_TIMEOUT_IN_MS,
} from "../../../data/constants"

const INITIAL_STATE = {
  cmIndex: 0,
  consumableItem: {},
  consumableList: [],
  consumableUsage: [],
  isLoading: false,
}
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "RETRIEVE_CONSUMABLE_LIST":
      console.warn("RETRIEVE_CONSUMABLE_LIST")
      return {
        ...state,
        consumableList: [],
        isLoading: true,
      }
    case "RETRIEVE_CONSUMABLE_LIST_SUCCESS":
      console.warn("RETRIEVE_CONSUMABLE_LIST_SUCCESS", action.payload)
      return {
        ...state,
        consumableList: action.payload.ConsumableByLocationList
          ? [...action.payload.ConsumableByLocationList]
          : [],
        isLoading: false,
      }
    case "RETRIEVE_CONSUMABLE_LIST_FAILURE":
      console.warn("RETRIEVE_CONSUMABLE_LIST_FAILURE")
      openToast({
        type: "error",
        title: "Retrieve failed",
        message: "Retrieve consumable list failed",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "RETRIEVE_CONSUMABLE_USAGE_BY_ID":
      console.warn("RETRIEVE_CONSUMABLE_USAGE_BY_ID")
      return {
        ...state,
        consumableUsage: [],
        isLoading: true,
      }
    case "RETRIEVE_CONSUMABLE_USAGE_BY_ID_SUCCESS":
      console.warn("RETRIEVE_CONSUMABLE_USAGE_BY_ID_SUCCESS", action.payload)
      return {
        ...state,
        consumableUsage: action.payload.ConsumableUsageByIdList || [],
        isLoading: false,
      }
    case "RETRIEVE_CONSUMABLE_USAGE_BY_ID_FAILURE":
      console.warn("RETRIEVE_CONSUMABLE_USAGE_BY_ID_FAILURE")
      openToast({
        type: "error",
        title: "Retrieve failed",
        message: "Retrieve consumable list failed",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "RETRIEVE_CONSUMABLE_BY_ID":
      console.warn("RETRIEVE_CONSUMABLE_BY_ID")
      return {
        ...state,
        consumableItem: {},
        isLoading: true,
      }
    case "RETRIEVE_CONSUMABLE_BY_ID_SUCCESS":
      console.warn("RETRIEVE_CONSUMABLE_BY_ID_SUCCESS", action.payload)
      return {
        ...state,
        consumableItem: action.payload.ConsumableByLocation || {},
        isLoading: false,
      }
    case "RETRIEVE_CONSUMABLE_BY_ID_FAILURE":
      console.warn("RETRIEVE_CONSUMABLE_BY_ID_FAILURE")
      openToast({
        type: "error",
        title: "Retrieve failed",
        message: "Retrieve consumable failed",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "SELECT_CM":
      console.warn("SELECT_CM")
      return {
        ...state,
        cmIndex: action.payload,
      }
    case "ADD_CONSUMABLE_LIST":
      console.warn("ADD_CONSUMABLE_LIST")
      return {
        ...state,
        isLoading: true,
      }
    case "ADD_CONSUMABLE_LIST_SUCCESS":
      console.warn("ADD_CONSUMABLE_LIST_SUCCESS")
      openToast({
        type: "success",
        title: "Add Done",
        message: "Add consumable list successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "ADD_CONSUMABLE_LIST_FAILURE":
      console.warn("ADD_CONSUMABLE_LIST_FAILURE")
      openToast({
        type: "error",
        title: "Add failed",
        message: "Add consumable list failed",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "DELETE_CONSUMABLE_LIST":
      console.warn("DELETE_CONSUMABLE_LIST")
      return {
        ...state,
        isLoading: true,
      }
    case "DELETE_CONSUMABLE_LIST_SUCCESS":
      console.warn("DELETE_CONSUMABLE_LIST_SUCCESS")
      openToast({
        type: "success",
        title: "Delete done",
        message: "Delete consumable successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "DELETE_CONSUMABLE_LIST_FAILURE":
      console.warn("DELETE_CONSUMABLE_LIST_FAILURE")
      openToast({
        type: "error",
        title: "Delete Failed",
        message: "Delete consumable failed",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "ADD_CONSUMABLE_USAGE_RECORD":
      console.warn("ADD_CONSUMABLE_USAGE_RECORD")
      return {
        ...state,
        isLoading: true,
      }
    case "ADD_CONSUMABLE_USAGE_RECORD_SUCCESS":
      console.warn("ADD_CONSUMABLE_USAGE_RECORD_SUCCESS")
      openToast({
        type: "success",
        title: "Add done",
        message: "Add consumable successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "ADD_CONSUMABLE_USAGE_RECORD_FAILURE":
      console.warn("ADD_CONSUMABLE_USAGE_RECORD_FAILURE")
      openToast({
        type: "error",
        title: "Add failed",
        message: "Add consumable failed",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "DELETE_CONSUMABLE_USAGE_RECORD":
      console.warn("DELETE_CONSUMABLE_USAGE_RECORD")
      return {
        ...state,
        isLoading: true,
      }
    case "DELETE_CONSUMABLE_USAGE_RECORD_SUCCESS":
      console.warn("DELETE_CONSUMABLE_USAGE_RECORD_SUCCESS")
      openToast({
        type: "success",
        title: "Delete done",
        message: "Delete consumable usage successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "DELETE_CONSUMABLE_USAGE_RECORD_FAILURE":
      console.warn("DELETE_CONSUMABLE_USAGE_RECORD_FAILURE")
      openToast({
        type: "error",
        title: "Delete Failed",
        message: "Delete consumable usage failed",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default reducer
