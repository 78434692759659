import React, { useState, useEffect } from "react"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import { useDispatch, useSelector } from "react-redux"
import Column from "@amzn/meridian/column"
import Text from "@amzn/meridian/text"
import axios from "axios"
import {
  retrieveFilesBySOP,
  retrieveFilesByTraining,
} from "../../../redux/project/action"
import env from "../../../services/Auth/env"
import FileInputWrapper from "../../../components/file-library/fileInputWrapper"
import { FormFilePicker, FormInput, FormSelect } from "../../../components/app"
import { getLocationsByCmName } from "../../../util/Util"
import * as auth from "../../../services/Auth/auth"

const UploadSelfModal = props => {
  const {
    uploadSelfModalOpen,
    setSelectEquipmentModalOpen,
    setUploadSelfModalOpen,
    currentData,
    cm,
  } = props
  let projectName = currentData && currentData.projectName
  const sopFiles = useSelector(state => state.projectReducer.sopFiles)
  const trainingFiles = useSelector(state => state.projectReducer.trainingFiles)
  const cms = useSelector(state => state.commonReducer.cms)
  const { username } = useSelector(state => state.userReducer)

  const selectedEquipIds = useSelector(
    state => state.projectReducer.selectedEquipIds
  )
  const [location, setLocation] = useState()
  const [sop, setSop] = useState([])
  const [training, setTraining] = useState([])
  const [files, setFiles] = useState()
  const [score, setScore] = useState()
  const reduxDispatch = useDispatch()

  const resetState = () => {
    setSop([])
    setTraining([])
    setFiles()
    setScore()
    setUploadSelfModalOpen(false)
  }

  useEffect(() => {
    if (uploadSelfModalOpen) {
      reduxDispatch(retrieveFilesBySOP({ cm }))
      reduxDispatch(retrieveFilesByTraining({ cm }))
    }
  }, [reduxDispatch, uploadSelfModalOpen, cm])
  return (
    <Modal
      title="Upload Self-Assessment"
      open={uploadSelfModalOpen}
      onClose={() => {
        resetState()
      }}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
      width="600px"
    >
      <form>
        <FormSelect
          value={location}
          setValue={setLocation}
          label={"Location"}
          options={getLocationsByCmName(cms, cm).map(x => ({
            value: x,
            label: x,
          }))}
        />
        <Row spacingInset="small none">
          <Column minWidth="150px">
            <Text tag="label" type="b200" color="primary" htmlFor="my-input">
              Self-assessment
            </Text>
          </Column>
          <Column minWidth="350px">
            <FileInputWrapper
              uploadButtonDisabled={!location}
              uploadButtonLabel={"Add File"}
              fileParam={{
                file_level: "project",
                file_type: "selfAssessment",
                cm: cm,
                cm_location: location,
                uploaded_by: username,
                project_name: projectName,
              }}
              callback={(filteredFile, fileId) => {
                setFiles(fileId)
              }}
            />
          </Column>
        </Row>
        <FormInput value={score} setValue={setScore} label={"Total Score"} />
        <FormFilePicker
          value={sop}
          setValue={setSop}
          files={sopFiles}
          disabled={!location}
          label={"Project SOP"}
          callback={() => reduxDispatch(retrieveFilesBySOP({ cm }))}
          fileParam={{
            file_level: "project",
            file_type: "sop",
            cm: cm,
            cm_location: location,
            uploaded_by: username,
            project_name: projectName,
          }}
        />
        <FormFilePicker
          value={training}
          disabled={!location}
          setValue={setTraining}
          files={trainingFiles}
          label={"Training Record"}
          callback={() => reduxDispatch(retrieveFilesByTraining({ cm }))}
          fileParam={{
            file_level: "project",
            file_type: "trainingRecord",
            cm: cm,
            cm_location: location,
            uploaded_by: username,
            project_name: projectName,
          }}
        />
      </form>
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            type="secondary"
            size="small"
            onClick={() => {
              resetState()
              setSelectEquipmentModalOpen(true)
            }}
          >
            &#60; Goback
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              auth.getToken().then(cookie => {
                axios(env.API_ENDPOINT + env.APIPath.uploadSelfAssessment, {
                  method: "PUT",
                  headers: { idToken: cookie },
                  data: {
                    cm: cm,
                    projectName: projectName,
                    equipmentIds: selectedEquipIds,
                    totalScore: parseInt(score),
                    selfAssessmentFileId: files,
                    sopFileIds: sop,
                    trainingRecordFileIds: training,
                  },
                })
                  .then(response => {
                    console.log("uploaded")
                  })
                  .catch(error => {
                    console.warn("error", error)
                  })
              })
              resetState()
            }}
          >
            Save
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default UploadSelfModal
