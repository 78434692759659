/**
 * Helpful form field components. In addition to the basics this includes things
 * like planet selectors. All fields include a custom `missing` prop that can be
 * used to show a "this field is required" error.
 */

import React from "react"
import Alert from "@amzn/meridian/alert"
import Column from "@amzn/meridian/column"
import MeridianInput from "@amzn/meridian/input"
import MeridianSelect, { SelectOption } from "@amzn/meridian/select"
import MeridianDatePicker from "@amzn/meridian/date-picker"

/**
 * This wraps up a field component in order to add a "missing" prop that can be
 * used to render the field in an error state and display an error alert below
 * the field.
 */
const createFieldWithErrorMessage = component => ({ missing, ...props }) => {
  const element = React.createElement(component, { ...props, error: missing })
  return (
    <Column spacing="small">
      {element}
      {missing ? (
        <Alert type="error" size="small">
          This field is required.
        </Alert>
      ) : null}
    </Column>
  )
}

const Input = createFieldWithErrorMessage(MeridianInput)
const DatePicker = createFieldWithErrorMessage(MeridianDatePicker)
const Select = createFieldWithErrorMessage(MeridianSelect)

export { Input, DatePicker, Select, SelectOption }
