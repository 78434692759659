import React, { Fragment, useState, useEffect } from "react"
import { useSelector } from "react-redux"

import Text from "@amzn/meridian/text"
import Link from "@amzn/meridian/link"
import Button from "@amzn/meridian/button"
import Icon from "@amzn/meridian/icon"
import Column from "@amzn/meridian/column"
import FileInput from "@amzn/meridian/file-input"

import Tab, { TabGroup } from "@amzn/meridian/tab"
import deleteTokens from "@amzn/meridian-tokens/base/icon/delete"
import { css } from "emotion"

import axios from "axios"
import qs from "qs"

import PageLayout from "../../../components/app/page-layout"
import * as auth from "../../../services/Auth/auth"
import env from "../../../services/Auth/env"
import { fileSvg } from "../../../data/constants"
import { DeleteConfirmModal } from "../../../components/app"

const filenameStyles = css({
  textWrap: "no-wrap",
  display: "flex",
  alignItems: "center",
})

const retrieveData = (query, apiPath, callback) => {
  auth.getToken().then(cookie => {
    axios(env.API_ENDPOINT + apiPath + "?" + query, {
      method: "GET",
      headers: { idToken: cookie },
    })
      .then(response => {
        callback && callback(response?.data)
      })
      .catch(error => {})
  })
}

const updateData = (payload, apiPath, callback) => {
  auth.getToken().then(cookie => {
    axios(env.API_ENDPOINT + apiPath, {
      method: "POST",
      data: payload,
      headers: { idToken: cookie },
    })
      .then(response => {
        // const data = response.data.complianceStatusList
        callback && callback()
      })
      .catch(error => {
        callback && callback()
      })
  })
}

const RequirementTab = props => {
  const [files, setFiles] = useState([])
  const { templates, type, username, setIsLoading, isAdmin } = props
  const [currentData, setCurrentData] = useState({})
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState("")

  return (
    <Column>
      {isAdmin && (
        <FileInput
          dropZoneDirection="column"
          showDropZone={true}
          uploadButtonLabel={`Upload ${type}`}
          uploadButtonType={"link"}
          uploadButtonSize={"small"}
          type={"single"}
          // accept={accept}
          onFileAttached={acceptedFiles => {
            let filteredFiles = acceptedFiles.filter(
              f => !files.some(fl => fl.name === f.name)
            )
            filteredFiles.forEach(file => {
              let params = {
                file_name: file.name,
                uploaded_date: new Date().getTime(),
                file_type: type,
                uploaded_by: username,
              }
              auth.getToken().then(cookie => {
                setIsLoading(true)
                axios(env.API_ENDPOINT + env.APIPath.uploadRequirementFile, {
                  method: "PUT",
                  params: params,
                  headers: { idToken: cookie },
                  // timeout: 10000,
                  data: file,
                  onUploadProgress: e => {
                    let completeProgress = ((e.loaded / e.total) * 100) | 0
                    file.uploadPercentage = completeProgress
                    setFiles([...filteredFiles])
                  },
                })
                  .then(response => {
                    setIsLoading(false)
                    if (response.data && response.data.errorMessage) {
                      file.error = true
                      file.errorMessage = response.data.errorMessage
                      setFiles([...files, ...filteredFiles])
                      console.warn("error", file.errorMessage)
                    } else {
                      // callback &&
                      // callback(filteredFiles[0].path, response.data.fileId)
                      console.warn("upload succeed")
                    }
                  })
                  .catch(error => {
                    setIsLoading(false)
                    console.warn("error", error)
                    file.error = true
                    setFiles([...files, ...filteredFiles])
                  })
              })
            })
            setFiles([...files, ...filteredFiles])
          }}
        />
      )}

      <Text tag="ol">
        {templates.map((file, index) => {
          return (
            <li className={filenameStyles} key={file.fileName + index}>
              <Icon
                tokens={{
                  iconData: fileSvg,
                  iconWidth: 16,
                  iconHeight: 16,
                }}
              />
              <Link
                onClick={() => {
                  window.open(file.presignedURL, "_blank")
                }}
              >
                <Text type="b200">{file.fileName}</Text>
              </Link>
              <Button
                type="icon"
                disabled={!isAdmin}
                onClick={() => {
                  setCurrentData(file)
                  setDeleteConfirmModalOpen(true)
                }}
              >
                <Icon className="delete-button" tokens={deleteTokens} />
              </Button>
            </li>
          )
        })}
      </Text>
      <DeleteConfirmModal
        modalOpen={deleteConfirmModalOpen}
        setModalOpen={setDeleteConfirmModalOpen}
        action={() => {
          setIsLoading(true)
          updateData(
            { templateFileId: currentData.fileId },
            env.APIPath.deleteRequirementFile,
            data => {
              // setComplianceStatusList(data.complianceStatusList)
              setIsLoading(false)
            }
          )
        }}
        message={"Are you sure you want to delete this template?"}
      />
    </Column>
  )
}

const Requirements = () => {
  // const reduxDispatch = useDispatch()
  const [currentTab, setCurrentTab] = useState("Calibration Criteria")
  const [templates, setTemplates] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { isAdmin } = useSelector(state => state.userReducer)

  useEffect(() => {
    const query = qs.stringify({
      fileType: currentTab,
    })
    if (!isLoading) {
      retrieveData(query, env.APIPath.retrieveRequirements, data => {
        // setComplianceStatusList(data.complianceStatusList)
        setTemplates(data.retrieveTemplateFilesResponse)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, isLoading])

  return (
    <React.Fragment>
      <PageLayout
        spacing="large"
        title="Requirements"
        loading={isLoading}
        alignmentHorizontal={"start"}
      >
        <Column spacing="small">
          <TabGroup
            value={currentTab}
            onChange={setCurrentTab}
            aria-labelledby="tablist-heading"
          >
            <Tab
              value="Calibration Criteria"
              aria-controls="Calibration Criteria"
              id="Calibration Criteria"
            >
              Calibration Criteria
            </Tab>
            <Tab
              value="Equipment Checklist"
              aria-controls="Equipment Checklist"
              id="Equipment Checklist"
            >
              Equipment Checklist
            </Tab>
          </TabGroup>
          <RequirementTab
            templates={templates}
            type={currentTab}
            username={"yfamzn"}
            setIsLoading={setIsLoading}
            isAdmin={isAdmin}
          />
        </Column>
      </PageLayout>
    </React.Fragment>
  )
}

export default Requirements
