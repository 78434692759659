import { openToast } from "../../../api/toasts"
import {
  SUCCESS_TIMEOUT_IN_MS,
  FAILURE_TIMEOUT_IN_MS,
} from "../../../data/constants"

const INITIAL_STATE = {
  capabilityTemplateList: [],
  isLoading: false,
}
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "RETRIEVE_CAPABILITY_TEMPLATE_LIST":
      console.warn("RETRIEVE_CAPABILITY_TEMPLATE_LIST")
      return {
        ...state,
        capabilityTemplateList: [],
      }
    case "RETRIEVE_CAPABILITY_TEMPLATE_LIST_SUCCESS":
      console.warn("RETRIEVE_CAPABILITY_TEMPLATE_LIST_SUCCESS", action.payload)
      return {
        ...state,
        capabilityTemplateList: action.payload,
      }
    case "RETRIEVE_CAPABILITY_TEMPLATE_LIST_FAILURE":
      console.warn("RETRIEVE_CAPABILITY_TEMPLATE_LIST_FAILURE")
      return {
        ...state,
      }
    case "ADD_CAPABILITY_TEMPLATE":
      return {
        ...state,
        isLoading: true,
      }
    case "ADD_CAPABILITY_TEMPLATE_SUCCESS":
      openToast({
        type: "success",
        title: "Add Done",
        message: "Add new capabilityTemplate successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "ADD_CAPABILITY_TEMPLATE_FAILURE":
      openToast({
        type: "error",
        title: "Update failed",
        message:
          action.payload ||
          "Failed to add new capabilityTemplate, please try again",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "DELETE_CAPABILITY_TEMPLATE":
      console.warn("DELETE_CAPABILITY_TEMPLATE")
      return {
        ...state,
        isLoading: true,
      }
    case "DELETE_CAPABILITY_TEMPLATE_SUCCESS":
      console.warn("DELETE_CAPABILITY_TEMPLATE_SUCCESS", action.payload)
      openToast({
        type: "success",
        title: "Delete Done",
        message: "Delete capabilityTemplate successfully",
        timeout: SUCCESS_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    case "DELETE_CAPABILITY_TEMPLATE_FAILURE":
      console.warn("DELETE_CAPABILITY_TEMPLATE_FAILURE", action.payload)
      openToast({
        type: "error",
        title: "Delete failed",
        message: "Failed to delete capabilityTemplate, please try again",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default reducer
