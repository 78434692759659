export const retrieveCalibrationMonthSummary = payload => ({
  type: "RETRIEVE_CALIBRATION_MONTH_SUMMARY",
  payload: payload,
})

export const retrieveCalibrationMonthSummarySuccess = payload => ({
  type: "RETRIEVE_CALIBRATION_MONTH_SUMMARY_SUCCESS",
  payload: payload,
})

export const retrieveCalibrationMonthSummaryFailure = payload => ({
  type: "RETRIEVE_CALIBRATION_MONTH_SUMMARY_FAILURE",
  payload: payload,
})

export const selectCM = payload => ({
  type: "SELECT_CM",
  payload: payload,
})

export const selectYear = payload => ({
  type: "SELECT_YEAR",
  payload: payload,
})

export const retrieveCalibrationMonthEquipmentDetails = payload => ({
  type: "RETRIEVE_CALIBRATION_MONTH_EQUIPMENT_DETAILS",
  payload: payload,
})

export const retrieveCalibrationMonthEquipmentDetailsSuccess = payload => ({
  type: "RETRIEVE_CALIBRATION_MONTH_EQUIPMENT_DETAILS_SUCCESS",
  payload: payload,
})

export const retrieveCalibrationMonthEquipmentDetailsFailure = payload => ({
  type: "RETRIEVE_CALIBRATION_MONTH_EQUIPMENT_DETAILS_FAILURE",
  payload: payload,
})
