/**
 * The application landing page/home page.
 */

import React, { useState } from "react"
import Column from "@amzn/meridian/column"
import Badge from "@amzn/meridian/badge"
import { useDispatch, useSelector } from "react-redux"
import Table, {
  TableRow,
  TableCell,
  TableActionBar,
} from "@amzn/meridian/table"
import SearchField from "@amzn/meridian/search-field"
import Row from "@amzn/meridian/row"
import Icon from "@amzn/meridian/icon"
import Text from "@amzn/meridian/text"
import pencilTokens from "@amzn/meridian-tokens/base/icon/pencil"
import plusCircleTokens from "@amzn/meridian-tokens/base/icon/plus-circle"
import Tooltip from "@amzn/meridian/tooltip"
import Toggle from "@amzn/meridian/toggle"
import Button from "@amzn/meridian/button"
import Link from "@amzn/meridian/link"
import filterTokens from "@amzn/meridian-tokens/base/icon/filter"
import { useHistory } from "react-router-dom"

import {
  retrieveProject,
  selectCM,
  archiveProject,
} from "../../redux/project/action"
import EmptyState from "../../components/app/empty-state"
import { usePagination, useSort } from "../../hooks"
import FilterTags from "../../components/file-library/filter-tag"
import useFilters from "../../hooks/use-filters"
import filterProject from "../../filter-project"
import { Select, SelectOption } from "../../components/app/form-fields"
import {
  AddProjectModal,
  EquipmentListModal,
  SelectEquipmentModal,
  UploadSelfModal,
  ProjectFileModal,
  EditProjectModal,
  ProjectFiltersModal,
} from "./modals"
import PageLayout from "../../components/app/page-layout"
import StatusTag from "../../components/app/status-tag"

const ProjectStatus = props => {
  const [keyword, setKeyword] = useState("")
  const [addProjectModalOpen, setAddProjecteModalOpen] = useState(false)
  const [equipmentListModalOpen, setEquipmentListModalOpen] = useState(false)
  const [selectEquipmentModalOpen, setSelectEquipmentModalOpen] = useState(
    false
  )
  const [projectFileModalOpen, setProjectFileModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [fileType, setFileType] = useState("")

  const [uploadSelfModalOpen, setUploadSelfModalOpen] = useState(false)
  const [editProjectModalOpen, setEditProjectModalOpen] = useState(false)

  const [currentData, setCurrentData] = useState()
  const cmList = useSelector(state => state.commonReducer.cmList)
  const { projectList, isLoading, cm } = useSelector(
    state => state.projectReducer
  )
  const { isCMUser } = useSelector(state => state.userReducer)

  const { filters } = useFilters()
  const data = filterProject(projectList, filters)
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const reduxDispatch = useDispatch()
  const history = useHistory()

  const { sortedData, onSort, sortColumn, sortDirection } = useSort(data)
  const { TableFooter, visibleIndices } = usePagination(projectList.length)

  const OpenEquipmentFileModal = (title, type, rowData) => {
    setProjectFileModalOpen(true)
    setCurrentData(rowData)
    setModalTitle(
      `${title} for ${rowData.projectFamilyName} - ${rowData.projectName}`
    )
    setFileType(type)
  }

  return (
    <PageLayout
      spacing="large"
      alignmentHorizontal={"start"}
      title="Project Status"
      loading={isLoading}
    >
      <Row
        minWidth={1540}
        alignmentVertical="center"
        spacingInset="400"
        minHeight={50}
      >
        <Column spacing="large">
          <Select
            label="CM Name"
            value={cm}
            onChange={c => cm !== c && reduxDispatch(selectCM(c))}
            minWidth={240}
            size={"small"}
          >
            {cmList.map(cm => (
              <SelectOption key={cm} value={cm} label={cm} />
            ))}
          </Select>
        </Column>
        <Column spacing="large">
          <Button
            disabled={!cm}
            minWidth={90}
            type="primary"
            onClick={() => {
              reduxDispatch(retrieveProject(cm))
            }}
          >
            View
          </Button>
        </Column>
      </Row>
      <Row>
        <Column minWidth={1540}>
          <Table
            headerRows={1}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={onSort}
            showDividers={true}
            spacing="small"
            // stickyHeaderRow={true}
            fixHeaderRows={true}
            minWidth={1540}
          >
            <TableActionBar widths={[300]}>
              <SearchField
                value={keyword}
                onChange={setKeyword}
                placeholder="Search for..."
                // size="medium"
                type="text"
                onSubmit={() => {}}
              />
              <Button onClick={() => setFilterModalOpen(true)} type="icon">
                {filters.size > 0 ? (
                  <Icon tokens={filterTokens}>
                    {`${filters.size} Filters`}
                    <Badge type="theme" value={filters.size} locale="en-US" />
                  </Icon>
                ) : (
                  <Icon tokens={filterTokens}></Icon>
                )}
              </Button>
              <Tooltip position="top" title="Add new project" id="myTooltip">
                <Button
                  type="icon"
                  disabled={isCMUser || !cm}
                  onClick={() => setAddProjecteModalOpen(true)}
                >
                  <Icon tokens={plusCircleTokens}>Save</Icon>
                </Button>
              </Tooltip>
              <div />
            </TableActionBar>
            <FilterTags stick={true} />
            <TableRow>
              <TableCell sortColumn="cm">CM</TableCell>
              <TableCell sortColumn="cmLocation">Location</TableCell>
              <TableCell sortColumn="projectName">Project</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Equipment list</TableCell>
              <TableCell sortColumn="sopApprovalStatus">Project SOP</TableCell>
              <TableCell sortColumn="trainingRecordApprovalStatus">
                Training record
              </TableCell>
              <TableCell>Self-assessment file</TableCell>
              <TableCell sortColumn="amazonAuditScore">Score</TableCell>
              <TableCell sortColumn="auditResult">Audit result</TableCell>
              <TableCell sortColumn="compliancePercentage">
                Compliance
              </TableCell>
              <TableCell sortColumn="isArchived">Archive</TableCell>
            </TableRow>
            {sortedData
              .slice(visibleIndices[0], visibleIndices[1])
              .map((row, index) => (
                <TableRow key={row.cm + index}>
                  <TableCell>{row.cm}</TableCell>
                  <TableCell>{row.cmLocation}</TableCell>
                  <TableCell>
                    <Link
                      onClick={() => {
                        history.push({
                          pathname: "audit-report",
                          state: {
                            cm: cm,
                            project: row,
                          },
                        })
                      }}
                    >
                      {`${row.projectFamilyName} - ${row.projectName}`}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Text>
                      <Link
                        onClick={() => {
                          setCurrentData(row)
                          setEditProjectModalOpen(true)
                          // setSelectEquipmentModalOpen(true)
                        }}
                      >
                        <Icon tokens={pencilTokens} />
                      </Link>
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Link
                      onClick={() => {
                        setCurrentData(row)
                        setEquipmentListModalOpen(true)
                      }}
                    >
                      Details
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      onClick={() => {
                        OpenEquipmentFileModal("Project SOP", "sop", row)
                      }}
                    >
                      {row.sopApprovalStatus}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      onClick={() => {
                        OpenEquipmentFileModal(
                          "Training Record",
                          "trainingRecord",
                          row
                        )
                      }}
                    >
                      {row.trainingRecordApprovalStatus}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      onClick={() => {
                        OpenEquipmentFileModal(
                          "Self-assessment file",
                          "selfAssessment",
                          row
                        )
                      }}
                    >
                      {"Details"}
                    </Link>
                  </TableCell>
                  <TableCell
                    width={220}
                  >{` Amazon Audit Score: ${row.amazonAuditScore?.toFixed(
                    2
                  )}`}</TableCell>
                  <TableCell>
                    <Text alignment="center">
                      <StatusTag status={row.auditResult} />
                    </Text>
                  </TableCell>
                  <TableCell>{`${row.compliancePercentage}%`}</TableCell>
                  <TableCell>
                    <Toggle
                      disabled={isCMUser}
                      checked={row.isArchived}
                      onChange={() => {
                        reduxDispatch(
                          archiveProject(
                            {
                              // cm: cm,
                              projectName: row.projectName,
                              isArchived: !row.isArchived,
                            },
                            cm
                          )
                        )
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </Table>
          {sortedData && sortedData.length === 0 ? (
            <EmptyState target={"projects"} />
          ) : null}
          <TableFooter />
        </Column>
      </Row>
      <AddProjectModal
        addProjectModalOpen={addProjectModalOpen}
        setAddProjecteModalOpen={setAddProjecteModalOpen}
        cm={cm}
      />
      <EquipmentListModal
        equipmentListModalOpen={equipmentListModalOpen}
        setEquipmentListModalOpen={setEquipmentListModalOpen}
        currentData={currentData}
      />
      <SelectEquipmentModal
        selectEquipmentModalOpen={selectEquipmentModalOpen}
        setSelectEquipmentModalOpen={setSelectEquipmentModalOpen}
        setUploadSelfModalOpen={setUploadSelfModalOpen}
        currentData={currentData}
      />
      <UploadSelfModal
        uploadSelfModalOpen={uploadSelfModalOpen}
        setUploadSelfModalOpen={setUploadSelfModalOpen}
        setSelectEquipmentModalOpen={setSelectEquipmentModalOpen}
        currentData={currentData}
        cm={cm}
      />
      <EditProjectModal
        editProjectModalOpen={editProjectModalOpen}
        setEditProjectModalOpen={setEditProjectModalOpen}
        currentData={currentData}
        cm={cm}
      />
      <ProjectFileModal
        projectFileModalOpen={projectFileModalOpen}
        setProjectFileModalOpen={setProjectFileModalOpen}
        currentData={currentData}
        fileType={fileType}
        modalTitle={modalTitle}
        cm={cm}
      />
      <ProjectFiltersModal
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
      />
    </PageLayout>
  )
}
export default ProjectStatus
