// /**
//  * This is a client for a fake notifications service. The API is theoretically
//  * similar to something you might use IRL, but this fake client gets/sets data
//  * in local storage whereas a real client would probably GET/POST data via
//  * HTTPS requests to a remote service.
//  */

// import startOfDay from "date-fns/startOfDay"
// import startOfWeek from "date-fns/startOfWeek"
// import startOfMonth from "date-fns/startOfMonth"
// import subDays from "date-fns/subDays"
// import getTime from "date-fns/getTime"
// import defaultNotifications from "../data/notifications"
import createApi from "./create-api"

// const now = Date.now()

// const groups = [
//   { label: "Today", sinceTimestamp: getTime(startOfDay(now)) },
//   { label: "Yesterday", sinceTimestamp: getTime(startOfDay(subDays(now, 1))) },
//   { label: "This week", sinceTimestamp: getTime(startOfWeek(now)) },
//   { label: "This month", sinceTimestamp: getTime(startOfMonth(now)) },
// ]

const api = createApi("notifications", {
  defaultData: [],
  networkDelay: 1000,
  persistInLocalStorage: false,
})

const listenToNotifications = api.listen

const openNotifications = notificationList => {
  // eslint-disable-next-line no-debugger
  debugger
  const id = Math.random().toString()
  return api.update(notifications =>
      notifications.concat(notificationList)
  )
}
const closeNotification = () => api.update(notifications => [])

// const updateNotifications = api.update

// const markNotificationAsRead = id =>
//   api.update(notifications =>
//     notifications.map(notification =>
//       notification.id === id ? { ...notification, read: true } : notification
//     )
//   )

// const markAllNotificationsAsRead = () =>
//   api.update(notifications =>
//     notifications.map(notification => ({
//       ...notification,
//       read: true,
//     }))
//   )

// /**
//  * Groups a flat array of notifications in an array of groups (label + array of
//  * notifications) according to the chronological categories listed above.
//  */
// const groupNotifications = notifications => {
//   let groupedNotifications = groups.map((group, index) => ({
//     ...group,
//     notifications: notifications.filter(notification => {
//       const previousGroup = groups[index - 1]
//       return (
//         notification.timestamp >= group.sinceTimestamp &&
//         (!previousGroup ||
//           notification.timestamp < previousGroup.sinceTimestamp)
//       )
//     }),
//   }))
//   groupedNotifications.push({
//     label: "Older",
//     notifications: notifications.filter(
//       notification =>
//         notification.timestamp < groups[groups.length - 1].sinceTimestamp
//     ),
//   })
//   return groupedNotifications.filter(group => group.notifications.length > 0)
// }

export { listenToNotifications, openNotifications, closeNotification }

// export {
//   listenToNotifications,
//   getNotifications,
//   updateNotifications,
//   markNotificationAsRead,
//   markAllNotificationsAsRead,
//   groupNotifications,
// }
