import React, { useState, useEffect } from "react"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import { useSelector, useDispatch } from "react-redux"
import Table, {
  TableRow,
  TableCell,
  TableActionBar,
} from "@amzn/meridian/table"
import Icon from "@amzn/meridian/icon"
import Link from "@amzn/meridian/link"
import Text from "@amzn/meridian/text"
import SearchField from "@amzn/meridian/search-field"
import { useHistory } from "react-router-dom"
import plusCircleTokens from "@amzn/meridian-tokens/base/icon/plus-circle"
import Tooltip from "@amzn/meridian/tooltip"
import deleteTokens from "@amzn/meridian-tokens/base/icon/delete"
import editTokens from "@amzn/meridian-tokens/base/icon/edit"

import { usePagination, useSort } from "../../../hooks"

import PageLayout from "../../../components/app/page-layout"
import EmptyState from "../../../components/app/empty-state"
import AddCapabilityTemplateModal from "../modals/add-capability-template"
import { DeleteConfirmModal } from "../../../components/app"

import {
  retrieveCapabilityTemplateList,
  deleteCapabilityTemplate,
} from "../../../redux/capabilityTemplate/action"
import { getRegex } from "../../../util/Util"

const CapabilityTemplate = () => {
  // const [checked, setChecked] = useState(false);
  const [currentData, setCurrentData] = useState({})
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState("")

  const [mode, setMode] = useState("add")
  // const [projectParam, setProjectParam] = useState()
  const { capabilityTemplateList, isLoading } = useSelector(
    state => state.templateReducer
  )
  const { isCMUser, isAdmin } = useSelector(state => state.userReducer)

  const reduxDispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  // for searching and filtering
  const [keyword, setKeyword] = useState("")
  const [
    addCapabilityTemplateModalOpen,
    setAddCapabilityTemplateModalOpen,
  ] = useState(false)

  const data = capabilityTemplateList.filter(f => {
    let regex = getRegex(keyword)
    return regex.test(JSON.stringify(f))
  })

  useEffect(() => {
    reduxDispatch(retrieveCapabilityTemplateList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { sortedData, onSort, sortColumn, sortDirection } = useSort(data)

  const history = useHistory()
  const { TableFooter, visibleIndices } = usePagination(data.length)

  return (
    <React.Fragment>
      <PageLayout
        spacing="large"
        alignmentHorizontal={"start"}
        title="Capability Template"
        loading={isLoading}
        width={"100%"}
      >
        <Row spacingInset="small none">
          <Column spacing="large" width="100%">
            <Table
              headerRows={1}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              onSort={onSort}
              showDividers={true}
              spacing="small"
              // stickyHeaderRow={true}
              fixHeaderRows={true}
              // showStripes={true}
            >
              <TableActionBar widths={[300]}>
                <SearchField
                  value={keyword}
                  onChange={setKeyword}
                  placeholder="Search for..."
                  // size="medium"
                  type="text"
                  onSubmit={() => {}}
                />
                <Tooltip position="top" title="Add new template" id="myTooltip">
                  <Button
                    type="icon"
                    disabled={!isAdmin}
                    onClick={() => {
                      setMode("add")
                      setCurrentData({})
                      setAddCapabilityTemplateModalOpen(true)
                    }}
                  >
                    <Icon tokens={plusCircleTokens}>Save</Icon>
                  </Button>
                </Tooltip>
              </TableActionBar>
              <TableRow>
                <TableCell sortColumn="category">Category</TableCell>
                <TableCell sortColumn="subcategory">Subcategory</TableCell>
                <TableCell sortColumn="capabilityTemplate">
                  Capability Template
                </TableCell>
                <TableCell sortColumn="coverageTemplate">Coverage</TableCell>
                <TableCell>Edit</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
              {sortedData &&
                sortedData
                  .slice(visibleIndices[0], visibleIndices[1])
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.category}</TableCell>
                      <TableCell>{row.subcategory}</TableCell>
                      <TableCell>
                        <span style={{ whiteSpace: "pre-line" }}>
                          {row.capacityTemplate}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ whiteSpace: "pre-line" }}>
                          {row.coverageTemplate}
                        </span>
                      </TableCell>
                      <TableCell>
                        <Text>
                          <Link
                            disabled={!isAdmin}
                            onClick={() => {
                              setMode("edit")
                              setCurrentData(row)
                              setAddCapabilityTemplateModalOpen(true)
                            }}
                          >
                            <Icon tokens={editTokens}>Edit</Icon>
                          </Link>
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text>
                          <Link
                            disabled={!isAdmin}
                            onClick={() => {
                              setCurrentData(row)
                              setDeleteConfirmModalOpen(true)
                            }}
                          >
                            <Icon tokens={deleteTokens} />
                          </Link>
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))}
            </Table>
            {sortedData && sortedData.length === 0 ? (
              <EmptyState target={"templates"} />
            ) : null}
            <TableFooter />
          </Column>
        </Row>

        <AddCapabilityTemplateModal
          modalOpen={addCapabilityTemplateModalOpen}
          setModalOpen={setAddCapabilityTemplateModalOpen}
          currentData={currentData}
          mode={mode}
        />
        <DeleteConfirmModal
          modalOpen={deleteConfirmModalOpen}
          setModalOpen={setDeleteConfirmModalOpen}
          action={() => {
            reduxDispatch(
              deleteCapabilityTemplate({
                templateId: currentData.id,
              })
            )
          }}
          message={"Are you sure you want to delete this template?"}
        />
      </PageLayout>
    </React.Fragment>
  )
}

export default CapabilityTemplate
