import React, { useState } from "react"
// import {useHistory} from "react-router-dom";
import Row from "@amzn/meridian/row"
// import Checkbox from "@amzn/meridian/checkbox";
import Button from "@amzn/meridian/button"

import Modal, { ModalFooter } from "@amzn/meridian/modal"
import DateRangePicker from "@amzn/meridian/date-range-picker"

const EffectiveModal = props => {
  // eslint-disable-next-line no-unused-vars
  const { effectiveModalOpen, setEffectiveModalOpen } = props
  const [value, setValue] = useState(["", ""])
  const resetState = () => {
    setValue(["", ""])
    setEffectiveModalOpen(false)
  }
  return (
    <Modal
      title="Update Effective Date"
      open={effectiveModalOpen}
      // onClose={onClose}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
    >
      <form>
        <Row spacingInset="small none">
          <DateRangePicker
            value={value}
            onChange={setValue}
            startLabel="Start date"
            endLabel="End date"
            monthsInView={2}
          />
        </Row>
      </form>
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            type="primary"
            size="small"
            disabled={value[0].length === 0 || value[1].length === 0}
            onClick={() => {
              let data = {
                effectiveStartDate: value[0],
                effectiveEndDate: value[1],
              }
              props.onUpdateEffectiveDate(data)
              setEffectiveModalOpen(false)
            }}
          >
            Confirm
          </Button>
          <Button type="secondary" size="small" onClick={() => resetState()}>
            Cancel
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export { EffectiveModal }
