import * as Cookies from "js-cookie"
// import axios from "axios"
import qs from "qs"
import CryptoJS from "crypto-js"

import env from "./env"
import { getUrlParams } from "../../util/Util"

const CODE_VERIFIER = "dBjftJeZ4CVP-mB92K27uhbUJU1p1r_wW1gFWFOEjXk" //generateRandomString(32)
const CODE_CHALLENGE = generateCodeChallenge(CODE_VERIFIER)
console.warn("CODE_VERIFIER", CODE_VERIFIER)
console.warn("CODE_CHALLENGE", CODE_CHALLENGE)

const authority = env.OIDC_CONFIG.authority
const client_id = env.OIDC_CONFIG.client_id
const client_secret = env.OIDC_CONFIG.client_secret
const redirect_uri = env.OIDC_CONFIG.post_logout_redirect_uri
const token_endpoint = env.OIDC_CONFIG.token_endpoint

export async function authenticate() {
  // let state = create_UUID()
  // let nonce = "somecorrelationnonce"

  // let query = `client_id=${client_id}&state=abc&nonce=${nonce}&response_type=code&redirect_uri=${redirect_uri}&scope=openid&response_mode=query`
  // let authorize_query = `state=${state}&client_id=${client_id}&response_type=code&redirect_uri=${encodeURIComponent(
  //   redirect_uri
  // )}&scope=openid+profile&nonce=${nonce}`

  let query = qs.stringify({
    client_id: client_id,
    // nonce: nonce,
    response_type: "code",
    redirect_uri: redirect_uri,
    scope: "openid",
    response_mode: "query",
    state: "abc",
  })

  let authorize_query = qs.stringify({
    client_id: client_id,
    code_challenge: CODE_CHALLENGE,
    code_challenge_method: "S256",
    response_type: "code",
    redirect_uri: redirect_uri,
    state: "abc",
    // scope: "openid+profile",
    // nonce: nonce,
  })

  await fetch(authority + "?" + query, {
    method: "GET",
    mode: "cors",
  })
    .then(response => response.json())
    .then(data => {
      console.warn("authenticate response", data)
      window.location.href = data.authorization_endpoint + "?" + authorize_query
    })
}
export async function getTokenApi() {
  const urlParams = getUrlParams(window.location.search)

  return await fetch(token_endpoint, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      // eslint-disable-next-line prettier/prettier
      // "Authorization": `Basic ${btoa(client_id + ":" + client_secret)}`,
    },
    body: qs.stringify({
      code: urlParams["code"],
      client_secret: client_secret,
      client_id: client_id,
      code_verifier: CODE_VERIFIER,
      redirect_uri: redirect_uri,
      grant_type: "authorization_code",
    }),
  })
}

// function generateRandomString(length) {
//   let text = ""
//   let possible =
//     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
//   for (var i = 0; i < length; i++) {
//     text += possible.charAt(Math.floor(Math.random() * possible.length))
//   }
//   return text
// }

function generateCodeChallenge(code_verifier) {
  return base64URL(CryptoJS.SHA256(code_verifier))
}
function base64URL(string) {
  return string
    .toString(CryptoJS.enc.Base64)
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
}

export async function login() {
  const endpoint = env.API_ENDPOINT + "/login"
  console.warn("endpoint", endpoint)
  const urlParams = getUrlParams(window.location.search)

  let data = JSON.stringify({
    loginHeaders: {
      authorization_code: urlParams["code"],
      // redirect_uri: redirect_uri,
    },
  })
  console.warn("request data", data)

  return await fetch(endpoint, {
    method: "POST",
    mode: "cors",
    headers: {
      // "Content-Type": "text/plain",
      // eslint-disable-next-line prettier/prettier
      authorization_code: urlParams["code"],
    },
    body: JSON.stringify({
      loginHeaders: {
        authorization_code: urlParams["code"],
        environment: "DEV-LOCAL",
        // redirect_uri: redirect_uri,
      },
    }),
  })
}

export function getAccessToken() {
  return {
    cookie_value: Cookies.get(env.AUTH_SESSION_COOKIE),
  }
}

export function removeToken() {
  if (Cookies.get(env.AUTH_SESSION_COOKIE)) {
    Cookies.remove(env.AUTH_SESSION_COOKIE, { path: "/" })
  }
}

export async function refreshToken() {
  const auth = getAccessToken()
  let cookie

  try {
    if (auth.accessToken) {
      cookie = JSON.parse(window.atob(auth.accessToken))
      let response = await fetch(
        env.LASSO_CORP_AUTH_SESSION_URL + "?redirectUri=" + env.WEB_HOSTNAME,
        {
          method: "GET",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-type": "application/json",
            "x-xsrf-token": cookie["xf"],
          },
        }
      )
      return response
    }
  } catch (error) {
    return error
  }
}

export async function getToken() {
  let accessToken = Cookies.get(env.AUTH_SESSION_COOKIE)
  if (!accessToken && window.location.search.indexOf("code") === -1) {
    // open auth
    return authenticate()
  } else if (!accessToken) {
    // return await login()
    return await getTokenApi()
      .then(response => {
        // eslint-disable-next-line no-debugger
        // debugger
        console.warn("login response", response)
        if (response.status === 403) {
          return response
        } else {
          return response.json()
        }
      })
      .then(data => {
        console.warn("data", data)
        if (data.status === 403) {
          return 403
        }
        if (data.id_token) {
          Cookies.set(env.AUTH_SESSION_COOKIE, data.id_token, {
            expires: 1 / 24,
          })

          return data.id_token
        }
      })
      .catch(function(error) {
        console.log("error", JSON.stringify(error))
      })
  } else {
    return accessToken
  }
}
