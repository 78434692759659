let APIPath = {
  testApi: "/login",
  uploadFile: "/test-fidelity/upload_file",
  listFiles: "/test-fidelity/retrieve_files",
  approveFile: "/test-fidelity/file_library_approve_files",
  deleteFile: "/test-fidelity/file_library_delete_file",
  downloadFile: "/test-fidelity/download_file",
  updateEffectiveDate: "/test-fidelity/update_effective_date",
  addEquipment: "/test-fidelity/add_equipments",
  copyEquipment: "/test-fidelity/copy_equipments",
  updateEquipment: "/test-fidelity/update_equipment",
  updateBasicInfo: "/test-fidelity/edit_equip_basic_info",
  deleteEquipment: "/test-fidelity/delete_equipment",
  linkFilesToEquipment: "/test-fidelity/link_files_to_equipment",
  unlinkFileToEquipment: "/test-fidelity/unlink_file_to_equipment",
  retrieveOperatorList: "/test-fidelity/retrieve_certified_operators_list",
  addOperator: "/test-fidelity/add_certified_operator",
  deleteOperator: "/test-fidelity/delete_certified_operator",
  retrieveEquipmentFiles: "/test-fidelity/retrieve_files_by_equipment",
  retrieveEquipmentAuditIssues:
    "/test-fidelity/retrieve_equipment_audit_report",
  // updateEquipmentIssues: "/test-fidelity/update_issues",
  deleteEquipmentIssues: "/test-fidelity/delete_audit_issues",
  // retrieveDashboard: "/test-fidelity/retrieve_dashboard_data",
  retrieveDashboard: "/test-fidelity/retrieve_dashboard",
  retrieveEquipmentStatusHistory:
    "/test-fidelity/retrieve_equipment_status_history",
  retrieveNotificationList: "/test-fidelity/retrieve_notifications",
  retrieveNotification: "/test-fidelity/get_notification",
  updateNotification: "/test-fidelity/set_notification",

  getNewProjectStaticData: "/prod/project",
  // getNewProjectStaticData: '/projectdata',
  saveProjectData: "/prod/project",
  getAllProjects: "/prod/home",
  useNotifications: "/prod/notification",
  getAllComponents: "/components",
  getNotification: "/notification",
  saveSpecDevProjectData: "/spec_development/save",
  submitSpecDevProjectData: "/spec_development/submit",
  generateTemplate: "/spec_development/get_bom",
  approveSpecProjectData: "/spec_approval/approve",
  rejectSpecProjectData: "/spec_approval/reject",
  approveCmProjectData: "/cm_approval/approve",
  rejectCmProjectData: "/cm_approval/reject",
  retrieveCms: "/test-fidelity/retrieve_users_with_test_location",
  retrieveAllEquipment: "/test-fidelity/retrieve_equips_by_location",
  retrieveEquipment: "/test-fidelity/retrieve_equip_basic_info",
  retrieveCalibratedEquips: "/test-fidelity/retrieve_calibrated_equips",
  retrieveProject: "/test-fidelity/retrieve_projects_by_cm",
  updateProject: "/test-fidelity/upload_self_assessment",
  retrieveFilesByFileType: "/test-fidelity/retrieve_files_by_file_type",
  addProject: "/test-fidelity/add_project",
  saveProjectComments: "/prod/comment",
  skip_approvals: "/skip_approvals",
  revert: "/revert",
  // uploadSelfAssessment:"/test-fidelity/upload_self_assessment",
  // retrieveProjectAudit:"/test-fidelity/retrieve_project_audit_report",
  addAuditReport: "/test-fidelity/add_audit_report",
  updateAuditReport: "/test-fidelity/update_audit_report",
  deleteAuditReport: "/test-fidelity/delete_audit_report",
  retrieveAuditReport: "/test-fidelity/retrieve_audit_report_list_by_cm",
  retrieveAuditIssues: "/test-fidelity/retrieve_audit_issues",
  retrieveIssuesByEquipment: "/test-fidelity/retrieve_issues_by_equipment",
  copyAuditIssues: "/test-fidelity/copy_audit_issues",
  addIssues: "/test-fidelity/add_issue",
  addAuditIssues: "/test-fidelity/add_audit_issue",
  updateAuditIssuesList: "/test-fidelity/update_audit_issues",
  addNextAuditDate: "/test-fidelity/add_cm_audit_date",
  updateAuditIssues: "/test-fidelity/update_project_audit_report",

  // Performance Summary
  retrievePerformanceSummaryByCMHeader:
    "/test-fidelity/retrieve_performance_summary_by_cm_header",
  retrievePerformanceSummaryByCMEquipmentCategory:
    "/test-fidelity/retrieve_performance_summary_by_cm_equipment_category",
  retrievePerformanceSummaryByCMEquipmentCM:
    "/test-fidelity/retrieve_performance_summary_by_cm_equipment_cm",
  retrievePerformanceSummaryByCMMonth:
    "/test-fidelity/retrieve_performance_summary_by_cm_month_data",
  retrievePerformanceSummaryByCMQuarter:
    "/test-fidelity/retrieve_performance_summary_by_cm_quarter_data",
  retrievePerformanceSummaryByCMAvgDays:
    "/test-fidelity/retrieve_performance_summary_by_cm_avg_days_data",
  retrievePerformanceSummaryByCPIHeader:
    "/test-fidelity/retrieve_performance_summary_by_cpi_header",
  retrievePerformanceSummaryByCPIData:
    "/test-fidelity/retrieve_performance_summary_by_cpi_data",
  retrievePerformanceSummaryBySystemHeader:
    "/test-fidelity/retrieve_performance_summary_by_system_utilization_header",
  retrievePerformanceSummaryBySystemData:
    "/test-fidelity/retrieve_performance_summary_by_system_utilization_data",
  archiveProject: "/test-fidelity/archive_project",
  login: "/login",

  // equipment details
  updateEquipmentFlag: "/test-fidelity/update_equip_flag",
  updateEquipmentImage: "/test-fidelity/upload_equip_picture",
  retrieveTimelineByEquipment: "/test-fidelity/retrieve_timeline_by_equip",
  retrieveConsumableByEquipment:
    "/test-fidelity/retrieve_consumable_by_equipment",
  retrieveConsumableByLocation:
    "/test-fidelity/retrieve_consumable_by_location",
  addConsumableUsageByEquipment: "/test-fidelity/add_consumable_usage_record",
  deleteConsumable: "/test-fidelity/delete_consumable_usage_record",
  // users
  retrieveUserList: "/test-fidelity/retrieve_users",
  addUser: "/test-fidelity/add_users",
  deleteUser: "/test-fidelity/delete_user",
  updateUser: "/test-fidelity/edit_user",
  // user activity
  retrieveTimelineByUser: "/test-fidelity/retrieve_timeline_details_by_user",
  retrieveCalendarByUser: "/test-fidelity/retrieve_timeline_by_user",
  // test location
  retrieveLocationList: "/test-fidelity/retrieve_users_with_test_location",
  addLocation: "/test-fidelity/add_users_to_test_location",
  deleteLocation: "/test-fidelity/delete_test_location",
  updateLocation: "/test-fidelity/edit_test_location",
  // capability template
  retrieveCapabilityTemplateList: "/test-fidelity/retrieve_equip_template",
  addCapabilityTemplate: "/test-fidelity/edit_equip_template",
  deleteCapabilityTemplate: "/test-fidelity/delete_capability_template",
  // calibration tool
  retrieveCalibrationMonthSummary:
    "/test-fidelity/retrieve_calibration_month_summary",
  retrieveCalibrationMonthEquipmentDetails:
    "/test-fidelity/retrieve_calibration_month_equipment_details",
  // lab personnel
  retrieveLabPersonnel: "/test-fidelity/retrieve_lab_personnel_by_location",
  addLabPersonnel: "/test-fidelity/add_lab_personnel",
  editLabPersonnel: "/test-fidelity/edit_lab_personnel",
  deleteLabPersonnel: "/test-fidelity/delete_lab_personnel",
  calculateTurnoverRate: "/test-fidelity/calculate_turnover_rate",
  // dashboard details
  retrieveComplianceStatus: "/test-fidelity/retrieve_compliance_status",
  retrieveHighlightIssues: "/test-fidelity/retrieve_highlight_issues",
  retrieveComplianceScore: "/test-fidelity/get_compliance_score",
  addComplianceScore: "/test-fidelity/add_compliance_score",
  retrieveTimelineByCm: "/test-fidelity/retrieve_timeline_by_cm",
  retrieveFileStatusByCm: "/test-fidelity/retrieve_file_status_by_cm",
  editCmDescription: "/test-fidelity/edit_cm_description",
  // consumable
  retrieveCosumableList: "/test-fidelity/retrieve_consumable_by_location",
  addConsumableByLocation: "/test-fidelity/add_consumable_by_location",
  deleteConsumableById: "/test-fidelity/delete_consumable_by_id",
  retrieveConsumableUsageById: "/test-fidelity/retrieve_consumable_usage_by_id",
  retrieveConsumableById: "/test-fidelity/retrieve_consumable_by_id",
  // requirements
  uploadRequirementFile: "/test-fidelity/upload_template_file",
  retrieveRequirements: "/test-fidelity/retrieve_template_files",
  deleteRequirementFile: "/test-fidelity/delete_template_file",
  // status checklist
  retrieveStatusChecklist: "/test-fidelity/retrieve_status_checklist_details",
}

export default APIPath
