export const retrieveNotification = payload => ({
  type: "RETRIEVE_NOTIFICATION",
  payload,
})

export const retrieveNotificationSuccess = notification => ({
  type: "RETRIEVE_NOTIFICATION_SUCCESS",
  payload: notification,
})

export const retrieveNotificationFailure = res => ({
  type: "RETRIEVE_NOTIFICATION_FAILURE",
  payload: res,
})

export const updateNotification = payload => ({
  type: "UPDATE_NOTIFICATION",
  payload,
})

export const updateNotificationSuccess = notification => ({
  type: "UPDATE_NOTIFICATION_SUCCESS",
  payload: notification,
})

export const updateNotificationFailure = res => ({
  type: "UPDATE_NOTIFICATION_FAILURE",
  payload: res,
})
