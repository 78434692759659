/**
 * Helpful form field components. In addition to the basics this includes things
 * like planet selectors. All fields include a custom `missing` prop that can be
 * used to show a "this field is required" error.
 */

import React, { useState } from "react"
import FileInput, { FileDetails } from "@amzn/meridian/file-input"
import axios from "axios"
import PropTypes from "prop-types"
import Icon from "@amzn/meridian/icon"
import checkCircleTokens from "@amzn/meridian-tokens/base/icon/check-circle"
import infoTokens from "@amzn/meridian-tokens/base/icon/info"

import env from "../../services/Auth/env"
import * as auth from "../../services/Auth/auth"

/**
 * This wraps up a field component in order to add a "missing" prop that can be
 * used to render the field in an error state and display an error alert below
 * the field.
 */

/**
 * An example for fileParam
 * {
 *  file_level: "project",
 *  file_type: "checklist",
 *  status: "pending",
 *  cm: "ensky",
 *  cm_location: "ensky_sz",
 *  uploaded_by: "yfamzn",
 *  project_name: "bruce",
 * }
 */

const FileInputWrapper = props => {
  const [files, setFiles] = useState([])
  let {
    fileParam,
    uploadButtonDisabled,
    showDropZone,
    uploadButtonLabel,
    uploadButtonType,
    uploadButtonSize,
    type,
    accept,
    simpleDetail,
    callback,
  } = props
  return (
    <FileInput
      dropZoneDirection="column"
      uploadButtonDisabled={uploadButtonDisabled}
      showDropZone={showDropZone}
      uploadButtonLabel={uploadButtonLabel}
      uploadButtonType={uploadButtonType}
      uploadButtonSize={uploadButtonSize}
      type={type}
      accept={accept}
      onFileAttached={acceptedFiles => {
        let filteredFiles = acceptedFiles.filter(
          f => !files.some(fl => fl.name === f.name)
        )
        filteredFiles.forEach(file => {
          let params = {
            file_name: file.name,
            uploaded_date: new Date().getTime(),
            ...fileParam,
          }
          auth.getToken().then(cookie => {
            axios(env.API_ENDPOINT + env.APIPath.uploadFile, {
              method: "PUT",
              params: params,
              headers: { idToken: cookie },
              // timeout: 10000,
              data: file,
              onUploadProgress: e => {
                let completeProgress = ((e.loaded / e.total) * 100) | 0
                file.uploadPercentage = completeProgress
                setFiles([...filteredFiles])
              },
            })
              .then(response => {
                if (response.data && response.data.errorMessage) {
                  file.error = true
                  file.errorMessage = response.data.errorMessage
                  setFiles([...files, ...filteredFiles])
                  console.warn("error", file.errorMessage)
                } else {
                  callback &&
                    callback(filteredFiles[0].path, response.data.fileId)
                  console.warn("upload succeed")
                }
              })
              .catch(error => {
                console.warn("error", error)
                file.error = true
                setFiles([...files, ...filteredFiles])
              })
          })
        })
        setFiles([...files, ...filteredFiles])
      }}
    >
      {!simpleDetail &&
        files.map(file => {
          console.warn("files", file)
          return (
            <FileDetails
              error={file.error}
              errorMessage={file.errorMessage}
              file={file}
              uploadPercentage={file.uploadPercentage}
              key={file.name}
              onClickRemoveFile={() => {
                const updatedFileArr = files.filter(fl => fl.name !== file.name)
                setFiles(updatedFileArr)
              }}
              removeFileButtonLabel={`Delete, ${file.name}`}
              uploadComplete={file.uploadPercentage === 100}
            />
          )
        })}
      {simpleDetail &&
        files.length > 0 &&
        (files.some(f => f.error) ? (
          <Icon className="fail" tokens={infoTokens} />
        ) : (
          <Icon className="pass" tokens={checkCircleTokens} />
        ))}
    </FileInput>
  )
}

const propTypes = {
  uploadButtonLabel: PropTypes.string.isRequired,
  fileParam: PropTypes.object.isRequired,
  uploadButtonDisabled: PropTypes.bool,
  showDropZone: PropTypes.bool,
  type: PropTypes.string,
  uploadButtonType: PropTypes.string,
  uploadButtonSize: PropTypes.string,
  simpleDetail: PropTypes.bool,
  callback: PropTypes.func,
}

const defaultProps = {
  uploadButtonDisabled: false,
  showDropZone: false,
  type: "single",
  accept: "*",
  uploadButtonSize: "small",
  uploadButtonType: "link",
  simpleDetail: true,
  callback: null,
}

FileInputWrapper.propTypes = propTypes
FileInputWrapper.defaultProps = defaultProps

export default FileInputWrapper
