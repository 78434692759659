import React, { useState, useEffect } from "react"
import Input from "@amzn/meridian/input"
import Textarea from "@amzn/meridian/textarea"

import Table, {
  TableRow,
  TableCell,
  TableActionBar,
} from "@amzn/meridian/table"
import Heading from "@amzn/meridian/heading"
import Button from "@amzn/meridian/button"
import DatePicker from "@amzn/meridian/date-picker"
import { useDispatch, useSelector } from "react-redux"

import { updateBasicInfo } from "../../../redux/equipmentDetail/action"

const EquipmentBasicInfoTab = props => {
  const { equipmentBasicInfo, cm, testLocation } = props
  const [isEditing, setIsEditing] = useState(false)
  const [currentInfo, setCurrentInfo] = useState()

  const reduxDispatch = useDispatch()

  const handleChangeInfo = (value, key, index) => {
    let temp = JSON.parse(JSON.stringify(currentInfo))

    if (Number.isInteger(index) && temp.scores && temp.scores[index]) {
      temp.scores[index].score = Number(value)
    } else if (!Number.isInteger(index)) {
      temp[key] = value
    }
    setCurrentInfo(temp)
  }

  useEffect(() => {
    if (equipmentBasicInfo) {
      setCurrentInfo(equipmentBasicInfo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentBasicInfo])

  const { isCMUser, isAdmin } = useSelector(state => state.userReducer)

  return (
    <div role="tabpanel" id="tabpanel-planets" aria-labelledby="tab-planets">
      <Table showDividers={true} spacing="small">
        <TableActionBar widths={[300, "fill"]}>
          <Heading level={5}>{"Information"}</Heading>
          <div />
          {!isEditing && (
            <Button
              disabled={!isCMUser && !isAdmin}
              onClick={() => setIsEditing(true)}
              type="primary"
            >
              Edit
            </Button>
          )}
          {isEditing && (
            <>
              <Button onClick={() => setIsEditing(false)} type="tertiary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  reduxDispatch(
                    updateBasicInfo({
                      editedBasicInfo: currentInfo,
                      equipmentId: currentInfo.equipmentId,
                      cm: cm,
                      testLocation: testLocation,
                    })
                  )
                  setIsEditing(false)
                }}
                type="primary"
              >
                Save
              </Button>
            </>
          )}
        </TableActionBar>
        <TableRow>
          <TableCell width={250} backgroundColor="secondary">
            Category
          </TableCell>
          {!isEditing ? (
            <TableCell>{equipmentBasicInfo.category}</TableCell>
          ) : (
            <TableCell>
              <Input
                value={currentInfo?.category}
                onChange={value => handleChangeInfo(value, "category")}
                placeholder="Enter value..."
                width="300px"
              />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell width={250} backgroundColor="secondary">
            Sub Category
          </TableCell>
          {!isEditing ? (
            <TableCell>{equipmentBasicInfo.subcategory}</TableCell>
          ) : (
            <TableCell>
              <Input
                // value={ticketText[i]}
                value={currentInfo?.subcategory}
                onChange={value => handleChangeInfo(value, "subcategory")}
                placeholder="Enter value..."
                width="300px"
              />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell width={250} backgroundColor="secondary">
            Calibration Due Date
          </TableCell>
          <TableCell>
            {equipmentBasicInfo.calibrationDate} (Get from calibration valid
            date)
          </TableCell>
          {/* {!isEditing ? (
            <TableCell>{equipmentBasicInfo.calibrationDate}</TableCell>
          ) : (
            <TableCell>
              <DatePicker
                value={currentInfo.calibrationDate}
                onChange={value => {
                  handleChangeInfo(value, "calibrationDate")
                }}
                width={180}
              />
            </TableCell>
          )} */}
        </TableRow>
        <TableRow>
          <TableCell width={250} backgroundColor="secondary">
            Brand
          </TableCell>
          {!isEditing ? (
            <TableCell>{equipmentBasicInfo.brand}</TableCell>
          ) : (
            <TableCell>
              <Input
                value={currentInfo?.brand}
                onChange={value => handleChangeInfo(value, "brand")}
                placeholder="Enter value..."
                width="300px"
              />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell width={250} backgroundColor="secondary">
            Model
          </TableCell>
          {!isEditing ? (
            <TableCell>{equipmentBasicInfo.model}</TableCell>
          ) : (
            <TableCell>
              <Input
                value={currentInfo?.model}
                onChange={value => handleChangeInfo(value, "model")}
                placeholder="Enter value..."
                width="300px"
              />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell width={250} backgroundColor="secondary">
            Serial Number
          </TableCell>
          {!isEditing ? (
            <TableCell>{equipmentBasicInfo.serialNumber}</TableCell>
          ) : (
            <TableCell>
              <Input
                value={currentInfo?.serialNumber}
                onChange={value => handleChangeInfo(value, "serialNumber")}
                placeholder="Enter value..."
                width="300px"
              />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell width={250} backgroundColor="secondary">
            Room
          </TableCell>
          {!isEditing ? (
            <TableCell>{equipmentBasicInfo.room}</TableCell>
          ) : (
            <TableCell>
              <Input
                value={currentInfo?.room}
                onChange={value => handleChangeInfo(value, "room")}
                placeholder="Enter value..."
                width="300px"
              />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell width={250} backgroundColor="secondary">
            Label
          </TableCell>
          {!isEditing ? (
            <TableCell>{equipmentBasicInfo.label}</TableCell>
          ) : (
            <TableCell>
              <Input
                value={currentInfo?.label}
                onChange={value => handleChangeInfo(value, "label")}
                placeholder="Enter value..."
                width="300px"
              />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell width={250} backgroundColor="secondary">
            Capabilities
          </TableCell>
          {!isEditing ? (
            <TableCell>{equipmentBasicInfo.equipmentCapacity}</TableCell>
          ) : (
            <TableCell>
              <Textarea
                value={currentInfo?.equipmentCapacity}
                onChange={value => handleChangeInfo(value, "equipmentCapacity")}
                placeholder="Enter value..."
                width="300px"
              />
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell width={250} backgroundColor="secondary">
            Coverage
          </TableCell>
          {!isEditing || isCMUser ? (
            <TableCell>{equipmentBasicInfo.equipmentCoverage}</TableCell>
          ) : (
            <TableCell>
              <Textarea
                value={currentInfo?.equipmentCoverage}
                onChange={value => handleChangeInfo(value, "equipmentCoverage")}
                placeholder="Enter value..."
                width="300px"
              />
            </TableCell>
          )}
        </TableRow>
        {currentInfo?.scores?.map((x, index) => (
          <TableRow key={`${x?.type} ${index}`}>
            <TableCell width={250} backgroundColor="secondary">
              {`${x?.type} Score`}
            </TableCell>
            {
              isEditing && (x.type === "Capability") && !isCMUser ? (
                <TableCell>
                  <Input
                    value={x.score}
                    onChange={value => handleChangeInfo(value, x.type, index)}
                    placeholder="Enter value..."
                    type="number"
                    width="300px"
                  />
                </TableCell>
              ) : (<TableCell>{x.score}</TableCell>)
            }
          </TableRow>
        ))}
      </Table>
    </div>
  )
}

export default EquipmentBasicInfoTab
