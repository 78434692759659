import { formatDate } from "../util/Util"

const fileTypeOptions = [
  {
    value: "calibrationReport",
    label: "Calibration Report",
  },
  {
    value: "checklist",
    label: "Checklist",
  },
  {
    value: "sop",
    label: "SOP",
  },
  {
    value: "maintenanceRecord",
    label: "Maintenance Record",
  },
  {
    value: "trainingRecord",
    label: "Training Record",
  },
  {
    value: "dailyCheck",
    label: "Daily Check",
  },
  {
    value: "trialRunData",
    label: "Trial Run Data",
  },
]

const projectOptions = [
  {
    value: "bruce",
    label: "Bruce",
  },
  {
    value: "dylan",
    label: "Dylan",
  },
]

const statusOptions = [
  {
    value: "expired",
    label: "Expired",
  },
  {
    value: "UnApproved",
    label: "UnApproved",
  },
  {
    value: "Approved",
    label: "Approved",
  },
  {
    value: "Pending",
    label: "Pending",
  },
]

const equipStatusOptions = [
  {
    value: "UnApproved",
    label: "UnApproved",
  },
  {
    value: "Approved",
    label: "Approved",
  },
  {
    value: "Pending",
    label: "Pending",
  },
]

const equipmentCategoryOptions = [
  {
    value: "mechanical",
    label: "Mechanical",
  },
  {
    value: "electrical",
    label: "Electrical",
  },
  {
    value: "chemical",
    label: "Chemical/Cosmetic",
  },
  {
    value: "environmental",
    label: "Environmental",
  },
  {
    value: "consumable",
    label: "Consumable",
  },
]

const projectFamilyOptions = [
  {
    value: "Accessories",
    label: "Accessories",
  },
  {
    value: "APT",
    label: "APT",
  },
  {
    value: "AuCC",
    label: "AuCC",
  },
  {
    label: "CSG",
    value: "CSG",
  },
  {
    value: "EReader",
    label: "EReader",
  },
  {
    label: "Emerging",
    value: "Emerging",
  },
  {
    label: "Jumpstart",
    value: "Jumpstart",
  },
  {
    label: "Phone",
    value: "Phone",
  },
  {
    label: "SMP",
    value: "SMP",
  },
  {
    label: "Tablet",
    value: "Tablet",
  },
  {
    label: "Vesta",
    value: "Vesta",
  },
]

const auditCategoryOptions = [
  { value: "Man", label: "Man" },
  { value: "Machine", label: "Machine" },
  { value: "Method", label: "Method" },
  { value: "Material", label: "Material" },
]
const auditBlockerOptions = [
  { value: "Y", label: "Y" },
  { value: "N", label: "N" },
]
const auditStatusOptions = [
  { value: "Open", label: "Open" },
  { value: "Closed", label: "Closed" },
]

const auditIssueStatusOptions = [
  { value: false, label: "Open" },
  { value: true, label: "Closed" },
]

const auditPriorityOptions = [
  { value: 0, label: "P0" },
  { value: 1, label: "P1" },
  { value: 2, label: "P2" },
]

const auditIssueTypeOptions = [
  // { value: "Audit", label: "Audit" },
  { value: "System", label: "System" },
  { value: "E-sop", label: "E-sop" },
  { value: "Calibration", label: "Calibration" },
  { value: "Equipment", label: "Equipment" },
  { value: "Consumable/Tool", label: "Consumable/Tool" },
  { value: "Maintenance", label: "Maintenance" },
  { value: "Training", label: "Training" },
  { value: "Operation", label: "Operation" },
  { value: "EHS", label: "EHS" },
  { value: "Report", label: "Report" },
  { value: "Other", label: "Other" },
]
const resultsPerPageOptions = [10, 50, 100]

const frequencyOptions = [
  { value: 1, label: "Daily" },
  { value: 7, label: "Weekly" },
  { value: 14, label: "Bi-weekly" },
]

const categoryOptions = [
  {
    value: "Environmental",
    label: "Environmental",
  },
  {
    value: "Mechanical",
    label: "Mechanical",
  },
  {
    value: "Decorative",
    label: "Decorative",
  },
  {
    value: "Tool",
    label: "Tool",
  },
  {
    value: "EE",
    label: "EE",
  },
]

const getStatusByValue = value => {
  let result = statusOptions.find(status => status.value === value)
  return result ? result.label : value
}

const getFileTypeByValue = value => {
  let result = fileTypeOptions.find(type => type.value === value)
  return result ? result.label : value
}

const getProjectByValue = value => {
  let result = projectOptions.find(project => project.value === value)
  return result ? result.label : value
}

const getProjectFamilyByValue = value => {
  let result = projectFamilyOptions.find(project => project.value === value)
  return result ? result.label : value
}

const getCategoryByValue = value => {
  let result = auditCategoryOptions.find(project => project.value === value)
  return result ? result.label : value
}

const getEffectivePeriod = file => {
  if (file.effectiveEndDate && file.effectiveStartDate) {
    let start = formatDate(new Date(file.effectiveStartDate), "yyyy/MM/dd")
    let end = formatDate(new Date(file.effectiveEndDate), "yyyy/MM/dd")
    return `${start}-${end}`
  }
  return null
}

const getReadableDate = date => {
  if (date && Number.isInteger(date)) {
    return formatDate(new Date(date), "yyyy/MM/dd")
  }
  return ""
}

export {
  fileTypeOptions,
  projectOptions,
  statusOptions,
  resultsPerPageOptions,
  projectFamilyOptions,
  getStatusByValue,
  getFileTypeByValue,
  getProjectByValue,
  getProjectFamilyByValue,
  getCategoryByValue,
  getEffectivePeriod,
  getReadableDate,
  equipmentCategoryOptions,
  auditCategoryOptions,
  auditBlockerOptions,
  auditStatusOptions,
  auditIssueTypeOptions,
  frequencyOptions,
  equipStatusOptions,
  categoryOptions,
  auditPriorityOptions,
  auditIssueStatusOptions,
}
