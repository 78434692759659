import { React } from "react"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Box from "@amzn/meridian/box"
import Text from "@amzn/meridian/text"
import Icon from "@amzn/meridian/icon"
import { css } from "emotion"

import arrowUpLargeTokens from "@amzn/meridian-tokens/base/icon/arrow-up-large"
import arrowDownLargeTokens from "@amzn/meridian-tokens/base/icon/arrow-down-large"

const titleStyles = css({
  position: "absolute",
  top: "5px",
  left: "8px",
})

const valueStyles = css({
  paddingTop: "20px",
})

const ascendingStyles = css({
  color: "green",
  fontSize: 25,
})

const descendingStyles = css({
  color: "red",
  fontSize: 25,
})

const containerStyle = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgb(186, 215, 252)",
  width: "300px",
  position: "relative",
})

const KPICard = props => {
  let { kpi, title, height = 140, isHalfHeight = false, trend } = props
  return (
    <div className={containerStyle} style={{ height }}>
      <Text className={titleStyles} type="h200" color="inverted">
        {title}
      </Text>
      <div className={isHalfHeight ? valueStyles : null}>
        <Text tag="span" color="inverted" type="h600">
          {kpi}
        </Text>
        {trend === "ascending" && (
          <Icon className={ascendingStyles} tokens={arrowUpLargeTokens} />
        )}
        {trend === "descending" && (
          <Icon className={descendingStyles} tokens={arrowDownLargeTokens} />
        )}
      </div>
    </div>
  )
}

export default KPICard
