export const retrieveLabPersonnel = payload => ({
  type: "RETRIEVE_LAB_PERSONNEL",
  payload: payload,
})

export const retrieveLabPersonnelSuccess = payload => ({
  type: "RETRIEVE_LAB_PERSONNEL_SUCCESS",
  payload: payload,
})

export const retrieveLabPersonnelFailure = payload => ({
  type: "RETRIEVE_LAB_PERSONNEL_FAILURE",
  payload: payload,
})

export const selectCM = payload => ({
  type: "SELECT_CM",
  payload: payload,
})

export const addLabPersonnel = payload => ({
  type: "ADD_LAB_PERSONNEL",
  payload: payload,
})

export const addLabPersonnelSuccess = payload => ({
  type: "ADD_LAB_PERSONNEL_SUCCESS",
  payload: payload,
})

export const addLabPersonnelFailure = payload => ({
  type: "ADD_LAB_PERSONNEL_FAILURE",
  payload: payload,
})

export const editLabPersonnel = payload => ({
  type: "EDIT_LAB_PERSONNEL",
  payload: payload,
})

export const editLabPersonnelSuccess = payload => ({
  type: "EDIT_LAB_PERSONNEL_SUCCESS",
  payload: payload,
})

export const editLabPersonnelFailure = payload => ({
  type: "EDIT_LAB_PERSONNEL_FAILURE",
  payload: payload,
})

export const deleteLabPersonnel = payload => ({
  type: "DELETE_LAB_PERSONNEL",
  payload: payload,
})

export const deleteLabPersonnelSuccess = payload => ({
  type: "DELETE_LAB_PERSONNEL_SUCCESS",
  payload: payload,
})

export const deleteLabPersonnelFailure = payload => ({
  type: "DELETE_LAB_PERSONNEL_FAILURE",
  payload: payload,
})

export const calculateTurnoverRate = payload => ({
  type: "CALCULATE_TURNOVER_RATE",
  payload: payload,
})

export const calculateTurnoverRateSuccess = payload => ({
  type: "CALCULATE_TURNOVER_RATE_SUCCESS",
  payload: payload,
})

export const calculateTurnoverRateFailure = payload => ({
  type: "CALCULATE_TURNOVER_RATE_FAILURE",
  payload: payload,
})
