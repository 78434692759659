/**
 * Connects to the toasts API client and renders toasts using the Toaster
 * component. This is mounted once at the app level so it's present on every
 * page and ready to show new toasts.
 */

import React, { useState, useEffect } from "react"
import Toaster from "@amzn/meridian/toaster"
import Alert from "@amzn/meridian/alert"
import Link from "@amzn/meridian/link"
import Row from "@amzn/meridian/row"
import { listenToToasts, closeToast } from "../../api/toasts"

const Toasts = () => {
  const [toasts, setToasts] = useState([])

  useEffect(() => listenToToasts(setToasts), [])

  return (
    <Toaster toasts={toasts} onCloseToast={closeToast}>
      {toast => (
        <Alert
          type={toast.type}
          title={toast.title}
          onClose={toast.timeout ? undefined : toast.onClose}
          toast={true}
          size="large"
        >
          {toast.actionLabel && toast.action ? (
            <Row spacing="small">
              <span>{toast.message}</span>
              <Link onClick={() => toast.action(toast.onClose)}>
                {toast.actionLabel}
              </Link>
            </Row>
          ) : (
            toast.message
          )}
        </Alert>
      )}
    </Toaster>
  )
}

export default Toasts
