export const retrieveConsumableList = payload => ({
  type: "RETRIEVE_CONSUMABLE_LIST",
  payload: payload,
})

export const retrieveConsumableListSuccess = payload => ({
  type: "RETRIEVE_CONSUMABLE_LIST_SUCCESS",
  payload: payload,
})

export const retrieveConsumableListFailure = payload => ({
  type: "RETRIEVE_CONSUMABLE_LIST_FAILURE",
  payload: payload,
})

export const selectCM = payload => ({
  type: "SELECT_CM",
  payload: payload,
})

export const addConsumableList = payload => ({
  type: "ADD_CONSUMABLE_LIST",
  payload: payload,
})

export const addConsumableListSuccess = payload => ({
  type: "ADD_CONSUMABLE_LIST_SUCCESS",
  payload: payload,
})

export const addConsumableListFailure = payload => ({
  type: "ADD_CONSUMABLE_LIST_FAILURE",
  payload: payload,
})

export const deleteConsumableList = payload => ({
  type: "DELETE_CONSUMABLE_LIST",
  payload: payload,
})

export const deleteConsumableListSuccess = payload => ({
  type: "DELETE_CONSUMABLE_LIST_SUCCESS",
  payload: payload,
})

export const deleteConsumableListFailure = payload => ({
  type: "DELETE_CONSUMABLE_LIST_FAILURE",
  payload: payload,
})

export const retrieveConsumableUsageById = payload => ({
  type: "RETRIEVE_CONSUMABLE_USAGE_BY_ID",
  payload: payload,
})

export const retrieveConsumableUsageByIdSuccess = payload => ({
  type: "RETRIEVE_CONSUMABLE_USAGE_BY_ID_SUCCESS",
  payload: payload,
})

export const retrieveConsumableUsageByIdFailure = payload => ({
  type: "RETRIEVE_CONSUMABLE_USAGE_BY_ID_FAILURE",
  payload: payload,
})

export const addConsumableUsageRecord = payload => ({
  type: "ADD_CONSUMABLE_USAGE_RECORD",
  payload: payload,
})

export const addConsumableUsageRecordSuccess = payload => ({
  type: "ADD_CONSUMABLE_USAGE_RECORD_SUCCESS",
  payload: payload,
})

export const addConsumableUsageRecordFailure = payload => ({
  type: "ADD_CONSUMABLE_USAGE_RECORD_FAILURE",
  payload: payload,
})

export const deleteConsumableUsageRecord = payload => ({
  type: "DELETE_CONSUMABLE_USAGE_RECORD",
  payload: payload,
})

export const deleteConsumableUsageRecordSuccess = payload => ({
  type: "DELETE_CONSUMABLE_USAGE_RECORD_SUCCESS",
  payload: payload,
})

export const deleteConsumableUsageRecordFailure = payload => ({
  type: "DELETE_CONSUMABLE_USAGE_RECORD_FAILURE",
  payload: payload,
})

export const retrieveConsumableById = payload => ({
  type: "RETRIEVE_CONSUMABLE_BY_ID",
  payload: payload,
})

export const retrieveConsumableByIdSuccess = payload => ({
  type: "RETRIEVE_CONSUMABLE_BY_ID_SUCCESS",
  payload: payload,
})

export const retrieveConsumableByIdFailure = payload => ({
  type: "RETRIEVE_CONSUMABLE_BY_ID_FAILURE",
  payload: payload,
})
