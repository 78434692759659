import Modal, { ModalFooter } from "@amzn/meridian/modal"
import React, { useState } from "react"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import { useDispatch, useSelector } from "react-redux"
import {
  FormDatePicker,
  FormInput,
  FormSelect,
  FormTextArea,
} from "../../../components/app"
import { addConsumableUsageRecord } from "../../../redux/consumable/action"

const ACTION_TYPE = [
  { value: 0, label: "Use in Test" },
  { value: 1, label: "Use in Equipment" },
  { value: 2, label: "Dispose" },
]

const AddConsumableUsageModal = ({
  isModalOpen,
  setModalOpen,
  consumableId,
}) => {
  const [actionDate, setActionDate] = useState()
  const [action, setAction] = useState(0)
  const [equipmentId, setEquipmentId] = useState()
  const [usageNum, setUsageNum] = useState(0)
  const [comment, setComment] = useState("")
  const [equipmentDisabled, setEquipmentDisabled] = useState(true)

  const reduxDispatch = useDispatch()
  const { equipment } = useSelector(state => state.equipmentReducer)
  const equipmentList = equipment.map(v => ({
    label: v.equipmentName,
    value: v.equipmentId,
  }))
  // const { locationList } = useSelector(state => state.locationReducer)
  // const { cmIndex } = useSelector(state => state.consumableReducer)

  const resetState = () => {
    setActionDate()
    setAction(0)
    setEquipmentId()
    setUsageNum(0)
    setComment()
    setModalOpen(false)
  }
  return (
    <form>
      <Modal
        title="Add/Edit"
        open={isModalOpen}
        onClose={() => resetState()}
        scrollContainer="viewport"
        closeLabel="Close"
        describedById="modal-description"
        bodySpacingInset="medium"
      >
        <form>
          <FormDatePicker
            value={actionDate}
            setValue={setActionDate}
            label="Action Date"
          />
          <FormSelect
            value={action}
            setValue={v => {
              setEquipmentDisabled(v !== 1)
              setAction(v)
            }}
            options={ACTION_TYPE}
            label={"Action"}
          />
          <FormSelect
            disabled={equipmentDisabled}
            value={equipmentId}
            setValue={setEquipmentId}
            options={equipmentList}
            label={"Equipment"}
          />
          <FormInput
            type={"number"}
            value={usageNum}
            setValue={setUsageNum}
            label={"Number of Usage"}
          />
          <FormTextArea
            value={comment}
            setValue={setComment}
            label={"Comment"}
            placeholder=""
          />
        </form>
        <ModalFooter>
          <Row alignmentHorizontal="right" widths="fit">
            <Button
              disabled={!actionDate || !usageNum}
              type="primary"
              size="small"
              onClick={() => {
                reduxDispatch(
                  addConsumableUsageRecord({
                    equipmentId: equipmentId,
                    consumableId: consumableId,
                    action: ACTION_TYPE[action].label,
                    comment: comment,
                    useNum: Number(usageNum),
                    actionDate: actionDate,
                  })
                )
                resetState()
              }}
            >
              Add
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </form>
  )
}

export default AddConsumableUsageModal
