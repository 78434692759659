import React from "react"
// import {useHistory} from "react-router-dom";
import Row from "@amzn/meridian/row"
import Column from "@amzn/meridian/column"
// import Checkbox from "@amzn/meridian/checkbox";
import Button from "@amzn/meridian/button"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import { useSelector } from "react-redux"
import Input from "@amzn/meridian/input"
// import { useSelector } from "react-redux"

import { Select, SelectOption } from "../../../components/app/form-fields"
import {
  fileTypeOptions,
  // projectOptions,
  statusOptions,
  getStatusByValue,
  getFileTypeByValue,
  // getProjectByValue,
} from "../../../data/options"
import useFilters from "../../../hooks/use-filters"

const FileFiltersModal = props => {
  // eslint-disable-next-line no-unused-vars
  const { filterModalOpen, setFilterModalOpen } = props
  const { locationList } = useSelector(state => state.locationReducer)

  const { dispatch, filters } = useFilters()
  // const cmList = useSelector(state => state.commonReducer.cmList)

  const onChangeFilter = (filter, makeLabel) => value => {
    if (!value || value.length === 0) {
      dispatch({ type: "REMOVE_FILTER", filter })
    } else {
      dispatch({
        type: "UPDATE_FILTER",
        filter,
        value,
        tagLabel: makeLabel(value),
      })
    }
  }

  return (
    <Modal
      title="Filters"
      open={filterModalOpen}
      onClose={() => setFilterModalOpen(false)}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="medium"
    >
      <form>
        <Column width={320} spacingInset="200">
          <text>CM</text>
          <Select
            value={filters.get("cm")?.value || []}
            onChange={onChangeFilter("cm", value =>
              value.map(cm => `CM: ${cm}`)
            )}
            id="cm-select"
            placeholder="Select a CM"
          >
            {locationList.map(x => (
              <SelectOption key={x.cm} value={x.cm} label={`${x.cm}`} />
            ))}
          </Select>
        </Column>
        {/* <Column width={320} spacingInset="200">
          <text>CM Location</text>
          <Select
            value={filters.get("cmLocation")?.value || []}
            onChange={onChangeFilter("cmLocation", value =>
              value.map(cmLocation => `CM Location: ${cmLocation}`)
            )}
            id="cm-location-select"
            placeholder="Select a CM Location"
          >
            {locationList.map(x => (
              <SelectOption
                key={x.cmLocation}
                value={x.cmLocation}
                label={`${x.cmLocation}`}
              />
            ))}
          </Select>
        </Column> */}
        <Column width={320} spacingInset="200">
          <text>Type</text>
          <Select
            value={filters.get("types")?.value || []}
            onChange={onChangeFilter("types", value =>
              value.map(type => `Types: ${getFileTypeByValue(type)}`)
            )}
            id="type-select"
            placeholder="Select a File Type"
          >
            {fileTypeOptions.map(type => (
              <SelectOption
                key={type.value}
                value={type.value}
                label={type.label}
              />
            ))}
          </Select>
        </Column>
        <Column spacingInset="200">
          <text>Status</text>
          <Select
            value={filters.get("statuses")?.value || []}
            onChange={onChangeFilter("statuses", value =>
              value.map(status => `Statuses: ${getStatusByValue(status)}`)
            )}
            id="status-select"
            placeholder="Select a status"
          >
            {statusOptions.map(type => (
              <SelectOption
                key={type.value}
                value={type.value}
                label={type.label}
              />
            ))}
          </Select>
        </Column>
        <Column spacingInset="200">
          <text>Uploaded By</text>
          <Input
            value={filters.get("uploadedBy")?.value || ""}
            onChange={onChangeFilter(
              "uploadedBy",
              value => `Uploaded By: ${value}`
            )}
            id="uploaded-by-input"
            placeholder="ex. Allen"
          />
        </Column>
        <Column spacingInset="200">
          <text>Approved By</text>
          <Input
            value={filters.get("approvedBy")?.value || ""}
            onChange={onChangeFilter(
              "approvedBy",
              value => `Approved By: ${value}`
            )}
            id="approved-by-input"
            placeholder="ex. Allen"
          />
        </Column>
      </form>
      <ModalFooter>
        <Row
          alignmentHorizontal="center"
          spacingInset="200 none 400 none"
          widths="fill"
        >
          <Button type="link" onClick={() => dispatch({ type: "RESET" })}>
            Reset filters
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export { FileFiltersModal }
