import React, { useState, useEffect } from "react"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import Text from "@amzn/meridian/text"
import Divider from "@amzn/meridian/divider"
import Header from "@amzn/meridian/heading"
import Row from "@amzn/meridian/row"
import {
  closeNotification,
  listenToNotifications,
} from "../../api/notifications"

const Notifications = () => {
  const [notifications, setNotification] = useState([])

  useEffect(() => listenToNotifications(setNotification), [])

  return (
    <Modal
      title="Notifications"
      open={notifications.length > 0}
      onClose={() => closeNotification()}
      scrollContainer="viewport"
      closeLabel="Close"
      aria-describedby="modal-description"
    >
      <Header>Hi,</Header>
      {notifications.length > 0 ? (
        notifications.map((val, key) => (
          <React.Fragment key={key}>
            <Text>{val}</Text>
            <Divider />
          </React.Fragment>
        ))
      ) : (
        <Header>No New Notifications right now</Header>
      )}
    </Modal>
  )
}

export default Notifications
