export const retrieveUserList = payload => ({
  type: "RETRIEVE_USER_LIST",
  payload,
})

export const retrieveUserListSuccess = list => ({
  type: "RETRIEVE_USER_LIST_SUCCESS",
  payload: list,
})

export const retrieveUserListFailure = res => ({
  type: "RETRIEVE_USER_LIST_FAILURE",
  payload: res,
})

export const addUser = payload => ({
  type: "ADD_USER",
  payload: payload,
})

export const addUserSuccess = list => ({
  type: "ADD_USER_SUCCESS",
  payload: list,
})

export const addUserFailure = res => ({
  type: "ADD_USER_FAILURE",
  payload: res,
})

export const updateUser = payload => ({
  type: "UPDATE_USER",
  payload: payload,
})

export const updateUserSuccess = list => ({
  type: "UPDATE_USER_SUCCESS",
  payload: list,
})

export const updateUserFailure = res => ({
  type: "UPDATE_USER_FAILURE",
  payload: res,
})

export const deleteUser = payload => ({
  type: "DELETE_USER",
  payload: payload,
})

export const deleteUserSuccess = list => ({
  type: "DELETE_USER_SUCCESS",
  payload: list,
})

export const deleteUserFailure = res => ({
  type: "DELETE_USER_FAILURE",
  payload: res,
})
