import callBackEndAPI from "./callBackEndAPI"

let _callBackEndAPI = new callBackEndAPI()

const testApiPromise = () => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.testApiPromise(resolve, reject)
  })
}

const uploadFilePromise = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.uploadFilePromise(resolve, reject, payload)
  })
}

const approveFilePromise = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.approveFilePromise(resolve, reject, payload)
  })
}
const updateEffectiveDatePromise = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.updateEffectiveDatePromise(resolve, reject, payload)
  })
}

const deleteFilePromise = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.deleteFilePromise(resolve, reject, payload)
  })
}

const downloadFilePromise = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.downloadFilePromise(resolve, reject, payload)
  })
}

const listFilesPromise = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.listFilesPromise(resolve, reject, payload)
  })
}

const addEquipmentPromise = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.addEquipmentPromise(resolve, reject, payload)
  })
}

const copyEquipmentPromise = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.copyEquipmentPromise(resolve, reject, payload)
  })
}

const updateEquipment = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.updateEquipmentPromise(resolve, reject, payload)
  })
}

const deleteEquipment = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.deleteEquipmentPromise(resolve, reject, payload)
  })
}

const retrieveEquipmentFiles = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveEquipmentFilesPromise(resolve, reject, payload)
  })
}

const retrieveConsumable = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveConsumablePromise(resolve, reject, payload)
  })
}

const addConsumable = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.addConsumablePromise(resolve, reject, payload)
  })
}

const deleteConsumable = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.deleteConsumablePromise(resolve, reject, payload)
  })
}

const retrieveOperatorList = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveOperatorListPromise(resolve, reject, payload)
  })
}

const addOperator = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.addOperatorPromise(resolve, reject, payload)
  })
}

const deleteOperator = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.deleteOperatorPromise(resolve, reject, payload)
  })
}

const saveNewProjectDataPromise = projectReqBody => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.saveNewProjectData(resolve, reject, projectReqBody)
  })
}
const getAllProjectsPromise = projectReq => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.getAllProjects(resolve, reject, projectReq)
  })
}
// const getNotificationsPromise = user => {
//   return new Promise(function(resolve, reject) {
//     _callBackEndAPI.getNotifications(resolve, reject, user)
//   })
// }
const getAllComponentsProjectPromise = programName => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.getAllComponentsProject(resolve, reject, programName)
  })
}
const saveSpecDevDataPromise = (uuid, projectReqBody) => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.saveSpecDevData(resolve, reject, uuid, projectReqBody)
  })
}
const submitSpecDevDataPromise = (uuid, projectReqBody) => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.submitSpecDevData(resolve, reject, uuid, projectReqBody)
  })
}
const generateTemplatePromise = uuid => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.generateTemplate(resolve, reject, uuid)
  })
}
const approveCmDataPromise = (uuid, projectReqBody) => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.submitCmApproval(resolve, reject, uuid, projectReqBody)
  })
}
const rejectCmDataPromise = (uuid, projectReqBody) => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.submitCmReject(resolve, reject, uuid, projectReqBody)
  })
}
const approveSpecDataPromise = (uuid, projectReqBody) => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.submitSpecApproval(resolve, reject, uuid, projectReqBody)
  })
}
const rejectSpecDataPromise = (uuid, projectReqBody) => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.submitSpecReject(resolve, reject, uuid, projectReqBody)
  })
}
const saveProjectCommentsPromise = (uuid, projectReqBody) => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.saveProjectComments(resolve, reject, uuid, projectReqBody)
  })
}
const useNotificationPromise = () => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.getNotifications(resolve, reject)
  })
}
const updateNotificationPromise = updateNotifications => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.updateNotifications(resolve, reject, updateNotifications)
  })
}
const onInternalApprovalPromise = uuid => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.onInternalApproval(resolve, reject, uuid)
  })
}
const onRevertPromise = uuid => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.onRevert(resolve, reject, uuid)
  })
}
const retrieveCms = (userName, cm_location_id) => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveCmsPromise(
      resolve,
      reject,
      userName,
      cm_location_id
    )
  })
}
const retrieveEquipment = (project, cmName) => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveEquipmentPromise(resolve, reject, project, cmName)
  })
}

const updateBasicInfo = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.updateBasicInfoPromise(resolve, reject, payload)
  })
}

const retrieveAllEquipment = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveAllEquipmentPromise(resolve, reject, payload)
  })
}
const retrieveCalibratedEquips = (cmName, cmLocation) => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveCalibratedEquipsPromise(
      resolve,
      reject,
      cmName,
      cmLocation
    )
  })
}
const retrieveProject = cmName => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveProjectPromise(resolve, reject, cmName)
  })
}

const archiveProject = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.archiveProjectPromise(resolve, reject, payload)
  })
}

const updateProject = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.updateProjectPromise(resolve, reject, payload)
  })
}

const addProject = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.addProjectPromise(resolve, reject, payload)
  })
}
const retrieveFilesByFileType = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveFilesByFileTypePromise(resolve, reject, payload)
  })
}

const addAuditReport = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.addAuditReportPromise(resolve, reject, payload)
  })
}

const updateAuditReport = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.updateAuditReportPromise(resolve, reject, payload)
  })
}

const deleteAuditReport = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.deleteAuditReportPromise(resolve, reject, payload)
  })
}

const retrieveAuditReports = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveAuditReportsPromise(resolve, reject, payload)
  })
}

const retrieveEquipmentAuditReports = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveEquipmentAuditReportsPromise(
      resolve,
      reject,
      payload
    )
  })
}

const retrieveAuditIssues = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveAuditIssuesPromise(resolve, reject, payload)
  })
}

const addAuditIssues = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.addAuditIssuesPromise(resolve, reject, payload)
  })
}

const editAuditIssues = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.editAuditIssuesPromise(resolve, reject, payload)
  })
}

const addNextAuditDate = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.addNextAuditDatePromise(resolve, reject, payload)
  })
}

const copyAuditIssues = (projectName, auditIssuesIds) => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.copyAuditIssuesPromise(
      resolve,
      reject,
      projectName,
      auditIssuesIds
    )
  })
}

const deleteAuditIssues = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.deleteAuditIssuesPromise(resolve, reject, payload)
  })
}

// Performance Summary CM
const retrievePerformanceSummaryByCMHeader = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrievePerformanceSummaryByCMHeaderPromise(
      resolve,
      reject,
      payload
    )
  })
}

const retrievePerformanceSummaryByCMEquipmentCategory = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrievePerformanceSummaryByCMEquipmentCategoryPromise(
      resolve,
      reject,
      payload
    )
  })
}

const retrievePerformanceSummaryByCMEquipmentCM = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrievePerformanceSummaryByCMEquipmentCMPromise(
      resolve,
      reject,
      payload
    )
  })
}

const retrievePerformanceSummaryByCMMonth = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrievePerformanceSummaryByCMMonthPromise(
      resolve,
      reject,
      payload
    )
  })
}

const retrievePerformanceSummaryByCMQuarter = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrievePerformanceSummaryByCMQuarterPromise(
      resolve,
      reject,
      payload
    )
  })
}

const retrievePerformanceSummaryByCMAvgDays = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrievePerformanceSummaryByCMAvgDaysPromise(
      resolve,
      reject,
      payload
    )
  })
}

// Performance Summary CPI
const retrievePerformanceSummaryByCPIHeader = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrievePerformanceSummaryByCPIHeaderPromise(
      resolve,
      reject,
      payload
    )
  })
}

const retrievePerformanceSummaryByCPIData = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrievePerformanceSummaryByCPIDataPromise(
      resolve,
      reject,
      payload
    )
  })
}

const retrievePerformanceSummaryBySystemHeader = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrievePerformanceSummaryBySystemHeaderPromise(
      resolve,
      reject,
      payload
    )
  })
}

const retrievePerformanceSummaryBySystemData = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrievePerformanceSummaryBySystemDataPromise(
      resolve,
      reject,
      payload
    )
  })
}

const retrieveDashboard = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveDashboardPromise(resolve, reject, payload)
  })
}

const retrieveEquipmentStatusHistory = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveEquipmentStatusHistoryPromise(
      resolve,
      reject,
      payload
    )
  })
}

const retrieveNotification = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveNotificationPromise(resolve, reject, payload)
  })
}

const updateNotification = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.updateNotificationPromise(resolve, reject, payload)
  })
}

const login = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.loginPromise(resolve, reject, payload)
  })
}

const retrieveNotificationList = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveNotificationListPromise(resolve, reject, payload)
  })
}
// users
const retrieveUserList = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveUserListPromise(resolve, reject, payload)
  })
}

const addUser = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.addUserPromise(resolve, reject, payload)
  })
}

const deleteUser = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.deleteUserPromise(resolve, reject, payload)
  })
}

const updateUser = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.updateUserPromise(resolve, reject, payload)
  })
}

// location
const retrieveLocationList = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveLocationListPromise(resolve, reject, payload)
  })
}

const addLocation = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.addLocationPromise(resolve, reject, payload)
  })
}

const updateLocation = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.updateLocationPromise(resolve, reject, payload)
  })
}

const deleteLocation = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.deleteLocationPromise(resolve, reject, payload)
  })
}

// capability template
const retrieveCapabilityTemplateList = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveCapabilityTemplateListPromise(
      resolve,
      reject,
      payload
    )
  })
}

const addCapabilityTemplate = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.addCapabilityTemplatePromise(resolve, reject, payload)
  })
}

const deleteCapabilityTemplate = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.deleteCapabilityTemplatePromise(resolve, reject, payload)
  })
}

// calibration tool
const retrieveCalibrationMonthSummary = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveCalibrationMonthSummaryPromise(
      resolve,
      reject,
      payload
    )
  })
}

const retrieveCalibrationMonthEquipmentDetails = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveCalibrationEquipmentDetailsPromise(
      resolve,
      reject,
      payload
    )
  })
}

// lab personnel
const retrieveLabPersonnel = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveLabPersonnelPromise(resolve, reject, payload)
  })
}

const addLabPersonnel = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.addLabPersonnelPromise(resolve, reject, payload)
  })
}

const editLabPersonnel = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.editLabPersonnelPromise(resolve, reject, payload)
  })
}

const deleteLabPersonnel = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.deleteLabPersonnelPromise(resolve, reject, payload)
  })
}

const calculateTurnoverRate = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.calculateTurnoverRatePromise(resolve, reject, payload)
  })
}

// consumable
const retrieveConsumableListByLocation = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveConsumableListByLocationPromise(
      resolve,
      reject,
      payload
    )
  })
}

const retrieveConsumableUsageById = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveConsumableUsageByIdPromise(resolve, reject, payload)
  })
}

const retrieveConsumableById = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.retrieveConsumableByIdPromise(resolve, reject, payload)
  })
}

const addConsumableListByLocation = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.addConsumableListPromise(resolve, reject, payload)
  })
}

const deleteConsumableById = payload => {
  return new Promise(function(resolve, reject) {
    _callBackEndAPI.deleteConsumableByIdPromise(resolve, reject, payload)
  })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  retrieveAllEquipment: retrieveAllEquipment,
  testApiPromise: testApiPromise,
  listFilesPromise: listFilesPromise,
  uploadFilePromise: uploadFilePromise,
  approveFilePromise: approveFilePromise,
  updateEffectiveDatePromise: updateEffectiveDatePromise,
  deleteFilePromise: deleteFilePromise,
  downloadFilePromise: downloadFilePromise,
  updateEquipment: updateEquipment,
  addEquipmentPromise: addEquipmentPromise,
  copyEquipmentPromise: copyEquipmentPromise,
  deleteEquipment: deleteEquipment,
  retrieveOperatorList: retrieveOperatorList,
  addOperator: addOperator,
  deleteOperator: deleteOperator,
  retrieveNotificationList,
  retrieveNotification: retrieveNotification,
  updateNotification: updateNotification,
  retrieveEquipmentStatusHistory: retrieveEquipmentStatusHistory,
  saveNewProjectDataPromise: saveNewProjectDataPromise,
  getAllProjectsPromise: getAllProjectsPromise,
  useNotificationPromise: useNotificationPromise,
  getAllComponentsProjectPromise: getAllComponentsProjectPromise,
  saveSpecDevDataPromise: saveSpecDevDataPromise,
  submitSpecDevDataPromise: submitSpecDevDataPromise,
  generateTemplatePromise: generateTemplatePromise,
  saveProjectCommentsPromise: saveProjectCommentsPromise,
  approveSpecDataPromise: approveSpecDataPromise,
  rejectSpecDataPromise: rejectSpecDataPromise,
  approveCmDataPromise: approveCmDataPromise,
  rejectCmDataPromise: rejectCmDataPromise,
  updateNotificationPromise: updateNotificationPromise,
  onInternalApprovalPromise: onInternalApprovalPromise,
  onRevertPromise: onRevertPromise,
  retrieveCms,
  retrieveEquipment,
  updateBasicInfo,
  addProject,
  retrieveCalibratedEquips,
  retrieveProject,
  archiveProject,
  updateProject,
  retrieveFilesByFileType,
  addAuditReport,
  updateAuditReport,
  retrieveAuditReports,
  deleteAuditReport,
  deleteAuditIssues,
  retrieveEquipmentAuditReports,
  retrieveAuditIssues,
  addAuditIssues,
  editAuditIssues,
  copyAuditIssues,
  addNextAuditDate,
  // Performance
  retrievePerformanceSummaryByCMHeader,
  retrievePerformanceSummaryByCMEquipmentCategory,
  retrievePerformanceSummaryByCMEquipmentCM,
  retrievePerformanceSummaryByCMMonth,
  retrievePerformanceSummaryByCMQuarter,
  retrievePerformanceSummaryByCMAvgDays,
  retrievePerformanceSummaryByCPIHeader,
  retrievePerformanceSummaryByCPIData,
  retrievePerformanceSummaryBySystemHeader,
  retrievePerformanceSummaryBySystemData,
  retrieveDashboard,
  login,
  // equipment detail
  retrieveEquipmentFiles,
  retrieveConsumable,
  addConsumable,
  deleteConsumable,
  // users
  retrieveUserList: retrieveUserList,
  addUser: addUser,
  deleteUser: deleteUser,
  updateUser,
  // test location
  retrieveLocationList: retrieveLocationList,
  addLocation: addLocation,
  updateLocation,
  deleteLocation: deleteLocation,
  // capability template
  retrieveCapabilityTemplateList: retrieveCapabilityTemplateList,
  addCapabilityTemplate: addCapabilityTemplate,
  deleteCapabilityTemplate: deleteCapabilityTemplate,
  // calibration tool
  retrieveCalibrationMonthSummary,
  retrieveCalibrationMonthEquipmentDetails,
  retrieveLabPersonnel,
  addLabPersonnel,
  editLabPersonnel,
  deleteLabPersonnel,
  calculateTurnoverRate,
  // consumable
  retrieveConsumableListByLocation,
  addConsumableListByLocation,
  deleteConsumableById,
  retrieveConsumableUsageById,
  retrieveConsumableById,
}
