/**
 * The hero/splash shown at the top of the landing page. Spaceship included.
 */

import React, { useState, useEffect, useCallback } from "react"

import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import Heading from "@amzn/meridian/heading"
import { css } from "emotion"

import { useDispatch, useSelector } from "react-redux"

import PageLayout from "../../components/app/page-layout"
import { Select, SelectOption } from "../../components/app/form-fields"
import {
  retrieveNotification,
  updateNotification,
} from "../../redux/settings/action"
import { frequencyOptions } from "../../data/options"

const rowStyles = css({
  display: "-webkit-inline-box",
})

const Settings = () => {
  const [fileExpiredFrequency, setFileExpiredFrequency] = useState()
  const [fileExpiredDays, setFileExpiredDays] = useState()
  const [
    equipmentDisqualifedFrequency,
    setEquipmentDisqualifedFrequency,
  ] = useState()
  const [pendingItemsDays, setPendingItemsDays] = useState()
  const [pendingItemsFrequency, setPendingItemsFrequency] = useState()

  const { notificationSettings, isLoading } = useSelector(
    state => state.settingsReducer
  )

  const reduxDispatch = useDispatch()

  const setInitialState = useCallback(data => {
    if (data?.length > 0) {
      setFileExpiredFrequency(data[0].frequency)
      setFileExpiredDays(-data[0].days)
      setEquipmentDisqualifedFrequency(data[1].frequency)
      setPendingItemsDays(data[2].days)
      setPendingItemsFrequency(data[2].frequency)
    }
  }, [])

  useEffect(() => {
    if (
      Number.isInteger(fileExpiredDays) &&
      Number.isInteger(pendingItemsDays)
    ) {
      reduxDispatch(
        updateNotification({
          listNotificationRequest: [
            {
              days: -fileExpiredDays,
              frequency: fileExpiredFrequency,
              id: 1,
            },
            {
              days: 0,
              frequency: equipmentDisqualifedFrequency,
              id: 2,
            },
            {
              days: pendingItemsDays,
              frequency: pendingItemsFrequency,
              id: 3,
            },
          ],
        })
      )
    }
  }, [
    reduxDispatch,
    fileExpiredDays,
    fileExpiredFrequency,
    equipmentDisqualifedFrequency,
    pendingItemsDays,
    pendingItemsFrequency,
  ])

  useEffect(() => {
    // reduxDispatch(retrieveAllEquipment(props.projectSelect));
    setInitialState(notificationSettings)
  }, [notificationSettings, setInitialState])

  useEffect(() => {
    reduxDispatch(retrieveNotification())
  }, [reduxDispatch])

  return (
    <PageLayout
      spacing="large"
      alignmentHorizontal={"start"}
      title="Settings"
      width={1200}
      loading={isLoading}
    >
      <Column spacing="xlarge">
        <Heading level={3}>Email Notifications</Heading>
      </Column>
      <Column spacing="medium">
        <Heading level={5}>● File is going to expire</Heading>
        <Row className={rowStyles}>
          <Text>{"Email notifications will be sent"}</Text>
          <input
            type="number"
            onChange={e => {
              setFileExpiredDays(Number(e.currentTarget.value))
            }}
            value={fileExpiredDays}
            min="0"
            max="100"
          />
          <Text>
            days in advance. Before it is solved, email notification frequency
            is
          </Text>
          <Select
            value={fileExpiredFrequency}
            onChange={value => {
              setFileExpiredFrequency(value)
            }}
            minWidth={120}
            size={"small"}
          >
            {frequencyOptions.map(o => (
              <SelectOption key={o.value} value={o.value} label={o.label} />
            ))}
          </Select>
        </Row>

        <Heading level={5}>● Equipment is disqualified</Heading>
        <Row className={rowStyles}>
          <Text>
            Email notifications will be sent right away. Before it is solved,
            email notification frequency is
          </Text>
          <Select
            value={equipmentDisqualifedFrequency}
            onChange={setEquipmentDisqualifedFrequency}
            minWidth={120}
            size={"small"}
          >
            {frequencyOptions.map(o => (
              <SelectOption key={o.value} value={o.value} label={o.label} />
            ))}
          </Select>
        </Row>

        <Heading level={5}>● There are pending items</Heading>
        <Row className={rowStyles}>
          <Text>
            {"Email notifications will be sent when the item is pending for"}
          </Text>
          <input
            type="number"
            onChange={e => setPendingItemsDays(Number(e.currentTarget.value))}
            value={pendingItemsDays}
            min="0"
            max="100"
          />
          <Text>
            days. Before it is solved, email notification frequency is
          </Text>
          <Select
            value={pendingItemsFrequency}
            onChange={setPendingItemsFrequency}
            minWidth={120}
            size={"small"}
          >
            {frequencyOptions.map(o => (
              <SelectOption key={o.value} value={o.value} label={o.label} />
            ))}
          </Select>
        </Row>
      </Column>
      <Column spacing="large"></Column>
    </PageLayout>
  )
}

export default Settings
