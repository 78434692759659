import { put, takeLatest } from "redux-saga/effects"
import APIPromises from "../../../util/APIPromises"
import {
  retrieveAuditReportSuccess,
  retrieveAuditReportFailure,
  retrieveAuditIssuesSuccess,
  retrieveAuditIssuesFailure,
  retrieveEquipmentSuccess,
  retrieveEquipmentFailure,
  updateBasicInfoSuccess,
  updateBasicInfoFailure,
  retrieveEquipmentFilesSuccess,
  retrieveEquipmentFilesFailure,
  retrieveEquipmentStatusHistorySuccess,
  retrieveEquipmentStatusHistoryFailure,
  retrieveConsumableSuccess,
  retrieveConsumableFailure,
  addConsumableSuccess,
  addConsumableFailure,
  deleteConsumableSuccess,
  deleteConsumableFailure,
  retrieveOperatorListSuccess,
  retrieveOperatorListFailure,
  addOperatorSuccess,
  addOperatorFailure,
  deleteOperatorSuccess,
  deleteOperatorFailure,
  SUFIX,
} from "../action"

function* retrieveAuditReport(req) {
  try {
    const response = yield APIPromises.retrieveEquipmentAuditReports(
      req.payload
    )
    const allAuditData = response.data
    yield put(retrieveAuditReportSuccess(allAuditData))
  } catch (error) {
    yield put(retrieveAuditReportFailure(error))
  }
}

export function* retrieveEquipmentAuditReportWatcher() {
  yield takeLatest("RETRIEVE_AUDIT_REPORT" + SUFIX, retrieveAuditReport)
}

function* retrieveAuditIssues(req) {
  try {
    const response = yield APIPromises.retrieveEquipmentAuditReports(
      req.payload
    )
    const auditIssues = response.data.retrieveIssuesByEquipmentResponse
    yield put(retrieveAuditIssuesSuccess(auditIssues))
  } catch (error) {
    yield put(retrieveAuditIssuesFailure(error))
  }
}

export function* retrieveEquipmentAuditIssuesWatcher() {
  yield takeLatest("RETRIEVE_AUDIT_ISSUES" + SUFIX, retrieveAuditIssues)
}

function* retrieveEquipment(req) {
  try {
    const response = yield APIPromises.retrieveEquipment(req.payload)
    const equipmentBasicInfo = response.data.equipBasicInfoResponse
    yield put(retrieveEquipmentSuccess(equipmentBasicInfo))
  } catch (error) {
    yield put(retrieveEquipmentFailure(error))
  }
}

export function* retrieveAuditEquipmentWatcher() {
  yield takeLatest("RETRIEVE_EQUIPMENT_AUDIT" + SUFIX, retrieveEquipment)
}

function* updateBasicInfo(req) {
  try {
    const res = yield APIPromises.updateBasicInfo(req.payload)
    if (res.data && res.data.isSuccess === false) {
      yield put(updateBasicInfoFailure())
    } else {
      yield put({
        type: "RETRIEVE_EQUIPMENT_AUDIT" + SUFIX,
        payload: {
          cm: req.payload.cm,
          test_location: req.payload.testLocation,
          equipment_id: req.payload.equipmentId,
        },
      })
      yield put(updateBasicInfoSuccess())
    }
  } catch (error) {
    yield put(updateBasicInfoFailure(error))
  }
}

export function* updateBasicInfoWatcher() {
  yield takeLatest("UPDATE_BASIC_INFO" + SUFIX, updateBasicInfo)
}

function* retrieveEquipmentStatusHistory(req) {
  try {
    const response = yield APIPromises.retrieveEquipmentStatusHistory(
      req.payload
    )
    const history = response.data
    console.warn("retrieveEquipmentStatusHistory response", response.data)
    yield put(retrieveEquipmentStatusHistorySuccess(history))
  } catch (error) {
    yield put(retrieveEquipmentStatusHistoryFailure(error))
  }
}

export function* retrieveEquipmentStatusHistoryWatcher() {
  yield takeLatest(
    "RETRIEVE_STATUS_HISTORY" + SUFIX,
    retrieveEquipmentStatusHistory
  )
}

function* retrieveEquipmentFiles(req) {
  try {
    const response = yield APIPromises.retrieveEquipmentFiles(req.payload)
    const files = response.data.retrieveFilesByEquipmentResponse
    console.warn("retrieveEquipmentFiles response", response.data)
    yield put(retrieveEquipmentFilesSuccess(files))
  } catch (error) {
    yield put(retrieveEquipmentFilesFailure(error))
  }
}

export function* retrieveEquipmentFilesWatcher() {
  yield takeLatest("RETRIEVE_EQUIPMENT_FILES" + SUFIX, retrieveEquipmentFiles)
}

function* retrieveConsumable(req) {
  try {
    const response = yield APIPromises.retrieveConsumable(req.payload)
    const consumable = response.data.ConsumableByEquipmentList
    console.warn("retrieveConsumable response", response.data)
    yield put(retrieveConsumableSuccess(consumable))
  } catch (error) {
    yield put(retrieveConsumableFailure(error))
  }
}

export function* retrieveConsumableWatcher() {
  yield takeLatest("RETRIEVE_CONSUMABLE" + SUFIX, retrieveConsumable)
}

function* addConsumable(req) {
  try {
    const res = yield APIPromises.addConsumable(req.payload)
    if (res.data && res.data.isSuccess === false) {
      yield put(addConsumableFailure())
    } else {
      yield put({
        type: "RETRIEVE_CONSUMABLE" + SUFIX,
        payload: {
          // cm: req.payload.cm,
          // test_location: req.payload.testLocation,
          equipment_id: req.payload.equipmentId,
        },
      })
      yield put(addConsumableSuccess())
    }
  } catch (error) {
    yield put(addConsumableFailure(error))
  }
}

export function* addConsumableWatcher() {
  yield takeLatest("ADD_CONSUMABLE" + SUFIX, addConsumable)
}

function* deleteConsumable(req) {
  try {
    const res = yield APIPromises.deleteConsumable(req.payload)
    if (res.data && res.data.isSuccess === false) {
      yield put(deleteConsumableFailure())
    } else {
      yield put({
        type: "RETRIEVE_CONSUMABLE" + SUFIX,
        payload: {
          // cm: req.payload.cm,
          // test_location: req.payload.testLocation,
          equipment_id: req.payload.equipmentId,
        },
      })
      yield put(deleteConsumableSuccess())
    }
  } catch (error) {
    yield put(deleteConsumableFailure(error))
  }
}

export function* deleteConsumableWatcher() {
  yield takeLatest("DELETE_CONSUMABLE" + SUFIX, deleteConsumable)
}

// Operator
function* retrieveOperatorList(req) {
  try {
    // eslint-disable-next-line no-unused-vars
    const res = yield APIPromises.retrieveOperatorList(req.payload)
    const list = res.data.operators || []
    if (res.data && res.data.isSuccess === false) {
      yield put(retrieveOperatorListFailure())
    } else {
      yield put(retrieveOperatorListSuccess(list))
    }
  } catch (error) {
    yield put(retrieveOperatorListFailure(error))
  }
}

export function* retrieveOperatorListWatcher() {
  yield takeLatest("RETRIEVE_OPERATOR_LIST", retrieveOperatorList)
}

function* addOperator(req) {
  try {
    let res = yield APIPromises.addOperator(req.payload)

    if (res.data && res.data.isSuccess === false) {
      yield put(addOperatorFailure())
    } else {
      yield put({
        type: "RETRIEVE_OPERATOR_LIST",
        payload: {
          equipmentId: req.payload.equipmentId,
        },
      })
      yield put(addOperatorSuccess(req.payload))
    }
  } catch (error) {
    yield put(addOperatorFailure(error))
  }
}

export function* addOperatorWatcher() {
  yield takeLatest("ADD_OPERATOR", addOperator)
}

function* deleteOperator(req) {
  try {
    let res = yield APIPromises.deleteOperator(req.payload)

    if (res.data && res.data.isSuccess === false) {
      yield put(deleteOperatorFailure())
    } else {
      yield put({
        type: "RETRIEVE_OPERATOR_LIST",
        payload: {
          equipmentId: req.payload.equipmentId,
        },
      })
      yield put(deleteOperatorSuccess(req.payload))
    }
  } catch (error) {
    yield put(deleteOperatorFailure(error))
  }
}

export function* deleteOperatorWatcher() {
  yield takeLatest("DELETE_OPERATOR", deleteOperator)
}
