import Modal, { ModalFooter } from "@amzn/meridian/modal"
import React, { useEffect, useState } from "react"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import {
  FormDatePicker,
  FormInput,
  FormTextArea,
} from "../../../components/app"

const AddConsumableModal = ({
  addConsumableModalOpen,
  setAddConsumableModalOpen,
  callback,
  data = {},
  consumableId,
}) => {
  console.log(data)
  const [name, setName] = useState()
  const [description, setDescription] = useState()
  const [purchaseNum, setPurchaseNum] = useState()
  const [unit, setUnit] = useState()
  const [purchaseDate, setPurchaseDate] = useState()
  const [expireDate, setExpireDate] = useState()

  useEffect(() => {
    setName(data.name)
    setDescription(data.description)
    setPurchaseNum(data.purchaseNum || 0)
    setUnit(data.unit)
    setPurchaseDate(data.purchaseDate)
    setExpireDate(data.expireDate)
  }, [
    data.description,
    data.expireDate,
    data.name,
    data.purchaseDate,
    data.purchaseNum,
    data.unit,
  ])

  const resetState = () => {
    setName()
    setDescription()
    setPurchaseNum(0)
    setUnit()
    setPurchaseDate()
    setExpireDate()
    setAddConsumableModalOpen(false)
  }
  return (
    <form>
      <Modal
        title={data ? "Edit" : "Add"}
        open={addConsumableModalOpen}
        onClose={() => resetState()}
        scrollContainer="viewport"
        closeLabel="Close"
        describedById="modal-description"
        bodySpacingInset="medium"
      >
        <form>
          <FormInput value={name} setValue={setName} label={"Item Name"} />
          <FormTextArea
            value={description}
            setValue={setDescription}
            label={"Description"}
          />
          <FormInput
            type={"number"}
            value={purchaseNum}
            setValue={setPurchaseNum}
            label={"Purchase Number"}
          />
          <FormInput value={unit} setValue={setUnit} label={"Unit"} />
          <FormDatePicker
            value={purchaseDate}
            setValue={setPurchaseDate}
            label="Purchase Date"
          />
          <FormDatePicker
            value={expireDate}
            setValue={setExpireDate}
            label="Expire Date"
          />
        </form>
        <ModalFooter>
          <Row alignmentHorizontal="right" widths="fit">
            <Button
              disabled={
                !name || !purchaseNum || !unit || !purchaseDate || !expireDate
              }
              type="primary"
              size="small"
              onClick={() => {
                callback(
                  consumableId,
                  name,
                  description,
                  parseInt(purchaseNum),
                  unit,
                  purchaseDate,
                  expireDate
                )
                resetState()
              }}
            >
              Confirm
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </form>
  )
}

export default AddConsumableModal
