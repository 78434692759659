import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import Row from "@amzn/meridian/row"
// import DateRangePicker from "@amzn/meridian/date-range-picker"
// import Checkbox from "@amzn/meridian/checkbox";
import Button from "@amzn/meridian/button"
import Column from "@amzn/meridian/column"
import Text from "@amzn/meridian/text"
import Icon from "@amzn/meridian/icon"
import Select, { SelectOptionGroup, SelectOption } from "@amzn/meridian/select"
import { DatePicker } from "../../../components/app/form-fields"
import { FormDatePicker, FormInput, FormSelect } from "../../../components/app"
import FormTextArea from "../../../components/app/form-text-area"
import { retrieveAllEquipment } from "../../../redux/equipment/action"
import { addAuditReport, updateAuditReport } from "../../../redux/audit/action"
import { openToast } from "../../../api/toasts"
import { auditReportObj, FAILURE_TIMEOUT_IN_MS } from "../../../data/constants"

const AuditReportFormModal = props => {
  const {
    auditReportModalOpen,
    setAuditReportModalOpen,
    cm,
    equipment,
    auditReportIndex,
    isEdit,
    auditReportData,
    setAuditReportData,
  } = props

  const { locationList } = useSelector(state => state.locationReducer)
  const { userList } = useSelector(state => state.usersReducer)
  const { auditReportList, isLoading } = useSelector(
    state => state.auditReducer
  )

  const [cmIndex, setCmIndex] = useState(cm)
  const [isFormDisabled, setIsFormDisabled] = useState(true)
  const [selectedEquipments, setSelectedEquipments] = useState([])
  // const [dateRange, setDateRange] = useState(["", ""])
  const [startDateRange, setStartDateRange] = useState("")
  const [endDateRange, setEndDateRange] = useState("")

  const [auditors, setAuditors] = useState([])

  // const [auditReportData, setAuditReportData] = useState()
  const { username } = useSelector(state => state.userReducer)

  const reduxDispatch = useDispatch()

  useEffect(() => {
    if (auditReportModalOpen && isEdit) {
      setStartDateRange(auditReportData.startDate)
      setEndDateRange(auditReportData.endDate)
      setAuditors(auditReportData.auditorList.map(x => x.id))
      setSelectedEquipments(
        auditReportData.equipmentsWithIssue.map(eq => eq.equipmentId)
      )
      isAddAuditReportDisabled()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditReportData, auditReportModalOpen])

  useEffect(() => {
    if (auditReportModalOpen && auditReportData?.reportName) {
      isAddAuditReportDisabled()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateRange, endDateRange])

  const parseEquipmentList = () => {
    return selectedEquipments.map(selectedEquipment => {
      return { equipmentId: selectedEquipment }
    })
  }
  const isAddAuditReportDisabled = () => {
    if (
      auditReportData.reportName == null ||
      auditReportData.reportName === "" ||
      startDateRange == null ||
      startDateRange === "" ||
      endDateRange == null ||
      endDateRange === "" ||
      auditReportData.purpose === "" ||
      auditReportData.purpose == null
    ) {
      setIsFormDisabled(true)
      return
    }
    setIsFormDisabled(false)
  }

  const onClickAddAuditReport = () => {
    let payload = auditReportData

    payload.cm = locationList[cmIndex]?.cm
    payload.testLocation = locationList[cmIndex]?.cmLocation
    payload.equipmentsWithIssue = parseEquipmentList()
    payload.auditorList = auditors.map(v => ({ id: v }))
    payload.startDate = startDateRange
    payload.endDate = endDateRange

    if (
      payload.reportName == null ||
      payload.reportName === "" ||
      payload.startDate == null ||
      payload.startDate === "" ||
      payload.endDate == null ||
      payload.endDate === "" ||
      payload.purpose === "" ||
      payload.purpose == null
    ) {
      openToast({
        type: "error",
        title: "Error",
        message: "Please Add Required Fields",
        timeout: FAILURE_TIMEOUT_IN_MS,
      })
      return
    }

    if (isEdit) {
      reduxDispatch(updateAuditReport(payload))
    } else {
      reduxDispatch(addAuditReport(payload))
    }
    setAuditReportModalOpen(false)
  }

  return (
    <Modal
      title={isEdit ? "Edit Audit Report" : "Add Audit Report"}
      open={auditReportModalOpen}
      // onClose={resetState}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      bodySpacingInset="400 500 500 500"
    >
      <form>
        <Row spacingInset="small none">
          <Column minWidth="50px">
            <FormInput
              value={auditReportData?.reportName}
              setValue={name =>
                setAuditReportData({ ...auditReportData, reportName: name })
              }
              label={"Audit Report Name"}
            />
          </Column>
        </Row>
        <Row>
          <Column minWidth="150px">
            <Text tag="label" type="b200" color="primary" htmlFor="my-input">
              {"Audit Test Location"}
            </Text>
          </Column>
          <Column minWidth="150px">
            <Select
              value={cmIndex}
              onChange={value => setCmIndex(value)}
              // placeholder="Select Audit Test location"
              width={250}
              size="small"
              disabled={true}
            >
              {locationList.map((x, index) => (
                <SelectOption
                  key={index}
                  value={index}
                  label={`${x.cm}-${x.cmLocation}`}
                />
              ))}
            </Select>
          </Column>
        </Row>
        <Row spacingInset="small none">
          <Column minWidth="150px">
            <FormInput
              value={auditReportData?.requestBy}
              setValue={requestBy =>
                setAuditReportData({ ...auditReportData, requestBy: requestBy })
              }
              label={"Request By"}
            />
          </Column>
        </Row>
        <Row>
          <Column minWidth="150px">
            <FormTextArea
              value={auditReportData?.purpose}
              setValue={purpose =>
                setAuditReportData({ ...auditReportData, purpose: purpose })
              }
              label={"Purpose"}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <FormDatePicker
              value={startDateRange}
              setValue={setStartDateRange}
              label={"Start Date"}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <FormDatePicker
              value={endDateRange}
              setValue={setEndDateRange}
              label={"End Date"}
            />
          </Column>
        </Row>
        <Row spacingInset="small none">
          <Column minWidth="150px">
            <Text
              tag="label"
              type="b200"
              color="primary"
              htmlFor="auditor-input"
            >
              {"Auditors"}
            </Text>
          </Column>
          <Column minWidth="150px">
            <Select
              value={auditors}
              onChange={setAuditors}
              width={250}
              size="small"
            >
              {(userList || [])
                .filter(x => !x.isCmUser)
                .map((x, index) => (
                  <SelectOption
                    key={index}
                    value={x.id}
                    label={x.name || x.userName}
                  />
                ))}
            </Select>
          </Column>
        </Row>
        <Row spacingInset="small none">
          <Column minWidth="150px">
            <Text tag="label" type="b200" color="primary" htmlFor="my-input">
              {"Involved Equipments"}
            </Text>
          </Column>
          <Column minWidth="150px">
            <Select
              value={selectedEquipments}
              onChange={val => setSelectedEquipments(val)}
              minWidth={250}
              size="small"
            >
              {equipment?.map((x, index) => (
                <SelectOption
                  key={index}
                  value={x.equipmentId}
                  label={`${x.equipmentName}`}
                />
              ))}
            </Select>
          </Column>
        </Row>
      </form>
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          <Button
            type="primary"
            size="small"
            onClick={onClickAddAuditReport}
            disabled={isFormDisabled}
          >
            {isEdit ? "Save" : "Add"}
          </Button>
          <Button
            type="secondary"
            size="small"
            onClick={() => setAuditReportModalOpen(false)}
          >
            Cancel
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default AuditReportFormModal
