import { all, fork } from "redux-saga/effects"
import userSaga, { notificationActionWatcher } from "./userInfo/saga"
import {
  retrieveEquipmentWatcher,
  addProjectWatcher,
  retrieveCalibratedEquipsWatcher,
  retrieveProjectWatcher,
  retrieveAssessmentFileWatcher,
  retrieveSopFileWatcher,
  retrieveFilesByTrainingWatcher,
  retrieveProjectFilesByTypeWatcher,
  archiveProjectWatcher,
  updateProjectWatcher,
} from "./project/saga"
import {
  uploadFileWatcher,
  deleteFileWatcher,
  listFilesWatcher,
  approveFileWatcher,
  downloadFileWatcher,
  updateEffectiveDateWatcher,
  retrieveProjectListWatcher,
} from "./fileLibrary/saga"
import {
  retrieveAuditReportWatcher,
  retrieveAuditIssuesWatcher,
  retrieveProjectAuditWatcher,
  addAuditReportWatcher,
  updateAuditReportWatcher,
  deleteAuditReportWatcher,
  editAuditIssuesWatcher,
  addAuditIssuesWatcher,
  addNextAuditDateWatcher,
  deleteAuditIssuesWatcher,
} from "./audit/saga"
import {
  addEquipmentWatcher,
  copyEquipmentWatcher,
  updateEquipmentWatcher,
  deleteEquipmentWatcher,
  retrieveAllEquipmentWatcher,
  retrieveEquipSopFileWatcher,
  retrieveEquipFilesByChecklistWatcher,
  retrieveEquipFilesByCalibrationReportWatcher,
  retrieveEquipmentFilesByTypeWatcher,
} from "./equipment/saga"

import {
  retrieveEquipmentAuditReportWatcher,
  retrieveEquipmentAuditIssuesWatcher,
  retrieveAuditEquipmentWatcher,
  retrieveEquipmentStatusHistoryWatcher,
  updateBasicInfoWatcher,
  retrieveEquipmentFilesWatcher,
  retrieveConsumableWatcher,
  addConsumableWatcher,
  deleteConsumableWatcher,
  retrieveOperatorListWatcher,
  addOperatorWatcher,
  deleteOperatorWatcher,
} from "./equipmentDetail/saga"

import { retrieveCmsWatcher } from "./common/saga"
import { retrieveDashboardWatcher } from "./dashboard/saga"
import {
  retrieveNotificationWatcher,
  updateNotificationWatcher,
} from "./settings/saga"

import {
  retrieveUserListWatcher,
  addUserWatcher,
  deleteUserWatcher,
  updateUserWatcher,
} from "./users/saga"

import {
  retrieveLocationListWatcher,
  addLocationWatcher,
  deleteLocationWatcher,
  updateLocationWatcher,
} from "./testLocation/saga"

import {
  retrieveCapabilityTemplateListWatcher,
  addCapabilityTemplateWatcher,
  deleteCapabilityTemplateWatcher,
} from "./capabilityTemplate/saga"
import {
  retrieveCalibrationMonthEquipmentDetailsWatcher,
  retrieveCalibrationMonthSummaryWatcher,
} from "./calibrationTool/saga"
import {
  retrievePerformanceSummaryByCMAvgDaysWatcher,
  retrievePerformanceSummaryByCMEquipmentCategoryWatcher,
  retrievePerformanceSummaryByCMEquipmentCMWatcher,
  retrievePerformanceSummaryByCMHeaderWatcher,
  retrievePerformanceSummaryByCMMonthWatcher,
  retrievePerformanceSummaryByCMQuarterWatcher,
  retrievePerformanceSummaryByCPIDataWatcher,
  retrievePerformanceSummaryByCPIHeaderWatcher,
  retrievePerformanceSummaryBySystemDataWatcher,
  retrievePerformanceSummaryBySystemHeaderWatcher,
} from "./performanceSummary/saga"
import {
  addLabPersonnelWatcher,
  editLabPersonnelWatcher,
  calculateTurnoverRateWatcher,
  deleteLabPersonnelWatcher,
  retrieveLabPersonnelWatcher,
} from "./labPersonnel/saga"
import {
  addConsumableListByLocationWatcher,
  addConsumableUsageRecordWatcher,
  deleteConsumableByIdWatcher,
  deleteConsumableUsageRecordWatcher,
  retrieveConsumableByIdWatcher,
  retrieveConsumableListByLocationWatcher,
  retrieveConsumableUsageByIdWatcher,
} from "./consumable/saga"

export default function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(notificationActionWatcher),
    fork(retrieveCmsWatcher),
    // fileLibrary
    fork(approveFileWatcher),
    fork(uploadFileWatcher),
    fork(deleteFileWatcher),
    fork(downloadFileWatcher),
    fork(listFilesWatcher),
    fork(updateEffectiveDateWatcher),
    fork(retrieveProjectListWatcher),
    // equipment
    fork(retrieveEquipmentWatcher),
    fork(retrieveAllEquipmentWatcher),
    fork(copyEquipmentWatcher),
    fork(addEquipmentWatcher),
    fork(updateEquipmentWatcher),
    fork(deleteEquipmentWatcher),
    fork(retrieveEquipSopFileWatcher),
    fork(retrieveEquipFilesByChecklistWatcher),
    fork(retrieveEquipFilesByCalibrationReportWatcher),
    fork(retrieveEquipmentFilesByTypeWatcher),
    // project
    fork(addProjectWatcher),
    fork(retrieveCalibratedEquipsWatcher),
    fork(retrieveProjectWatcher),
    fork(retrieveSopFileWatcher),
    fork(retrieveFilesByTrainingWatcher),
    fork(retrieveProjectFilesByTypeWatcher),
    fork(archiveProjectWatcher),
    fork(updateProjectWatcher),
    fork(retrieveAssessmentFileWatcher),
    // audit
    fork(addAuditReportWatcher),
    fork(updateAuditReportWatcher),
    fork(retrieveAuditReportWatcher),
    fork(retrieveAuditIssuesWatcher),
    fork(retrieveProjectAuditWatcher),
    fork(deleteAuditReportWatcher),
    fork(addAuditIssuesWatcher),
    fork(editAuditIssuesWatcher),
    fork(addNextAuditDateWatcher),
    fork(deleteAuditIssuesWatcher),
    // Performance Summary
    fork(retrievePerformanceSummaryByCMHeaderWatcher),
    fork(retrievePerformanceSummaryByCMEquipmentCategoryWatcher),
    fork(retrievePerformanceSummaryByCMEquipmentCMWatcher),
    fork(retrievePerformanceSummaryByCMMonthWatcher),
    fork(retrievePerformanceSummaryByCMQuarterWatcher),
    fork(retrievePerformanceSummaryByCMAvgDaysWatcher),
    fork(retrievePerformanceSummaryByCPIHeaderWatcher),
    fork(retrievePerformanceSummaryByCPIDataWatcher),
    fork(retrievePerformanceSummaryBySystemHeaderWatcher),
    fork(retrievePerformanceSummaryBySystemDataWatcher),
    // equipment detail
    fork(retrieveEquipmentAuditReportWatcher),
    fork(retrieveEquipmentAuditIssuesWatcher),
    fork(retrieveAuditEquipmentWatcher),
    fork(retrieveEquipmentStatusHistoryWatcher),
    fork(updateBasicInfoWatcher),
    fork(retrieveEquipmentFilesWatcher),
    fork(retrieveConsumableWatcher),
    fork(addConsumableWatcher),
    fork(deleteConsumableWatcher),
    fork(retrieveOperatorListWatcher),
    fork(addOperatorWatcher),
    fork(deleteOperatorWatcher),
    // dashboard
    fork(retrieveDashboardWatcher),
    // settings
    fork(retrieveNotificationWatcher),
    fork(updateNotificationWatcher),
    // users
    fork(retrieveUserListWatcher),
    fork(addUserWatcher),
    fork(deleteUserWatcher),
    fork(updateUserWatcher),
    // test location
    fork(retrieveLocationListWatcher),
    fork(addLocationWatcher),
    fork(deleteLocationWatcher),
    fork(updateLocationWatcher),
    // capability template
    fork(retrieveCapabilityTemplateListWatcher),
    fork(addCapabilityTemplateWatcher),
    fork(deleteCapabilityTemplateWatcher),
    // calibration tool
    fork(retrieveCalibrationMonthSummaryWatcher),
    fork(retrieveCalibrationMonthEquipmentDetailsWatcher),
    fork(retrieveLabPersonnelWatcher),
    fork(addLabPersonnelWatcher),
    fork(editLabPersonnelWatcher),
    fork(deleteLabPersonnelWatcher),
    fork(calculateTurnoverRateWatcher),
    fork(retrieveConsumableListByLocationWatcher),
    fork(addConsumableListByLocationWatcher),
    fork(deleteConsumableByIdWatcher),
    fork(retrieveConsumableUsageByIdWatcher),
    fork(addConsumableUsageRecordWatcher),
    fork(deleteConsumableUsageRecordWatcher),
    fork(retrieveConsumableByIdWatcher),
  ])
}
